<div class="row">
    <div class="col-12">
        <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
        <h4 class="titulo-wizard">Conoce las competencias de un estudiante exitoso del mundo</h4>
        <p>Sabías que las instituciones internacionales valoran ciertas características en los estudiantes, que les permitirán a los postulados desarrollarse de manera exitosa en un ámbito académico y laboral internacional.</p>
        <hr class="hrLine" />
    </div>
    <div class="col-12">
        <p class="colorVerde">
            Dependiendo del tipo de institución necesitarás unas más que otras. ¡Conócelas todas!
        </p>
    </div>
  </div>
  <div class = "row">
    <div class = "col-6">
        <div class="card">
            <div class="card-body" id="card1">
                <div class="row">
                  <div class="col-6">
                      <div class="imgPre imgPre1"></div>
                  </div>
                    <div class="col-6">
                        <h5 class="text-left">Liderazgo</h5>
                        <em class="fas fa-solid fa-plus floatR" id = "1" (click)="verdetalle(1)"></em>
                    </div>
                </div>
            </div>
            <!-- <div class="card-body flip-box-back">
                <div class="row">
                    <div class="col-12">
                        <p>
                          El perfil de líder es muy demandado a nivel internacional y es una de las competencias que más buscan las universidades para aceptar sus candidatos. <br><br>
        ¿Qué es el liderazgo internacional y por qué es importante? <br><br>
        Los lideres, influencian, saben trabajar en equipo, integran y desarrollan ideas. <br><br>
        Es una cualidad que da garantías de una persona con iniciativa, responsable, capaz de proponerse objetivos, aparte de ser generalmente muy optimistas. Los líderes tienen una alta influencia en sí mismos y en otros, lo que lleva a mejores y más rápidos resultados. Sin embargo, un perfil de líder nos se crea de la noche a la mañana.

                        </p>
                    </div>
                </div>
            </div> -->
          </div>

          <div class="card">
            <div class="card-body" id="card2">
                <div class="row">
                    <div class="col-6">
                        <div class="imgPre imgPre2"></div>
                    </div>
                    <div class="col-6">
                        <h5 class="text-left">Logro y Acción</h5>
                        <em class="fas fa-solid fa-plus floatR" id = "2" (click)="verdetalle(2)"></em>
                    </div>
                </div>
            </div>
            <!-- <div class="card-body flip-box-back">
                <div class="row">
                    <div class="col-12">
                        <p>
                            Recurrentemente las personas suelen hablar más de lo que ejecutan. Un perfil que tiene esta competencia tiene el poder de llevar sus planes a la realidad. <br><br>
                            ¿Qué significa trabajo y acción y por qué es importante? <br> <br>
                             Si eres el tipo de persona se suma a iniciativas y ve en el esfuerzo y la dedicación valores indispensables para lograr sus metas, seguramente esta competencia es la tuya. <br><br>
                            La parte académica es importante, pero no siempre es un indicativo de éxito.  Para una universidad es muy importante contar con personas comprometidas que no abandonen sus objetivos.
                        </p>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="card">
            <div class="card-body" id="card3">
                <div class="row">
                    <div class="col-6">
                        <div class="imgPre imgPre3"></div>
                    </div>
                    <div class="col-6">
                        <h5 class="text-left">Impacto Social</h5>
                        <em class="fas fa-solid fa-plus floatR" id = "3" (click)="verdetalle(3)"></em>
                    </div>
                </div>
            </div>
            <!-- <div class="card-body flip-box-back">
                <div class="row">
                    <div class="col-12">
                        <p>
                            Ser ciudadano del mundo implica también tener interés por lo que ocurre en tu entorno; ser ajeno a tu realidad es imposible para alguien que busca tener impacto social. <br><br>
                            ¿Qué significa Impacto Social y Ambienta y por qué es importante? <br> <br>
                            La consciencia social es la base para una sociedad más justa y libre y para contribuir a la construcción de un mejor futuro. Pero no basta con conocer las problemáticas. Hay personas que cuentan con iniciativas para cambiar realidades. <br><br>
                            El impacto social es una competencia que buscan las universidades para formar líderes íntegros.

                        </p>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="card">
            <div class="card-body" id="card4">
                <div class="row">
                    <div class="col-6">
                        <div class="imgPre imgPre4"></div>
                    </div>
                    <div class="col-6">
                        <h5 class="text-left">Competencia Académica</h5>
                        <em class="fas fa-solid fa-plus floatR" id = "4" (click)="verdetalle(4)"></em>
                    </div>
                </div>
            </div>
            <!-- <div class="card-body flip-box-back">
                <div class="row">
                    <div class="col-12">
                        <p>
                            A nivel internacional la competencia académica permite a las universidades del mundo conocer tu capacidad de generar resultados de manera consistente. Si no los tienes no te preocupes, no solo lo académico es importante para un desarrollo académico exitoso. <br><br>

                            ¿Qué significa Competencia Ademica? <br><br>

                           Los hábitos nos definen, eso que hacemos constantemente muestra quienes somos:  si escribes todos los días eres escritor, si haces obras de arte eres artista, si obtienes conocimiento de manera estructurada  cada día, tienes competencia académica. < Pero ten cuidado: no se trata de ir al colegio o universidad sino de esforzarse extracurricularmente para ser muy bueno. >

                        </p>
                    </div>
                </div>
            </div> -->
        </div>

        <div class="card">
            <div class="card-body" id="card5">
                <div class="row">
                    <div class="col-6">
                        <div class="imgPre imgPre5"></div>
                    </div>
                    <div class="col-6">
                        <h5 class="text-left">Global Mind</h5>
                        <em class="fas fa-solid fa-plus floatR" id = "5" (click)="verdetalle(5)"></em>
                    </div>
                </div>
            </div>
            <!-- <div class="card-body flip-box-back">
                <div class="row">
                    <div class="col-12">
                        <p>
                            ¿Estás listo para impactar al mundo? Nuestros más profundos arraigos nos generan creencias limitantes que normalizamos, pero… ¿Queremos un mundo realmente mejor? <br><br>
                            ¿Qué significa Global Mind y por qué es importante? <br> <br>

                           Las mentes globales se adecuan para convivir en cualquier parte del mundo, incluso en su propio país. La disposición, el conocimiento y la puesta en práctica de la interacción con otras culturas son las características más importantes de un ciudadano con global mind. Si adicionalmente entiendes los aspectos más relevantes de tu campo de acción o negocio de manera integral, y cómo puedes impactar al mundo se maximizan las probabilidades de éxito global.

                        </p>
                    </div>
                </div>
            </div> -->
        </div>
    </div>

      <div class = "col-6 left-side">
        <h5 class = "title-desc colorVerde" id = "title-desc"></h5>

        <p class = "intro-desc" id = "intro-desc"></p>
        <h5 class = "question-desc" id = "question-desc"></h5>
        <p class = "content-desc" id = "content-desc"></p>
        <div id="video-desc"></div>
      </div>
  </div>





  <div class="row marginBottom">
    <div class="col-sm-6 flip-box">

      </div>
    <div class="col-sm-6 flip-box">

      </div>
  </div>
  <div class="row marginBottom">
    <div class="col-sm-6 flip-box">

      </div>
    <div class="col-sm-6 flip-box">

      </div>
  </div>
  <div class="row marginBottom">
    <div class="col-sm-6 flip-box">

      </div>
  </div>
  <div class="row">
    <div class="col-12">
        <p>¡Evaluemos tu nivel en cada una de estas áreas para definir la mejor ruta para tu futuro!</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
        <em class="fas fa-solid fa-angle-left brnVolver" (click)="volverPaso3()" ></em>
        <button class="btn-continuar" (click)="continuarPaso5()">Continuar</button>
    </div>
  </div>
