import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso17',
  templateUrl: './paso17.component.html',
  styleUrls: ['./paso17.component.scss']
})
export class Paso17Component implements OnInit {
  public preguntas: any[]=[]
  @Output() notificacionTab10 = new EventEmitter<string>();
  public preguntas_seccion10:any;
  public dataFrm10:any;
  public dataFrm10Copy:any;
  public sumItems:number=0;

  public userData15: any;
  readonly getTestPaso15 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest15 = 'api/get_internalizacionUser/';
  pasoActivo: any;

  constructor( private apiService:ApiService) {
    this.userData15 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.userData15) {
      this.apiService.get({ api: this.getTestPaso15 + this.userData15._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //  
            let data_test= JSON.parse(response.test[0]);
            this.dataFrm10 = response;
            this.pasoActivo= response.hojaTest;
            this.dataFrm10.test = JSON.parse(this.dataFrm10.test);
            this.preguntas_seccion10= data_test.preguntas[9];
               
            let i = 0
            for(let preg of  this.preguntas_seccion10.preguntas){
               
               
               

              this.preguntas[i].opcionSeccionada = preg.opcionSeccionada
              i++
            }
             
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    } 
  }

  ngOnInit(): void {
    this.preguntas=[
      {pregunta:"Tengo una rica red de relaciones que me permiten saber perfectamente lo que ocurre en mi entorno.",opcionSeccionada:0},
      {pregunta:"Cuando trabajo en equipo logro armonizar puntos de vista aparentemente contrapuestos.",opcionSeccionada:0},
      {pregunta:"Me preocupa y me interesa percatarme de las cuestiones más preocupantes a nivel político, social, ambiental, económico y humanitario, de mi país y del mundo.",opcionSeccionada:0},
      {pregunta:"Recurrentemente recojo basura de otros en la naturaleza (Playa, Trocha, Lago, Rio, Páramo, etc.)",opcionSeccionada:0},
      {pregunta:"He apoyado fundaciones ambientales, o he liderado alguna iniciativa para contaminar menos.",opcionSeccionada:0},
      {pregunta:"He conseguido certificados de voluntariados civiles, ambientales ó culturales.",opcionSeccionada:0},
      {pregunta:"Ayudar a personas en estado de vulnerabilidad debe de ser responsabilidad total del estado, ¡allí deberían invertir nuestros impuestos!",opcionSeccionada:0},
      {pregunta:"Me gusta tener iniciativas para ayudar a personas en estado de vulnerabilidad, y las realizo.",opcionSeccionada:0},
      {pregunta:"Me intereso por el patrimonio cultural de mi país o la humanidad y me interesaría difundir la cultura.",opcionSeccionada:0},
    ]
  }
  radioChangeSelect(event,pos) {
     
     
    this.preguntas_seccion10.preguntas[pos].opcionSeccionada= event.target.value;
     
}
  public volverTab9(){
    this.notificacionTab10.emit("volver")

   }
   public continuarTab11(){
    let paso = this.pasoActivo
     for(let preg of this.preguntas_seccion10.preguntas){
      let puntos;
      if(parseInt(preg.opcionSeccionada) >0){
        this.sumItems= this.sumItems+1;
      }
      if(preg.invertida){
        if(preg.opcionSeccionada == '1'){
          puntos= '5'
        }
        if(preg.opcionSeccionada == '2'){
          puntos= '4'
        }
        if(preg.opcionSeccionada == '3'){
          puntos= '3'
        }
        if(preg.opcionSeccionada == '4'){
          puntos= '2'
        }
        if(preg.opcionSeccionada == '5'){
          puntos= '1'
        }
      }else{
        puntos = preg.opcionSeccionada
      }
       
      for(let afeccion of preg.afecciones){
         let index
         const found = this.preguntas_seccion10.habilidades.find(element => element.nombre === afeccion.habilidad);
         
         if(found){
          let resultadoOperacion = found.puntaje+parseFloat(puntos);
          found.puntaje = resultadoOperacion
         }
      }
    }
    if(this.pasoActivo < 9){
      paso = this.pasoActivo = 9;
    }
    this.dataFrm10.hojaTest= paso
     
    if(this.sumItems >= 6){
      this.dataFrm10.test.preguntas[9] = this.preguntas_seccion10;
      this.dataFrm10.test = JSON.stringify(this.dataFrm10.test );
      this.actualizarPaso15();
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe responder todas las preguntas'
      })
      this.sumItems=0
      return false
    }
    
    
  }
  actualizarPaso15(){
    let data = this.dataFrm10;
    this.apiService
    .put({ api: this.updateFrmTest15 + this.dataFrm10._id, data })
    .subscribe(
      (response) => {
         
        this.notificacionTab10.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
}
