<div class="programs-section">
  <!-- Words and Order -->
  <div class="container-max-width">
    <div class="row">
      <div class="col-lg-12 display-mobile-none">
        <ng-container [ngTemplateOutlet]="searchBar"></ng-container>
      </div>
      <div class="col-lg-5 display-mobile-none">
        <ng-container [ngTemplateOutlet]="filters"></ng-container>
      </div>
      <img (click)="goBack()" class="left-arrow display-mobile" src="/assets/wizard-images/left-arrow.png"
        alt="left-arrow">
      <div class="col-lg-7">
        <!--Results section-->
        <div class="program-result">
          <div class="container-text-result" *ngIf="queryNumber > 0">
            <ul class="nav_filter nav-tabs" id="myTab" role="tablist">
              <li class="">
                <label class="botonBase" [ngClass]="{ btnActivo: tabActivo == 'Programs_active' }" id="program-tab"
                  (click)="cardActive('Programs_active')" style="border:0%">
                  {{ "Programs-list.programs" | translate }} <br class="display-mobile"> ({{ queryNumberP }})
                </label>
              </li>
              <li class="">
                <label class="botonBase" [ngClass]="{ btnActivo: tabActivo == 'Universities_active' }"
                  (click)="cardActive('Universities_active')" id='unis-tab' style="border:0%">
                  {{ "Programs-list.universities" | translate }} <br class="display-mobile"> ({{ queryNumberU }})
                </label>
              </li>
            </ul>
            <div class="display-mobile mb-2">
              <ng-container [ngTemplateOutlet]="filters"></ng-container>
            </div>
            <div class="display-mobile mb-4">
              <ng-container [ngTemplateOutlet]="searchBar"></ng-container>
            </div>
            <p class="match-text" hidden>¡Encuentra tu curso ideal para estudiar en el extranjero!</p>
            <p style="font-weight: bold;">
              {{ "Programs-list.showing" | translate }} {{ programsPerPage * (page - 1) + 1 }}-{{
              page * programsPerPage
              }}
              {{ "Programs-list.outof" | translate }} {{ queryNumber }} {{ "Programs-list.results" | translate }}
            </p>
            <div class="disclaimer-text" style="width: 100%;">
              {{ "Programs-list.prices-are-given" | translate }}
            </div>
            <br>
            <div style="display: flex;">
              <div class="container-wishlist">
                <img routerLink="/wishlist" src="assets/utils/wishlist.png" alt="favourites" />
                <label routerLink="/wishlist">{{ "Favourites.favourites" | translate }}</label>
              </div>
              <div class="ordering-filter">
                <img width="6%" src="assets/utils/swap.png" />
                <div class="dropdown source">
                  <label id="order-label" class="order-label-big" for="order-by">{{ "Programs-list.order" | translate
                    }}</label>
                  <div class="dropdown-content source-content">
                    <select [(ngModel)]="orderCriteria" (ngModelChange)="onOrderingChange()" id="order-by">
                      <option value="0">
                        {{ "Programs-list.top-first" | translate }}
                      </option>
                      <option value="1">
                        {{ "Programs-list.price-asc" | translate }}
                      </option>
                      <option value="2">
                        {{ "Programs-list.price-desc" | translate }}
                      </option>
                      <option value="3">
                        {{ "Programs-list.program-a-z" | translate }}
                      </option>
                      <option value="4">
                        {{ "Programs-list.program-z-a" | translate }}
                      </option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="container-text-result ocultar_error" *ngIf="queryNumber < 1">
            <p class="match-text">
              Al parecer, no hay coincidencias para tu búsqueda
            </p>
            <div class="disclaimer-text">
              <label>Sugerencias de búsqueda:</label>
              <ul>
                <li>
                  Asegúrate de haber escrito correctamente todas las palabras.
                </li>
                <li>Intenta usar palabras diferentes o más generales.</li>
                <li>Prueba usar menos palabras.</li>
                <li>
                  Contáctanos a través del CHAT, podemos ayudarte a encontrar lo
                  que buscas.
                </li>
              </ul>
            </div>
          </div>
          <div class="clear-filters-section display-mobile-none">
            <div *ngIf="nameFilterActive" class="delete-filter">
              <div class="delete-filter-text">
                {{ "Programs-list.name" | translate }}
              </div>
              <mat-icon (click)="onFilterDeleteChange({ filter: 'name' })">
                clear
              </mat-icon>
            </div>
            <div *ngIf="countriesFilterActive" class="delete-filter">
              <div class="delete-filter-text">
                {{ "Programs-list.country" | translate }}
              </div>
              <mat-icon (click)="onFilterDeleteChange({ filter: 'countryFilter' })">
                clear
              </mat-icon>
            </div>
            <div *ngIf="regionFilterActive" class="delete-filter">
              <div class="delete-filter-text">
                {{ "Programs-list.region" | translate }}
              </div>
              <mat-icon (click)="onFilterDeleteChange({ filter: 'region' })">clear</mat-icon>
            </div>
            <div *ngIf="cityFilterActive" class="delete-filter">
              <div class="delete-filter-text">
                {{ "Programs-list.city" | translate }}
              </div>
              <mat-icon (click)="onFilterDeleteChange({ filter: 'city' })">clear</mat-icon>
            </div>
            <div *ngIf="universityFilterActive" class="delete-filter">
              <div class="delete-filter-text">
                {{ "Programs-list.university" | translate }}
              </div>
              <mat-icon (click)="onFilterDeleteChange({ filter: 'university' })">clear</mat-icon>
            </div>
            <div *ngIf="accreditationFilterActive" class="delete-filter">
              <div class="delete-filter-text">
                {{ "Programs-list.accreditation" | translate }}
              </div>
              <mat-icon (click)="onFilterDeleteChange({ filter: 'accreditation' })">clear</mat-icon>
            </div>
            <div *ngIf="disciplineFilterActive" class="delete-filter">
              <div class="delete-filter-text">
                {{ "Programs-list.discipline" | translate }}
              </div>
              <mat-icon (click)="onFilterDeleteChange({ filter: 'discipline' })">clear</mat-icon>
            </div>
            <div *ngIf="courseTypeFilterActive" class="delete-filter">
              <div class="delete-filter-text">
                {{ "Programs-list.course-type" | translate }}
              </div>
              <mat-icon (click)="onFilterDeleteChange({ filter: 'courseType' })">
                clear
              </mat-icon>
            </div>
            <div *ngIf="startsFilterActive" class="delete-filter">
              <div class="delete-filter-text">
                {{ "Programs-list.start-time" | translate }}
              </div>
              <mat-icon (click)="onFilterDeleteChange({ filter: 'starts' })">
                clear
              </mat-icon>
            </div>
            <div *ngIf="timeFilterActive" class="delete-filter">
              <div class="delete-filter-text">
                {{ "Programs-list.time" | translate }}
              </div>
              <mat-icon (click)="onFilterDeleteChange({ filter: 'time' })">
                clear
              </mat-icon>
            </div>
            <div *ngIf="coOpFilterActive" class="delete-filter">
              <div class="delete-filter-text">
                {{ "Program-list.coop-practice" }}
              </div>
              <mat-icon (click)="onFilterDeleteChange({ filter: 'coOp' })">
                clear
              </mat-icon>
            </div>
          </div>
          <div class="container-results" *ngIf="queryNumber > 0">
            <div *ngFor="
              let program of programs
                | paginate
                  : {
                      itemsPerPage: programsPerPage,
                      currentPage: page,
                      totalItems: queryNumber
                    }
            " class="container-card tab-content" id="myTabContent">
              <app-program-card [program]="program" [showRequisitesButton]="true" [userTest]="userTest"
                *ngIf="tabActivo === 'Programs_active'" class="tab-pane fade show active"></app-program-card>

              <app-program-card-universities [queryNumber]="queryNumber" [programs]="programs" [university]="program"
                [userTest]="userTest" [showRequisitesButton]="true" [filtrado]="filtrado"
                (queryNumberModified)="updateQueryNumber($event)" *ngIf="tabActivo === 'Universities_active'"
                class="tab-pane fade show active"></app-program-card-universities>
            </div>
              <div class="container-card">
            </div>
          </div>
          <div class="row" *ngIf="queryNumber > 0">
            <div class="col-lg-12 d-flex flex-column align-items-center justify-content-center">
              <pagination-controls
                previousLabel="{{ scrWidth > 950 ? ('Programs-list.previous-page' | translate) : '' }}"
                nextLabel="{{  scrWidth > 950 ? ('Programs-list.next-page' | translate) : '' }}" [responsive]="false"
                maxSize="5" (pageChange)="onPageChange($event)"></pagination-controls>
              <div class="document-number-selector display-mobile-none">
                {{ "Programs-list.per-page" | translate }}:
                <select id="pagiantor-select" [ngModel]="programsPerPage" (ngModelChange)="onPerPageChange($event)">
                  <option [value]="4">4</option>
                  <option [value]="6">6</option>
                  <option [value]="10">10</option>
                  <option [value]="25">25</option>
                  <option [value]="50">50</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row" *ngIf="queryNumber === 0">
            <div class="col-lg-12">
              <img src="assets/utils/empty-state.png" class="empty-query-image" alt="not found" />
              <p class="empty-query-text">{{ "Programs-list.no-results" | translate }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
      <div class="container-principal-content">
    </div>
  </div>
</div>
<!-- Test section -->
<ng-template #searchBar>
  <div class="container-header">
    <div class="search-bar">
      <img (click)="onSubmitSearch({ page: 1 })" class="search-image display-mobile-none"
        src="assets/utils/second-search-icon.png" alt="search" />
      <input (keydown.enter)="onSubmitSearch({ page: 1 })" class="search-input" [placeholder]="
                scrWidth > 951
                  ? 'Busca programas, universidades, destinos...'
                  : 'Programas, universidades, destinos'
              " styles="font-height:10px" type="text" id="program-name" [(ngModel)]="name" />
    </div>
    <div class="container-button-search display-mobile-none">
      <button (click)="onSubmitSearch({ page: 1 })">Buscar</button>
    </div>
    <div class="container-button-search display-mobile">
      <button (click)="onSubmitSearch({ page: 1 })">
        <img (click)="onSubmitSearch({ page: 1 })" src="assets/utils/buscador.png" alt="search" />
      </button>
    </div>
  </div>
</ng-template>

<ng-template #filters>
  <div class="filter" [ngClass]="filterHidden ? 'hide-big-filter-container' : 'show-big-filter-container'">
    <div class="filter-header">
      <div class="container-filter" (click)="onHideFilterChange()">
        <img alt="filter" width="1.5rem" height="1.5rem" class="filter-img display-mobile-none"
          src="assets/utils/filter.png" alt="filter" />
        <h4>Filtrar</h4>
        <div [ngClass]="filtrado ? 'filter-applied-bubble': 'hide-bubble'"></div>
      </div>
      <button class="clear-filters-responsive" [ngClass]="filterHidden ? 'hide-filter' : 'show-filter'"
        (click)="onClearFilters()" id="clear-filter-button">
        {{ "Programs-list.clear-filters" | translate }}
      </button>
      <div class="container-wishlist-small hide-big display-mobile-none"
        [ngClass]="filterHidden ? 'show-filter' : 'hide-filter'" id="favourites-responsive">
        <img alt="Favourites" routerLink="/favourites" src="assets/utils/wishlist.png" />
        <label routerLink="/favourites" class="wishlist-label">{{ "Favourites.favourites" | translate }}</label>
      </div>
      <div class="dropdown source hide-big container-order-label-small"
        [ngClass]="filterHidden ? 'show-filter' : 'hide-filter'">
        <img class="display-mobile-none" width="25px" src="assets/utils/swap.png" />
        <label *ngIf="!dropdownOrderResponsive" id="order-label" for="order-by" class="order-label-small"
          (click)="onOrder()">{{ "Programs-list.order" | translate }}</label>
        <div class="dropdown-content source-content">
          <select *ngIf="dropdownOrderResponsive" [(ngModel)]="orderCriteria" (ngModelChange)="onOrderingChange()"
            id="order-by-responsive">
            <option value="0">
              {{ "Programs-list.top-first" | translate }}
            </option>
            <option value="1">
              {{ "Programs-list.price-asc" | translate }}
            </option>
            <option value="2">
              {{ "Programs-list.price-desc" | translate }}
            </option>
            <option value="3">
              {{ "Programs-list.program-a-z" | translate }}
            </option>
            <option value="4">
              {{ "Programs-list.program-z-a" | translate }}
            </option>
          </select>
        </div>
      </div>
    </div>
    <div [ngClass]="filterHidden ? 'filter-hidden' : ''" class="filter-content">
      <!--Country filter-->
      <label class="search-label" for="countries">{{ "Programs-list.country" | translate }}
      </label>
      <ng-multiselect-dropdown class="advanced-filter-select" placeholder="{{ 'Programs-list.select' | translate }}"
        [settings]="settingsA" [data]="countriesDropdownList" [(ngModel)]="countryFilter"
        (onSelect)="onSelect($event, 'country')" (onDeSelect)="onDeSelect($event, 'country')"
        (onDeSelectAll)="onDeSelectAll($event, 'country')" (onSelect)="onSubmitSearch({ page: 1 })">
      </ng-multiselect-dropdown>
      <!--Region filter-->
      <label class="search-label" for="regions">
        {{ "Programs-list.region" | translate }}</label>
      <ng-multiselect-dropdown class="advanced-filter-select" placeholder="{{ 'Programs-list.select' | translate }}"
        [settings]="settingsB" [data]="regions" [(ngModel)]="region" (onSelect)="onSelect($event, 'region')"
        (onDeSelect)="onDeSelect($event, 'region')" (onDeSelectAll)="onDeSelectAll($event, 'region')"
        (onSelect)="onSubmitSearch({ page: 1 })">
      </ng-multiselect-dropdown>
      <!--City filter-->
      <label class="search-label" for="cities">
        {{ "Programs-list.city" | translate }}</label>
      <ng-multiselect-dropdown class="advanced-filter-select" placeholder="{{ 'Programs-list.select' | translate }}"
        [settings]="settingsB" [data]="cities" [(ngModel)]="city" (onSelect)="onSelect($event, 'city')"
        (onDeSelect)="onDeSelect($event, 'city')" (onDeSelectAll)="onDeSelectAll($event, 'city')"
        (onSelect)="onSubmitSearch({ page: 1 })">
      </ng-multiselect-dropdown>
      <!--University filter-->
      <label class="search-label" for="universities">
        <!--  {{ "Programs-list.Institution" | translate }}-->
        {{ "Programs-list.institution" | translate }}
      </label>
      <ng-multiselect-dropdown placeholder="{{ 'Programs-list.select' | translate }}" class="advanced-filter-select"
        [settings]="settingsB" [data]="universities" [(ngModel)]="university" (onSelect)="onSubmitSearch({ page: 1 })"
        (onDeSelectAll)="onDeSelectAll($event, 'university')">
      </ng-multiselect-dropdown>
      <!--Accreditation filter-->
      <label class="search-label" for="accreditation">
        {{ "Programs-list.accreditation" | translate }}
      </label>
      <ng-multiselect-dropdown placeholder="{{ 'Programs-list.select' | translate }}" class="advanced-filter-select"
        [settings]="settingsA" [data]="accreditationDropdownList" [(ngModel)]="accreditation"
        (onSelect)="onSubmitSearch({ page: 1 })">
      </ng-multiselect-dropdown>
      <!--Discipline filter-->
      <label class="search-label" for="discipline">
        {{ "Programs-list.discipline" | translate }}
      </label>
      <ng-multiselect-dropdown placeholder="{{ 'Programs-list.select' | translate }}" class="advanced-filter-select"
        [settings]="settingsA" [data]="disciplineDropdownList" [(ngModel)]="discipline"
        (onDeSelectAll)="onDeSelectAll($event, 'discipline')" (onSelect)="onSubmitSearch({ page: 1 })">
      </ng-multiselect-dropdown>

      <mat-accordion>
        <mat-expansion-panel class="mt-2">
          <mat-expansion-panel-header>
            <mat-panel-title>
              Filtros avanzados
            </mat-panel-title>
          </mat-expansion-panel-header>
          <!--Start filter-->
          <label class="search-label" for="start">
            {{ "Programs-list.start" | translate }}
          </label>
          <ng-multiselect-dropdown class="advanced-filter-select" placeholder="{{ 'Programs-list.select' | translate }}"
            [settings]="settingsA" [data]="startTimeDropdownList" [(ngModel)]="startTime"
            (onSelect)="onSubmitSearch({ page: 1 })">
          </ng-multiselect-dropdown>
          <!--Time filter-->
          <label class="search-label" for="time">
            {{ "Programs-list.time" | translate }}
          </label>
          <ng-multiselect-dropdown class="advanced-filter-select" placeholder="{{ 'Programs-list.select' | translate }}"
            [settings]="settingsA" [data]="timeDropdownList" [(ngModel)]="time"
            (onSelect)="onSubmitSearch({ page: 1 })">
          </ng-multiselect-dropdown>
          <!--Tuition Cost-->
          <label class="slider-label">
            {{ "Programs-list.tuition-cost" | translate }}
          </label>
          <br />
          <ngx-slider class="custom-slider" *ngIf="isBrowser" [(value)]="minTuitionCost" [(highValue)]="maxTuitionCost"
            [options]="tuitionSliderOptions"></ngx-slider>
          <label class="slider-label" id="min-max-current">{{ minTuitionCost | currency }} -
            {{ maxTuitionCost | currency }}</label>

        </mat-expansion-panel>
      </mat-accordion>
      <br />

      <div id="filter-controls">
        <button (click)="onSubmitSearch({ page: 1 })" id="apply-filter-button">
          {{ "Programs-list.apply-filters" | translate }}
        </button>

      </div>
    </div>
  </div>
</ng-template>
