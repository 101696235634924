<div class="program-card-universities" *ngIf="this.university">
  <a class="clickable-section anchor-nostyle" (click)="onMultipleClicksHandles({ event: $event })">
    <div class="card-program-header">
      <div class="universities-header-name">
        <div [matTooltip]="university?.nombre" matTooltipPosition="above" class="program-title"
          (click)="onMultipleClicksHandles({ event: $event })">
          {{ university?.nombre }}
        </div>
        <div>
          <button (click)="onMultipleClicksHandles({ event: $event })" aria-label="add or remove from favourites"
            class="favourite-button bottom-favourite" [ngClass]="
            university.inFavourites === true
                ? 'favourite-button-active'
                : 'favourite-button-inactive'
            "></button>
        </div>
        <div class="card-location">
          <img class="card-country-logo" [src]="university?.ciudad?.pais?.icono"
            alt="{{university?.ciudad?.pais?.nombre}}">
          <p class="location-name" (click)="onMultipleClicksHandles({ event: $event })">
            {{ university?.ciudad?.nombre }},
            {{ university?.ciudad.estado }}
          </p>
        </div>
      </div>
      <button (click)="onMultipleClicksHandles({ event: $event })" aria-label="add or remove from favourites"
        class="favourite-button top-favourite" [ngClass]="
        university.inFavourites === true
          ? 'favourite-button-active'
          : 'favourite-button-inactive'
      "></button>

    </div>

    <div>
      <div class="card-location">
        <!-- <div
          class="program-subtitle"
          *ngIf="filtrado"
          (click)="onMultipleClicksHandles({ event: $event })"
        >
        </div>-->
        <div class="program-subtitle" (click)="onMultipleClicksHandles({ event: $event })">
          {{ university?.aboutEspaniol | ellipsis: 215 }}
        </div>
      </div>
    </div>
  </a>
  <div class="container-buttons">
    <!-- <div class="text-center">
      </button>
      <mat-progress-spinner
        *ngIf="loading"
      >
    </div> -->
    <div class="row" style="align-items: center;">
      <div class="col-xl-6">
        <!-- <a
              *ngIf="showRequisitesButton && !university.brochure"
              [routerLink]="'../' + program.slug"
              fragment="requirements-accordion"
              >
            </a> -->
        <button class="requisites-button" (click)="goProgramsU()">
          <!-- {{ "Programs-list.requisites-card" | translate }} -->
          {{ "Programs-list.programs" | translate }}({{numPrograms}})
        </button>
        <!-- <button
              *ngIf="showRequisitesButton && university?.brochure"
              </button> -->
      </div>
      <div class="col-xl-5 afinity-probability-container">
        <label class="value-afinity-probability" style="margin-right: 20.54px;">
          <strong class="afinity-probability">Afinidad: </strong>{{classificationAfinity || 'No Definida'}}</label>
        <label class="value-afinity-probability"><strong class="afinity-probability">Probabilidad:
          </strong>{{university.Probabilidad}}</label>
      </div>
    </div>
  </div>
</div>
