import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import { AuthService } from 'src/app/public/services/auth.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso5',
  templateUrl: './paso5.component.html',
  styleUrls: ['./paso5.component.scss']
})
export class Paso5Component implements OnInit {
  @Output() notificacionTab5 = new EventEmitter<string>();
  public preguntas_seccion05:any;
  public dataFrm05:any;
  public dataFrm05Copy:any;
 public preguntas: any[]=[];
 public sumItems:number=0;
 responseUser05: any;
 readonly getTest05 = 'api/test_internalizacionUserSemestre/';
 readonly updateFrmTest05 = 'api/get_internalizacionUser/';
  pasoActivo: any;
  constructor( private apiService:ApiService,private authService:AuthService) {

    this.responseUser05 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.responseUser05) {
      this.apiService.get({ api: this.getTest05 + this.responseUser05._id+'/'+semestreAct  }).subscribe(
        (response) => {
          if (response) {
            //  
            let res = response;
            let data_test03
            if(res.test[0].preguntas){
              data_test03=res.test[0];
            }else{
              data_test03=JSON.parse(res.test[0]);
            }
            this.dataFrm05 = response;
            this.pasoActivo= response.hojaTest;
            this.dataFrm05.test = data_test03;
            this.preguntas_seccion05= data_test03.preguntas[4];
            // console.log('preguntas de la seccion liderazgo',this.preguntas_seccion05)
            let i = 0
            for(let item of this.preguntas_seccion05.preguntas){
              //  
              this.preguntas[i].opcionSeccionada= item.opcionSeccionada
              i++
            }
            // 
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    }
   }
  ngOnInit(): void {
    let get_id = document.getElementById('abcde');

    this.preguntas=[
      {pregunta:"¿Reconozco con claridad cuáles son mis capacidades y habilidades y he logrado expresararlas con éxito en mi contexto?.",opcionSeccionada:0},
      {pregunta:"¿Hago parte de un grupo o comunidad relacionada con el campo o área que me interesan?.",opcionSeccionada:0},
      {pregunta:"Puedo entender lo que le ocurrirá a mi campo de interés en 5 años. (Recursos Humanos, Impacto Ambiental, Proyección Económica).",opcionSeccionada:0},
      {pregunta:"Tengo una definición clara de quién quiero ser y actúo con hábitos diarios congruentes para lograrlo.",opcionSeccionada:0},
      {pregunta:"He estudiado reiteradamente cómo puedo mejorar mi entorno desde mi campo o carrera de interés.",opcionSeccionada:0},
      {pregunta:"Suelo ayudar a otros cuando tienen sentimientos como tristeza, rabia ó frustración.",opcionSeccionada:0},
      {pregunta:"¿Puedo expresar de forma apropiada mis propias emociones, sin verme afectad@ por el contexto y/o interlocutor?.",opcionSeccionada:0},
      {pregunta:"Los demás me consideran una persona siempre amable. Hago siempre un poco más de lo normal para que los demás estén bien.",opcionSeccionada:0},
      {pregunta:"¿Puedo establecer con facilidad relaciones participativas y cooperativas, compartiendo recursos y conocimiento fomentando ambientes abiertos y flexibles?.",opcionSeccionada:0},
      {pregunta:"Tengo una rica red de relaciones que me permiten saber perfectamente lo que ocurre en mi entorno.",opcionSeccionada:0},
      {pregunta:"Me quejo regularmente del sistema.",opcionSeccionada:0},
      {pregunta:"Para mi todo tiene que quedar perfecto y considero que mi criterio es generalmente adecuado.",opcionSeccionada:0},
      {pregunta:"Sé de que forma quiero impactar al mundo. He investigado para entenderlo.",opcionSeccionada:0}
    ]
  }
  public volverPaso4(){
    this.notificacionTab5.emit("volver")

   }
   public async continuarPaso6(){
    let resetCalculo = this.preguntas_seccion05.habilidades
    for(let calculos of resetCalculo){
      calculos.puntaje=0;
    }
    this.preguntas_seccion05.habilidades= resetCalculo
    for(let preg of this.preguntas_seccion05.preguntas){
      let puntos;
      if(parseInt(preg.opcionSeccionada) >0){
        this.sumItems= this.sumItems+1;
      }
      if(preg.invertida){
        if(preg.opcionSeccionada == '1'){
          puntos= '5'
        }
        if(preg.opcionSeccionada == '2'){
          puntos= '4'
        }
        if(preg.opcionSeccionada == '3'){
          puntos= '3'
        }
        if(preg.opcionSeccionada == '4'){
          puntos= '2'
        }
        if(preg.opcionSeccionada == '5'){
          puntos= '1'
        }
      }else{
        puntos = preg.opcionSeccionada
      }
      //  
      for(let afeccion of preg.afecciones){
         let index
         const found = this.preguntas_seccion05.habilidades.find(element => element.nombre === afeccion.habilidad);
        //  
         if(found){
          let resultadoOperacion = found.puntaje+parseFloat(puntos);
          found.puntaje = resultadoOperacion
         }
      }
    }
    let paso = this.pasoActivo
      if(this.pasoActivo < 5){
        paso = this.pasoActivo = 5;
      }
      this.dataFrm05.hojaTest= paso
    this.dataFrm05.test.preguntas[4] = this.preguntas_seccion05;
     
    //this.dataFrm05.test = JSON.stringify(this.dataFrm05.test );
    //  
    if(this.sumItems >= 13){
      this.actualizarPaso5();
    }else{
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe responder todas las preguntas'
      })
      this.sumItems=0
      return false
    }
  }
  public actualizarPaso5(){
    let data = this.dataFrm05;
    this.apiService
    .put({ api: this.updateFrmTest05 + this.dataFrm05._id, data })
    .subscribe(
      (response) => {
         
        this.notificacionTab5.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
  radioChange(event,pos) {
    //  
    //  
    this.preguntas_seccion05.preguntas[pos].opcionSeccionada= event.target.value
}
}


