<div class="blog-card">
  <a class="link-blog" [href]="'web/blog/' + blog.slug" (click)="saveBlogSlug()">
    <div class="blog-container">
      <div class="blog-title">
        {{ blog.titulo }}
      </div>
      <div
        class="blog-text-preview"
        [innerHTML]="blog.texto | ellipsis: 144"
      ></div>
      <div class="blog-date">
        {{ blog.createdAt | date }} - 5 minutos de lectura
      </div>
    </div>
    <div>
      <iframe
        class="card-video"
        *ngIf="blog.video !== undefined && blog.video !== ''"
        [src]="blog.video | safe"
      ></iframe>
      <!-- <img
        *ngIf="
          blog.imagen !== undefined &&
          blog.imagen !== '' &&
          (blog.video === undefined || blog.video === '')
        "
        class="blog-image"
        [src]="blog.imagen"
        alt="blog image"
      /> -->
    </div>
  </a>
</div>
