<!--Jumbotron section-->
<div class="jumbotron jumbotron-fluid">
  <div id="banner-container" class="container">
    <img
      id="jumbotron-logo"
      src="assets/home/logos/U-logo-white.png"
      alt="u360 logo"
    />
    <h2 class="jumbotron-text" *ngIf="isVerified">
      Your email has been verified! <br />
    </h2>
    <h2 class="jumbotron-text" *ngIf="!isVerified">
      Something went wrong!<br /><span class="smaller-font"
        >We sent you a new verification email</span
      >
    </h2>
  </div>
</div>
