import { Injectable } from '@angular/core';
import { MyFormData } from '../experienceModalComponent/experienceModalComponent';

@Injectable({
  providedIn: 'root'
})
export class FormDataService {
  private formData: MyFormData[] = [];

  saveFormData(data: MyFormData) {
    this.formData.push(data);
  }

  getFormData(): MyFormData[] {
    return this.formData;
  }
}
