<div class="row">
    <div class="col-12">
        <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
        <h4 class="titulo-wizard">Hablemos de Competencia Académica</h4>
        <h5>Pregunta 11 / 16</h5>
        <p>Actualmente cómo es la comprensión general de una segunda Lengua</p>
        <hr class="hrLine" />
    </div>
  </div>
  <div class="row marginBot">
    <div class="col-12">
        <div class="row align-items-stretch">
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <button class="inputPorcentaje" [ngClass]="posElement === 0?'btn-selected':''" (click) = "changeColor($event,0)" id = "basico-a1">Básico - A1</button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <button class="inputPorcentaje" [ngClass]="posElement === 1?'btn-selected':''" (click) = "changeColor($event,1)" id = "intermedio-b1">Intermedio - B1</button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <button class="inputPorcentaje" [ngClass]="posElement === 2?'btn-selected':''" (click) = "changeColor($event,2)" id = "avanzado-c1">Avanzado - C1</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="row marginBot">
    <div class="col-12">
        <div class="row">
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <button class="inputPorcentaje" [ngClass]="posElement === 3?'btn-selected':''" (click) = "changeColor($event,3)" id = "basico-a2">Básico - A2</button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <button class="inputPorcentaje" [ngClass]="posElement === 4?'btn-selected':''" (click) = "changeColor($event,4)" id = "intermedio-b2">Intermedio - B2</button>
                    </div>
                </div>
            </div>
            <div class="col-4">
                <div class="row">
                    <div class="col-12">
                        <button class="inputPorcentaje" [ngClass]="posElement === 5?'btn-selected':''" (click) = "changeColor($event,5)" id = "avanzado-c2">Avanzado - C2</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
        <p class="color">Si no sabes tu nivel, puedes tomar nuestro <label class="negrilla" (click)="verTest()"> test de inglés gratuito.</label></p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
        <em class="fas fa-solid fa-angle-left brnVolver" (click)="volverTab11()" ></em>
        <button class="btn-continuar" (click)="continuarTab13()">Continuar</button>
    </div>
  </div>
