import { Component, ViewChild, OnInit, Output, Input, TemplateRef, EventEmitter, Renderer2  } from '@angular/core';
import { FormDataService } from '../experienceApprovalComponent/formData.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';
import { AngularEditorConfig } from '@kolkov/angular-editor';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ApiService } from '../../public/services/api.service';

export interface MyFormData {
  experienceName: string;
  supervisorName: string;
  supervisorLastName: string;
  supervisorEmail: string;
  supervisorNumber: number;
  initDate: Date;
  endDate: Date;
  isOngoing: boolean;
  selectedActivity: any[];
  selectedType: any[];
  selectedApproaches: any[];
  selectedSkills: any[];
  htmlContent: string;
}

@Component({
  selector: 'app-experience-modal',
  templateUrl: './experienceModalComponent.html',
  styleUrls: ['./experienceModalComponent.scss'],
})
export class ExperienceModalComponent implements OnInit {
  @ViewChild('editor') editor: any;
  @ViewChild('dp') datepicker: any;
  @ViewChild('content', { static: true })
  private contentTemplateRef: TemplateRef<any>;
  @Output() formDataSubmitted: EventEmitter<any[]> = new EventEmitter<any[]>();
  skills = [
    { id: 'Liderazgo', name: 'Liderazgo', selected: false },
    { id: 'Trabajo y acción', name: 'Trabajo y acción', selected: false },
    { id: 'Aprendizaje', name: 'Aprendizaje', selected: false },
    { id: 'Impacto', name: 'Impacto', selected: false },
    { id: 'Global Mind', name: 'Global Mind', selected: false },
  ];
  approaches = [
    { id: 'En curso', name: 'En curso', selected: false },
    { id: 'School-based', name: 'School-based', selected: false },
    { id: 'Individual', name: 'Individual', selected: false },
  ];
  activityType = [
    { id: 'Creatividad', name: 'Creatividad', selected: false },
    { id: 'Actividad', name: 'Actividad', selected: false },
    { id: 'Servicio', name: 'Servicio', selected: false },
  ];
  typeOfService = [
    { id: 'Directa', name: 'Directa', selected: false },
    { id: 'Indirecta', name: 'Indirecta', selected: false },
    { id: 'Advocacy', name: 'Advocacy', selected: false },
  ];
  isModalOpenedFromApproval: boolean = false;
  selectedSkills: any[] = [];
  isEndDateDisabled: boolean = false;
  selectedApproaches: any[] = [];
  selectedActivityType: any[] = [];
  selectedTypeOfService: any[] = [];
  form: FormGroup;
  formList: MyFormData[] = [];
  formListData: any[] = [];
  files: File[] = [];
  name = 'Angular 6';
  editorContent: string;
  datepickerConfig: Partial<BsDatepickerConfig>;
  currentStep: number = 1;
  user: number = 1234567890;
  isModalOpen = false;
  modalRef: NgbModalRef;
  student: boolean = true;
  config: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: '12rem',
    minHeight: '5rem',
    placeholder: 'Enter text here...',
    translate: 'no',
    defaultParagraphSeparator: 'p',
    defaultFontName: 'Arial',
    toolbarHiddenButtons: [
      ['bold'],
      ['link', 'unlink', 'insertImage', 'insertVideo', 'insertHorizontalRule', 'removeFormat', 'toggleEditorMode'],
    ],
    customClasses: [
      {
        name: 'quote',
        class: 'quote',
      },
      {
        name: 'redText',
        class: 'redText',
      },
      {
        name: 'titleText',
        class: 'titleText',
        tag: 'h1',
      },
    ],
  };
  experienceNameInvalid: boolean = false;
  supervisorNameInvalid: boolean = false;
  supervisorLastNameInvalid: boolean = false;
  supervisorEmailInvalid: boolean = false;
  initDateInvalid: boolean = false;
  endDateInvalid: boolean = false;
  selectedActivityInvalid: boolean = false;
  selectedTypeInvalid: boolean = false;
  selectedApproachesInvalid: boolean = false;
  selectedSkillsInvalid: boolean = false;
  htmlContentInvalid: boolean = false;

  constructor(private modalService: NgbModal, private apiService: ApiService, private formDataService: FormDataService, private renderer: Renderer2) {
    this.datepickerConfig = {
      containerClass: 'theme-dark-blue custom-datepicker',
      dateInputFormat: 'DD/MM/YYYY',
    };
    this.form = new FormGroup({
      experienceName: new FormControl('', Validators.required),
      supervisorName: new FormControl('', Validators.required),
      supervisorLastName: new FormControl('', Validators.required),
      supervisorEmail: new FormControl('', [Validators.required, Validators.email]),
      supervisorNumber: new FormControl('', Validators.required),
      initDate: new FormControl('', Validators.required),
      endDate: new FormControl('', Validators.required),
      isOngoing: new FormControl(''),
      selectedActivity: new FormControl('', Validators.required),
      selectedType: new FormControl('', Validators.required),
      selectedApproaches: new FormControl('', Validators.required),
      selectedSkills: new FormControl('', Validators.required),
      htmlContent: new FormControl('', Validators.required),
      descriptionPreview: new FormControl('')
    });
  }
  setStudentValue(value: boolean) {
    this.student = value;
    if (!this.student) {
      this.form.controls['experienceName'].disable();
      this.form.controls['supervisorName'].disable();
      this.form.controls['supervisorLastName'].disable();
      this.form.controls['supervisorEmail'].disable();
      this.form.controls['supervisorNumber'].disable(); 
      this.form.controls['initDate'].disable(); 
      this.form.controls['endDate'].disable(); 
      this.form.controls['isOngoing'].disable(); 
      this.form.controls['selectedActivity'].disable(); 
      this.form.controls['selectedType'].disable(); 
      this.form.controls['selectedApproaches'].disable(); 
      this.form.controls['selectedSkills'].disable(); 
      this.form.controls['htmlContent'].disable(); 
      this.form.controls['descriptionPreview'].disable();
    } else {
      this.form.controls['experienceName'].disable();
      this.form.controls['supervisorName'].disable();
      this.form.controls['supervisorLastName'].disable();
      this.form.controls['supervisorEmail'].disable();
      this.form.controls['supervisorNumber'].disable(); 
      this.form.controls['initDate'].disable(); 
      this.form.controls['endDate'].disable(); 
      this.form.controls['isOngoing'].disable(); 
      this.form.controls['selectedActivity'].disable(); 
      this.form.controls['selectedType'].disable(); 
      this.form.controls['selectedApproaches'].disable(); 
      this.form.controls['selectedSkills'].disable(); 
      this.form.controls['htmlContent'].disable(); 
      this.form.controls['descriptionPreview'].disable();
    }
  }
  

  setFormListData(formListData) {
    this.formListData = formListData;
  
    if (this.formListData) {
  
      
      this.form.patchValue({
        experienceName: formListData.experienceName || '',
        supervisorName: formListData.supervisorName || '',
        supervisorLastName: formListData.supervisorLastName || '',
        supervisorEmail: formListData.supervisorEmail || '',
        supervisorNumber: formListData.supervisorNumber || '',
        initDate: formListData.initDate ? new Date(formListData.initDate) : null,
        endDate: formListData.endDate ? new Date(formListData.endDate) : null,
        isOngoing: formListData.isOngoing || false,
        selectedActivity: formListData.activityType || [],
        selectedType: formListData.serviceType || [],
        selectedApproaches: formListData.approaches || [],
        selectedSkills: formListData.skills || [],
        htmlContent: formListData.description || '',
      });
  
      this.selectedSkills = this.form.value.selectedSkills || [];
      this.selectedApproaches = this.form.value.selectedApproaches || [];
      this.selectedActivityType = this.form.value.selectedActivity || [];
      this.selectedTypeOfService = this.form.value.selectedType || [];
    } else {
      this.form.reset();
      this.selectedSkills = [];
      this.selectedApproaches = [];
      this.selectedActivityType = [];
      this.selectedTypeOfService = [];
    }
  }
  
  ngOnInit() {
    this.toggleEndDate();
  }

  getContent(): TemplateRef<any> {
    return this.contentTemplateRef;
  }

  closeModal() {
    if (this.modalRef) {
      this.modalRef.close();
    }
  }

  setModalRef(modalRef: NgbModalRef) {
    this.modalRef = modalRef;
    this.resetValidation();
  }

resetValidation() {
this.experienceNameInvalid = false;
this.supervisorNameInvalid = false;
this.supervisorLastNameInvalid = false;
this.supervisorEmailInvalid = false;
this.initDateInvalid = false;
this.endDateInvalid = false;
this.selectedActivityInvalid = false;
this.selectedTypeInvalid = false;
this.selectedApproachesInvalid = false;
this.selectedSkillsInvalid = false;
this.htmlContentInvalid = false;
}
  nextStep() {
    this.currentStep++;
  }

  previousStep() {
    this.currentStep--;
  }

  onSelect(event) {
    this.files.push(...event.addedFiles);
  }

  onRemove(event) {
    this.files.splice(this.files.indexOf(event), 1);
  }

  toggleOption(option: any, instance: number): void {
    option.selected = !option.selected;
    switch (instance) {
      case 1:
        this.selectedSkills = this.skills.filter((opt) => opt.selected).map((opt) => opt.id);
        break;
      case 2:
        this.selectedApproaches = this.approaches.filter((opt) => opt.selected).map((opt) => opt.id);
        break;
      case 3:
        this.selectedActivityType = this.activityType.filter((opt) => opt.selected).map((opt) => opt.id);
        break;
      case 4:
        this.selectedTypeOfService = this.typeOfService.filter((opt) => opt.selected).map((opt) => opt.id);
        break;
    }
  }

  onInput(event: any) {
    const input = event.target.value;
    if (!this.validateLetters(input)) {
      event.preventDefault();
    }
  }

  validateLetters(input: string): boolean {
    const lettersRegex = /^[A-Za-z]+$/;
    return lettersRegex.test(input);
  }

  toggleEndDate() {
    if (this.form.value.isOngoing) {
      this.form.get('endDate')?.disable();
    } else {
      this.form.get('endDate')?.enable();
    }
  }

  onSubmit() {
    if (this.form.valid) {
      const formData: MyFormData = {
        experienceName: this.form.value.experienceName,
        supervisorName: this.form.value.supervisorName,
        supervisorLastName: this.form.value.supervisorLastName,
        supervisorEmail: this.form.value.supervisorEmail,
        supervisorNumber: this.form.value.supervisorNumber,
        initDate: this.form.value.initDate,
        endDate: this.form.value.endDate,
        isOngoing: this.form.value.isOngoing,
        selectedActivity: this.form.value.selectedActivity,
        selectedType: this.form.value.selectedType,
        selectedApproaches: this.form.value.selectedApproaches,
        selectedSkills: this.form.value.selectedSkills,
        htmlContent: this.form.value.htmlContent,
      };
      if (this.student) {
        this.closeModal();
      }
      this.formDataService.saveFormData(formData);
      this.formList.push(formData);
      this.form.reset();
      this.currentStep = 1;
      this.formDataSubmitted.emit(this.formList);
    } else {
      this.experienceNameInvalid = this.form.controls['experienceName'].invalid;
      this.supervisorNameInvalid = this.form.controls['supervisorName'].invalid;
      this.supervisorLastNameInvalid = this.form.controls['supervisorLastName'].invalid;
      this.supervisorEmailInvalid = this.form.controls['supervisorEmail'].invalid;
      this.htmlContentInvalid = this.form.controls['htmlContent'].invalid;
    }
  }
}
