
<div class="footer">
  <!--Contact section-->
  <div class="contact">
    <div class="display-container">
        <div class="contact-container">
          <div>
            <p>
                <strong>¿Quieres explorar más?</strong><br>
                Inscríbete a nuestro newsletter
            </p>
          </div>
            <div class="email-container">
              <form class="m-0 p-0" (ngSubmit)="onSubmit({event: $event.submitter})" [formGroup]="createForm">
                <input type="text" placeholder="Escribe tu correo electrónico" type="email" id="email" formControlName="correo">
                <button id="create" [disabled] = "">Suscribirse</button>
              </form>
            </div>
        </div>
        <div class="social-networks">
            <div class="social-icons">
                <a href="https://web.facebook.com/U360education" target="_blank">
                    <img class="social-network-button" src="assets/footer/footer-facebook.png" alt="facebook">
                </a>
                <a href="https://www.instagram.com/u360education/?hl=en" target="_blank">
                    <img class="social-network-button" src="assets/footer/footer-instagram.png" alt="instagram">
                </a>
                <a href="https://www.linkedin.com/company/heclatam/about/" target="_blank">
                  <img class="social-network-button" src="assets/footer/footer-linkedin.png" alt="linkedin">
                </a>
                <a href="https://youtube.com/channel/UCK1YJmkFvLPqfFPNZ0n_4Jg" target="_blank">
                  <img class="social-network-button" src="assets/footer/footer-youtube.png" alt="youtube">
                </a>
                <img class="u360-logo" src="assets/footer/logo-pequeño.png" alt="u360 logo">
            </div>
        </div>
    </div>
  </div>

  <div class="topic-section">
    <div class="topics-container">
      <div class="topic">
        <div class="label-container">
          <label>Temas populares</label>
          <img (click)="showDetails({item: 'topic'})" *ngIf="scrWidth < 951" src="assets/utils/detail-arrow.png" [class.hide-details]="displayPopularTopics" alt="dropdown arrow">
        </div>
        <ng-container *ngIf="scrWidth > 950 || displayPopularTopics">
          <span routerLink="/program/search" [queryParams]="{discipline: 'Business and Management' }">Negocios</span>
          <span routerLink="/program/search" [queryParams]="{discipline: 'Computer Sciences and IT' }">Ciencias de la computación e informática</span>
          <span routerLink="/program/search" [queryParams]="{discipline: 'Engineering' }">Ingenierías</span>
          <span routerLink="/program/search" [queryParams]="{discipline: 'Arts, Design and Architecture' }">Artes, Diseño y Arquitectura</span>
          <span routerLink="/program/search" [queryParams]="{discipline: 'Sciences and Mathematics' }">Ciencias y Matemáticas</span>
          <span routerLink="/program/search" [queryParams]="{discipline: 'Community Services' }">Servicios Comunitarios</span>
        </ng-container>
      </div>
      <div class="topic">
        <div class="label-container">
          <label>Destinos populares</label>
          <img (click)="showDetails({item: 'destination'})" *ngIf="scrWidth < 951" src="assets/utils/detail-arrow.png" [class.hide-details]="displayDestinations" alt="dropdown arrow">
        </div>
        <ng-container *ngIf="scrWidth > 950 || displayDestinations">
          <span (click)="filterProgramByCountry({page:1 , country:'Canada'})" >Canadá</span>
          <span (click)="filterProgramByCountry({page:1 , country:'United Kingdom'})" >Reino Unido</span>
          <span (click)="filterProgramByCountry({page:1 , country:'United States'})" >Estados Unidos</span>
          <span (click)="filterProgramByCountry({page:1 , country:'Germany'})" >Alemania</span>
          <span routerLink="/program/search" [queryParams]="{pais: '60482c2baea5080017464e85' }" >Países Bajos</span>
          <span routerLink="/program/search" [queryParams]="{pais: '60482c3aaea5080017464e86' }" >España</span>
          <!-- <span routerLink="/program/search" [queryParams]="{pais: '60482c2baea5080017464e85' }" (click)="filterProgramByCountry({page:1 , country:'Paises bajos'})" >Países Bajos</span> -->
        </ng-container>
      </div>
      <div class="topic interest-container">
        <div class="label-container">
          <label>De tu interés</label>
          <img (click)="showDetails({item: 'interest'})" *ngIf="scrWidth < 951" src="assets/utils/detail-arrow.png" [class.hide-details]="displayInterests" alt="dropdown arrow">
        </div>
        <ng-container *ngIf="scrWidth > 950 || displayInterests">
          <span routerLink="/marketplace" [queryParams]="{serviceType: 'servicios-medicos' }">Servicios para tu viaje</span>
          <span routerLink="/marketplace" [queryParams]="{serviceType: 'consultoria-vip' }">Agendar Consulta VIP</span>
          <span routerLink="/student">Contacta Estudiantes</span>
          <span routerLink="/blog">Blog</span>
          <span routerLink="/visa-thermometer">Test gratuitos</span>
        </ng-container>
      </div>
      <div class="topic">
        <div class="label-container">
          <label>Sobre Nosotros</label>
          <img (click)="showDetails({item: 'aboutUs'})" *ngIf="scrWidth < 951" src="assets/utils/detail-arrow.png" [class.hide-details]="displayAboutUs" alt="dropdown arrow">
        </div>
        <ng-container *ngIf="scrWidth > 950 || displayAboutUs">
          <span routerLink="/manifest">Cómo funciona U360</span>
          <!-- <span routerLink="/manifest">¡En qué creemos! Manifiesto equipo U360</span> -->
          <a href="https://api.whatsapp.com/send?phone=573156676627&text=Hola%2C%20Quiero%20información%20sobre%20U360" target="_blank" rel="noopener"><span>Contacto</span></a>
          <span (click)="openModalAmbassador()">Quiero ser embajador</span>
          <span (click)="navigateUniversitiesSection()">Las universidades aliadas</span>
          <span routerLink="/privacy-policy">Políticas de privacidad</span>
          <span routerLink="/terms-and-conditions">T&C / Garantía de soporte</span>
        </ng-container>
      </div>
    </div>
    <div class="logos">
      <div class="footer-logo">
        <img id="footer-logo-img" src="assets/navbar/logo.png" alt="u360 logo">
        <p> {{ "Footer.data-protection-policy" | translate }} <br>
          {{ "Footer.copyright" | translate }}
      </div>
      <div class="logos-supported">
        <span>Supported by:</span>
        <img id="u360-logo" src="assets/navbar/logo.png" alt="u360 logo" >
        <img id="hec-logo" src="assets/footer/HEC.png" alt="HEC logo">
        <img id="teducamos-logo" src="assets/footer/TEDUCAMOS.png" alt="teducamos logo">
        <img id="years-logo" src="assets/footer/experiencia-azul.png" alt="years of experience">
        <img id="anex-logo" src="assets/footer/Anex.png" alt="Anex Colombia">
      </div>
    </div>
    <div class="links">
      <a routerLink="/terms-and-conditions">{{ "Footer.terms-of-use" | translate }}</a>
      <a routerLink="/privacy-policy">{{ "Footer.privacy-policy" | translate }}</a>
    </div>
  </div>
</div>
