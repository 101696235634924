<div class="be-accepted-sub-steps">
    <div class="sub-step-container" *ngFor="let step of substeps; index as i"
    [ngStyle]="{'opacity': selected !== i?'0.3':'1'}">
        <app-substep-card
        (click)="changeSelected({index: i})"
        [stepName]="step.name"
        [status]="step.status"></app-substep-card>
        <div class="sub-step-data" [ngSwitch]="selected" [ngClass]="selected === i?'sub-step-data-active':''">
            <div *ngSwitchCase="0">
                <ng-container *ngTemplateOutlet="substep0"></ng-container>
            </div>
            <div *ngSwitchCase="1">
                <ng-container *ngTemplateOutlet="substep1"></ng-container>
            </div>
            <div *ngSwitchCase="2">
                <ng-container *ngTemplateOutlet="substep2"></ng-container>
            </div>
        </div>
    </div>
</div>
<div [ngSwitch]="selected" class="be-accepted-content">
    <div *ngSwitchCase="0">
        <ng-container *ngTemplateOutlet="substep0"></ng-container>
    </div>
    <div *ngSwitchCase="1">
        <ng-container *ngTemplateOutlet="substep1"></ng-container>
    </div>
    <div *ngSwitchCase="2">
        <ng-container *ngTemplateOutlet="substep2"></ng-container>
    </div>
</div>

<ng-template #substep0>
    <p class="info-text" *ngIf="substeps[0].status !== 'completed'">1. {{'Be-accepted.upload-offer-letter' | translate}}</p>
    <p>
        En caso de que nosotros recibamos la carta, la subiremos por ti y te notificaremos. Si la recibes directamente, por favor subirla para validación, revisión y continuar con el proceso.
    </p>
    <a  class="document-link"
       *ngIf="beAccepted.recepcionOferta.offerLetter !== ''
       && beAccepted.recepcionOferta.offerLetter !== null &&
       beAccepted.recepcionOferta.offerLetter !== undefined ;else notUploadedYet"
       [href]="beAccepted.recepcionOferta.offerLetter"
       target="_blank">{{'Be-accepted.view-offer-letter'|translate}}</a> <br>
    <label *ngIf="substeps[0].status !== 'completed'" for="offer-letter" class="upload-document">{{'Be-accepted.upload-letter' | translate}}</label>
    <input *ngIf="substeps[0].status !== 'completed'" (change)="onUploadChange({event: $event, nombreDoc: 'offer-letter'})" id="offer-letter" type=file>
    <p class="info-text" *ngIf="substeps[0].status === 'completed'">{{'Be-accepted.done' | translate}}</p>
</ng-template>
<ng-template #substep1>
    <p class="info-text" *ngIf="substeps[1].status !== 'completed'">2. {{'Be-accepted.upload-loa' | translate}}</p>
    <p>
        Por favor leer detalladamente y verifica que toda la información personal, fecha de inicio y datos del programa estén correctos.<br><br> 
        Leer a detalle todas las condiciones de la oferta y siguientes pasos.<br><br>
        Si tienes alguna duda, por favor contacta a tu asesor de admisiones. 
    </p>
    <a class="document-link"
       *ngIf="beAccepted.recepcionLOA.LOA !== '' && beAccepted.recepcionLOA.LOA !== null && beAccepted.recepcionLOA.LOA !== undefined ;else notUploadedYet"
       [href]="beAccepted.recepcionLOA.LOA"
       target="_blank">{{'Be-accepted.view-loa' | translate}}</a> <br>
    <label *ngIf="substeps[1].status !== 'completed'" for="loa" class="upload-document">{{'Be-accepted.upload-letter' | translate}}</label>
    <input *ngIf="substeps[1].status !== 'completed'" (change)="onUploadChange({event: $event, nombreDoc: 'LOA'})" id="loa" type=file>
    <br>
    <input type="checkbox" [(ngModel)]="beAccepted.recepcionLOA.aceptacionTerminosLOA" [disabled]="beAccepted.recepcionLOA.aceptacionTerminosLOA || !(beAccepted.recepcionLOA.LOA !== '' && beAccepted.recepcionLOA.LOA !== null && beAccepted.recepcionLOA.LOA !== undefined)" (ngModelChange)="onLOARead()"> {{'Be-accepted.loa-confirm' | translate}} <br>

    <p class="info-text" *ngIf="substeps[1].status === 'completed'">{{'Be-accepted.done' | translate}}</p>
</ng-template>
<ng-template #substep2>
    <p class="info-text" *ngIf="substeps[2].status !== 'completed'">3. {{'Be-accepted.university-quota' | translate}}</p>
    <a class="document-link"
       *ngIf="beAccepted.pagoCupo.comprobantePago !== '' && beAccepted.pagoCupo.comprobantePago !== null && beAccepted.pagoCupo.comprobantePago !== undefined ;else notUploadedYet"
       [href]="beAccepted.pagoCupo.comprobantePago"
       target="_blank">{{'Be-accepted.view-payment-proof' | translate}}</a> <br>
    <label *ngIf="substeps[2].status !== 'completed'" for="payment-proof" class="upload-document">{{'Be-accepted.upload-payment-proof' | translate}}</label>
    <input *ngIf="substeps[2].status !== 'completed'" (change)="onUploadChange({event: $event, nombreDoc: 'payment-proof'})" id="payment-proof" type=file>

    <p class="info-text" *ngIf="substeps[2].status === 'completed'">{{'Be-accepted.done' | translate}}</p>
</ng-template>
<!--General templates-->
<ng-template #notUploadedYet>
  <p class="fallback-text">{{'Be-accepted.not-uploaded-yet' | translate}}</p>
</ng-template>
