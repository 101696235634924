import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso15',
  templateUrl: './paso15.component.html',
  styleUrls: ['./paso15.component.scss']
})
export class Paso15Component implements OnInit {
  @Output() notificacionTab6 = new EventEmitter<string>();
  public preguntas_seccion06:any;
  public dataFrm06:any;
  public dataFrm06Copy:any;
  public listCheck:any;
  public seleccion: boolean = false;
  public userData15: any;
  readonly getTestPaso15 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest15 = 'api/get_internalizacionUser/';
  pasoActivo: any;
  constructor( private apiService:ApiService) {

    this.userData15 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.userData15) {
      this.apiService.get({ api: this.getTestPaso15 + this.userData15._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //  
            let res = response;
            let data_test
            if(res.test[0].preguntas){
              data_test=res.test[0];
            }else{
              data_test=JSON.parse(res.test[0]);
            }
            this.dataFrm06 = res;
            this.pasoActivo= res.hojaTest;
            //  
            this.dataFrm06.test = data_test;
            this.preguntas_seccion06= data_test.preguntas[5];
            //  
            let i = 0
            for(let marcar of this.preguntas_seccion06.preguntas[0].opciones){
              this.listCheck[i].seleccionado = marcar.interesa
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    } 

  }

  ngOnInit(): void {
    this.listCheck=[
      {seleccionado:false},
      {seleccionado:false},
      {seleccionado:false},
      {seleccionado:false},
      {seleccionado:false},

    ]
  }
  public checkValue(pos){
    //  
    //  
    let i = 0
    for(let marcar of this.preguntas_seccion06.preguntas[0].opciones){
      //  
      if(i == pos){
        marcar.interesa= !marcar.interesa
      }
      i++

    }
    //this.preguntas_seccion06.preguntas[0].opciones[pos].interesa= this.listCheck[pos].seleccionado;
  }
  getCheckBox(event){
    let id = event.target.id;
    let disableId = event.target.id;
    let checkBoxPressed = document.getElementById(id) as HTMLInputElement;

    if(checkBoxPressed.checked){
      if(id.includes('value1')){
        disableId = id.replace('value1', 'value2');
      }
      else if(id.includes('value2')){
        disableId = id.replace('value2', 'value1');
        
      }
      //  
      let checkBoxDisable = document.getElementById(disableId);
      checkBoxDisable.setAttribute('disabled', new Boolean(true).toString());
    }
    else{
      if(id.includes('value1')){
        disableId = id.replace('value1', 'value2');
      }
      else if(id.includes('value2')){
        disableId = id.replace('value2', 'value1');
      }
      let checkBoxDisable = document.getElementById(disableId);
      checkBoxPressed.removeAttribute('disabled');
      checkBoxDisable.removeAttribute('disabled');
    }
  
  }
  public volverPaso5(){
    this.notificacionTab6.emit("volver")

   }
   public continuarPaso7(){
      let paso = this.pasoActivo
      if(this.pasoActivo < 7){
        paso = this.pasoActivo = 6;
      }
      this.dataFrm06.hojaTest= paso;
      //  
      this.dataFrm06.test.preguntas[5] = this.preguntas_seccion06;
      //  
      this.dataFrm06.test = JSON.stringify(this.dataFrm06.test );
      this.actualizarPaso14();
  }
  actualizarPaso14(){
    let data = this.dataFrm06;
    //  
    this.apiService
    .put({ api: this.updateFrmTest15 + data._id, data })
    .subscribe(
      (response) => {
         
        this.notificacionTab6.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
}
