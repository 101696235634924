import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { NavigationService } from '../../services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-wishlist',
  templateUrl: './wishlist.component.html',
  styleUrls: ['./wishlist.component.scss']
})
export class WishlistComponent implements OnInit {
  /** API path for getting the favourites information */
  readonly FAVOURITES_API = 'api/user/favoritos/';

  /** API path for student get test*/
  readonly GET_TEST ='api/test_internalizacionUser/'

  /** Variable para el usuario */
  user = JSON.parse(localStorage.getItem('user'))

  /** Variable para los favoritos del usuario */
  userFavourites:any;

  constructor(
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    //  
    this.getFavourites();
  }

  getFavourites(){
    this.apiService.get({api:this.FAVOURITES_API+this.user._id}).subscribe(
      (response)=>{
        if(response.length>0){
          this.apiService.get({api:this.GET_TEST+this.user._id}).subscribe(
            (test)=>{
              for (const favourite of response) {
                if(test){
                  let afinity= (Math.abs(test.puntaje_general - favourite.universidad.indiceCompetencias)-1).toFixed(2);
                  favourite.afinity = afinity
                }
                favourite.inFavourites = true;
              }
              this.userFavourites=response
            }
          )
        }
      }
    )
  }
}
