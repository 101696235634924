<div>
  <div class="experienceCard" *ngIf="formList.length > 0">
    <div class="FiltersExperinceContainer row m-0 p-0">
      <div class="row filterContainer col-xl-5 col-12 m-0 p-0 pl-lg-2">
        <div class="col-xl-12">
          <p class="filterName">Filtros por competencias</p>
        </div>
        <div *ngFor="let skill of skillsArray; index as i" (click)="toggleSkillSelection(skill.name)" [ngClass]="{
          'tagFilterSelected': isSelectedSkill(skill.name),
          'display-mobile-none': !showCompetenciesFilters}" class="tagFilters">
          {{ skill.name }}
        </div>
      </div>
    </div>
  </div>
</div>

<div class="experienceCard" *ngIf="formList.length > 0">
  <div class="cardComponent">
    <div class="card" *ngFor="let formData of formList; let i = index" [class.hidden]="!shouldShowCard(formData)">
      <div class="cardTitle">
        <h5>{{ formData.experienceName }}</h5>
        <p class="approvalStatus" *ngIf="formData.approve === true">
          Aprobado
        </p>
        <p class="approvalStatus" *ngIf="formData.approve === false">
          Aprobación negada
        </p>
        <p class="approvalStatus" *ngIf="formData.approve === undefined">
          Pendiente de aprobación
        </p>
      </div>
      <div class="cardBody" *ngIf="shouldShowCard(formData)">
        <div [innerHTML]="decodeEntities(trimHtmlContent(formData.htmlContent))"></div>
        <p class="typeOfCard">
          <img src="../../../assets/experiences/experience.png" alt="">
          {{ formData.selectedSkills.join(', ') }}
        </p>
        <p class="date">
          <img src="../../../assets/experiences/calendario.png" alt="">
          {{ formatDate(formData.initDate) }} - {{ formData.endDate ? formatDate(formData.endDate) : '' }}{{
          formData.isOngoing ? 'En curso' : '' }}
        </p>
        <div class="editButton">
          Editar
        </div>
        <div class="deleteButton" (click)="showDeleteConfirmation(formData)">
          Borrar
        </div>
      </div>
    </div>
  </div>
</div>
