<div class="row">
    <div class="col-12">
        <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
        <h4 class="titulo-wizard">Hablemos de Impacto Social</h4>
        <h5>Pregunta 9 / 16</h5>
        <p>Identifica las causas sociales que realizas.</p>
        <hr class="hrLine" />
    </div>
  </div>
  <div class="row marginBottom">
    <div class="col-sm-4 content-img">
      <img src = "/assets/wizard-images/Test-15.png" class = "sizingImage" alt="Voluntario Ambiental">
     <h6>{{preguntas_seccion09.preguntas[0].pregunta}}</h6> 
     <p><input type = "checkbox" value = "1" id = "checkBox0value1" [checked]="A01" (click) = "getCheckBox($event,0,'si')">Si</p>
     <p><input type = "checkbox" value = "2" id = "checkBox0value2" [checked]="A02" (click) = "getCheckBox($event,0,'no')">No</p>  
    </div>
    <div class="col-sm-4 content-img">
      <img src = "/assets/wizard-images/Test-16.png" class = "sizingImage" alt="Voluntario Cultural">
      <h6>{{preguntas_seccion09.preguntas[1].pregunta}}</h6>
      <p><input type = "checkbox" value = "3" id = "checkBox1value1" [checked]="B01" (click) = "getCheckBox($event,1,'si')">Si</p>
      <p><input type = "checkbox" value = "4" id = "checkBox1value2" [checked]="B02" (click) = "getCheckBox($event,1,'no')">No</p>  
    </div>
    <div class="col-sm-4 content-img">
      <img src = "/assets/wizard-images/Test-17-2.png" class = "sizingImage" alt="Ayuda Civil">
      <h6>{{preguntas_seccion09.preguntas[2].pregunta}}</h6>
      <p><input type = "checkbox" value = "4" id = "checkBox2value1" [checked]="C01"  (click) = "getCheckBox($event,2,'si')">Si</p>
      <p><input type = "checkbox" value = "5" id = "checkBox2value2" [checked]="C02" (click) = "getCheckBox($event,2,'no')">No</p>   
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
        <em class="fas fa-solid fa-angle-left brnVolver" (click)="volverTab7()" ></em>
        <button class="btn-continuar" (click)="continuarTab9()">Continuar</button>
    </div>
  </div>