import { MatTooltipModule } from '@angular/material/tooltip';
import { MatExpansionModule } from '@angular/material/expansion';
import { AuthInterceptor } from './public/interceptors/auth.interceptor';
import { FooterComponent } from './public/shared/footer/footer.component';
import { PrincipalMenuComponent } from './public/shared/principal-menu/principal-menu.component';
import { BrowserModule, BrowserTransferStateModule } from '@angular/platform-browser';
import { APP_INITIALIZER, Injectable, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './public/shared/header/header.component';
import { SideMenuComponent } from './public/shared/side-menu/side-menu.component';
import { PublicModule } from './public/public.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDialogModule } from '@angular/material/dialog';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { TokenInterceptor } from './public/interceptors/token.interceptor';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { NgxSpinnerModule } from 'ngx-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AuthGuardGuard } from './public/guards/auth-guard.guard';
import { SocialLoginModule, SocialAuthServiceConfig } from 'angularx-social-login';
import { GoogleLoginProvider, FacebookLoginProvider } from 'angularx-social-login';

import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatIconModule } from '@angular/material/icon';
import { AuthService } from './public/services/auth.service';
import { MatSidenavModule } from '@angular/material/sidenav';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import es from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';

import { MatButtonModule } from '@angular/material/button';
registerLocaleData(es);

const fbLoginOptions = {
  scope: 'pages_messaging,pages_messaging_subscriptions,email,pages_show_list,manage_pages',
  return_scopes: true,
  enable_profile_selector: true,
}; // https://developers.facebook.com/docs/reference/javascript/FB.login/v2.11

export function appInitializerFactory(translate: TranslateService) {
  return () => {
    translate.setDefaultLang('es');
  };
}

const googleLoginOptions = {
  scope: 'profile email',
};

@NgModule({
  declarations: [AppComponent, HeaderComponent, SideMenuComponent, FooterComponent, PrincipalMenuComponent],
  imports: [
    ReactiveFormsModule,
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    BrowserTransferStateModule,
    AppRoutingModule,
    FormsModule,
    PublicModule,
    RouterModule,
    BrowserAnimationsModule,
    MatDialogModule,
    MatSidenavModule,
    HttpClientModule,
    SimpleNotificationsModule.forRoot(),
    NgxSpinnerModule,
    MatProgressBarModule,
    SocialLoginModule,
    TranslateModule,
    MatTooltipModule,
    MatIconModule,
    BsDropdownModule,
    MatButtonModule,
    MatTableModule,
    MatPaginatorModule,
  ],
  providers: [
    CookieService,
    AuthService,
    AuthGuardGuard,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [TranslateService],
      multi: true,
    },
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [
          {
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(
              '348968938862-6kf2g2rpr40af3q5ff2klmu4bjbb20ml.apps.googleusercontent.com'
            ),
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('834740827083218'),
          },
        ],
      } as SocialAuthServiceConfig,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: LOCALE_ID, useValue: 'es-ES' },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
