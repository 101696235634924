<div class="card card-complement">
    <div class="card-body p-0">
        <div class="row">
            <div class="row col-11">
                <div class="col-12">
                    <label class="program-title" (click)="onClickProgram()">{{program.nombre}}</label>
                </div>
                <div class="col-12">
                    <label class="at-college mb-0" (click)="onClickUniversity()">At {{program.universidad?.nombre}}</label>
                    <img class="app-university-img display-mobile" [src]="program.universidad?.logo" alt="{{program.universidad?.nombre}}">
                </div>

                <div class="col-12">
                    <img class="country-img" [src]="program.universidad?.ciudad?.pais?.icono" alt="icono-pais">
                    <label class="city-country">
                        {{program.universidad?.ciudad?.nombre}}, {{program.universidad?.ciudad?.pais?.nombre}}
                    </label>
                </div>
            </div>

            <!-- Aqui va el div del favorito -->
            <div class="col-1">
                <button aria-label="add or remove from favourites" class="favourite-button"
                [ngClass]="program.inFavourites === true ? 'favourite-button-active' : 'favourite-button-inactive'"
                (click)="addFavourite()"></button>
            </div>
        </div>

        <div class="row d-flex align-items-center">
            <div class="row col-xl-8 info">
                <div class="col-xl-12 d-flex first-col">
                    <p class="program-detail mr-4">
                        <img src="/assets/utils/fee.png" alt="fee" class="img-fee">
                        Matricula:
                        <span *ngIf="program.yearTuition!=null || program.yearTuition!=undefined">
                            ${{program.yearTuition}} {{program.universidad.ciudad.pais.moneda}} / Año
                        </span>
                        <span *ngIf="program.yearTuition==null || program.yearTuition==undefined">
                            ${{program.programTuition}} {{program.universidad.ciudad.pais.moneda}} / Completo
                        </span>
                        <!-- <span *ngIf="!tuitionCost">ND</span> -->
                      </p>
                      <p class="program-detail first-col display-mobile-none">
                        <img src="/assets/utils/fee.png" alt="fee" class="img-fee">
                        Aplicación:
                        <span *ngIf="program.universidad.applicationFee">
                                ${{program.universidad.applicationFee}} {{program.universidad.ciudad.pais.moneda}}
                            </span>
                        <span *ngIf="!program.universidad.applicationFee">ND</span>
                      </p>
                </div>

                <div class="col-xl-12 display-mobile">
                  <p class="program-detail first-col">
                    <img src="/assets/utils/fee.png" alt="fee" class="img-fee">
                    Aplicación:
                    <span *ngIf="program.universidad.applicationFee">
                                ${{program.universidad.applicationFee}} {{program.universidad.ciudad.pais.moneda}}
                            </span>
                    <span *ngIf="!program.universidad.applicationFee">ND</span>
                  </p>
                </div>

                <div class="col-xl-12">
                    <p class="program-detail">
                        <img src="/assets/utils/libro.png" alt="fee" class="img-book">
                        {{ program.acreditacionShown }} - {{ program.stdLength }}
                    </p>
                </div>

            </div>

            <div class="col-xl-4 container-logo-university display-mobile-none">
                <img class="app-university-img" [src]="program.universidad?.logo" alt="{{program.universidad?.nombre}}">
            </div>
        </div>

        <div class="row p-0 m-0 pr-2">
            <div class="col-12 p-0 order-1 order-xl-0 col-xl-6 container-button">
                <button class="button-action" (click)="onApply()">
                    Aplicar
                </button>
            </div>

            <div class="col-12 order-0 order-xl-1 col-xl-6 px-0 afinity-probability-container">
                <label *ngIf="program.afinity" class="value-afinity-probability mr-2"><strong class="afinity-probability">Afinidad: </strong>{{program.afinity}}%</label>
                <label *ngIf="!program.afinity" class="value-afinity-probability"><strong class="afinity-probability">Afinidad: </strong>ND</label>
                <label class="value-afinity-probability"><strong class="afinity-probability">Probabilidad: </strong>{{program.universidad?.indiceCompetencias*100}}%</label>
              </div>

        </div>
    </div>
</div>
