<div class="row test-container d-flex justify-content-center">
    <div class="col-xl-4 mx-0 px-0 d-flex align-items-center display-mobile-none d-lg-none d-xl-flex">
        <img class="girl-test-size" src="/assets/pre-test/girl-test.png" alt="">
    </div>

    <div class="row col-xl-7 pl-4">
        <div class="row col-xl-12">
            <h4 class="title-test" style="font-weight: bold;">¿No estas seguro? ¡Vamos a la fija!</h4>
        </div>

        <div class="row col-xl-12">
            <p class="description-test" style="font-weight: 600;">¡Adios al miedo! ¡A un paso de lograr tus objetivos! <br class="display-mobile-none">
                Conoce las mejores opciones según tu perfil.<br class="display-mobile-none">
                Usa las herramientas para guiar tu proyecto.
            </p>
        </div>

        <div class="row col-xl-12">

            <!--IA Skills-->
            <div class="col-lg-3 col-12 p-0 pl-2 px-lg-2 pt-3 pb-2 py-lg-0 d-flex flex-lg-column align-items-center justify-content-lg-center">
            <img class="img-todo-test" src="/assets/pre-test/world.png" alt="ia">
            <div class="d-block px-2 pt-3">
              <h4 class="subtitle"
                  [ngClass]="{ 'red-subtitle': testIntStatus?.alt == 'start' || testIntStatus?.alt == 'pending', 'green-subtitle': testIntStatus?.alt == 'back' || testIntStatus?.alt == 'done'}">
                IA<br class="display-mobile-none">Skills
              </h4>
              <div *ngIf="testIntStatus" class="item-status-mobile display-mobile" [ngSwitch]="testIntStatus.alt" (click)="goToTestInt()">
                <p *ngSwitchCase="'start'">Comenzar</p>
                <p *ngSwitchCase="'back'">Volver a realizar</p>
                <p *ngSwitchCase="'pending'">Comenzar</p>
                <p *ngSwitchCase="'done'">Volver a realizar</p>
              </div>
              <div class="img-status-container display-mobile-none">
                <img *ngIf="testIntStatus" class="img-status-test" [src]="testIntStatus.src" [alt]="testIntStatus.alt"
                     [hidden]="testIntStatus.alt != 'done' && testIntStatus.alt != 'pending'"
                     (click)="onImage('inter',testIntStatus.alt)">

                <img *ngIf="testIntStatus" class="img-status-test" src="/assets/pre-test/status-start.png" alt="start"
                     [hidden]="testIntStatus.alt != 'start' " (click)="goToTestInt()">

                <img *ngIf="testIntStatus" class="img-status-test" src="/assets/pre-test/status-back.png" alt="back"
                     [hidden]="testIntStatus.alt != 'back' " [ngClass]="{'back-fade': testIntStatus.alt == 'back'}"
                     (click)="goToTestInt()">
              </div>
            </div>
            <img class="img-action display-mobile" src="/assets/pre-test/rigth-arrow.png" alt="arrow" (click)="goToTestInt()">
            </div>

             <!--English test-->
             <div class="col-lg-3 col-12 p-0 pl-2 px-lg-2 pt-3 pb-3 py-lg-0 d-flex flex-lg-column align-items-center justify-content-lg-center">
              <img class="img-todo-test" src="/assets/pre-test/test-bw.png" alt="ingles">
              <div class="d-block px-2 pt-3">
                <h4 class="subtitle">English<br class="display-mobile-none">Test</h4>
                <div class="item-status-mobile display-mobile">
                  <p>Pendiente</p>
                </div>
                <div class="img-status-container display-mobile-none">
                  <img class="img-status-test display-mobile-none" src="/assets/pre-test/status-pending.png" alt="done">
                </div>
              </div>
              <img class="img-action display-mobile" src="/assets/pre-test/rigth-arrow.png" alt="arrow">
            </div>

            <!--Cultura match-->
            <div class="col-lg-3 col-12 p-0 pl-2 px-lg-2 pt-3 pb-2 py-lg-0 d-flex flex-lg-column align-items-center justify-content-lg-center">
                <img class="img-todo-test" src="/assets/pre-test/world.png" alt="internacionalizacion">
                <div class="d-block px-2 pt-3">
                  <h4 class="subtitle"
                      [ngClass]="{ 'red-subtitle': testMatchStatus?.alt == 'start' || testMatchStatus?.alt == 'pending', 'green-subtitle': testMatchStatus?.alt == 'back' || testMatchStatus?.alt == 'done'}">
                    Cultural <br class="display-mobile-none">Match
                  </h4>
                  <div *ngIf="testIntStatus" class="item-status-mobile display-mobile" [ngSwitch]="testIntStatus.alt" (click)="goToTestMatch()">
                    <p *ngSwitchCase="'start'">Comenzar</p>
                    <p *ngSwitchCase="'back'">Volver a realizar</p>
                    <p *ngSwitchCase="'pending'">Comenzar</p>
                    <p *ngSwitchCase="'done'">Volver a realizar</p>
                  </div>
                  <div class="img-status-container display-mobile-none">
                    <img *ngIf="testMatchStatus" class="img-status-test" [src]="testMatchStatus.src" [alt]="testMatchStatus.alt"
                         [hidden]="testMatchStatus.alt != 'done' && testMatchStatus.alt != 'pending'"
                         (click)="onImage('match',testMatchStatus.alt)">

                    <img *ngIf="testMatchStatus" class="img-status-test" src="/assets/pre-test/status-start.png" alt="start"
                         [hidden]="testMatchStatus.alt != 'start' " (click)="goToTestMatch()">

                    <img *ngIf="testMatchStatus" class="img-status-test" src="/assets/pre-test/status-back.png" alt="back"
                         [hidden]="testMatchStatus.alt != 'back' " [ngClass]="{'back-fade': testMatchStatus.alt == 'back'}"
                         (click)="goToTestMatch()">
                  </div>
                </div>
              <img class="img-action display-mobile" src="/assets/pre-test/rigth-arrow.png" alt="arrow" (click)="goToTestMatch()">
            </div>

            <!--Visa test-->
            <div class="col-lg-3 col-12 p-0 pl-2 px-lg-2 pt-3 pb-3 py-lg-0 d-flex flex-lg-column align-items-center justify-content-lg-center">
                <img class="img-todo-test" src="/assets/pre-test/visa.png" alt="visa">
                <div class="d-block px-2 pt-3">
                  <h4 class="subtitle"
                      [ngClass]="{ 'red-subtitle': testVisaStatus?.alt == 'start' || testVisaStatus?.alt == 'pending', 'green-subtitle': testVisaStatus?.alt == 'back' || testVisaStatus?.alt == 'done'}">
                    Visa<br class="display-mobile-none">Test
                  </h4>
                  <div *ngIf="testIntStatus" class="item-status-mobile display-mobile" [ngSwitch]="testIntStatus.alt" (click)="goToTestVisa()">
                    <p *ngSwitchCase="'start'">Comenzar</p>
                    <p *ngSwitchCase="'back'">Volver a realizar</p>
                    <p *ngSwitchCase="'pending'">Comenzar</p>
                    <p *ngSwitchCase="'done'">Volver a realizar</p>
                  </div>
                  <div class="img-status-container display-mobile-none">
                    <img *ngIf="testVisaStatus" class="img-status-test" [src]="testVisaStatus.src" [alt]="testVisaStatus.alt"
                         [hidden]="testVisaStatus.alt != 'done' && testVisaStatus.alt != 'pending'"
                         (click)="onImage('visa',testVisaStatus.alt)">

                    <img *ngIf="testVisaStatus" class="img-status-test" src="/assets/pre-test/status-start.png" alt="start"
                         [hidden]="testVisaStatus.alt != 'start' " (click)="goToTestVisa()">

                    <img *ngIf="testVisaStatus" class="img-status-test" src="/assets/pre-test/status-back.png" alt="back"
                         [hidden]="testVisaStatus.alt != 'back' " [ngClass]="{'back-fade': testVisaStatus.alt == 'back'}"
                         (click)="goToTestVisa()">
                  </div>
                </div>
                <img class="img-action display-mobile" src="/assets/pre-test/rigth-arrow.png" alt="arrow" (click)="goToTestVisa()">
            </div>
        </div>
    </div>
</div>
