import { AccreditationPipe } from './../pipes/accreditation.pipe';
import { NavigationService } from './../services/navigation.service';
import { DecimalPipe, isPlatformBrowser } from '@angular/common';
import { ApiService } from './../services/api.service';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { AlertService } from '../services/alert.service';
import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { MatDialog } from '@angular/material/dialog';
import { AddFavouriteComponent } from './add-favourite/add-favourite.component';
const imagenCabecera = require('../../../assets/utils/imagen-cabecera-pdf.js');
const imagenPie = require('../../../assets/utils/imagen-footer-pdf.js');
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { LocalDataSource } from 'ng2-smart-table';

@Component({
  selector: 'app-favourites',
  templateUrl: './favourites.component.html',
  styleUrls: ['./favourites.component.scss'],
  providers: [AccreditationPipe],
})
export class FavouritesComponent implements OnInit {
  /** CONSTANTS */
  /** Recruitment type */
  readonly TIPO_RECRUITMENT = 'Recruitment';
  /** Variable that holds the api for getting  the students by recruitment */
  readonly USERS_BY_RECRUITMENT = 'api/user/recruitment/';
  /** API path for getting the favourites information */
  readonly FAVOURITES_API = 'api/user/favoritos/';
  /** API path for getting the favourites added by the recruiter */
  readonly ADDED_FAVOURITES_API = 'api/favoritos-adicionales/usuario/';
  readonly FAVOURITE_UNIVERSITIES_API = 'api/user/universidadesFavoritas/';
  /** API path for getting the favourites added by student */
  readonly STUDENT_FAVORITES_GET_API='api/favorito/get-favoritos/';
  readonly STUDENT_FAVORITES_PUT_API='api/favorito/update-favoritos/';
  readonly STUDENT_FAVORITES_DELETE_API='api/favorito/remove-favoritos/';
  /** Constant that holds the api for the user */
  readonly USER_API = 'api/user/';

  /** Settings for the smart table */
  readonly settings = {
    mode:'inline',
    edit: { confirmSave: true, editButtonContent:'<img src="assets/utils/edit.png" alt="search"></img>' },
    // delete:{confirmDelete: true},
    actions: {
      position: 'right',
      add: false,
      delete: false,
      custom: [
        {
          name: 'view',
          title: '<img src="assets/utils/search.png" alt="search"></img>',
        },
        // {
        //   name: 'edit',
        //   title: '<img src="assets/utils/edit.png" alt="search"></img>',
        // },
        {
          name: 'delete',
          title: '<img src="assets/utils/remove.png" alt="search"></img>',
        }
      ],
      edit: true,
      
    },
    columns: {
      university: {
        title: 'College/Universidad',
      },
      city: {
        title: 'Location',
      },
      program: {
        title: 'Program',
      },
      programType: {
        title: 'Program type',
      },
      length: {
        title: 'Length',
      },
      start: {
        title: 'Start date',
      },
      applicationFee: {
        title: 'App fee',
      },
      tuition: {
        title: 'Tuition cost',
      },
      comments:{
        title: 'Comentarios'
      }
    },
  };

  readonly otherProgramsSettings = {
    actions: {
      position: 'right',
      add: false,
      edit: false,
      delete: false,
      custom: [
        {
          name: 'edit',
          title: '<img src="assets/utils/search.png" alt="search"></img>',
        }
      ]
    },
    columns: {
      university: {
        title: 'College/Universidad',
      },
      city: {
        title: 'Location',
      },
      programa: {
        title: 'Program',
      },
      programType: {
        title: 'Program type',
      },
      length: {
        title: 'Length',
      },
      start: {
        title: 'Start date',
      },
      applicationFee: {
        title: 'App fee',
      },
      tuition: {
        title: 'Tuition cost',
      },
      comments:{
        title: 'Comentarios'
      },
      link: {
        type: 'html',
        title: 'Link',
        valuePrepareFunction: (value) => {
          if(value){
            return `<a target="_blank" href="${value}">Visitar</a>`;
          } else {
            return;
          }
        }
      }
    }
  }

  readonly Studentsettings = {
    mode:'inline',
    edit: { confirmSave: true, editButtonContent:'<img src="assets/utils/edit.png" alt="search"></img>' },
    // delete:{confirmDelete: true},
    actions: {
      position: 'right',
      add: false,
      delete: false,
      custom: [
        {
          name: 'view',
          title: '<img src="assets/utils/search.png" alt="search"></img>',
        }
      ],
      edit: false,
    },
    columns: {
      university: {
        title: 'College/Universidad',
      },
      city: {
        title: 'Location',
      },
      programa: {
        title: 'Program',
      },
      programType: {
        title: 'Program type',
      },
      length: {
        title: 'Length',
      },
      start: {
        title: 'Start date',
      },
      applicationFee: {
        title: 'App fee',
      },
      tuition: {
        title: 'Tuition cost',
      },
      comments:{
        title: 'Comentarios'
      }
    },
  };

  /** Variable that holds the id of the current user */
  id: string;
  /** Variable that holds the list of favourites of the user */
  favourites = [];
    /** Variable that holds the list of favourites the recruiter add the user */
  addedFavourites = [];
  /** Variable that holds the list of the user's favourite universities*/
  favouriteUniveristies = [];
  /** Variable that holds the table data */
  tableData = [];
  tableDataStudent=[];
  /** Variable that holds the data of the programs added by the recruiters */
  otherPrograms : any;
  /** Variable with basic information of current user */
  user: any = {};

  /** Selected student if is recruitment advisor */
  student;

  /** Current recruiter students */
  students;

  /** If is a recruitment partner */
  isRecruitment = true;

  /** Variable containing base64 code */
  fileBold: string;

  /** Variable containing base64 code */
  fileNormal: string;
  newData: any;
  /** Variable that holds the current dialog */
  activeDialog: any;

  favouritesStudent:any;
  dataPdf:any;

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private decimalPipe: DecimalPipe,
    private accreditationPipe: AccreditationPipe,
    private navigationService: NavigationService,
    private ngxSpinnerService: NgxSpinnerService,
    private dialogService: MatDialog,
    private translate: TranslateService,
    @Inject(PLATFORM_ID) private platformId
  ) {}

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.user = JSON.parse(localStorage.getItem('user'));
      if (this.user.tipo === this.TIPO_RECRUITMENT) {
        this.isRecruitment = true;
        this.getRecruitmentStudents({ id: this.user._id }).subscribe((response) => {
          this.students = response as any[];
        });
      } else {
        this.isRecruitment = false;
        this.id = this.user._id;
        this.getFavouritesInfo();
        this.getFavouriteUniversitiesInfo();
      }
      fetch('assets/Fonts/Monstserrat-bold-pdf.txt')
        .then((response) => response.text())
        .then((text) => (this.fileBold = text));

      fetch('assets/Fonts/Monstserrat-regular-pdf.txt')
        .then((response) => response.text())
        .then((text) => (this.fileNormal = text));
    }
  }

  /**
   * Handles on student change when is a recruiter
   * @param object with student id property
   */
  onStudentChange({ id }): void {
    this.student = this.students.find((s) => s._id === id);
    this.getFavouritesInfo();
  }

  /**
   * Retrieves recruitment partner students
   * @returns Observable with response from backend
   */
  getRecruitmentStudents({ id }): Observable<any> {
    return this.apiService.get({ api: this.USERS_BY_RECRUITMENT + id });
  }

  /** Retrieves the favourites information from the backend */
  getFavouritesInfo(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.FAVOURITES_API + this.id }).subscribe(
      (response) => {
        this.apiService.get({ api: `${this.ADDED_FAVOURITES_API}${this.isRecruitment ? this.student._id : this.id}` }).subscribe(responseAdded => {
          if(responseAdded.success){
            for (const favourite of response) {
              favourite.inFavourites = true;
            }
            for(const favouriteAdded of responseAdded.result){
              favouriteAdded.inFavourites = true;
            }
            this.favourites = response;
            this.addedFavourites = responseAdded.result;
            this.setUpAditionalFavourites({aditionals:this.addedFavourites})
            // this.setupTableInfo({ programs: response, addedPrograms: responseAdded.result });
            // this.prepareData();
          }
        });
      },
      (err) => {
        this.alertService.showError({ msg: 'There was an error finding the information, please try again later' });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );

    this.apiService.get({ api: this.STUDENT_FAVORITES_GET_API + this.id }).subscribe(
      (response) => {
        this.favouritesStudent=[]
        for (let program of response) {
          this.favouritesStudent.push(program)
        }
        //  
        this.dataToPdf();
      })

  }

  /** Retrieves the favourite universities information from the backend */
  getFavouriteUniversitiesInfo(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.FAVOURITE_UNIVERSITIES_API + this.id }).subscribe(
      (response) => {
        //  
        for (const favourite of response) {
          favourite.inFavourites = true;
        }
        this.favouriteUniveristies = response;
      },
      (err) => {
         
        this.alertService.showError({ msg: 'There was an error finding the information, please try again later' });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  setUpAditionalFavourites({aditionals}){
    let listAditionals =[];
    for(const program of aditionals){
      if(program.activo){
        const programDataStudent = {
          _id: program._id,
          city: program.ubicacion,
          university: program.universidad,
          programa: program.programa,
          length: program.stdLength,
          start: program.start,
          applicationFee: program.fee,
          programType: program.tipoPrograma,
          tuition: program.tuitionCost,
          comments:program.comentarios?program.comentarios:"",
          link: program.link,
          // isAddedByRecruiter: true,
        };
        // this.otherPrograms.push(programDataStudent)
        listAditionals.push(programDataStudent)
      }
      
    }
    this.otherPrograms = new LocalDataSource(listAditionals);
    //  
  }

  /** Set's up the table information */
  setupTableInfo({ programs, addedPrograms }: { programs: Array<any>, addedPrograms: Array<any> }): void {
    const tableData = [];
    const otherPrograms = [];
    let tuitionByPeriod;
    let period;
    let startPeriod;
    let coOp;
    let appFee;
    let tuCost;
    for (const program of programs) {
      startPeriod = '';
      appFee = 'ND';
      tuCost = 'ND';
      if (program.yearTuition !== undefined && program.yearTuition !== null) {
        tuitionByPeriod = program.yearTuition;
        period = 'Year';
      } else {
        tuitionByPeriod = program.programTuition;
        period = 'Full program';
      }
      for (const start of program.starts) {
        switch (start) {
          case 0:
            startPeriod += 'Winter, ';
            break;
          case 1:
            startPeriod += 'Spring, ';
            break;
          case 2:
            startPeriod += 'Summer, ';
            break;
          case 3:
            startPeriod += 'Fall, ';
            break;
        }
      }
      if (program.applicationCost || program.universidad.applicationFee) {
        appFee =
          this.decimalPipe.transform(program.applicationCost || program.universidad.applicationFee) +
          ' ' +
          program.universidad.ciudad.pais.moneda;
      }
      if (tuitionByPeriod) {
        tuCost =
          this.decimalPipe.transform(tuitionByPeriod) + ' ' + program.universidad.ciudad.pais.moneda + '/' + period;
      }
      const programData = {
        id: program._id,
        destiny: program.universidad.ciudad.pais.icono,
        city: program.universidad.ciudad.nombre,
        university: program.universidad.nombre,
        program: program.nombre,
        length: program.length,
        start: startPeriod,
        applicationFee: appFee,
        programType: program.acreditacionShown,
        tuition: tuCost,
        slug: program.slug,
      };
      tableData.push(programData);
    }
    for(const program of addedPrograms){
      if(program.activo !== false){
        const programData = {
          _id: program._id,
          ubicacion: program.ubicacion,
          universidad: program.universidad,
          programa: program.programa,
          stdLength: program.stdLength,
          start: program.start,
          fee: program.fee,
          tipoPrograma: program.tipoPrograma,
          tuitionCost: program.tuitionCost,
          link: program.link,
          comentarios: program.comentarios,
          slug: undefined,
          isAddedByRecruiter: false,
        };
        const programDataStudent = {
          _id: program._id,
          destiny: program.ubicacion,
          city: program.ubicacion,
          university: program.universidad,
          program: program.programa,
          length: program.stdLength,
          start: program.start,
          applicationFee: program.fee,
          programType: program.tipoPrograma,
          tuition: program.tuitionCost,
          slug: program.link,
          comentarios: program.comentarios,
          isAddedByRecruiter: true,
        };
        otherPrograms.push(programData);
        tableData.push(programDataStudent);
      }
    }
    this.tableData = tableData;
    //  
    this.otherPrograms = otherPrograms;
  }

  onCustomAction(event): void {
    if (event.action === 'view') {
      if(!event.data.slug || event.data.slug.includes('not-found') || event.data.slug.includes('undefined')) {
        return
      }
      if(event.data.isAddedByRecruiter){
        const anchor = document.createElement('a');
        anchor.href = event.data.slug;
        anchor.target="_blank";
        anchor.click();
      } else {
        this.navigationService.navigateTo({ path: `program/${event.data.slug}` });
      }
    }
    if(event.action == 'edit'){
      return
    }
    if(event.action == 'delete'){
      this.deleteFavorite(event.data)
      return
    }
  }

  onCustomOtherPrograms(event): void {
    if (event.action === 'edit') {
      this.openEditFavouriteModal(event.data)
    }
  }

  deleteFavorite(data){
    Swal.fire({
      title: '¿Estas seguro que deseas eliminar el favorito?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      if(result.isConfirmed){
        this.apiService.delete({api:this.STUDENT_FAVORITES_DELETE_API + data._id}).subscribe(
          (response)=>{
            this.apiService.post({ api: this.USER_API + 'remove-favourite/', data:data={...data,id:this.id} }).subscribe(
              (responseDeleted) => {
                this.alertService.showSuccess({ msg: this.translate.instant('Alerts.removed-favourite') });
                // Update favourites list in the local storage to keep the information consistent
                let favoritos = JSON.parse(localStorage.getItem('Favoritos'));
                favoritos = favoritos.filter((pId) => pId !== data.programa);
                localStorage.setItem('Favoritos', JSON.stringify(favoritos));
                this.getFavouritesInfo();
              },
              (err) => {
                this.alertService.showError({
                  msg: this.translate.instant('Programs-list.could-not-remove'),
                });
              }
            );
          }
        )
      }
    })    
  }

  updateFavorite(event){
    this.apiService.put({api:this.STUDENT_FAVORITES_PUT_API + event._id , data:event}).subscribe(
      (response)=>{
        //  
        this.getFavouritesInfo();
      }
    )
    
  }

  /**
   * The method is responsible for preparing the data in the pdf file.
   */
  // prepareData() {
  //   const data = [];
  //   // Prepare the data
  //   let tuitionByPeriod;
  //   let period;
  //   let startPeriod;
  //   let coOp;
  //   let appFee;
  //   let tuCost;
  //   for (const program of this.favourites) {
  //     startPeriod = '';
  //     appFee = 'ND';
  //     tuCost = 'ND';
  //     if (program.yearTuition !== undefined && program.yearTuition !== null) {
  //       tuitionByPeriod = program.yearTuition;
  //       period = 'Year';
  //     } else {
  //       tuitionByPeriod = program.programTuition;
  //       period = 'Full program';
  //     }
  //     if (program.coOp == true) {
  //       coOp = 'with Coop';
  //     } else {
  //       coOp = 'no Coop';
  //     }
  //     for (const start of program.starts) {
  //       switch (start) {
  //         case 0:
  //           startPeriod += 'Winter, ';
  //           break;
  //         case 1:
  //           startPeriod += 'Spring, ';
  //           break;
  //         case 2:
  //           startPeriod += 'Summer, ';
  //           break;
  //         case 3:
  //           startPeriod += 'Fall, ';
  //           break;
  //       }
  //     }
  //     if (program.applicationCost) {
  //       appFee = this.decimalPipe.transform(program.applicationCost) + ' ' + program.universidad.ciudad.pais.moneda;
  //     }
  //     if (tuitionByPeriod) {
  //       tuCost =
  //         this.decimalPipe.transform(tuitionByPeriod) + ' ' + program.universidad.ciudad.pais.moneda + '/' + period;
  //     }

  //     if (program.inFavourites === true) {
  //       const programData = [];
  //       programData.push(program.nombre);
  //       programData.push(program.universidad.nombre);
  //       programData.push(program.acreditacionShown);
  //       programData.push(program.universidad.ciudad.nombre);
  //       programData.push(program.stdLength);
  //       programData.push(startPeriod);
  //       programData.push(tuCost);
  //       let slug = program.slug;
  //       slug = slug.replaceAll('\n', '%0D%0A');
  //       programData.push('https://www.u360education.com/web/program/' + slug);
  //       data.push(programData);
  //     }
  //   }
  //   for(let addedProgram of this.addedFavourites){
  //     const programData = [];
  //     programData.push(addedProgram.programa);
  //     programData.push(addedProgram.universidad);
  //     programData.push(addedProgram.tipoPrograma);
  //     programData.push(addedProgram.ubicacion);
  //     programData.push(addedProgram.stdLength);
  //     programData.push(addedProgram.start);
  //     programData.push(addedProgram.tuitionCost);
  //     if(addedProgram.link){
  //       programData.push(addedProgram.link);
  //     } else {
  //       programData.push('not-found');
  //     }
  //     data.push(programData);
  //   }
  //   this.newData = data;
  // }

  dataToPdf(){
    let data=[]
    for (const program of this.favouritesStudent) {
        const programData = [];
        programData.push(program.programa);
        programData.push(program.university);
        programData.push(program.programType);
        programData.push(program.city);
        programData.push(program.length);
        programData.push(program.start);
        programData.push(program.tuition);
        programData.push(program.comments);
        let slug = program.slug;
        slug = slug.replaceAll('\n', '%0D%0A');
        programData.push('https://www.u360education.com/web/program/' + slug);
        data.push(programData);
    }
    this.dataPdf=data
  }

  /** Opens up the modals that actives the add-favourite component, only for the recruiters */
  openAddFavouriteModal(){
    if(this.student){
      this.activeDialog = this.dialogService.open(AddFavouriteComponent, { data: {
        usuario: this.student._id,
        isEditing: false
      }}).afterClosed().subscribe(() => this.getFavouritesInfo());
    }

  }

  openEditFavouriteModal(data){
    if(this.student){
      this.activeDialog = this.dialogService.open(AddFavouriteComponent, { data: {
        usuario: this.student._id,
        additionalFav: data,
        isEditing: true
      }}).afterClosed().subscribe(() => this.getFavouritesInfo())
    }
  }

  /** Handles the click on the download pdf button */
  onDownloadPdfTable(): void {
    let student;
    if (this.isRecruitment) {
      student = this.student;
    } else {
      student = this.user;
    }
    /** Generate the pdf */
    const doc = new jsPDF('landscape', 'mm', [297, 300]);
    // let logo_url = '../../../assets/utils/recurso-pdf.jpg';
    // let imag = new Image();
    // // imag.alt = 'u360 logo';


    // add the font to jsPDF
    doc.addFileToVFS('Montserrat-Bold.ttf', `${this.fileBold}`);
    doc.addFont('Montserrat-Bold.ttf', 'montBold', 'normal');
    doc.addFileToVFS('Montserrat-Regular.ttf', `${this.fileNormal}`);
    doc.addFont('Montserrat-Regular.ttf', 'montNormal', 'normal');
    // Header
    doc.setFont('montNormal');
    doc.addImage(imagenCabecera.base, 'JPEG', 0, 0, 300, 40);
    doc.setFontSize(13);
    doc.setTextColor('#332F2E');
    moment.locale('es');
    doc.text(`${moment().format('Do MMMM YYYY')}`, 15, 56);
    doc.text(`Hola, ¡${student.nombre} ${student.apellido}!`, 15, 70);

    doc.text(
      'Es un gusto poder conocer un poco más acerca de tu proyecto de estudios y esperamos hacer parte de él!',
      15,
      80
    );

    doc.text(
      'Nuestro propósito principal es encontrar juntos la opción que más se ajuste a tus preferencias académicas,',
      15,
      90
    );
    doc.text(
      'personales y profesionales y crear un plan de estudios bajo nuestra filosofía "creado por ti, creado con nosotros":',
      15,
      96
    );
    doc.text(' - Realizamos un filtro de programas para encontrar los más ideales para ti. ', 15, 104);
    doc.text(
      ' - Tú analizas y decides cúal opción es la mejor. ¡Nosotros siempre te podemos acompañar en esa toma de decisión! ',
      15,
      110
    );
    doc.text(
      ' - Los precios son dados cómo referencia y deben ser confirmados por el consultor y por la institución.',
      15,
      116
    );
    doc.text('A continuación encuentras algunas opciones que encontramos ideales para ti:', 15, 124);
    autoTable(doc, {
      html: '#mytable',
      headStyles: {
        halign: 'center',
        fontStyle: 'bold',
        fontSize: 13,
        fillColor: '#575BA3',
        lineColor: '#DEDEDE',
        lineWidth: 0.5,
      },
      bodyStyles: { fontSize: 13, lineColor: '#DEDEDE', lineWidth: 0.5 },
      columnStyles: {
        0: { cellWidth: 35 },
        1: { cellWidth: 35 },
        2: { cellWidth: 30 },
        3: { cellWidth: 30 },
        4: { cellWidth: 25 },
        5: { cellWidth: 25 },
        6: { cellWidth: 20 },
        7: { cellWidth: 35 },
        8: { cellWidth: 35, fontStyle: 'bold' },
      },
      didDrawCell: function (data) {
        if (data.column.index === 8 && data.cell.section === 'body') {
          let td: any = data.cell.raw;
          let a = td.getElementsByTagName('a')[0];
          if(a.href.includes("undefined") || a.href.includes('not-found')) return
          let textPos = data.cell.getTextPos();
          doc.textWithLink('Link', textPos.x, textPos.y + 3, { url: a.href });
          doc.setDrawColor(10, 10, 10);
          doc.line(textPos.x, textPos.y + 4, textPos.x + 10, textPos.y + 4);
        }
      },
      startY: 136,
    });

    // @ts-ignore
    let finalY = doc.lastAutoTable.finalY;

    doc.setFont('montBold');
    finalY += 10;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('Notas:', 15, finalY);

    doc.setFont('montNormal');
    finalY += 10;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text(
      '*Precios estimados para el año 2022-2023 únicamente de la parte de estudios (Tuition) - no incluye otros costos anuales',
      15,
      finalY
    );
    finalY += 5;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text(
      'como fees adicionales gubernamentales, libros (a menos que esté especificado). seguros médicos, valores de visado ni',
      15,
      finalY
    );
    finalY += 5;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('cualquier otro gasto que se efectúe durante el proceso.', 15, finalY);
    finalY += 5;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.setFont('montBold');
    doc.text('Por favor estimar Incrementos de las Instituciones anuales para curso después de Mayo 2022', 15, finalY);
    doc.setFont('montNormal');
    finalY += 10;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text(
      '*Este es un cuadro comparativo con datos e información de universidades. Los precios y cobros finales son estipulados',
      15,
      finalY
    );
    finalY += 6;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('por cada institución en el momento de entregar la factura.', 15, finalY);
    finalY += 10;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text(
      'HEC solamente recibe el pago del App Fee en Colombia. Los demás Fees deben ser pagados directamente a la ',
      15,
      finalY
    );
    finalY += 6;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('Universidad/College PROYECTO ACADÉMICO 2022-2023', 15, finalY);

    finalY += 20;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.setFont('montBold');
    doc.text('Información de interés Destinos / Q&A', 15, finalY);

    let newData = [
      [
        ['Alemania'],
        [
          'Se debe bloquear una cuenta con 853 (euros) mensuales x hasta máximo 1 año (10.236 euro/año). Se desbloquerán una vez en destino de forma mensual para gastos de manutención. Excepción de bloqueo: fondos de gobierno ej ICETEX',
        ],
        ['75 USD'],
      ],
      [
        ['Canadá'],
        [
          '10.000 CAD x año para el aplicante principal / Quebec 11.000 CAD + costo programa primer año + valor tiquete. Mínimo a demostrar, en pesos a TRM del día que se analiza el caso en embajada (siempre se recomienda incrementar un 10%)',
        ],
        ['150 CAD Study Permit + 85 CAD Biométricos'],
      ],
      [
        ['UK'],
        [
          'Para programas de 1+ años - General TIER4                   Londres: £1334 * 9 meses = £12006                                  Afuera de Londres: £1023* 9meses = £9207                            + valor programa - abono para CAS - valores becas ',
        ],
        ['£ 348 + £300 seguro médico (a partir de octubre, £470'],
      ],
    ];

    finalY += 20;
    autoTable(doc, {
      headStyles: {
        halign: 'center',
        fontStyle: 'bold',
        fontSize: 13,
        fillColor: '#575BA3',
        lineColor: '#DEDEDE',
        lineWidth: 0.5,
      },
      head: [['País', 'Demostración fondos para visas', 'Valor visa']],
      body: newData,
      bodyStyles: { fontSize: 13, lineColor: '#DEDEDE', lineWidth: 0.5 },
      columnStyles: {
        0: { cellWidth: 60, fontStyle: 'bold' },
        1: { cellWidth: 130 },
        2: { cellWidth: 60 },
      },
      startY: finalY,
    });

    // @ts-ignore
    finalY = doc.lastAutoTable.finalY + 20;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('¿Se puede trabajar durante mis estudios?', 15, finalY);

    doc.setFont('montNormal');
    finalY += 15;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('Alemania, Canadá, UK: Sí, solamente durante estudios de Educación Superior: medio', 15, finalY);
    finalY += 5;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('tiempo durante estudios / tiempo completo durante vacaciones.', 15, finalY);

    doc.setFont('montBold');
    finalY += 15;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('¿Tendré un permiso de trabajo para quedarme una vez me gradúe?', 15, finalY);
    doc.setFont('montNormal');
    finalY += 15;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('Alemania: 18 meses para programas de pregrado y posgrado.', 15, finalY);
    finalY += 15;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('Canadá: 1 año para programas de 1 año y hasta 3 años para programas de 2+años. Solamente', 15, finalY);
    finalY += 5;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('cuando la institución tiene el DLI aprobado por el gobierno.', 15, finalY);
    finalY += 15;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('UK: 2 años para programas de pregrado y posgrado desde 1 año de carrera.', 15, finalY);
    finalY += 15;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    doc.text('USA: Depende de la carrera. Las carreras STEM tendrán hasta 3 años de OPT', 15, finalY);

    finalY += 5;
    finalY = this.verifyEndOfPage({ doc, value: finalY });
    // let footer_url = '../../../assets/utils/footer-pdf.jpg';
    // let footer_img = new Image();
    // footer_img.src = footer_url;
    // footer_img.alt = 'footer';
    // Footer

    doc.addImage(imagenPie.base2, 'JPG', 0, finalY, 300, 75);
    doc.save('Favourites.pdf');
  }

  /**
   * verifies if is necessary to add a new page
   * @param doc jspdf document
   * @param value coordinate that is being evaluated
   */
  verifyEndOfPage({ doc, value }: { doc: jsPDF; value: number }): number {
    if (doc.internal.pageSize.getHeight() < value + 15) {
      doc.addPage();
      return 15;
    } else {
      return value;
    }
  }
}
