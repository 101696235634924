<div class="free-guides">
  <div class="free-guides-container">
    <div class="free-guides-right">
      <img (click)="goBack()" class="left-arrow display-mobile" src="/assets/wizard-images/left-arrow.png" alt="left-arrow">
      <h2 class="free-guides-subtitle">¿Sabes qué necesitas para convertirte en un líder global?</h2>
      <video src = "/assets/new-wizard-videos/bienvenida.mp4" controls></video>
      <h1 class="free-guides-title">¡Anímate y realiza nuestro test!</h1>
      <div class="row">
        <div class="col-1">
          <img *ngIf="seleccionado==0" (click)="cambiarSeleccionado()" style="height: auto; width: 20px;" src="/assets/utils/radio-boton-gris-vacio.png" alt="acepta">
          <img *ngIf="seleccionado==1" style="height: auto; width: 20px;" src="/assets/utils/radio-boton-gris-lleno.png" alt="acepta">
        </div>

        <div class="col-10">
          <p class="conditions">
            Acepto los <span class="negrilla">términos y condiciones y politica de privacidad</span>
          </p>

        </div>
      </div>
        <!-- <input type="radio" id="radio2" name="radio01" class="margin-radio" > -->

        <!-- <img class="margin-radio" *ngIf="seleccionado==0" src="/assets/utils/radio-boton-rosado-vacio.png" alt="acepta" (click)="cambiarSeleccionado()"> -->




      <div class="buttons-container">
        <button class="btnComenzar" (click)="comenzarTest()">Empezar test</button>
      </div>
    </div>
  </div>
</div>
