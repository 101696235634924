import { Component, OnInit, Inject, Optional, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl, ValidatorFn } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { AlertService } from '../services/alert.service';
import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from '../terms-conditions/terms-conditions.component';
import { Location } from '@angular/common';
import { NavigationService } from '../services/navigation.service';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { BlockedModalComponent } from '../shared/blocked-modal/blocked-modal.component';
import { SignUpSuccessComponent } from './sign-up-success/sign-up-success.component';
import { NotVerifiedComponent } from './not-verified/not-verified.component';
import { ApiService } from '../services/api.service';
import Swal from 'sweetalert2';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent implements OnInit {
  /** Variable that holds the login form */
  loginForm: FormGroup;

  /** Variable that holds the observable for login monitoring */
  allObservable: Observable<any>;

  /** Variable that indicates if the component is loading */
  loading = false;

  countriesList: any = [];
  filteredCountries: Observable<any>;

  /** Variable that holds the current authAction  */
  authActionVar: string;
  newConfirmType: string = 'password';
  eye: string = 'assets/utils/eye-solid.svg';
  eyeSlash: string = 'assets/utils/eye-slash-solid.svg';

  /**It's to english test login */
  @Input() oculto: any;

  /** Constant that hols the API route for validate code */
  readonly GETCODE = 'api/codigoTestCode/';

  constructor(
    private formBuilderService: FormBuilder,
    @Optional() private dialogRef: MatDialogRef<AuthComponent>,
    public authService: AuthService,
    private apiService: ApiService,
    private alertService: AlertService,
    private dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) private data: any,
    private location: Location,
    private navigationService: NavigationService
  ) {}

  async ngOnInit() {
    if (this.data !== null && this.data !== undefined) {
      this.authActionVar = this.data.authAction;
    } else {
      this.authActionVar = 'login';
    }
    this.setForm({ authAction: this.authActionVar });
    this.countriesList = await this.apiService.getCountries();
    if (this.authActionVar === 'signup') {
      this.filteredCountries = this.loginForm.get('cod').valueChanges.pipe(
        startWith(''),
        map((search) => this._filterCountries(search)),
      );
    }
  }

  private _filterCountries(search: string) {
    const filterValue = search.toLowerCase();
    return this.countriesList.filter((country) => {
      const countryString = `
      ${country.idd?.root || ''}${country.idd.suffixes ? country.idd.suffixes[0] : ''}
      ${country.name?.common?.toLowerCase() || ''}`;
      return countryString.includes(filterValue);
    });
  }

  public async singInFacebook() {
    if (this.dialogRef) {
      this.authService.signInWithFB({
        callback: () => {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
          this.authService.verifyPhoneUser();
        },
      });
    } else {
      this.authService.signInWithFB({});
    }
  }

  public async singInGoogle() {
    if (this.dialogRef) {
      this.authService.signInWithGoogle({
        callback: () => {
          if (this.dialogRef) {
            this.dialogRef.close();
          }
          this.authService.verifyPhoneUser();
        },
      });
    } else {
      this.authService.signInWithGoogle({});
    }
  }

  /**
   * Sets up the form depending on the action that is being performed
   * @param authAction action that is being performed in the form, can be login or signup
   */
  setForm({ authAction }: { authAction: string }): void {
    switch (authAction) {
      case 'login':
        this.loginForm = this.formBuilderService.group({
          email: ['', [Validators.required, Validators.email]],
          password: ['', Validators.required],
        });
        break;
      case 'signup':
        this.loginForm = this.formBuilderService.group({
          nombre: ['', Validators.required],
          apellido: ['', Validators.required],
          email: ['', [Validators.required, Validators.email,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
          password: ['', [Validators.required, this.validatePass()]],
          telefono: ['', [Validators.required, Validators.min(1000000000), Validators.max(9999999999)]],
          procedenciaComoLead: '',
          cod: ['', Validators.required],
        });
        break;
    }
  }

  /**
   * Returns the current auth action
   */
  get authAction(): string {
    return this.authActionVar;
  }

  /**
   * Returns the email form control
   */
  get email(): AbstractControl {
    return this.loginForm.get('email');
  }
  /**
   * Returns the password form control
   */
  get password(): AbstractControl {
    return this.loginForm.get('password');
  }
  /**
   * Returns the name form control
   */
  get name(): AbstractControl {
    return this.loginForm.get('nombre');
  }
  /**
   * Returns the form control for last name
   */
  get lastName(): AbstractControl {
    return this.loginForm.get('apellido');
  }

  /**
   * Returns the abstract control for telephone
   */
  get telephone(): AbstractControl {
    return this.loginForm.get('telefono');
  }

  /**
   * Returns the code form control
   */
  get code(): AbstractControl {
    return this.loginForm.get('cod');
  }

  /**
   * Returns the abstract control for precedence as lead
   */
  get precedenceAsLead(): AbstractControl {
    return this.loginForm.get('procedenciaComoLead');
  }

  /**
   * Handles the form change event
   * @param authAction new auth action, can be login or signup
   */
  onFormChange({ authAction }: { authAction: string }): void {
    Object.keys(this.loginForm.controls).forEach((control) => {
      this.loginForm.removeControl(control);
    });
    this.authActionVar = authAction;
    this.setForm({ authAction });
  }

  /**
   * Handles login form submission
   */
  onSubmit(): void {
    if (this.loginForm.valid) {
      if (this.authAction === 'login') {
        this.login();
      } else if (this.authAction === 'signup') {
        this.signUp();
      }
    } else {
      this.loginForm.markAllAsTouched();
    }
  }

  /**
   * Handles the login event
   */
  login(): void {
    const credentials = this.loginForm.value;
    credentials.email = credentials.email.trim();
    credentials.email = credentials.email.toLowerCase();
    this.loading = true;
    this.allObservable = this.authService.authenticateUser({
      email: credentials.email,
      password: credentials.password,
    });
    this.allObservable.subscribe(
      (response) => {
        if (response.success) {
          if (!response.user.confirmado) {
            this.dialog.open(NotVerifiedComponent);
          } else if (response.user.activo) {
            const userData = JSON.stringify(response.user);
            const tipoUser = JSON.stringify(response.user.tipo);
            const favoritos = JSON.stringify(response.user.favoritos);
            const uniFavoritas = JSON.stringify(response.user.universidadesFavoritas);
            const code = response.user.procedenciaComoLead;
            this.authService.setIsPremiumUser(code && code !== 'NA');
            localStorage.setItem('Favoritos', favoritos);
            localStorage.setItem('UniFavoritas', uniFavoritas);
            localStorage.setItem('tipoUser', tipoUser);
            this.authService.saveCredentials({
              user: userData,
              token: response.token,
            });
            this.authService.setLoggedIn({ status: true });
            if (this.dialogRef !== null) {
              this.dialogRef.close();
              this.navigationService.navigateTo({ path: '/preTest' });
            } else {
              const intendedUrl = this.authService.getIntendedUrl();
              if (intendedUrl !== null && intendedUrl !== undefined) {
                this.navigationService.navigateTo({ path: intendedUrl });
              } else {
                this.navigationService.navigateTo({ path: '/preTest' });
              }
            }
          } else {
            this.dialog.open(BlockedModalComponent, { disableClose: true });
          }
        } else {
          alert(response.msg);
        }
      },
      (err) => {
        this.alertService.showError({
          msg: 'There was a problem logging in, please try again later',
        });
      },
      (complete?) => {
        this.loading = false;
      }
    );
  }

  /**
   * Handles the signup event
   */
  async signUp(): Promise<void> {
    this.loading = true;
    const credentials = this.loginForm.value;
    credentials.email = credentials.email.trim();
    credentials.email = credentials.email.toLowerCase();
    if (credentials.procedenciaComoLead) {
      try {
        const validation = await this.validateCode(credentials.procedenciaComoLead, credentials.email);
        if (validation) {
          this.registerUser(credentials);
        } else {
          this.loading = false;
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'El código ingresado no se encuentra o no esta asignado al email especificado',
          });
        }
      } catch (e) {
        this.loading = false;
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'El código ingresado no existe',
        });
      }
    } else {
      this.registerUser(credentials);
    }
  }

  registerUser(credentials: any): void {
    // tslint:disable-next-line: no-string-literal
    this.allObservable = this.authService.register({
      nombre: credentials.nombre,
      apellido: credentials.apellido,
      password: credentials.password,
      email: credentials.email,
      tipo: 'Estudiante',
      telefono: credentials.cod + credentials.telefono,
      procedenciaComoLead: credentials.procedenciaComoLead || 'NA',
      confirmado: false,
    });
    this.allObservable.subscribe(
      (response) => {
        if (response.success) {
          this.dialogRef.close();
          this.dialog.open(SignUpSuccessComponent);
        } else {
          this.alertService.showError({ msg: response.msg });
        }
      },
      (err) => {
        this.alertService.showError({
          msg: 'There was a problem creating your account, please try again later',
        });
      },
      (complete?) => {
        this.loading = false;
      }
    );
  }

  /**
   * Opens privacy policy modal
   */
  openPrivacyModal() {
    this.dialog.open(PrivacyPolicyComponent, {
      maxHeight: '500px',
    });
  }

  /**
   * Opens terms and conditions modal
   */
  openTermsModal() {
    this.dialog.open(TermsConditionsComponent, {
      maxHeight: '500px',
    });
  }

  /**
   * Open mdl forget password
   */
  public onForgotPassword() {
    this.dialog.open(ForgotPasswordComponent, {
      data: {},
    });
  }

  private invertPassType(): string {
    if (this.newConfirmType === 'text') {
      this.newConfirmType = 'password';
      return this.newConfirmType;
    }
    this.newConfirmType = 'text';
    return this.newConfirmType;
  }

  validatePass():ValidatorFn{
    return (control:AbstractControl):{[key:string]:any} | null =>{
      const value = control.value;
      if(!value){
        return null
      }
      const hasNumber = /[0-9]/.test(value);
      const hasUpperCase = /[A-Z]/.test(value);
      const hasMinLength = value.length>=6;

      const valid = hasNumber && hasUpperCase && hasMinLength;
      return valid ? null : {password : true}
    }
  }

  async validateCode(code: string, email: string): Promise<boolean> {
    const result = await this.apiService.get({api: this.GETCODE + code}).toPromise();
    return result && result[0]?.emailUsuario  === email;
  }
}
