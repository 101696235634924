import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { MatDialog } from '@angular/material/dialog';
import { MoreInfoComponent } from './more-info/more-info.component';
import SwiperCore, { Pagination } from 'swiper';
SwiperCore.use([Pagination]);

@Component({
  selector: 'app-public-home',
  templateUrl: './public-home.component.html',
  styleUrls: ['./public-home.component.scss']
})
export class PublicHomeComponent implements OnInit {

  /** Constant that holds the path to the blog API */
  readonly CONFIG_API = 'api/config/';

  /** Variable that contain all configs */
  config: any;

  //Variable logos de la universidad
  universitiesLogosList = Array(14).fill(1).map((value, index) => `assets/home/logos/universities/logo-${index+1}.png`);

  //variables para mostrar informacion
  showStudent:boolean = false;
  showUniversity:boolean = false;
  showSchool:boolean = false;

  constructor(
    private apiService: ApiService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    this.getConfig();
  }

  getConfig(): void {
    this.apiService.get({ api: this.CONFIG_API }).subscribe(
      (response) => {
        this.config = response[0];
      }
    );
  }

  openModalInfo(){
   this.dialog.open(MoreInfoComponent)
  }

}
