import { Component, EventEmitter, Input, OnInit, Output, ViewChild, ElementRef, HostListener } from '@angular/core';
import { NavigationService } from '../../services/navigation.service';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-principal-menu',
  templateUrl: './principal-menu.component.html',
  styleUrls: ['./principal-menu.component.scss']
})
export class PrincipalMenuComponent implements OnInit {


  constructor(
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
  ) {
  }

  actived: string;
  isRecruitment: boolean;
  isPremiumUser: boolean;
  isActiveMenuMobile: boolean;
  isColegio: boolean;
  isEstudiante: boolean;
  showMisExperiencias: boolean = false;

  @Input() displayMenu = false;
  @Input() escapeMenu = false;
  @Output() newEventEmiter = new EventEmitter<boolean>();
  @Output() isActiveMenuMobileEmitter = new EventEmitter<boolean>();
  @ViewChild('boxSidebarMenu') boxSidebarMenu: ElementRef;


  ngOnInit(): void {
    this.checkUrl();
    const user = JSON.parse(localStorage.getItem('user'));
    this.checkProfileType(user);
    this.authService.isPremiumUser$.subscribe((result) => this.isPremiumUser = result);
    this.activatedRoute.url.subscribe((url) => this.checkUrl(url[0].path));
  }

  activeOption(option): void {
    this.actived = option;
  }

  @HostListener('click')
  clickout(): void{
      this.displayMenu = false;
  }

  onGoToProfile(): void {
    this.navigationService.navigateTo({ path: 'profile' });
  }

  checkUrl(urlActived?: string): void{
    const url = urlActived || document.location.pathname;
     
    if (url === '/web/home' || url === '/web/program/search'){
      this.actived = 'search';
    } else if (url === '/web/preTest'){
      this.actived = 'match';
    } else if (url === '/web/wishlist'){
      this.actived = 'favourites';
    } else if (url === '/web/skill-dashboard'){
      this.actived = 'dashboard';
    } else if (url === '/web/profile'){
      this.actived = 'documents';
    } else if (url === '/web/my-applications'){
      this.actived = 'applications';
    } else if (url === '/web/blog'){
      this.actived = 'blog';
    } else if (url === '/web/curriculum' || url === '/web/letter') {
      this.actived = 'cv';
    } else if (url === '/web/dashboard-school'){
      this.actived = 'dashboard-school';
    } else if (url === '/web/students'){
      this.actived = 'students';
    } else if (url === '/web/my-experiences'){
      this.actived = 'experiences';
    }
  }

  checkProfileType(user): void{
    this.isRecruitment = user?.tipo === 'Recruitment';
    this.isColegio = user?.tipo === 'Colegio';
    this.isEstudiante = user?.tipo === 'Estudiante';
  }

  onToogleMenuMobile(): void {
    this.isActiveMenuMobile = !this.isActiveMenuMobile;
    this.isActiveMenuMobileEmitter.emit(this.isActiveMenuMobile);
  }

  toggleMisExperiencias(): void {
    this.showMisExperiencias = !this.showMisExperiencias;
  }
}
