<div class="row">
  <div class="col-12">
      <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
      <h4 class="titulo-wizard">Conoce los tipos de institución y elige tu favorita</h4>
      <h5>Pregunta 3 / 16</h5>
      <p>Elige entre las siguientes opciones el tipo de universidad en la que te interesaría aplicar.</p>
      <hr class="hrLine" />
  </div>
</div>
<div class="row">
  <div class="col-12">
    <div class="row">
      <div class="col-12 col-md-6 col-lg-6 col-xl-3"  *ngFor="let item of listVideos; index as i">
        <input type="checkbox" [(ngModel)]="item.seleccionado" [ngModelOptions]="{standalone: true}" id="subscribeNews"  [hidden]="seleccionado && !item.seleccionado"
        (change)="checkValue(i)"
        class = "subsNews" name="subscribe" value="newsletter">
        <label for="subscribeNews" > <span [hidden]="seleccionado && !item.seleccionado"> Me interesa</span><span [hidden]="!seleccionado && item.seleccionado"> <br> </span></label>
        <div class = "news-card">
          <div class="card-body pad0">
        <video [src] = "item.url" controls></video>
          </div>
          <div class = "news-card-footer">
            <small class="floatl">{{item.detalle}}</small>
            <em class="fas fa-solid fa-plus floatR" id = "{{i}}" (click)="verdetalle(i)"></em>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="card-content" [hidden]="muestraDiv == false">
          <div class="first-content row">
            <div class="detalle-video col-lg-6" id="detalle-video"></div>
            <div class="card cardinfo col-lg-6"  id="detalle-informacion"></div>
          </div>
        <div class="card cardinfo" id = "detalle-requisitos"></div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="row">
  <div class="col-12 text-center">
      <em class="fas fa-solid fa-angle-left brnVolver" (click)="volverTab2()" ></em>
      <button class="btn-continuar" (click)="continuarTab2()">Continuar</button>
  </div>
</div>
