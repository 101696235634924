import { isPlatformBrowser } from '@angular/common';
import { S3Service } from './../../services/s3.service';
import { ActivatedRoute } from '@angular/router';
import { NavigationService } from './../../services/navigation.service';
import { AlertService } from './../../services/alert.service';
import { FormArray, FormBuilder } from '@angular/forms';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-my-languages',
  templateUrl: './my-languages.component.html',
  styleUrls: ['./my-languages.component.scss'],
})
export class MyLanguagesComponent implements OnInit {
  /** CONSTANTS */
  /** Constant that holds the API route for the user services */
  readonly USER_API = 'api/user/';
  /** Constant that holds the possible language options for the languages select fields */
  readonly languageOptions = [
    {
      // name: this.translate.instant('My-languages.dutch'),
      value: 'Holandés',
      name: 'Holandés',
    },
    {
      // name: this.translate.instant('My-languages.english'),
      value: 'Inglés',
      name: 'Inglés'
    },
    {
      // name: this.translate.instant('My-languages.french'),
      value: 'Francés',
      name: 'Francés'
    },
    {
      // name: this.translate.instant('My-languages.german'),
      value: 'Alemán',
      name: 'Alemán'
    },
    {
      // name: this.translate.instant('My-languages.italian'),
      value: 'Italiano',
      name: 'Italiano'
    },
    {
      // name: this.translate.instant('My-languages.portuguese'),
      value: 'Portugués',
      name: 'Portugués'
    },
    {
      // name: this.translate.instant('My-languages.spanish'),
      value: 'Español',
      name: 'Español'
    },
  ];
  /** Variable that will hold the form controls for the languages information */
  languages: FormArray;

  /** Variable tha holds the id of the current user */
  id: string;

  /** Variable that signals if the save changes button is loading or not */
  loading = false;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
    private s3Service: S3Service,
    private ngxSpinner: NgxSpinnerService,
    @Inject(PLATFORM_ID) private platformId,
    private translate: TranslateService
  ) {}

  ngOnInit(): void {
    this.languages = this.fb.array([]);
    if (isPlatformBrowser(this.platformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.tipo === 'Recruitment') {
        const student = localStorage.getItem('student_profile');
        this.id = student;
      } else {
        this.id = user._id;
      }
      this.getUserInfo();
    }
  }

  /** Adds a new language to the languages form array */
  onAddLanguage(): void {
    const language = this.fb.group({
      nombre: '',
      nivel: '',
      certificados: this.fb.array([]),
    });
    this.languages.push(language);
    this.onAddCertificate({index: this.languages.length - 1});
  }

  /** Adds a new certificate to a language
   * @param index index of the language in the languages array
   */
  onAddCertificate({ index }: { index: number }): void {
    const certificate = this.fb.group({
      nombre: '',
      calificacion: 0,
      archivo: '',
    });
    const certificates = this.languages.controls[index].get(
      'certificados'
    ) as FormArray;
    certificates.push(certificate);
  }

  /**
   * Removes a language from the languages array
   * @param index index of the language in the languages array
   */
  onRemoveLanguage({ index }: { index: number }): void {
    this.languages.removeAt(index);
  }

  /**
   * Removes a certificate from the certificates array of a language
   * @param languageIndex Index of the language in the languages array
   * @param certificateIndex Index of the certificate in the certificates array
   */
  onRemoveCertificate({
    languageIndex,
    certificateIndex,
  }: {
    languageIndex: number;
    certificateIndex: number;
  }): void {
    const certificates = this.languages.controls[languageIndex].get(
      'certificados'
    ) as FormArray;
    certificates.removeAt(certificateIndex);
  }

  /**
   * Returns the certificates form array of the language that has the given index
   * @param index index of the language in the languages form array
   */
  onGetcertificates({ index }: { index: number }): FormArray {
    return this.languages.controls[index].get('certificados') as FormArray;
  }

  /**
   * Retrieves the user information from the backend
   */
  getUserInfo(): void {
    this.ngxSpinner.show();
    this.apiService.get({ api: this.USER_API + this.id }).subscribe(
      (response) => {
        for (const lang of response.idiomas) {
          const langForm = this.fb.group({
            nombre: lang.nombre,
            nivel: lang.nivel,
            certificados: this.fb.array([]),
          });
          for (const cert of lang.certificados) {
            const certForm = this.fb.group({
              nombre: cert.nombre,
              calificacion: cert.calificacion,
              archivo: cert.archivo,
            });
            const certs = langForm.get('certificados') as FormArray;
            certs.push(certForm);
          }
          const certList = langForm.get('certificados') as FormArray;
          if(certList.length === 0) {
            const certificate = this.fb.group({
              nombre: '',
              calificacion: 0,
              archivo: '',
            });
            certList.push(certificate);
          }
          this.languages.push(langForm);
        }

        // If the array is empty, enter an empty form group to encourage the user to fill the form
        if (this.languages.length < 1 ) {
          const langForm = this.fb.group({
            nombre: '',
            nivel: '',
            certificados: this.fb.array([]),
          });
          this.languages.push(langForm);
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('My-languages.load-error'),
        });
        this.ngxSpinner.hide();
      }
    , (complete?) => {
      this.ngxSpinner.hide();
    });
  }

  /**
   * Saves the changes of the form by submitting the values to the backend
   */
  onSaveChanges(): void {
    this.loading = true;
    const data = {
      idiomas: this.languages.value,
    };
    let errorForm;
    for (const idioma of data.idiomas) {
      if(idioma.nombre.length<1 || idioma.nivel.length<1){
        errorForm=true
      }else{
        errorForm=true
      }
    }

    if(errorForm == true){
      this.alertService.showError({
        msg: this.translate.instant('Porfavor llena los "Idioma" y "Nivel"'),
      });
      this.loading=false;
    }else{
      this.apiService.put({ api: this.USER_API + this.id, data }).subscribe(
        (response) => {
          if (response.success === false) {
            this.alertService.showError({
              msg: 'Hubo un error actualizando los datos',
            });
            this.loading = false;
          } else {
            this.alertService.showSuccess({
              msg: 'Se han actualizado los datos correctamente',
            });
            this.loading = false;
            this.navigationService.navigateTo({ path: 'profile'});
          }
        },
        (err) => {
          this.alertService.showError({
            msg: 'Hubo un error actualizando los datos',
          });
          this.loading = false;
        }
      );
    }
    
  }

  /**
   * Uploads a new file to S3
   * @param event upload event emmited by the input type file
   * @param langIndex index of the language in the languages array
   * @param certIndex index of the certificate in the certificates array of the language
   */
  onUploadLanguageCertificate({
    event,
    langIndex,
    certIndex,
  }: {
    event: any;
    langIndex: number;
    certIndex: number;
  }): void {
    if (event.target.files.length > 1) {
      alert(this.translate.instant('My-languages.you-must-upload-1-file'));
    } else {
      this.ngxSpinner.show();
      const subKey = this.s3Service.generateKey();
      const languageName = this.languages.controls[langIndex].get('nombre')
        .value;
      const file = event.target.files[0];
      const key = `user/${this.id}/languages/${languageName}/certificates/${subKey}`;
      // Callback that handles S3 response
      const callback = (err, data) => {
        if (err) {
          // If there is an error alert the user
          this.ngxSpinner.hide();
          this.alertService.showError({
            msg: 'Hubo error subiendo el archivo',
          });
        } else {
          // Assign the value to the corresponding component
          const certs = this.languages.controls[langIndex].get(
            'certificados'
          ) as FormArray;
          certs.controls[certIndex].get('archivo').setValue(data.Location);
          this.ngxSpinner.hide();
        }
      };
      this.s3Service.uploadFile({ file, key, callback });
    }
  }
}
