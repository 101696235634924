import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { isPlatformBrowser } from '@angular/common';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationService } from '../../services/navigation.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MatDialog } from '@angular/material/dialog';
import { LetterTipsComponent } from './letter-tips/letter-tips.component';
import { LetterPreviewComponent } from './letter-preview/letter-preview.component';
import {AngularEditorConfig} from '@kolkov/angular-editor';

@Component({
  selector: 'app-letter',
  templateUrl: './letter.component.html',
  styleUrls: ['./letter.component.scss']
})
export class LetterComponent implements OnInit {
  readonly api = 'api/carta-intencion/create';
  readonly API_GET_LETTER = 'api/carta-intencion/getByUser/';
  readonly API_PUT_LETTER = 'api/carta-intencion/update/';

  /** Variable that holds the login form */
  form: FormGroup;

  /** Variable that holds the id of the current user */
  id: string;
  idLetter: any;
  currentLetter: any;

  editorConfig: AngularEditorConfig = {
    editable: true,
    spellcheck: true,
    height: 'auto',
    minHeight: '0',
    maxHeight: 'auto',
    width: 'auto',
    minWidth: '0',
    enableToolbar: true,
    showToolbar: true,
    placeholder: 'Escribe aquí  (máx. 125 palabras)',
    defaultParagraphSeparator: '',
    defaultFontName: 'Arial',
    defaultFontSize: '3',
    fonts: [
      {class: 'arial', name: 'Arial'},
    ],
    sanitize: true,
    toolbarPosition: 'top',
    toolbarHiddenButtons: [
      [
        'strikeThrough',
        'subscript',
        'superscript',
        'heading',
        'fontName'
      ],
      [
        'fontSize',
        'textColor',
        'backgroundColor',
        'customClasses',
        'insertImage',
        'insertVideo',
        'insertHorizontalRule',
        'toggleEditorMode'
      ]
    ]
  };

  /**
   * Returns the destinatario form control
   */
   get destinatario(): AbstractControl {
    return this.form.get('destinatario');
  }

  constructor(
    private apiService: ApiService,
    private alertService: AlertService,
    private formBuilderService: FormBuilder,
    @Inject(PLATFORM_ID) private plaformId,
    private ngxSpinnerService: NgxSpinnerService,
    private navigationService: NavigationService,
    private modalService: NgbModal,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.plaformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.tipo === 'Recruitment') {
        const student = localStorage.getItem('student_profile');
        this.id = student;
      } else {
        this.id = user._id;
      }
      // this.getUserinfo();
    }
    this.setForm();
    this.getUserLetter();
  }

  setForm() {
    this.form = this.formBuilderService.group({
      destinatario: ['', [ Validators.required ]],
      introduccion: ['', [ Validators.required ]],
      antecendente: ['', [ Validators.required ]],
      justificacion: ['', [ Validators.required ]],
      meta: ['', [ Validators.required ]],
      conclusion: ['', [ Validators.required ]],
      datoPersonal: ['', [ Validators.required ]]
    });
  }

  getUserLetter() {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.API_GET_LETTER + this.id }).subscribe(
      (response) => {
        if (response) {
          this.currentLetter = response;
          this.idLetter = response._id;
          this.form.get('destinatario').setValue(response.destinatario);
          this.form.get('introduccion').setValue(response.introduccion);
          this.form.get('antecendente').setValue(response.antecendente);
          this.form.get('justificacion').setValue(response.justificacion);
          this.form.get('meta').setValue(response.meta);
          this.form.get('conclusion').setValue(response.conclusion);
          this.form.get('datoPersonal').setValue(response.datoPersonal);
          this.ngxSpinnerService.hide();
        } else {
          this.ngxSpinnerService.hide();
        }

      }
    );
  }

  onSubmit(){
    if (!this.idLetter){
      this.save();
    }else{
      this.updateLetter();
    }
  }

  save() {
    this.ngxSpinnerService.show();
    const data: any = this.form.value;
    data.usuario = this.id;
    this.apiService.post({api: this.api, data}).subscribe(
      (response) => {
        if (response.success){
          this.alertService.showSuccess({
            msg: 'Se ha la carta de intencion con exito'
          });
          this.ngxSpinnerService.hide();
          this.backToCurriculum();
        }else{
          this.alertService.showError({
            msg: 'Hubo un error creando la carta de intencion'
          });
          this.ngxSpinnerService.hide();
        }
      }
    );

  }

  updateLetter(){
    this.ngxSpinnerService.show();
    const data: any = this.form.value;
    this.apiService.put({api: this.API_PUT_LETTER + this.id, data}).subscribe(
      (response) => {
        if (response.success){
          this.alertService.showSuccess({
            msg: 'Se ha actualizado los datos con exito'
          });
          this.ngxSpinnerService.hide();
          this.backToCurriculum();
        }else{
          this.alertService.showError({
            msg: 'Hubo un error actualizando los datos'
          });
          this.ngxSpinnerService.hide();
        }
      }
    );
  }

  backToCurriculum(){
    this.navigationService.navigateTo({ path: 'curriculum'});
  }

  openTips() {
    // this.modalService.open(content, {
    //   size: 'xl'
    // });

    this.dialog.open(LetterTipsComponent,
      {
        height: '80%',
        width: '90%',
      });
  }

  openPreview(){
    this.dialog.open(LetterPreviewComponent, {
      data: {
        destinatario: this.form.get('destinatario').value,
        introduccion: this.form.get('introduccion').value,
        antecendente: this.form.get('antecendente').value,
        justificacion: this.form.get('justificacion').value,
        meta: this.form.get('meta').value,
        conclusion: this.form.get('conclusion').value,
        datoPersonal: this.form.get('datoPersonal').value,
      },
      width: '60vw',
      height: '90vh',
      maxWidth: '60vw',
      maxHeight: '90vh',
    });
  }

}
