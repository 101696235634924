<div *ngIf="university.videosResuelve && university.videosResuelve.length > 0" class="container-content">
    <h2 class="program-name-h5">¿Dudas?</h2>
    <div *ngIf="primerVideo">
        <div>
            <span>
                {{university.titulosResuelve[0]? university.titulosResuelve[0] : ''}}
            </span>
            <iframe id="university-video0" frameborder="0" type="text/html" [src]="primerVideo | safe"
            class="video-framePrincipal" title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowfullscreen>
        </iframe>
        </div>
        <div class="container-carousel">
          <carousel id="carousel-2" cellsToShow="2" style="height: 295px;">
            <div *ngFor="let video of videosSinPrimero; index as i" class="carousel-cell">
                <div>
                    <div style="height:40px;">
                      {{university.titulosResuelve? university.titulosResuelve[i+1] : ''}}
                    </div>
                    <iframe id="university-video{{ i }}" frameborder="0" type="text/html" [src]="video | safe"
                        class="video-frame" title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe>
                </div>

            </div>
          </carousel>
        </div>
      </div>

    <!--<div class="image-slider">
        <div class="slider">
            <div class="video-space" *ngFor="let video of university.videosResuelve; index as i">
                {{university.titulosResuelve? university.titulosResuelve[i] : ''}}
                <div class="container-frame">
                    <img src="assets/utils/q&a-{{i%5}}.png" alt="Q&A">
                    <iframe id="university-video{{ i }}" frameborder="0" type="text/html" [src]="video | safe"
                        class="video-frame" title="YouTube video player"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowfullscreen>
                    </iframe>
                </div>
            </div>
        </div>
    </div>-->
</div>
