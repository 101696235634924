<div class="shopping-cart">
  <h3 class="shopping-cart-title">{{ "Shopping-cart.shopping-cart" | translate }}</h3>
  <button *ngIf="pageStatus === 0
  && (insuranceServices.length !== 0
  || translationServices.length !== 0
  || visaServices.length !== 0
  || languageTests.length !== 0
  || consultancyServices.length !== 0)" (click)="onClearShoppingCart()" class="empty-cart">{{
    "Shopping-cart.empty-shopping-cart" | translate }}</button>
  <div class="shopping-cart-empty-state"
  *ngIf="insuranceServices.length === 0
  && translationServices.length === 0
  && visaServices.length === 0
  && languageTests.length === 0
  && consultancyServices.length === 0">
    <div class="empty-disclaimer">
      {{'Shopping-cart.your-cart-is-empty'|translate}}
    </div>
  </div>
  <div class="shopping-cart-content">
    <div class="row">
      <div class="col-md-8">
        <div *ngIf="pageStatus === 0" class="cart-product-list">
          <app-medical-services-shopping-card *ngFor="let service of insuranceServices" [id]="service.id"
            [serviceName]="service.serviceName" [numberOfDays]="service.numberOfDays" [plans]="service.plans"
            [currency]="service.currency" [image]="service.image" [isShoppingCart]="true"
            (deleteFromCart)="removeMedicalService($event)"
            (changeNumberOfDays)="changeInsuranceServiceQuantity($event)"></app-medical-services-shopping-card>
          <app-translation-services-shopping-card *ngFor="let service of translationServices" [id]="service.id"
            [serviceName]="service.docType" [numberOfPages]="service.numberOfPages"
            [pricePerPage]="service.pricePerPage" [image]="service.image" [currency]="service.currency"
            [isShoppingCart]="true" (removeService)="removeTranslationService($event)"
            (changeNumberOfPages)="changeTranslationServiceQuantity($event)"></app-translation-services-shopping-card>
          <app-visa-services-shopping-card *ngFor="let service of visaServices" [id]="service.id"
            [serviceName]="service.country" [image]="service.img" [currency]="service.currency" [cost]="service.cost"
            [type]="'visa'"
            (removeService)="removeVisaService($event)"></app-visa-services-shopping-card>
          <app-language-test-shopping-cart *ngFor="let test of languageTests" [id]="test.id" [name]="test.name"
            [language]="test.language" [currency]="test.currency" [image]="test.image" [cost]="test.cost" [type]="test.type"
            (removeService)="removeLanguageTest($event)"></app-language-test-shopping-cart>
            <app-visa-services-shopping-card *ngFor="let service of consultancyServices" [id]="service.id"
            [serviceName]="service.country" [image]="service.img" [currency]="service.currency" [cost]="service.cost"
            [name]="service.name" [type]="'consultancy'"
            (removeService)="removeConsultancyService($event)"></app-visa-services-shopping-card>
        </div>
        <app-payment-info (startPurchase)="startPurchase($event)" [subtotal]="total" [refCode]="refCode" (returnToPrevious)="onReturnFromCheckout()" *ngIf="pageStatus === 1"></app-payment-info>
      </div>
      <div class="col-md-4" [hidden]="subtotal == 0">
        <mat-spinner diameter="40" class="loasingSpinner" *ngIf="loadingCalculo"></mat-spinner>
        <!-- <h1 class="loasingSpinner" *ngIf="loadingCalculo">CARGANDOOOOO......</h1> -->
        <app-invoice *ngIf="!loadingCalculo"  [pageStatus]="pageStatus" (toCheckout)="proceedToCheckout()" [subtotal]="subtotal" [tax]="taxes"
          [total]="total">
        </app-invoice>
      </div>
    </div>
  </div>
</div>
