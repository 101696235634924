<div class="payment-information-form">
    <form [formGroup]="paymentInformationForm">
        <h4> {{ "Payment-info-pse.personal-inf" | translate }} </h4>
        <div class="sub-form" formGroupName="informacionPersonal">
            <div class="row">
                <div class="col-sm-6">
                    <label for="name"> {{ "Payment-info-pse.name" | translate }} </label>
                    <input class="full-width form-input" id="name" type="text" formControlName="nombreCompleto"
                        placeholder="Full name">
                </div>
                <div class="col-sm-6">
                    <label for="email"> {{ "Payment-info-pse.email" | translate }} </label>
                    <input class="full-width form-input" id="email" type="email" formControlName="email"
                        placeholder="E-mail">
                </div>
                <div class="col-sm-6">
                    <label for="id-number"> {{ "Payment-info-pse.select-bank" | translate }} </label>
                    <select class="full-width form-input" id="bank" formControlName="banco">
                        <option value="null"> {{ "Payment-info-pse.select" | translate }}</option>
                        <option *ngFor="let b of banksPse" value="{{b.pseCode}}">{{b.description}}</option>
                    </select>
                </div>
                <div class="col-sm-6">
                    <label for="id-number"> {{ "Payment-info-pse.customer-type" | translate }} </label>
                    <select class="full-width form-input" id="customer" formControlName="tipoUsuario">
                        <option value="null"> {{ "Payment-info-pse.select" | translate }}</option>
                        <option value="N"> {{ "Payment-info-pse.natural-person" | translate }} </option>
                        <option value="J"> {{ "Payment-info-pse.legal-person" | translate }} </option>
                    </select>
                </div>
                <div class="col-sm-6">
                    <label for="id-number"> {{ "Payment-info-pse.doc-type" | translate }} </label>
                    <select class="full-width form-input" id="documentType" formControlName="documentType">
                        <option value="null"> {{ "Payment-info-pse.select" | translate }} </option>
                        <option *ngFor="let t of document_type" value="{{t.iso}}">{{t.nombre}}</option>
                    </select>
                </div>
                <div class="col-sm-6">
                    <label for="id-number"> {{ "Payment-info-pse.doc" | translate }} </label>
                    <input class="full-width form-input" id="document" type="text" formControlName="document"
                        placeholder="Document number">
                </div>
                <div class="col-sm-6">
                    <label for="personal-telephone"> {{ "Payment-info-pse.telephone" | translate }} </label>
                    <input class="full-width form-input" id="personal-telephone" type="tel" formControlName="telefono"
                        placeholder="Telephone number">
                </div>
                <div class="col-md-12 submit-section">
                    <button (click)="onSubmitClick()" id="submit-button" type="submit" > {{
                        "Payment-info-pse.make-payment" | translate }} </button>
                </div>

            </div>
        </div>
    </form>
</div>
<!-- <script type="text/javascript" src="https://maf.pagosonline.net/ws/fp/tags.js?id=${deviceSessionId}80200"></script> -->
<noscript>
   <iframe title="pagosonline" style="width: 100px; height: 100px; border: 0; position: absolute; top: -5000px;" src="https://maf.pagosonline.net/ws/fp/tags.js?id=${deviceSessionId}80200"></iframe>
</noscript>
