import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-resumen-test-user',
  templateUrl: './resumen-test-user.component.html',
  styleUrls: ['./resumen-test-user.component.scss']
})
export class ResumenTestUserComponent implements OnInit {
  idUser: any;
  formulario: any;
  public progreso: any;
  public progreso2: any;
  public progreso3: any;
  public progreso4: any;
  public progresoLiderazgo: any;

  public progreso5: any;

  public scrollBar: number = 100;
  public scrollBar2: number = 20.00;
  public scrollBar3: number = 60.00;
  public scrollBar4: number = 70.00;
  public scrollBar5: number = 50.00;
  public TopTier = [
    { habilidad: 'Liderazgo', porcentaje: 80 },
    { habilidad: 'Global Mind', porcentaje: 90 },
    { habilidad: 'Impacto Social', porcentaje: 80 },
    { habilidad: 'Logro y Acción', porcentaje: 70 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 85 },
    { habilidad: 'Promedio', porcentaje: 81 },
  ];
  public Experiential = [
    { habilidad: 'Liderazgo', porcentaje: 60 },
    { habilidad: 'Global Mind', porcentaje: 60 },
    { habilidad: 'Impacto Social', porcentaje: 50 },
    { habilidad: 'Logro y Acción', porcentaje: 60 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 50 },
    { habilidad: 'Promedio', porcentaje: 56 },
  ];
  public PracticalLearning = [
    { habilidad: 'Liderazgo', porcentaje: 50 },
    { habilidad: 'Global Mind', porcentaje: 50 },
    { habilidad: 'Impacto Social', porcentaje: 50 },
    { habilidad: 'Logro y Acción', porcentaje: 50 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 50 },
    { habilidad: 'Promedio', porcentaje: 50 },
  ];
  public Vocational = [
    { habilidad: 'Liderazgo', porcentaje: 40 },
    { habilidad: 'Global Mind', porcentaje: 40 },
    { habilidad: 'Impacto Social', porcentaje: 40 },
    { habilidad: 'Logro y Acción', porcentaje: 40 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 40 },
    { habilidad: 'Promedio', porcentaje: 40 },
  ];

  public userData15: any;
  readonly getTestPaso15 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest15 = 'api/get_internalizacionUser/';
  readonly deleteTest = 'api/delete_internalizacionUser/';
  pasoActivo: any;
  nombre: any;
  edad: any;
  fecha: any;
  perfil: any;
  recomendacionesLiderazgo: any[] = [];
  recomendacionesGlobal: any[] = [];
  recomendacionesImpacto: any[] = [];
  recomendacionesLogro: any[] = [];
  recomendacionesAprendizaje: any[] = [];
  recomendacionesPromedio: any[] = [];
  //Variables para calcular el puntaje en % del estudiante
  liderazgo_puntaje_maximo: number = 85;
  global_puntaje_maximo: number = 80;
  impacto_puntaje_maximo: number = 40;
  logro_puntaje_maximo: number = 45;
  competencia_puntaje_maximo: number = 50;
  puntosLiderazgo: any;
  puntosGlobal: any;
  puntosImpacto: any;
  puntosLogro: any;
  puntosCompetencia: any;
  indicadoresGlobales: { habilidad: string; porcentaje: number; }[];
  indicadorLiderazgo: string;
  indicadorGlobal: string;
  indicadorImpacto: string;
  indicadorLogro: string;
  indicadorAprendizaje: string;
  progresoGlobal: string;
  progresoImpacto: string;
  progresoLogro: string;
  progresoCompetencia: string;
  promedioGeneral: any;
  progresoGeneral: string;
  indicadorGeneral: number;
  preguntasCerradas = []
  preguntasPresupuesto = []
  public idTest: any;
  grafico: any;
  public sortsGraph: any


  constructor(
    private activatedRoute: ActivatedRoute,
    private apiService: ApiService,
    private router: Router

  ) {
    this.idUser = this.activatedRoute.snapshot.params["id"];
    this.progreso = 'width:' + this.scrollBar + '%';
    this.progreso2 = 'width:' + this.scrollBar2 + '%';
    this.progreso3 = 'width:' + this.scrollBar3 + '%';
    this.progreso4 = 'width:' + this.scrollBar4 + '%';
    this.progreso5 = 'width:' + this.scrollBar4 + '%';
    let semestreAct
    if (this.activatedRoute.snapshot.params["semestre"]) {
      semestreAct = this.activatedRoute.snapshot.params["semestre"]
    } else {
      semestreAct = 1
    }
    //
    if (this.idUser) {
      this.apiService.get({ api: this.getTestPaso15 + this.idUser + '/' + semestreAct }).subscribe(
        (response) => {
          if (response) {
            this.idTest = response._id;
            let resp = response
            resp.test = JSON.parse(response.test)
            let listaPreguntas = resp.test.preguntas

            this.nombre = resp.test.nombreUSer
            this.edad = resp.test.edadActual
            this.fecha = moment(resp.createdAt).format('DD-MMMM-YYYY');

            for (let uni of resp.test.preguntas[2].preguntas[0].opciones) {
              if (uni.interesa) {
                this.perfil = uni.institucion
              }
            }
            if (this.perfil == 'Top Tier') {
              this.indicadoresGlobales = this.TopTier;
            }
            if (this.perfil == 'Experiential University') {
              this.indicadoresGlobales = this.Experiential;

            }
            if (this.perfil == 'Practical learning') {
              this.indicadoresGlobales = this.PracticalLearning;

            }
            if (this.perfil == 'Vocational / Passion') {
              this.indicadoresGlobales = this.Vocational;

            }

            this.indicadorLiderazgo = 'width:' + this.indicadoresGlobales[0].porcentaje + '%';
            this.indicadorGlobal = 'width:' + this.indicadoresGlobales[1].porcentaje + '%';
            this.indicadorImpacto = 'width:' + this.indicadoresGlobales[2].porcentaje + '%';
            this.indicadorLogro = 'width:' + this.indicadoresGlobales[3].porcentaje + '%';
            this.indicadorAprendizaje = 'width:' + this.indicadoresGlobales[4].porcentaje + '%';
            this.indicadorGeneral = this.indicadoresGlobales[5].porcentaje;

            this.listarPreguntas(resp.test.preguntas);

            this.sortsGraph = {
              type: 'radar',
              data: {
                labels: ["Liderazgo",
                  "Global Mind",
                  "Impacto social",
                  "Logro y accion",
                  "Compentecia Academica / Aprendizaje"],
                datasets: [
                  {
                    label: "Puntuacion de competencias",
                    data: [this.puntosLiderazgo,
                    this.puntosGlobal,
                    this.puntosImpacto,
                    this.puntosLogro,
                    this.puntosCompetencia]
                  }
                ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: true,
                scale: {
                  ticks: {
                    min: 0,
                    max: 100
                  }
                }
              }
            }

            listaPreguntas.map(registro => {
              if (registro.tipo == "optener_datos") {
                registro.preguntas.map(pregunta => {
                  if (!pregunta.opciones) {
                    this.preguntasCerradas.push(pregunta) //-> Para evitar la pregunta general de presepuesto
                  }

                  if (pregunta.opciones) {
                    pregunta.opciones.map(presupuesto => {
                      this.preguntasPresupuesto.push(presupuesto)
                    })
                  }
                })
              }
            })
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    }


  }
  ngOnInit(): void {

  }

  reiniciarTest() {
    Swal.fire({
      title: '¿Estas seguro que deseas reiniciar el test?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.apiService.delete({ api: this.deleteTest + this.idTest }).subscribe(response => {
          if (response) {
            this.router.navigate(['leaderTest']);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al reiniciar el test',
            });
            return false;
          }
        });
      }
    })


  }

  listarPreguntas(lista) {
    let preguntasEvaluar = []
    for (let secciones of lista) {
      if (secciones.tipo == 'puntaje') {
        preguntasEvaluar.push(secciones)
      }
    }
    let liderazgo = 0
    let global = 0
    let impacto = 0
    let logro = 0
    let competencia = 0
    let recomendaciones = preguntasEvaluar

    let total_liderazgo = 0;
    let total_global = 0;
    let total_impacto = 0;
    let total_competencia = 0;
    let total_logro = 0;


    preguntasEvaluar.map(seccion => {
      seccion.preguntas.map(pregunta => {
        if (pregunta.afecciones) {
          pregunta.afecciones.map(habilidades => {
            if (habilidades.habilidad) {
              if (habilidades.habilidad == 'Liderazgo') {
                total_liderazgo++
              } else if (habilidades.habilidad == 'Global Mind') {
                total_global++
              } else if (habilidades.habilidad == 'Impacto Social') {
                total_impacto++
              }
              else if (habilidades.habilidad == 'Compentecia Academica / Aprendizaje') {
                total_competencia++
              }
              else if (habilidades.habilidad == 'Logro y Acción') {
                total_logro++
              }
            }
          })
        }
      })
    })


    total_liderazgo = total_liderazgo * 5
    total_global = total_global * 5
    total_impacto = total_impacto * 5
    total_competencia = total_competencia * 5
    total_logro = total_logro * 5

    for (let eva of preguntasEvaluar) {
      for (let not of eva.habilidades) {
        if (not.nombre == 'Liderazgo') {
          liderazgo += parseInt(not.puntaje)
        }
        if (not.nombre == 'Global Mind') {
          global += parseInt(not.puntaje)
        }
        if (not.nombre == 'Impacto Social') {
          impacto += parseInt(not.puntaje)
        }
        if (not.nombre == 'Logro y Acción') {
          logro += parseInt(not.puntaje)
        }
        if (not.nombre == 'Compentecia Academica / Aprendizaje') {
          competencia += parseInt(not.puntaje)
        }
      }
    }
    let listRecomendaciones = []
    for (let eva of recomendaciones) {
      for (let recomendaciones of eva.preguntas) {
        //
        if (recomendaciones.invertida) {
          if (parseFloat(recomendaciones.opcionSeccionada) >= 3) {
            listRecomendaciones.push(recomendaciones)
          }
        }
        if (parseFloat(recomendaciones.opcionSeccionada) <= 3) {
          listRecomendaciones.push(recomendaciones)
        }
      }
    }

    this.puntosLiderazgo = (liderazgo / total_liderazgo) * 100;
    this.puntosLiderazgo = this.puntosLiderazgo.toFixed(2);
    if (this.puntosLiderazgo > 100) { this.puntosLiderazgo = 100 }
    this.progresoLiderazgo = 'width:' + this.puntosLiderazgo + '%';

    this.puntosGlobal = (global / total_global) * 100;
    this.puntosGlobal = this.puntosGlobal.toFixed(2);
    if (this.puntosGlobal > 100) { this.puntosGlobal = 100 }
    this.progresoGlobal = 'width:' + this.puntosGlobal + '%';

    this.puntosImpacto = (impacto / total_impacto) * 100;
    this.puntosImpacto = this.puntosImpacto.toFixed(2);
    if (this.puntosImpacto > 100) { this.puntosImpacto = 100 }
    this.progresoImpacto = 'width:' + this.puntosImpacto + '%';

    this.puntosLogro = (logro / total_logro) * 100;
    this.puntosLogro = this.puntosLogro.toFixed(2);
    if (this.puntosLogro > 100) { this.puntosLogro = 100 }
    this.progresoLogro = 'width:' + this.puntosLogro + '%';

    this.puntosCompetencia = (competencia / total_competencia) * 100;
    this.puntosCompetencia = this.puntosCompetencia.toFixed(2);
    if (this.puntosCompetencia > 100) { this.puntosCompetencia = 100 }
    this.progresoCompetencia = 'width:' + this.puntosCompetencia + '%';

    this.promedioGeneral = (parseFloat(this.puntosLiderazgo) + parseFloat(this.puntosGlobal) + parseFloat(this.puntosImpacto) + parseFloat(this.puntosLogro) + parseFloat(this.puntosCompetencia));
    this.promedioGeneral = (this.promedioGeneral / 5);
    this.promedioGeneral = this.promedioGeneral.toFixed(2);
    if (this.promedioGeneral > 100) { this.promedioGeneral = 100 }
    this.progresoGeneral = 'width:' + this.promedioGeneral + '%';

    let data = {
      liderazgo: this.puntosLiderazgo,
      gobal_mind: this.puntosGlobal,
      impacto_social: this.puntosImpacto,
      logro_accion: this.puntosLogro,
      puntaje_general: this.promedioGeneral,
      competencia_academica: this.puntosCompetencia
    }
    this.apiService.put({ api: this.updateFrmTest15 + this.idTest, data }).subscribe(response => {

    });

    for (let newList of listRecomendaciones) {
      if (newList.recomendacion && newList.recomendacion !== '') {
        for (let habilidades of newList.afecciones) {

          if (habilidades.habilidad == 'Liderazgo') {
            this.recomendacionesLiderazgo.push(newList)
          }
          if (habilidades.habilidad == 'Global Mind') {
            this.recomendacionesGlobal.push(newList)
          }
          if (habilidades.habilidad == 'Impacto Social') {

            this.recomendacionesImpacto.push(newList)
          }
          if (habilidades.habilidad == 'Compentecia Academica / Aprendizaje') {
            this.recomendacionesAprendizaje.push(newList)
          }
          if (habilidades.habilidad == "Logro y Acción") {
            this.recomendacionesLogro.push(newList)
          }
        }
      }

    }

  }
}
