import {Component, Inject, OnDestroy, OnInit, PLATFORM_ID,} from '@angular/core';
import {MatDialog} from '@angular/material/dialog';
import {AuthComponent} from '../../auth/auth.component';
import {AuthService} from '../../services/auth.service';
import {isPlatformBrowser} from '@angular/common';
import {NavigationEnd, Router} from '@angular/router';
import {NavigationService} from '../../services/navigation.service';
import {Subscription} from 'rxjs';
import {TranslateService} from '@ngx-translate/core';
import {ApiService} from '../../services/api.service';
import {RegisterStudentComponent} from '../../register-student/register-student.component';
import {SetPhoneComponent} from '../set-phone/set-phone.component';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {
  /** Constant that holds the API for retrieving configuration */
  readonly CONFIG_API = 'api/config/';

  /** Variable that determines if the sidebar should be toggled */
  public toggle = true;

  /** Variable that determines if the user is logged in */
  loggedIn = false;
  /** Variable that holds the type of the current user */
  currentUserType = '';
  /** Variable that determines if the current url is the homepage */
  isHome = false;
  isProgram = false;
  isUniversity = false;
  /** Variable that determines if the current url is the email confirmation page */
  isConfirmation = false;
  /** Variable that holds the name of the user that is currently logged in */
  username = '';
  tipoUSer='';
  /** Variable that determines if menu can be displayed */
  showMenu = false;

  /** Subscription used to monitor the login status */
  logInSubscription: Subscription;
  /** Subscription to monitor the logged in user's username */
  usernameSubscription: Subscription;
  userTypeSubscription: Subscription;
  /** Subscription used to monitor the router events */
  routerSubscription: Subscription;

  /** Current used language */
  currentLanguage;

  isPremiumUser: boolean;

  /** Variable that holds the configuration */
  config = {
    id: '',
    tagList: [],
    maxProgramsWithOutLogin: 0,
    usePaymentCost: 0,
    breakingNewsSpanish: '',
    breakingNewsEnglish: '',
  };

  /** Text of banner (breaking news) */
  newsTexts: any = '';
  public openMenu = false
  // public closeOutMenu = false;

  constructor(
    private dialog: MatDialog,
    public authService: AuthService,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private navigationService: NavigationService,
    private translate: TranslateService,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      this.setLanguage();
      this.authService.isPremiumUser$.subscribe((result) => this.isPremiumUser = result);
      this.logInSubscription = this.authService.loggedIn.subscribe(
        (logInStatus) => {
          if (logInStatus === true) {
            this.currentUserType = JSON.parse(
              localStorage.getItem('user')
            )?.tipo;
          } else {
            this.currentUserType = '';
          }
          this.loggedIn = logInStatus;
        }
      );
      this.usernameSubscription = this.authService.userName.subscribe(
        (username) => {
          this.username = username;
        }
      );
    }
    this.routerSubscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        let route = event.urlAfterRedirects.split('/');
        const last = route[route.length - 1];
        if (event.urlAfterRedirects === '/home') {
          this.isHome = true;
        } else if (event.urlAfterRedirects.includes('/university')){
          this.isUniversity = true;
        } else if (event.urlAfterRedirects.includes('/program') && !(event.urlAfterRedirects.includes('/program') && event.urlAfterRedirects.includes('/search'))) {
          this.isProgram = true;
        }
        else {
          this.isHome = false;
          this.isProgram = false;
          this.isUniversity = false;
          if (last === 'confirmation') {
            this.isConfirmation = true;
          }
        }
      }
    });
  }

  getConfig(language): void {
    this.apiService.get({ api: this.CONFIG_API }).subscribe((response) => {
      this.config = response[0];
      if (language == 'es') {
        this.newsTexts = this.config.breakingNewsSpanish;
      } else {
        this.newsTexts = this.config.breakingNewsEnglish;
      }
    });
  }

  ngDoCheck(): void{
    if (localStorage.getItem('tipoUser')) {
      this.tipoUSer = JSON.parse(localStorage.getItem('tipoUser'));
    }
  }

  ngOnDestroy(): void {
    if (
      this.logInSubscription !== null &&
      this.logInSubscription !== undefined
    ) {
      this.logInSubscription.unsubscribe();
    }
    if (
      this.routerSubscription !== null &&
      this.routerSubscription !== undefined
    ) {
      this.routerSubscription.unsubscribe();
    }
    if (
      this.usernameSubscription !== null &&
      this.usernameSubscription !== undefined
    ) {
      this.usernameSubscription.unsubscribe();
      // this.userTypeSubscription.unsubscribe();

    }
  }

  /**
   * Displays and hides the side navbar
   */
  public toggleNav(): void {
    this.toggle = !this.toggle;
  }

  /**
   * Handles the click on the login button
   */
  onLoginLogoutClick(): void {
    if (this.loggedIn === false) {
      this.authService.sitioMensaje = '';
      this.dialog.open(AuthComponent, { data: { authAction: 'login' }, maxWidth: '95vw' });
    } else {
      this.authService.logout();
      this.authService.setLoggedIn({ status: false });
      this.navigationService.navigateTo({ path: '/public-home' });
    }
  }

  /** Handles the click on the signup button */
  onSignUpButtonClick(): void {
    this.authService.sitioMensaje = '';
    this.dialog.open(AuthComponent, { data: { authAction: 'signup' }, maxWidth: '95vw' });
  }

  /**
   * Handles the click on the logo --> Redirect to the homepage
   */
  onLogoClick(): void {
    this.navigationService.navigateTo({ path: '/public-home' });
  }
  /**
   * Handles the click on the go to favourites button
   */
  onFavouritesClick(): void {
    if (this.loggedIn === false) {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    } else {
      this.navigationService.navigateTo({ path: 'favourites' });
    }
  }

  /**
   * Handles the click on the go to webinars button
   */
  onWebinarClick(): void {
    if (this.loggedIn === false) {
      this.dialog.open(AuthComponent, { data: { authAction: 'login' } });
    } else {
      this.navigationService.navigateTo({ path: 'webinar' });
    }
  }

  /** Handles the navigation to profile event */
  onGoToProfile(): void {
    const user = localStorage.getItem('user');
    this.navigationService.navigateTo({ path: 'profile' });
  }

  /**
   * Changes language of the app with translator service
   */
  useLanguage(language: string): void {
    this.currentLanguage = language;
    localStorage.setItem('lang', this.currentLanguage);
    this.translate.use(language);
    this.getConfig(language);
    window.location.reload();
  }

  /**
   * Sets language depending on localStorage when the app launches
   */
  public setLanguage(): void {
    const saved = localStorage.getItem('lang');
    if (saved && saved.length === 2) {
      this.currentLanguage = saved;
      this.translate.use(saved);
      this.getConfig(saved);
    } else {
      this.currentLanguage = 'es';
      localStorage.setItem('lang', this.currentLanguage);
    }
  }

  /**
   * Handles the click on the register student button
   */
  onRegisterStudent(): void {
    this.dialog.open(RegisterStudentComponent);
  }

  showMenuAction (){
    this.showMenu = !this.showMenu;
  }
  onDisplayMenu(){
    this.openMenu = !this.openMenu
  }

  onFullAccess($event: MouseEvent): void {
    $event.stopPropagation();
    this.authService.verifyPhoneUser(true);
  }
}
