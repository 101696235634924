import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso10',
  templateUrl: './paso10.component.html',
  styleUrls: ['./paso10.component.scss']
})
export class Paso10Component implements OnInit {
  public rondaPreguntas: any[]=[];
  @Output() notificacionTab14 = new EventEmitter<string>();
  public preguntas_seccion14:any;
  public dataFrm14:any;
  public dataFrm14Copy:any;
  readonly getTestPaso14 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest14 = 'api/get_internalizacionUser/';
  pasoActivo: any;
  public userDataID:any;
  constructor(private apiService:ApiService) {
    this.userDataID = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.userDataID) {
      this.apiService.get({ api: this.getTestPaso14 + this.userDataID._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //  
            let data_test= JSON.parse(response.test[0]);
            this.dataFrm14 = response;
            this.pasoActivo= response.hojaTest;
            this.dataFrm14.test = JSON.parse(this.dataFrm14.test);
            this.preguntas_seccion14= data_test.preguntas[13];
            //  
            let i = 0;
            for(let item of this.preguntas_seccion14.preguntas){
              //  
              this.rondaPreguntas[i].opcionSeccionada= item.opcionSeccionada;
              i++
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    } 



   }

  ngOnInit(): void {
    this.rondaPreguntas=[
      {pregunta:"¿Cuentas con cartas de recomendación?",opcionSeccionada:0},
      {pregunta:"¿Cuentas con ensayos de perspectiva profesional?",opcionSeccionada:0},
      {pregunta:"¿Cuentas con un Portafolio? (Aplica principalmente para artes, música, industria creativa)",opcionSeccionada:0},
      {pregunta:"¿Tienes experiencias académicas, voluntariados u otros con personas de culturas de otra lengua?",opcionSeccionada:0},
    ]
  }

  getCheckBox(event, pos,seleccion){
    let id = event.target.id;
    let disableId = event.target.id;
    let checkBoxPressed = document.getElementById(id) as HTMLInputElement;
    //  
    this.preguntas_seccion14.preguntas[pos].opcionSeccionada=seleccion;
    if(checkBoxPressed.checked){
      if(id.includes('value1')){
        disableId = id.replace('value1', 'value2');
      }
      else if(id.includes('value2')){
        disableId = id.replace('value2', 'value1');
        
      }
      //  
      let checkBoxDisable = document.getElementById(disableId);
      checkBoxDisable.setAttribute('disabled', new Boolean(true).toString());
    }
    else{
      if(id.includes('value1')){
        disableId = id.replace('value1', 'value2');
      }
      else if(id.includes('value2')){
        disableId = id.replace('value2', 'value1');
      }
      let checkBoxDisable = document.getElementById(disableId);
      checkBoxPressed.removeAttribute('disabled');
      checkBoxDisable.removeAttribute('disabled');
    }
   
  }
  public volverTab13(){
    this.notificacionTab14.emit("volver")

   }
   public continuarTab14(){
    let paso = this.pasoActivo
    if(this.pasoActivo < 15){
      paso = this.pasoActivo = 15;
    }
    this.dataFrm14.hojaTest= paso
    this.dataFrm14.test.preguntas[13] = this.preguntas_seccion14;
    this.dataFrm14.test = JSON.stringify(this.dataFrm14.test );
    this.actualizarPaso14();
  }
  public actualizarPaso14(){
    let data = this.dataFrm14;
    this.apiService
    .put({ api: this.updateFrmTest14 + this.dataFrm14._id, data })
    .subscribe(
      (response) => {
        //  
        this.notificacionTab14.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
}
