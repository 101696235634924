
<div class="row">
  <div class="col-12">
      <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
      <h4 class="titulo-wizard">Datos personales</h4>
      <h5>Pregunta 2 / 16</h5>
      <p>Cuéntanos un poco sobre ti completando esta información</p>
      <hr class="hrLine" />
  </div>
</div>
<div class="row">
    <div class="col-md-6">
      <img src = "/assets/wizard-images/Test-4.png" class = "imagen" alt="chica">
    </div>
    <div class="col-md-5">
        <form>
            <div class="form-group">
              <label for="exampleFormControlSelect1">Género:</label>
              <select class="form-control" id="exampleFormControlSelect1"  [(ngModel)]="genero" [ngModelOptions]="{standalone: true}">
                <option selected>Selecciona</option>
                <option value="Masculino">Masculino</option>
                <option value="Femenino">Femenino</option>
                <option value="Otro">Otro</option>
              </select>
            </div>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Colegio o universidad:</label>
              <input type="text" class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder = "Escribe el nombre de tu institución"  [(ngModel)]="colegio" [ngModelOptions]="{standalone: true}">
            </div>
            <div class="form-group">
                <label for="exampleFormControlTextarea1">Tu edad:</label>
                <input type="number" min="0" class="form-control" id="exampleFormControlTextarea1" rows="3" placeholder = "Escribe tu edad"  [(ngModel)]="edad" [ngModelOptions]="{standalone: true}">
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1">Nivel académico actual:</label>
                <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="nivel_academico" [ngModelOptions]="{standalone: true}">
                  <option selected>Selecciona tu nivel</option>
                  <option value="Bachillerato 6to">Bachillerato 6to</option>
                  <option value="Bachillerato 7mo">Bachillerato 7mo</option>
                  <option value="Bachillerato 8vo">Bachillerato 8vo</option>
                  <option value="Bachillerato 9no">Bachillerato 9no</option>
                  <option value="Bachillerato 10mo">Bachillerato 10mo</option>
                  <option value="Bachillerato 11vo">Bachillerato 11vo</option>
                  <option value="Bachillerato 12vo">Bachillerato 12vo</option>
                  <option value="Pregrado en Curso">Pregrado en Curso</option>
                  <option value="Pregrado Terminado">Pregrado Terminado</option>
                </select>
              </div>
              <div class="form-group">
                <label for="exampleFormControlSelect1">¿Qué te gustaría estudiar?</label>
                <select class="form-control" id="exampleFormControlSelect1"  [(ngModel)]="disciplina" [ngModelOptions]="{standalone: true}">
                  <option selected>Selecciona una disciplina</option>
                  <option *ngFor="let item of ListDisciplinas" [value]="item.value">{{item.value}}</option>
                </select>
              </div>

              <div class="form-group">
                <ng-container *ngIf="disciplina=='Other'">
                  <input type="text" class="form-control" rows="3" placeholder = "Escribe que te gustaria estudiar" [(ngModel)]="otraDisciplina">
                </ng-container>
              </div>

              <div class="form-group">
                <label for="otherOpcional">¿Qué otra carrera quieres estudiar? (Opcional)</label>
                <input type="text" class="form-control" id="otherOpcional" rows="3" placeholder = "Escribe que te gustaria estudiar"  [(ngModel)]="otraDisciplinaOpcional" [ngModelOptions]="{standalone: true}">
              </div>
          </form>
          <div class="row">
            <div class="col-12 p-0 text-center">
                <em class="fas fa-solid fa-angle-left brnVolver" (click)="volver()" ></em>
                <button class="btn-continuar" (click)="continuar()">Continuar</button>
            </div>
          </div>
    </div>
</div>
