<div class="personal-info">
    <div class="personal-info-header">
        <img routerLink="../" class="back-button" src="assets/utils/back.png" alt="go back"/>
        <h3 class="personal-info-title"> {{ "Personal-information.personal-info" | translate }}</h3>
    </div>
    <div class="personal-info-form">
        <form #personalInfoForm (ngSubmit)="onSaveChanges()">
            <div class="form-group">
                <label for="name"> {{ "Personal-information.name" | translate }} (<span style="color: red;">*</span>)</label>
                <input [placeholder]="'Personal-information.name'|translate" [(ngModel)]="personalInfo.nombre" class="form-input" id="name" name="name"
                    type="text" />
            </div>
            <div class="form-group">
                <label for="lastname"> {{ "Personal-information.last-name" | translate }} (<span style="color: red;">*</span>)</label>
                <input [placeholder]="'Personal-information.last-name'|translate" [(ngModel)]="personalInfo.apellido" class="form-input" id="lastname"
                    name="lastname" type="text" />
            </div>
            <div class="form-group">
                <label for="email"> {{ "Personal-information.email" | translate }} </label>
                <input disabled [placeholder]="'Personal-information.email'|translate" [(ngModel)]="personalInfo.email" class="form-input" id="email"
                    name="email" type="email">
            </div>
            <div class="form-group">
                <label for="phone"> {{ "Personal-information.telephone" | translate }} </label>
                <input [placeholder]="'Personal-information.telephone'|translate" [(ngModel)]="personalInfo.telefono" class="form-input" id="telephone"
                    name="telephone" type="telephone">
            </div>
            <div class="form-group">
                <label for="country"> {{ "Personal-information.country" | translate }} </label>
                <select (change)="onSelectCountry({id: $event.target.value})" [(ngModel)]="personalInfo.pais"
                    class="form-input" id="country" name="country">
                    <option *ngFor="let country of countries" value="{{country.id}}">{{country.name}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="city"> {{ "Personal-information.city" | translate }} </label>
                <select [(ngModel)]="personalInfo.ciudad" class="form-input" id="city" name="city">
                    <option *ngFor="let city of cities" value="{{city.id}}">{{city.name}}</option>
                </select>
            </div>
            <div class="form-group">
                <label for="birth"> Birth date </label>
                <input placeholder="DD/MM/YYYY" [(ngModel)]="personalInfo.fechaNacimiento" class="form-input" id="birth"
                    name="birth" type="date">
            </div>
            <div class="form-group">
                <label for="gender"> {{ "Personal-information.gender" | translate }} </label>
                <select class="form-input" id="gender" [(ngModel)]="personalInfo.sexo" name="gender">
                    <option value="Masculino"> {{ "Personal-information.gender-male" | translate }} </option>
                    <option value="Femenino"> {{ "Personal-information.gender-female" | translate }} </option>
                    <option value="Not-specified">{{ "Personal-information.gender-prefer-not-say" | translate }}
                    </option>
                </select>
            </div>
            <div class="form-group">
              <label for="facebook"> Facebook</label>
              <input [placeholder]="'Personal-information.your-facebook'|translate" [(ngModel)]="personalInfo.facebook" class="form-input" id="facebook"
                  name="facebook" type="text">
            </div>
            <div class="form-group">
              <label for="linked-in"> LinkedIn</label>
              <input [placeholder]="'Personal-information.your-linkedin'|translate" [(ngModel)]="personalInfo.linkedIn" class="form-input" id="linked-in"
                  name="linked-in" type="text">
            </div>
            <div class="form-group">
              <label for="instagram"> Instagram</label>
              <input [placeholder]="'Personal-information.your-instagram'|translate" [(ngModel)]="personalInfo.instagram" class="form-input" id="instagram"
                  name="instagram" type="text">
            </div>
            <div class="form-footer">
                <button (click)="onChangePasswordClick()" type="button" id="change-password-button">{{
                    "Personal-information.change-password" | translate }} </button>
                <button *ngIf="!loading" type="submit" id="save-button"> {{ "Personal-information.save-changes" |
                    translate }} </button>
                <mat-spinner class="profile-loading-spinner" *ngIf="loading" diameter="40"></mat-spinner>
            </div>
        </form>
    </div>
</div>
