<div class="row">
    <div class="col-12">
        <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
        <h4 class="titulo-wizard">Hablemos de Presupuesto</h4>
        <h5>Pregunta 16 / 16</h5>
        <p>Es importante tener en cuenta el presupuesto con el que cuentas este momento para iniciar tu camino hacia el éxito. Selecciona una de las siguientes opciones</p>
        <hr class="hrLine" />
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-12">
        <table summary="Pregunta 16">
            <tr>
                <th scope="col"></th>
                <th scope="col" class="paddingTh">Si</th>
                <th scope="col" class="paddingTh">No</th>
            </tr>
            <tr *ngFor="let preg of rondaPreguntas; let i = index;" class="marginTr">
                <td> {{preg.pregunta}}</td>
                <td class="textCenterInput"> <input type="checkbox"  [value]="1" name="opcion" id = "checkBox{{i}}value1" [checked]="preg.seleccionada == 'si'"  (click) = "getCheckBox($event,i,true)"></td>
                <td class="textCenterInput"> <input type="checkbox" [value]="2" name="opcion" id = "checkBox{{i}}value2" [checked]="preg.seleccionada == 'no'"  (click) = "getCheckBox($event,i,false)"></td>
            </tr>
            
        </table>
    </div>
  </div> -->

  <div class="row">

    <div class="col-md-6 cardContent content-img" *ngFor="let preg of rondaPreguntas; let imageIndex=index" >
        <div class="row">
            <div class="col-md-12">
                <img src = "/assets/images_360/pantalla7/{{imageIndex}}.jpg" class="img-from-divs sizingImage">
            </div>

            <div class="col-md-12 questionContainer">
                <p class="question">{{preg.pregunta}}</p>
            </div>

            <div class="col-md-12">
                <div class="optionsContent">
                    <div><strong class="labelCheckbox" *ngFor="let indexGroup of ['Si','No']">{{indexGroup}}</strong></div>
                    <input class="checkBoxEdit" type="checkbox" [value]="1" name="opcion" id = "checkBox{{imageIndex}}value1" [checked]="preg.seleccionada == 'si'"  (click) = "getCheckBox($event,imageIndex,true)">
                    <input class="checkBoxEdit" type="checkbox" [value]="2" name="opcion" id = "checkBox{{imageIndex}}value2" [checked]="preg.seleccionada == 'no'"  (click) = "getCheckBox($event,imageIndex,false)">
                </div>
            </div>

        </div>
    </div>
  </div>


  <div class="row">
    <div class="col-12 text-center">
        <em class="fas fa-solid fa-angle-left brnVolver" (click)="volverTab14()" ></em>
        <button class="btn-continuar" (click)="continuarTab16()">Continuar</button>
    </div>
  </div>