import { Component, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from '../../public/services/api.service';
import { ExperienceModalComponent } from '../experienceModalComponent/experienceModalComponent';
import { ActivatedRoute } from '@angular/router';
import { ExperienceComponent } from '../experiences/experience.component';

@Component({
  selector: 'app-experience-approval',
  templateUrl: './experienceApprovalComponent.html',
  styleUrls: ['./experienceApprovalComponet.scss']
})
export class ExperienceApprovalComponent implements OnInit, AfterViewInit {
  @ViewChild(ExperienceModalComponent)
  private experienceModalComponent: ExperienceModalComponent;
  @ViewChild(ExperienceComponent, { static: true })
  private experienceComponent: ExperienceComponent;
  formList: any[] = [];
  formListData: any[] = [];
  approve: boolean = false;
  isModalOpen = true; 
  modalRef: NgbModalRef;

  constructor(private apiService: ApiService, private modalService: NgbModal, private route: ActivatedRoute) {}

  ngOnInit() {
    this.route.paramMap.subscribe(params => {
      const id = params.get('id');
      this.apiService.get({ api: `api/experiences/${id}` }).subscribe(
        (response) => {
          this.formListData = response;
          this.experienceModalComponent.setFormListData(this.formListData);
        },
        (error) => {
          console.error('Error retrieving the data :', error);
        }
      );
    });
  }
  

  ngAfterViewInit() {
    setTimeout(() => {
      this.openModal();
    });
  }

  openModal() {
    const content = this.experienceModalComponent.getContent();
    this.modalRef = this.modalService.open(content, { backdrop: 'static', keyboard: false });
    this.experienceModalComponent.setModalRef(this.modalRef);
    this.experienceModalComponent.setStudentValue(false);
    this.isModalOpen = true;
  }  
}
