import { WorkExperienceComponent } from './public/profile/work-experience/work-experience.component';
import { MarketplaceComponent } from './public/marketplace/marketplace.component';
import { VisaThermometerComponent } from './public/visa-thermometer/visa-thermometer.component';
import { CulturalMatchesComponent } from './public/cultural-matches/cultural-matches.component';
import { ItemCulturalComponent } from './public/item-cultural/item-cultural.component';
import { FavouritesComponent } from './public/favourites/favourites.component';
import { ViewWebinarsComponent } from './public/view-webinars/view-webinars.component';
import { AcademicHistoryComponent } from './public/profile/academic-history/academic-history.component';
import { MyBudgetComponent } from './public/profile/my-budget/my-budget.component';
import { MyLanguagesComponent } from './public/profile/my-languages/my-languages.component';
import { AcademicInterestsComponent } from './public/profile/academic-interests/academic-interests.component';
import { Component, NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { HomeComponent } from './public/home/home.component';
import { ProgramDetailComponent } from './public/program/program-detail/program-detail.component';
import { ProgramComponent } from './public/program/program.component';
import { ApplicationComponent } from './public/application/application.component';
import { VisaFormComponent } from './public/visa-form/visa-form.component';
import { BlogComponent } from './public/blog/blog.component';
import { ProfileComponent } from './public/profile/profile.component';
import { BlogDetailComponent } from './public/blog/blog-detail/blog-detail.component';
import { PersonalInfoComponent } from './public/profile/personal-info/personal-info.component';
import { TravelHistoryComponent } from './public/profile/travel-history/travel-history.component';
import { LegalRepresentativeComponent } from './public/profile/legal-representative/legal-representative.component';
import { ShoppingCartComponent } from './public/shopping-cart/shopping-cart.component';
import { PurchaseHistoryComponent } from './public/purchase-history/purchase-history.component';
import { UniversityComponent } from './public/university/university.component';
import { LoginComponent } from './public/login/login.component';
import { AuthGuardGuard } from './public/guards/auth-guard.guard';
import { MyApplicationsComponent } from './public/my-applications/my-applications.component';
import { NotFoundComponent } from './public/not-found/not-found.component';

import { ProgramResolver } from './public/services/resolvers/program-resolver.service';
import { BlogResolver } from './public/services/resolvers/blog-resolver.service';
import { UniversityResolver } from './public/services/resolvers/university-resolver.service';
import { AlertsComponent } from './public/alerts/alerts.component';
import { TicketDetailComponent } from './public/alerts/ticket-detail/ticket-detail.component';
import { OneTimeFeeComponent } from './public/one-time-fee/one-time-fee.component';
import { ApplicationFeePaymentComponent } from './public/application-fee-payment/application-fee-payment.component';
import { EmailConfirmationComponent } from './public/email-confirmation/email-confirmation.component';
import { PseResponseComponent } from './public/pse-response/pse-response.component';
import { ServicePrincipalComponent } from './public/home/service-principal/service-principal.component';
import { StudentsComponent } from './public/students/students.component';
import { EnglishTestComponent } from './public/english-test/english-test.component';
import { UnlinkAccountComponent } from './public/unlink-account/unlink-account.component';
import { EnglishFormComponent } from './public/english-test/english-form/english-form.component';
import { EnglishTestGuardGuard } from './public/guards/english-test-guard.guard';
import { TermsAndConditionsComponent } from './public/terms-and-conditions/terms-and-conditions.component';
import { PrivacyPolicyComponent } from './public/privacy-policy/privacy-policy.component';
import { PersonalDataPolicyComponent } from './public/personal-data-policy/personal-data-policy.component';
import { ManifestComponent } from './public/manifest/manifest.component';
import { FreeGuidesComponent } from './public/free-guides/free-guides.component';
import { FreeGuidesResourcesComponent } from './public/free-guides/free-guides-resources/free-guides-resources.component';
import { FreeGuidesDetailComponent } from './public/free-guides/free-guides-detail/free-guides-detail.component';
import { LeaderTestComponent } from './public/leader-test/leader-test.component';
import { Paso1Component } from './public/leader-test/wizard/paso1/paso1.component';
import { DashboardSchoolComponent } from './public/dashboard-school/dashboard-school.component';
import { ResumenTestUserComponent } from './public/dashboard-school/resumen-test-user/resumen-test-user.component'
import { PublicHomeComponent } from './public/home/public-home/public-home.component';
import { PreTestComponent } from './public/leader-test/pre-test/pre-test.component';
import { CurriculumComponent } from './public/curriculum/curriculum/curriculum.component';
import { LetterComponent } from './public/curriculum/letter/letter.component';
import { EditComponent } from './public/curriculum/curriculum/edit/edit.component';
import { Paso18Component } from './public/leader-test/wizard/paso18/paso18.component';
import { WishlistComponent } from './public/favourites/wishlist/wishlist.component';
import {InSessionGuardGuard} from './public/guards/in-session-guard.guard';
import { LetterReadyComponent } from './public/curriculum/letter/letter-ready/letter-ready.component';
import { SchoolStudentsListComponent } from './public/school-students-list/school-students-list.component';
import { StudentResumeComponent } from './public/school-students-list/student-resume/student-resume.component';
import { ExperienceComponent } from './public/experiences/experience.component';
import { ExperienceApprovalComponent } from './public/experienceApprovalComponent/experienceApproval.component';

const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        redirectTo: 'public-home',
        pathMatch: 'full',
      },
      {
        path: 'public-home',
        component: PublicHomeComponent,
        canActivate: [InSessionGuardGuard],
      },
      {
        path: 'preTest',
        component: PreTestComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'leaderTest',
        component: LeaderTestComponent,
      },
      {
        path: 'wizard',
        component: Paso1Component,
      },
      {
        path: 'home',
        component: HomeComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'home/buscador',
        component: HomeComponent,
      },
      {
        path: 'program/search',
        component: ProgramComponent,
      },
      {
        path: 'program/:slug',
        component: ProgramDetailComponent,
        resolve: {
          program: ProgramResolver,
        }
      },
      {
        path: 'application/:id',
        component: ApplicationComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'resument-test/:id/:semestre',
        component: ResumenTestUserComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'application/:id/visa',
        component: VisaFormComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'blog',
        component: BlogComponent,
      },
      {
        path: 'profile',
        component: ProfileComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'blog/:slug',
        component: BlogDetailComponent,
        resolve: {
          blog: BlogResolver,
        },
      },
      {
        path: 'profile/personal-info',
        component: PersonalInfoComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'profile/academic-interests',
        component: AcademicInterestsComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'profile/travel-history',
        component: TravelHistoryComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'profile/legal-representative',
        component: LegalRepresentativeComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'profile/my-budget',
        component: MyBudgetComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'profile/my-languages',
        component: MyLanguagesComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'skill-dashboard',
        component: Paso18Component,
        data: { readOnly: true },
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'viewDashboardStudent/:student',
        component: Paso18Component,
        data: { readOnly: true },
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'favourites',
        component: FavouritesComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'wishlist',
        component: WishlistComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'webinar',
        component: ViewWebinarsComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'student',
        component: StudentsComponent
      },
      {
        path: 'profile/academic-history',
        component: AcademicHistoryComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'cultural-item',
        component: ItemCulturalComponent,
      },
      {
        path: 'cultural-match',
        component: CulturalMatchesComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'english-test',
        component: EnglishTestComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'english-test/:level',
        component: EnglishFormComponent,
        // canActivate: [EnglishTestGuardGuard]
      },
      {
        path: 'visa-thermometer',
        component: VisaThermometerComponent,
      },
      {
        path: 'shopping-cart',
        component: ShoppingCartComponent,
      },
      { path: 'marketplace', component: MarketplaceComponent },
      { path: 'services', component: ServicePrincipalComponent },
      {
        path: 'purchase-history',
        component: PurchaseHistoryComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'university/:slug',
        component: UniversityComponent,
        resolve: {
          university: UniversityResolver,
        },
      },
      {
        path: 'login',
        component: LoginComponent,
      },
      {
        path: 'my-applications',
        component: MyApplicationsComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'notifications',
        component: AlertsComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'ticket/:id',
        component: TicketDetailComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'profile/work-experience',
        component: WorkExperienceComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'application-fee/:id',
        component: ApplicationFeePaymentComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'not-found',
        component: NotFoundComponent,
      },
      {
        path: 'unlink-account',
        component: UnlinkAccountComponent
      },
      {
        path:'dashboard-school',
        component: DashboardSchoolComponent,
        canActivate: [AuthGuardGuard],
      },
      {
        path: 'email/:email/:token/confirmation',
        component: EmailConfirmationComponent,
      },
      {
        path: 'payment/u360-payment/:estudiante',
        component: OneTimeFeeComponent,
      },
      {
        path: 'response',
        component: PseResponseComponent,
      },
      {
        path: 'terms-and-conditions',
        component: TermsAndConditionsComponent,
      },
      {
        path: 'privacy-policy',
        component: PrivacyPolicyComponent,
      },
      {
        path: 'personal-data-policy',
        component: PersonalDataPolicyComponent,
      },
      {
        path: 'manifest',
        component: ManifestComponent,
      },
      {
        path: 'free-guides',
        component: FreeGuidesComponent
      },
      {
        path: 'free-guides/resources',
        component: FreeGuidesResourcesComponent
      },
      {
        path: 'free-guide/:id',
        component: FreeGuidesDetailComponent
      },
      {
        path: 'curriculum',
        component: CurriculumComponent
      },
      {
        path: 'viewProfile/:link',
        component: CurriculumComponent
      },
      {
        path: 'studentProfile/:student',
        component: CurriculumComponent
      },
      {
        path: 'letter',
        component: LetterComponent
      },
      {
        path: 'letter-ready',
        component: LetterReadyComponent
      },
      {
        path: 'curriculum-edit',
        component: EditComponent
      },
      {
        path: 'studentsList',
        component: SchoolStudentsListComponent
      },
      {
        path: 'student-resume/:estudiante',
        component: StudentResumeComponent
      },
      {
        path: 'my-experiences',
        component: ExperienceComponent
      },
      {
        path: 'experienceApproval/:id',
        component: ExperienceApprovalComponent
      },
      {
        path: '**',
        redirectTo: 'not-found',
      }
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      initialNavigation: 'enabled',
      scrollPositionRestoration: 'enabled',
      anchorScrolling: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
