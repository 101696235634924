import { Component, Inject, Input, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertService } from '../../services/alert.service';
import { ApiService } from '../../services/api.service';
import { Favourite } from './favourite.interface';
@Component({
  selector: 'app-add-favourite',
  templateUrl: './add-favourite.component.html',
  styleUrls: ['./add-favourite.component.scss']
})
export class AddFavouriteComponent {
  readonly ADDITIONAL_FAVOURITES_API = 'api/favoritos-adicionales'
  additionalFav: any = {
    usuario: '',
    universidad: '',
    programa: '',
    tipoPrograma: '',
    ubicacion: '',
    stdLength: '',
    start: '',
    fee: '',
    tuitionCost: '',
    comentarios: '',
    link: 'https://'
  };

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: {usuario: string, additionalFav: any, isEditing: boolean},
    private apiService: ApiService,
    private alertService: AlertService,
    private dialogRef: MatDialogRef<AddFavouriteComponent>,
  ) { }

  ngOnInit(){
    if(this.data.additionalFav){
      this.additionalFav = this.data.additionalFav;
      this.additionalFav.usuario = this.data.usuario;
    };
  }

  onAddFavourite(form: NgForm){
    if(form.valid){
      this.additionalFav.usuario = this.data.usuario;
      //  
      this.apiService.post({ api: this.ADDITIONAL_FAVOURITES_API, data: this.additionalFav }).subscribe(response => {
        if(response.success){
          this.alertService.showSuccess({
            msg: 'Favorito agregado exitosamente'
          })
          this.dialogRef.close();
        } else {
          this.alertService.showError({
            msg: response.error
          })
          this.dialogRef.close();
        }
      })
    }
  }

  onEditFavourite(form: NgForm){
    if(form.valid){
      this.apiService.put({api: `${this.ADDITIONAL_FAVOURITES_API}/${this.additionalFav._id}`, data: this.additionalFav}).subscribe(response => {
        if(response.success){
          this.alertService.showSuccess({
            msg: 'Favorito editado exitosamente'
          });
          this.dialogRef.close();
        } else {
          this.alertService.showError({
            msg: response.error
          })
          this.dialogRef.close();
        }
      })
    }
  }

  onDeleteFavourite(form: NgForm) {
    //  
    this.apiService.delete({ api: `${this.ADDITIONAL_FAVOURITES_API}/${this.additionalFav._id}` }).subscribe(response => {
      if (response.success) {
        this.alertService.showSuccess({
          msg: 'Favorito eliminado exitosamente'
        });
        this.dialogRef.close();
      } else {
        this.alertService.showError({
          msg: response.error
        })
        this.dialogRef.close();
      }
    })

  }
}
