<div class="academic-history">
  <div class="academic-history-header">
    <img routerLink="../" class="back-button" src="assets/utils/back.png" alt="go back">
    <h3 class="academic-history-title"> {{ "Academic-history.academic-history" | translate }} </h3>
  </div>
  <div class="academic-history-form">
    <form #academicHistoryForm (ngSubmit)="onSubmitForm()">
      <div class="academic-history-entry" *ngFor="let entry of academicHistoryArray.controls; index as i"
        [formGroup]="entry">
        <div class="form-group">
          <label [for]="'studyLevel' + i" class="form-label"> {{ "Academic-history.study-level" | translate }} </label>
          <br>
          <select [id]="'studyLevel' + i" class="form-select" formControlName="estudiosRealizados">
            <option value="bachelor"> {{ "Academic-interests.bachelor" | translate }} </option>
            <option value="master"> {{ "Academic-interests.master" | translate }}</option>
            <option value="certificate"> {{ "Academic-interests.certificate" | translate }}</option>
            <option value="diploma"> {{ "Academic-interests.diploma" | translate }}</option>
            <option value="pre-master"> {{ "Academic-interests.pre-master" | translate }} </option>
          </select>
        </div>
        <div class="form-group">
          <label [for]="'institution' + i" class="form-label"> {{ "Academic-history.institution" | translate }} </label>
          <br>
          <input [id]="'institution' + i" type="text" class="form-input" [placeholder]="'Academic-history.academic-institution'|translate"
            formControlName="institucion">
        </div>
        <div class="form-group">
          <label [for]="'degree' + i" class="form-label"> {{ "Academic-history.obtained-degree" | translate }} </label>
          <br>
          <input [id]="'degree' + i" type="text" class="form-input" [placeholder]="'Academic-history.obtained-degree'|translate"
            formControlName="tituloAlcanzado">
        </div>
        <div class="form-group">
          <label [for]="'startDate' + i" class="form-label"> {{ "Academic-history.studies-start-date" | translate }}
          </label> <br>
          <input [id]="'startDate' + i" type="date" class="form-input" placeholder="dd/mm/yyyy"
            formControlName="fechaDeInicio">
        </div>
        <div class="form-group">
          <label [for]="'endDate' + i" class="form-label"> {{ "Academic-history.studies-end-date" | translate }}
          </label> <br>
          <input [id]="'endDate' + i" type="date" class="form-input" placeholder="dd/mm/yyyy"
            formControlName="fechaDeFinalizacion">
        </div>
        <div class="form-group">
          <label [for]="'grade' + i" class="form-label"> {{ "Academic-history.gpa" | translate }} </label> <br>
          <input [id]="'grade' + i" type="number" class="form-input" placeholder="GPA" formControlName="notaPromedio">
        </div>
        <div class="form-group">
          <label class="upload-document">
            <img class="upload-image" src="assets/utils/camera.png" alt="camera">
            {{ "Academic-history.upload-grades-certificate" | translate }}
            <input (change)="onUploadCertificate({event: $event, index: i})" class="upload-input" type="file">
          </label>
        </div>
        <div class="form-group">
          <label class="upload-document">
            <img class="upload-image" src="assets/utils/camera.png" alt="camera">
            {{ "Academic-history.upload-diploma" | translate }}
            <input (change)="onUploadDiploma({event: $event, index: i})" class="upload-input" type="file">
          </label>
        </div>
        <div class="form-group"
          *ngIf="entry.get('certificadoDeNotas').value !== null && entry.get('certificadoDeNotas').value !== undefined && entry.get('certificadoDeNotas').value !== ''">
          <a [href]="entry.get('certificadoDeNotas').value"> {{ "Academic-history.view-certificate" | translate }} </a>
        </div>
        <div class="form-group"
          *ngIf="entry.get('diploma').value !== null && entry.get('diploma').value !== undefined && entry.get('diploma').value !== ''">
          <a [href]="entry.get('diploma').value"> {{ "Academic-history.view-diploma" | translate }} </a>
        </div>
        <div class="form-group">
          <label [for]="'leadership' + i" class="form-label"> {{ "Academic-history.leadership" | translate }} </label>
          <br>
          <textarea [id]="'leadership' + i" class="form-input" [placeholder]="'Academic-history.most-inclusive'|translate"
            formControlName="leadershipAwards"></textarea>
        </div>
        <div class="form-group">

        </div>
        <div class="form-group">
          <button class="delete-entry" (click)="onRemoveEntry({index: i})"> {{ "Academic-history.remove-entry" |
            translate }} </button>
        </div>
      </div>
      <button id="add-entry-button" (click)="onAddEntry()" type="button"> + {{ "Academic-history.add-new-entry" |
        translate }} </button>
      <button id="save-changes" type="submit"> {{ "Academic-history.save-changes" | translate }} </button>
    </form>
  </div>
</div>
