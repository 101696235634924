<div class="change">
  <div class="title">
    {{'Password-recovery.recover-password'|translate}}
  </div>
  <div class="description">
    {{'Password-recovery.type-your-data'|translate}}
  </div>
  <div>
    <div class="email-title">{{'Password-recovery.email'|translate}}</div>
    <div class="container-email">
      <input placeholder="Escribe aquí tu correo" type="email" [(ngModel)]="email">
      <button (click)="onRecuperar()">{{'Password-recovery.recover'|translate}}</button>

    </div>
  </div>
  <!-- <div class="change-password-form">
    <form [formGroup]="changePasswordForm" (ngSubmit)="onChangePassword()">
      <div class="form-group">
        <label class="form-label" for="old-password"> {{ "Password-recovery.old-password" | translate }} </label> <br>
        <input class="form-input" type="password" id="old-password" formControlName="password"
          placeholder="Old password">
      </div>
      <div class="form-group">
        <label class="form-label" for="new-password"> {{ "Password-recovery.new-password" | translate }} </label> <br>
        <input class="form-input" type="password" id="new-password" formControlName="passwordNueva"
          placeholder="New password">
      </div>
      <div class="form-group">
        <label class="form-label" for="confirm-password"> {{ "Password-recovery.confirm-password" | translate }}
        </label> <br>
        <input class="form-input" type="password" id="confirm-password" formControlName="confirmPassword"
          placeholder="Confirm new password">
      </div>
      <p *ngIf="changePasswordForm.invalid && changePasswordForm.touched" class="error-msg">New and confirm password
        must match {{ "Password-recovery.match-alert" | translate }} </p>
      <button [disabled]="changePasswordForm.invalid" type="submit" id="change-password-button">{{
        "Password-recovery.change-password" | translate }} </button>
    </form>
  </div> -->
</div>
