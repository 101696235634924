import { Component, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ExperienceCardsComponent } from '../experiencesCards/experiencesCards.component';
import { ExperienceModalComponent } from '../experienceModalComponent/experienceModalComponent';
import { ApiService } from '../../public/services/api.service';

@Component({
  selector: 'app-experience',
  templateUrl: './experience.component.html',
  styleUrls: ['./experience.component.scss']
})
export class ExperienceComponent {
  formList: any[] = [];
  isModalOpen = false;
  modalRef: NgbModalRef;
  editingMode: boolean = false;

  @ViewChild(ExperienceCardsComponent, { static: false })
  private experienceCardsComponent: ExperienceCardsComponent;

  @ViewChild(ExperienceModalComponent, { static: false })
  private experienceModalComponent: ExperienceModalComponent;

  constructor(private modalService: NgbModal, private apiService: ApiService) {}

  activateEditingMode() {
    this.editingMode = true;
  }

  openModal() {
    const content = this.experienceModalComponent.getContent();
    this.modalRef = this.modalService.open(content);
    this.experienceModalComponent.setModalRef(this.modalRef);
    this.isModalOpen = true;
  }

  closeModal() {
    this.modalRef.dismiss();
    this.isModalOpen = false;
  }

  handleFormDataSubmitted(formData: any[]) {
    this.formList = formData;
    this.experienceCardsComponent.updateFormData(this.formList);
  }
}
