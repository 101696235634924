<div class="services">
    <div class="service-img-container">
        <img class="service-img" src="assets/home/images/imagen-servicios.png" alt="banner test visa">
    </div>
    <div class="max-width-container">
        <div class="service-header-container">
            <img class="birrete-dorado" src="assets/utils/birrete-amarillo.png" alt="yellow line">
            <label id="title-section">{{ "Service.all-you-need" | translate }}</label>
            <p id="text-section">
                {{ "Service.get-services" | translate }}
            </p>
        </div>
        <div class="all-service-container">
            <div class="service-top-row">
                <div class="service-container">
                    <button routerLink="/marketplace" [queryParams]="{'serviceType': 'consultoria-vip'}"
                        [ngStyle]="{'border-color': colors[0], 'color': colors[0]}">
                        {{ "Service.consulting" | translate }}<br><span>{{ "Service.consulting-types" | translate }}</span></button>
                </div>
                <div class="service-container">
                    <button routerLink="/marketplace" [queryParams]="{'serviceType': 'servicios-medicos'}"
                        [ngStyle]="{'border-color': colors[2], 'color': colors[2]}">{{ "Service.travel-insurance" | translate }}</button>
                </div>
                <div class="service-container">
                    <button routerLink="/marketplace" [queryParams]="{'serviceType': 'traducciones'}"
                        [ngStyle]="{'border-color': colors[1], 'color': colors[1]}">{{ "Service.official-translations" | translate }}</button>
                </div>
                <!-- <div class="service-container">
                    <button routerLink="/marketplace" [queryParams]="{'serviceType': 'gallup'}"
                        [ngStyle]="{'border-color': colors[3], 'color': colors[3]}">{{ "Service.vocational-tests" | translate }}</button>
                </div> -->
                <div class="service-container">
                    <button routerLink="/marketplace" [queryParams]="{'serviceType': 'visas'}"
                        [ngStyle]="{'border-color': colors[3], 'color': colors[3]}">{{ "Service.visa-services" | translate }}</button>
                </div>
                <div class="service-container">
                    <button routerLink="/marketplace" [queryParams]="{'serviceType': 'test-de-idioma'}"
                        [ngStyle]="{'border-color': colors[0], 'color': colors[0]}">{{ "Service.language-test" | translate }}</button>
                </div>
            </div>
        </div>
    </div>
</div>

<app-test-section></app-test-section>