<div class="work-experience">
  <div class="work-expereince-title">
    <img routerLink="../" class="back-button" src="assets/utils/back.png" alt="go back">
    <h3 class="work-experience-title"> {{ "Work-experience.work-experience" | translate }} </h3>
  </div>
  <form [formGroup]="workExperienceForm" (ngSubmit)="onSubmitForm()">
    <div class="work-experience-content">
      <div class="form-title"> {{ "Work-experience.do-you-have" | translate }} </div>
      <div class="radio-group">
        <input [value]="true" formControlName="tieneExperiencia" type="radio" class="form-radio"
          id="rejected-visa-yes">
        <label for="rejected-visa-yes" class="form-label"> {{ "Work-experience.yes" | translate }} </label>
      </div>
      <div class="radio-group">
        <input [value]="false" formControlName="tieneExperiencia" class="form-radio" type="radio"
          id="rejected-visa-no">
        <label for="rejected-visa-no" class="form-label"> {{ "Work-experience.no" | translate }} </label>
      </div>
      <p>{{"Work-experience.if-your-answer" | translate}}</p>
      <div class="work-experinces" formArrayName="experiencias">
        <div class="experience row" *ngFor="let experience of experiences.controls; index as i" [formGroupName]="i">
          <div class="form-group">
            <label [for]="'position' + i" class="form-label"> {{ "Work-experience.position" | translate }} (<span style="color: red;">*</span>)</label>
            <input formControlName="posicion" class="form-input" [id]="'position' + i" type="text" [placeholder]="'Work-experience.position-example'|translate">
          </div>
          <div class="form-group">
            <label [for]="'company' + i" class="form-label"> {{ "Work-experience.company" | translate }} (<span style="color: red;">*</span>)</label>
            <input formControlName="nombreEmpresa" class="form-input" [id]="'company' + i" type="text" placeholder="Imagineapps & co">
          </div>
          <div class="form-group">
            <label [for]="'start-year-' + i" class="form-label"> {{ "Work-experience.start-year" | translate }} (<span style="color: red;">*</span>)</label>
            <input formControlName="anoInicio" class="form-input" [id]="'start-year-' + i" type="number" placeholder="2020">
            <p class="error-msg" *ngIf="experience.get('anoInicio').invalid && experience.get('anoInicio').touched"> {{"Work-experience.invalid-year" | translate}} </p>
          </div>
          <div class="form-group">
            <label [for]="'end-year-' + i" class="form-label"> {{ "Work-experience.end-year" | translate }} (<span style="color: red;">*</span>)</label>
            <input formControlName="anoFin" class="form-input" [id]="'end-year-' + i" type="number" placeholder="2020">
            <p class="error-msg" *ngIf="experience.get('anoFin').invalid && experience.get('anoFin').touched"> {{"Work-experience.invalid-year" | translate}} </p>
          </div>
          <div class="wrapper">
            <div class="form-group">
              <label [for]="'info' + i" class="form-label"> {{ "Work-experience.additional-info" | translate }} </label>
              <input formControlName="informacionAdicional" class="form-input" [id]="'info' + i" type="text" [placeholder]="'Work-experience.additional-info-example'|translate">
            </div>
              <button type="button" (click)="onDeleteExperience({index: i})" class="delete-button"> {{"Work-experience.delete" | translate}} </button>
          </div>
        </div>
        <button type="button" (click)="onAddExperince()" id="add-experience"> {{"Work-experience.add" | translate}} </button>
      </div>
      <button *ngIf="!loading" id="save-changes-button" type="submit" [disabled]="workExperienceForm.invalid"> {{"Work-experience.save-changes" | translate}}</button>
      <mat-spinner *ngIf="loading" diameter="40"></mat-spinner>
    </div>
  </form>
</div>
