<div class="free-guides">
    <div class="free-guides-container">
        <div class="card centro">
            <div class="row"  [hidden]="pasoActivo == 18">
                <div class="col-12">
                    <div class="progress">
                        <div class="progress-bar" role="progressbar" [style]='progreso' aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                      </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12">
                <div class="card cuerpo-card">
                    <div class="row">
                        <div class="col-12">
                            <div *ngIf="pasoActivo == 1">
                                <div class="row">
                                    <div class="col-12">
                                        <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
                                        <h4 class="titulo-wizard">Elige tu objetivo</h4>
                                        <h5>Pregunta 1 / 16</h5>
                                        <p>Creemos juntos tu perfil para maximizar tu éxito de admisión a la universidad de tus sueños. Elige el objetivo</p>
                                        <hr class="hrLine" />
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="card element-card"  [ngClass]="cardSeleccionada === 'Pregrado'? 'cardActiva':''">
                                        <div class="row no-gutters h-100" (click)="seleccionarCard('Pregrado')">
                                            <div class="col-md-6 my-auto imgPregrado">
                                            </div>
                                            <div class="col-md-5 my-auto">
                                              <div class="text-center">
                                                <h5 class="card-title">Pregrado</h5>
                                              </div>
                                            </div>
                                          </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="card element-card"  [ngClass]="cardSeleccionada === 'Postgrado'? 'cardActiva':''">
                                        <div class="row no-gutters h-100" (click)="seleccionarCard('Postgrado')">
                                            <div class="col-md-6 my-auto imgPost">
                                            </div>
                                            <div class="col-md-5 my-auto">
                                              <div class="text-center">
                                                <h5 class="card-title">Postgrado</h5>
                                              </div>
                                            </div>
                                          </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12 text-center" *ngIf="cardSeleccionada">
                                        <em *ngIf="pasoActivo != 1" class="fas fa-solid fa-angle-left brnVolver" (click)="volverFrm()"></em>
                                        <button class="btn-continuar" (click)="continuarFrm()">Continuar</button>

                                    </div>
                                </div>
                            </div>
                            <app-paso2 *ngIf="pasoActivo == 2" (notificacion)="ejecutarMetodo($event)"></app-paso2>
                            <app-paso3 *ngIf="pasoActivo == 3" (notificacionTab3)="ejecutarMetodo($event)"></app-paso3>
                            <app-paso4 *ngIf="pasoActivo == 4" (notificacionTab4)="ejecutarMetodo($event)"></app-paso4>
                            <app-paso5 *ngIf="pasoActivo == 5" (notificacionTab5)="ejecutarMetodo($event)"></app-paso5>
                            <app-paso15 *ngIf="pasoActivo == 6" (notificacionTab6)="ejecutarMetodo($event)"></app-paso15>
                            <app-paso16 *ngIf="pasoActivo == 7" (notificacionTab8)="ejecutarMetodo($event)"></app-paso16>
                            <app-paso6 *ngIf="pasoActivo == 8" (notificacionTab7)="ejecutarMetodo($event)"></app-paso6>
                            <app-paso17 *ngIf="pasoActivo == 9" (notificacionTab10)="ejecutarMetodo($event)"></app-paso17>
                            <app-paso7 *ngIf="pasoActivo == 10" (notificacionTab9)="ejecutarMetodo($event)"></app-paso7>

                            <app-paso8 *ngIf="pasoActivo == 11" (notificacionTab11)="ejecutarMetodo($event)"></app-paso8>
                            <app-paso9 *ngIf="pasoActivo == 12" (notificacionTab12)="ejecutarMetodo($event)"></app-paso9>
                            <app-paso12 *ngIf="pasoActivo == 13" (notificacionTab13)="ejecutarMetodo($event)"></app-paso12>
                            <app-paso14 *ngIf="pasoActivo == 14" (notificacionTab15)="ejecutarMetodo($event)"></app-paso14>
                            <app-paso10 *ngIf="pasoActivo == 15" (notificacionTab14)="ejecutarMetodo($event)"></app-paso10>
                            <app-paso11 *ngIf="pasoActivo == 16" (notificacionTab16)="ejecutarMetodo($event)"></app-paso11>
                            <app-paso13 *ngIf="pasoActivo == 17" (notificacionTab17)="ejecutarMetodo($event)"></app-paso13>
                            <app-paso18 *ngIf="pasoActivo == 18" (notificacionTab18)="ejecutarMetodo($event)"></app-paso18>

                        </div>
                    </div>
                    <div class="row my-3" [hidden]="pasoActivo == 18">
                        <div class="col-12">
                            <img
                            alt="U360 logo"
                            id="home-logo"
                            src="assets/navbar/logo.png"
                          />
                        </div>
                    </div>
                </div>
                </div>
            </div>

        </div>
    </div>
  </div>
