import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso7',
  templateUrl: './paso7.component.html',
  styleUrls: ['./paso7.component.scss']
})
export class Paso7Component implements OnInit {
  @Output() notificacionTab9 = new EventEmitter<string>();
  public preguntas_seccion09:any;
  public dataFrm09:any;
  public dataFrm09Copy:any;
  responseUser09: any;
  public A01:boolean = false;
  public A02:boolean = false;
  public B01:boolean = false;
  public B02:boolean = false;
  public C01:boolean = false;
  public C02:boolean = false;

  readonly getTest07 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest07 = 'api/get_internalizacionUser/';
   pasoActivo: any;
  constructor(private apiService:ApiService) {
    this.responseUser09 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.responseUser09) {
      this.apiService.get({ api: this.getTest07 + this.responseUser09._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //  
            let data_test03= JSON.parse(response.test[0]);
            this.dataFrm09 = response;
            this.pasoActivo= response.hojaTest;
            this.dataFrm09.test = JSON.parse(this.dataFrm09.test);
            this.preguntas_seccion09= data_test03.preguntas[8];
             
            //  
            let i = 0;
            for(let item of  this.preguntas_seccion09.preguntas){
                 
                if(i == 0){
                  if(item.opcionSeccionada == 'si'){
                      this.A01= true;
                  }else if(item.opcionSeccionada == 'no'){
                    this.A02= true;
                  }
                }
                if(i == 1){
                  if(item.opcionSeccionada == 'si'){
                      this.B01= true;
                    }else if(item.opcionSeccionada == 'no'){
                      this.B02= true;
                  }
                }
                if(i == 2){
                  if(item.opcionSeccionada == 'si'){
                      this.C01= true;
                    }else if(item.opcionSeccionada == 'no'){
                      this.C02= true;
                  }
                }
              i++
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    }
   }

  ngOnInit(): void {
  }
  public continuarTab9(){
    let paso = this.pasoActivo
    if(this.pasoActivo < 10){
      paso = this.pasoActivo = 10;
    }
    this.dataFrm09.hojaTest= paso
    this.dataFrm09.test.preguntas[8] = this.preguntas_seccion09;
    this.dataFrm09.test = JSON.stringify(this.dataFrm09.test );



    
    this.actualizarPaso7();
  }
  public actualizarPaso7(){
    let data = this.dataFrm09;
     
    this.apiService
    .put({ api: this.updateFrmTest07 + this.dataFrm09._id, data })
    .subscribe(
      (response) => {
         
        this.notificacionTab9.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
  public volverTab7(){
    this.notificacionTab9.emit("volver")
  }
  getCheckBox(event, pos, seleccion){
    let id = event.target.id;
    let disableId = event.target.id;
    let checkBoxPressed = document.getElementById(id) as HTMLInputElement;
    this.preguntas_seccion09.preguntas[pos].opcionSeccionada=seleccion;
     
    if(checkBoxPressed.checked){
      if(id.includes('value1')){
        disableId = id.replace('value1', 'value2');
      }
      else if(id.includes('value2')){
        disableId = id.replace('value2', 'value1');
        
      }
       
      let checkBoxDisable = document.getElementById(disableId);
      checkBoxDisable.setAttribute('disabled', new Boolean(true).toString());
    }
    else{
      if(id.includes('value1')){
        disableId = id.replace('value1', 'value2');
      }
      else if(id.includes('value2')){
        disableId = id.replace('value2', 'value1');
      }
      let checkBoxDisable = document.getElementById(disableId);
      checkBoxPressed.removeAttribute('disabled');
      checkBoxDisable.removeAttribute('disabled');
    }
   
  }

}
