<main class="school-dashboard">
  <section>
    <div class="row m-0">
      <div class="col-6 text-center tab" [ngClass]="{'tab-active': generalDisplay}">
        <label class="unselected-section display-mobile-none" [ngClass]="{'selected-section': generalDisplay}" (click)="displaySection('general')">
          Gráficos generales
        </label>
        <label class="unselected-section display-mobile" [ngClass]="{'selected-section': generalDisplay}" (click)="displaySection('general')">
          Generales
        </label>
      </div>

      <div class="col-6 text-center tab" [ngClass]="{'tab-active': skillsDisplay}">
        <label class="unselected-section display-mobile-none" [ngClass]="{'selected-section': skillsDisplay}" (click)="displaySection('skills')">
          Gráficos por competencias
        </label>
        <label class="unselected-section display-mobile" [ngClass]="{'selected-section': skillsDisplay}" (click)="displaySection('skills')">
          Por competencias
        </label>
      </div>

    </div>
  </section>

  <section *ngIf="generalDisplay" class="mt-4">
    <div class="col-12 p-0">
      <drag-scroll [scrollbar-hidden]="true" class="d-flex m-0 cards-container py-2 display-mobile-none">
        <ng-container [ngTemplateOutlet]="cardsGeneral"></ng-container>
      </drag-scroll>
      <div class="display-mobile d-flex m-0 cards-container mb-4">
        <ng-container [ngTemplateOutlet]="cardsGeneral"></ng-container>
      </div>
    </div>

    <div class="col-12 p-0 mt-2 mt-sm-4 mt-xl-5">
      <div class="row m-0">
        <div class="col-12 col-xl-6 p-0 d-flex flex-column justify-content-start align-items-center align-items-lg-start graphic-container">
          <label class="graphic-title pr-4 mb-4">Puntuación de Colegios Colombia por puntaje global</label>
          <canvas baseChart class="chart"
                  [labels]="graphicGlobalSchools.data.labels"
                  [datasets]="graphicGlobalSchools.data.datasets"
                  [options]="graphicGlobalSchools.options"
                  [plugins]="chartPlugins"
                  width="580px"
                  chartType="bar">
          </canvas>
        </div>
        <div class="col-12 col-xl-6 p-0 mt-5 mt-xl-0 text-center d-flex flex-column justify-content-start align-items-center align-items-lg-start graphic-container">
          <label class="graphic-title mb-4">Porcentaje países por aplicaciones</label>
          <canvas baseChart class="chart doughnut"
                  [labels]="graphicCountriesApplications.data.labels"
                  [datasets]="graphicCountriesApplications.data.datasets"
                  [options]="graphicCountriesApplications.options"
                  [plugins]="chartPlugins"
                  width="580px"
                  chartType="doughnut"
          >
          </canvas>
        </div>
        <div class="col-12 col-xl-6 p-0 text-center d-flex flex-column justify-content-start align-items-center align-items-lg-start mt-5 graphic-container">
          <label class="graphic-title mb-4">Porcentaje resultados English Test</label>
          <canvas baseChart class="chart doughnut"
                  [labels]="graphicEnglishTest.data.labels"
                  [datasets]="graphicEnglishTest.data.datasets"
                  [options]="graphicEnglishTest.options"
                  [plugins]="chartPlugins"
                  width="580px"
                  chartType="doughnut"
          >
          </canvas>
        </div>
        <div class="col-12 col-xl-6 p-0 d-flex flex-column justify-content-start align-items-center align-items-lg-start mt-5 graphic-container">
          <label class="graphic-title mb-4">Porcentaje por perfil de competencias </label>
          <canvas baseChart class="chart"
                  [labels]="graphicCompetences.data.labels"
                  [datasets]="graphicCompetences.data.datasets"
                  [options]="graphicCompetences.options"
                  [plugins]="chartPlugins"
                  width="580px"
                  chartType="bar">
          </canvas>
        </div>
        <div class="col-12 col-xl-6 p-0 d-flex flex-column justify-content-start align-items-center align-items-lg-start mt-5 graphic-container">
          <label class="graphic-title mb-4">Estudiantes por disciplina </label>
          <canvas baseChart class="chart doughnut last-chart"
                  [labels]="graphicStudentsDiscipline.data.labels"
                  [datasets]="graphicStudentsDiscipline.data.datasets"
                  [options]="graphicStudentsDiscipline.options"
                  [plugins]="chartPlugins"
                  width="580px"
                  chartType="doughnut"
          >
          </canvas>
        </div>
      </div>
    </div>
  </section>

  <section *ngIf="skillsDisplay">
    <div class="row d-flex justify-content-start align-items-center flex-wrap m-0 mt-2 filters">
      <select class="filter-select" [(ngModel)]="disciplina">
        <option value="" selected disabled>Disciplina</option>
        <option value="todos">Todos</option>
        <option *ngFor="let item of ListDisciplinas" [value]="item.text">{{item.value}}</option>
      </select>
      <select class="filter-select" [(ngModel)]="nivelAcademico">
        <option value="" selected disabled>Nivel académico</option>
        <option value="todos">Todos</option>
        <option value="Bachillerato-6to">Bachillerato 6to</option>
        <option value="Bachillerato-7mo">Bachillerato 7mo</option>
        <option value="Bachillerato-8vo">Bachillerato 8vo</option>
        <option value="Bachillerato-9no">Bachillerato 9no</option>
        <option value="Bachillerato-10mo">Bachillerato 10mo</option>
        <option value="Bachillerato-11vo">Bachillerato 11vo</option>
        <option value="Bachillerato-12vo">Bachillerato 12vo</option>
        <option value="Pregrado-en-Curso">Pregrado en Curso</option>
        <option value="Pregrado-Terminado">Pregrado Terminado</option>
      </select>
      <button class="mb-0 filter-button" (click)="buildSkillsGraphics(disciplina, nivelAcademico)">
        Aplicar filtros
      </button>
    </div>
    <div class="row m-0 mt-4 mt-lg-5">
      <p class="section-title pl-lg-5 mb-0">Puntaje general por semestre</p>
    </div>
    <hr class="mt-2 display-mobile-none">
    <div class="row m-0 mt-2 mt-lg-4">
      <canvas baseChart class="m-auto"
              [labels]="graphicGeneralSkills.data.labels"
              [datasets]="graphicGeneralSkills.data.datasets"
              [options]="graphicGeneralSkills.options"
              chartType="line"
      >
      </canvas>
    </div>

    <div class="row m-0 display-mobile-none submenu mt-5">
      <div class="text-center tab submenu-tab"
           [ngClass]="{'tab-active': submenuOptionActive == 0}"
           (click)="submenuOptionActive = 0">
        <label class="unselected-section" [ngClass]="{'selected-section': submenuOptionActive == 0}" >
          Puntaje global
        </label>
      </div>

      <div class="text-center tab submenu-tab"
           [ngClass]="{'tab-active': submenuOptionActive == 1}"
           (click)="submenuOptionActive = 1">
        <label class="unselected-section" [ngClass]="{'selected-section': submenuOptionActive == 1}">
          Liderazgo
        </label>
      </div>

      <div class="text-center tab submenu-tab"
           [ngClass]="{'tab-active': submenuOptionActive == 2}"
           (click)="submenuOptionActive = 2">
        <label class="unselected-section" [ngClass]="{'selected-section': submenuOptionActive == 2}">
          Global mind
        </label>
      </div>

      <div class="text-center tab submenu-tab"
           [ngClass]="{'tab-active': submenuOptionActive == 3}"
           (click)="submenuOptionActive = 3">
        <label class="unselected-section" [ngClass]="{'selected-section': submenuOptionActive == 3}">
          Impacto social
        </label>
      </div>

      <div class="text-center tab submenu-tab"
           [ngClass]="{'tab-active': submenuOptionActive == 4}"
           (click)="submenuOptionActive = 4">
        <label class="unselected-section" [ngClass]="{'selected-section': submenuOptionActive == 4}">
          Competencia académica
        </label>
      </div>
    </div>

    <div class="row m-0 mt-4 p-lg-2" *ngIf="submenuOptionActive == 0">
      <label class="skill-subtitle mb-4 display-mobile">Puntaje global</label>
      <div class="col-12 col-xl-6 p-0 row m-0 pl-lg-5">
        <div class="col-12 p-0 d-flex align-items-center">
          <img class="title-icon" src="assets/dashboard-skill/insignias/master.png" alt="insignia">
          <p class="skill-title mb-0 mx-2">Master Profile</p>
        </div>
        <div class="col-12 blue-card d-flex mt-4">
          <img class="card-icon" src="assets/utils/happy-outline.png" alt="happy">
          <p class="ml-2 mb-0">¡Felicitaciones! Tus estudiantes cumplen con todos los requisitos para acceder a una universidad Top Tier, continua trabajando en tu perfil</p>
        </div>
        <div class="col-12 yellow-card d-flex mt-2">
          <img class="card-icon" src="assets/utils/check-outline.png" alt="check">
          <p class="ml-2 mb-0">No olvides revisar requisitos específicos de admisión cómo exámenes internacionales, idioma, entre otros.</p>
        </div>
      </div>
      <div class="col-12 col-xl-6 mt-2 mt-lg-5 px-0">
        <app-radar-chart-component class="chart" [sorts]="sortsGraph"></app-radar-chart-component>
      </div>
    </div>

    <div class="row d-flex flex-column m-0 mt-4 p-lg-2" *ngIf="submenuOptionActive == 1 || scrWidth < 1000">
      <div class="d-flex mb-2 mb-lg-4">
        <img class="dot-icon display-mobile-none" src="assets/utils/icono-amarillo.png" alt="point">
          <div class="ml-lg-5">
            <label class="skill-subtitle">Liderazgo</label>
            <div class="info-card">
              <div class="row m-0 d-flex flex-column flex-lg-row align-items-start align-items-lg-center mb-2">
                <p class="progress-description mb-0 mr-2 order-1 order-lg-0">Alcanzado {{resultadoGeneral?.liderazgo ? resultadoGeneral?.liderazgo.toFixed(2) : 0}}%</p>
                <progress class="order-0 order-lg-1" [value]="resultadoGeneral?.liderazgo || 0" max="100"></progress>
              </div>
              <p class="skill-description">
                El liderazgo es la capacidad de mantener motivado a tu equipo o tus para obtener un resultado común. Al ser líder aprendes el poder de la atención enfocada en lo importante, y de trasmitir la pasión de un objetivo en el que todos continúan creyendo.
              </p>
            </div>
          </div>
      </div>
      <canvas baseChart class="m-auto"
              [labels]="graphicLeadership.data.labels"
              [datasets]="graphicLeadership.data.datasets"
              [options]="graphicLeadership.options"
              chartType="line"
      >
      </canvas>
    </div>

    <div class="row m-0 mt-4 p-lg-2" *ngIf="submenuOptionActive == 2 || scrWidth < 1000">
      <div class="d-flex mb-2 mb-lg-4">
        <img class="dot-icon display-mobile-none" src="assets/utils/icono-amarillo.png" alt="point">
        <div class="ml-lg-5">
          <label class="skill-subtitle">Global mind</label>
          <div class="info-card">
            <div class="row m-0 d-flex flex-column flex-lg-row align-items-start align-items-lg-center mb-2">
              <p class="progress-description mb-0 mr-2 order-1 order-lg-0">Alcanzado {{resultadoGeneral?.gobal_mind ? resultadoGeneral?.gobal_mind.toFixed(2) : 0}}%</p>
              <progress class="order-0 order-lg-1" [value]="resultadoGeneral?.gobal_mind || 0" max="100"></progress>
            </div>
            <p class="skill-description">
              Es la capacidad que una persona tiene de proyectarse en el mundo, basado en sus fortalezas y acompañados de un nivel de investigación y conocimiento que demuestre la profesionalización.
            </p>
          </div>
        </div>
      </div>
      <canvas baseChart class="m-auto"
              [labels]="graphicGlobalMind.data.labels"
              [datasets]="graphicGlobalMind.data.datasets"
              [options]="graphicGlobalMind.options"
              chartType="line"
      >
      </canvas>
    </div>

    <div class="row m-0 mt-4 p-lg-2" *ngIf="submenuOptionActive == 3 || scrWidth < 1000">
      <div class="d-flex mb-2 mb-lg-4">
        <img class="dot-icon display-mobile-none" src="assets/utils/icono-amarillo.png" alt="point">
        <div class="ml-lg-5">
          <label class="skill-subtitle">Impacto social</label>
          <div class="info-card">
            <div class="row m-0 d-flex flex-column flex-lg-row align-items-start align-items-lg-center mb-2">
              <p class="progress-description mb-0 mr-2 order-1 order-lg-0">Alcanzado {{resultadoGeneral?.impacto_social ? resultadoGeneral?.impacto_social.toFixed(2) : 0}}%</p>
              <progress class="order-0 order-lg-1" [value]="resultadoGeneral?.impacto_social || 0" max="100"></progress>
            </div>
            <p class="skill-description">
              El mundo esta llamado a finalmente superar grandes problemáticas tales cómo pobreza, falta de acceso al agua y los alimentos,
              calentamiento global, salud emocional, entre muchas otras. Un primer paso para demostrar tu capacidad de solidarizarte y aportar
              es a través del Impacto Social.
            </p>
          </div>
        </div>
      </div>
      <canvas baseChart class="m-auto"
              [labels]="graphicSocialImpact.data.labels"
              [datasets]="graphicSocialImpact.data.datasets"
              [options]="graphicSocialImpact.options"
              chartType="line"
      >
      </canvas>
    </div>

    <div class="row m-0 mt-4 p-lg-2" *ngIf="submenuOptionActive == 4 || scrWidth < 1000">
      <div class="d-flex mb-2 mb-lg-4">
        <img class="dot-icon display-mobile-none" src="assets/utils/icono-amarillo.png" alt="point">
        <div class="ml-lg-5">
          <label class="skill-subtitle">Competencia académica</label>
          <div class="info-card">
            <div class="row m-0 d-flex flex-column flex-lg-row align-items-start align-items-lg-center mb-2">
              <p class="progress-description mb-0 mr-2 order-1 order-lg-0">Alcanzado {{resultadoGeneral?.competencia_academica ? resultadoGeneral?.competencia_academica.toFixed(2) : 0}}%</p>
              <progress class="order-0 order-lg-1" [value]="resultadoGeneral?.competencia_academica || 0" max="100"></progress>
            </div>
            <p class="skill-description">
              Las universidades TOP en el mundo entenderán a través de tus notas tu capacidad para ser constante en la dedicación para
              obtener resultados. También entenderán tu afinidad para la carrera a la que te presentas, dónde recurrentemente se deben
              obtener los mejores resultados.
            </p>
          </div>
        </div>
      </div>
      <canvas baseChart class="m-auto"
              [labels]="graphicAcademic.data.labels"
              [datasets]="graphicAcademic.data.datasets"
              [options]="graphicAcademic.options"
              chartType="line"
      >
      </canvas>
    </div>

    <div class="padding-content"  *ngIf="false">
      <div class="row" style="margin-top: 10rem">
        <div id="content-wrapper" class="d-flex flex-column">
          <!-- Main Content -->
          <div id="content">
            <!-- Begin Page Content -->
            <div class="container-fluid">
              <!-- Page Heading -->
              <div class="d-sm-flex align-items-center justify-content-between mb-4" *ngIf="colegioData">
                <div class="row h-100">
                  <div class="my-auto">
                    <img [src]="colegioData.logo" class="img-logo" alt="User Image" />
                  </div>
                  <div class="my-auto">
                    <h1 class="h3 mb-0 text-gray-800 titulo-colegio">{{ colegioData.nombre }}</h1>
                  </div>
                </div>

              </div>

            </div>
            <!-- /.container-fluid -->
          </div>
          <!-- End of Footer -->
        </div>
      </div>
      <div class="row" style="margin-bottom:3rem">
        <div class="col-12">
          <span (click)="tabActivo='indicadores'" [ngClass]="{
            'tab-activo': tabActivo === 'indicadores',
            'tab-inactivo': tabActivo !== 'indicadores'
          }">Indicadores colegio</span>
          <span (click)="tabActivo='codigos'" [ngClass]="{
            'tab-activo': tabActivo !== 'indicadores',
            'tab-inactivo': tabActivo === 'indicadores'
          }">Códigos colegio</span>
        </div>

      </div>
      <div class="row" *ngIf="tabActivo == 'indicadores'">
        <div class="col-12">
          <div class="row">
            <div class="col-xl-4 col-md-6 mb-4">
              <div class="card border-left-info shadow h-100 py-2">
                <div class="card-body">
                  <div class="row no-gutters align-items-center">
                    <div class="col mr-2">
                      <div class="text-xs font-weight-bold text-info text-uppercase mb-1 titulo">Test realizados</div>
                      <div class="row no-gutters align-items-center">
                        <div class="col-auto">
                          <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                            <span class="font-semestres">1er Semestre : {{ primerSemestre }}</span>
                            <h5 class="font-semestres">{{inicioA}} / {{finA}}</h5>


                          </div>
                          <div class="h5 mb-0 mr-3 font-weight-bold text-gray-800">
                            <span class="font-semestres">2do Semestre : {{ segundoSemestre }}</span>
                            <h5 class="font-semestres">{{inicioB}} / {{finB}}</h5>


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="card-footer">
                  <div class="row no-gutters align-items-center">
                    <div class="col">
                      <button type="button" class="btn btn-primary btn-sm btn-descargar" (click)="getLisTest()">Descargar listado</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <!-- Content Column -->
            <div class="col-lg-3 mb-4">
              <!-- Project Card Example -->
              <div class="card shadow mb-4">
                <div class="card-header py-3">
                  <h6 class="m-0 font-weight-bold text-primary text-gray-800">Filtros</h6>
                </div>
                <div class="card-body">
                  <div class="row" *ngIf="colegioData">
                    <div class="col-12">
                      <label for="exampleFormControlSelect1">Fecha Inicio:</label>
                      <input type="date" class="form-control" [(ngModel)]="fechaInicio">
                    </div>
                    <div class="col-12">
                      <label for="exampleFormControlSelect1">Fecha Fin:</label>
                      <input type="date" class="form-control"  [(ngModel)]="fechaFin">
                    </div>
                    <div class="col-12">
                      <label for="exampleFormControlSelect1">Disciplina:</label>
                      <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="disciplina" >
                          <option value="Todos" selected>Selecciona una disciplina</option>
                          <option *ngFor="let item of ListDisciplinas" [value]="item.text">{{item.value}}</option>
                        </select>
                    </div>
                    <div class="col-12">
                        <label for="exampleFormControlSelect1">Nivel académico:</label>
                        <select class="form-control" id="exampleFormControlSelect1" [(ngModel)]="nivelAcademico" >
                          <option value="Todos" selected>Todos</option>
                          <option value="Bachillerato-6to">Bachillerato 6to</option>
                          <option value="Bachillerato-7mo">Bachillerato 7mo</option>
                          <option value="Bachillerato-8vo">Bachillerato 8vo</option>
                          <option value="Bachillerato-9no">Bachillerato 9no</option>
                          <option value="Bachillerato-10mo">Bachillerato 10mo</option>
                          <option value="Bachillerato-11vo">Bachillerato 11vo</option>
                          <option value="Bachillerato-12vo">Bachillerato 12vo</option>
                          <option value="Pregrado-en-Curso">Pregrado en Curso</option>
                          <option value="Pregrado-Terminado">Pregrado Terminado</option>
                        </select>

                    </div>
                    <div class="col-12">
                      <button type="button" class="btn btn-outline-primary btn-descargar" (click)="ejecutarFiltro()">Aplicar</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-9 mb-4">
              <div class="row">
                <!-- Area Chart -->
                <div class="col-xl-8 col-lg-7">
                  <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 text-primary text-gray-800 titulo">Promedio general</h6>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                      <div class="chart-area">
                        <chart type="line" class="type-chart" [data]="graficaGeneral" [options]="options"></chart>
                      </div>
                    </div>
                  </div>
                </div>
                  <div class="col-xl-4 col-lg-5" hidden>
                  <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 text-primary text-gray-800 titulo">Revenue Sources</h6>
                      <div class="dropdown no-arrow">
                        <a
                          class="dropdown-toggle"
                          href="#"
                          role="button"
                          id="dropdownMenuLink"
                          data-toggle="dropdown"
                          aria-haspopup="true"
                          aria-expanded="false">
                          <i class="fas fa-ellipsis-v fa-sm fa-fw text-gray-400"></i>
                        </a>
                        <div
                          class="dropdown-menu dropdown-menu-right shadow animated--fade-in"
                          aria-labelledby="dropdownMenuLink">
                          <div class="dropdown-header">Dropdown Header:</div>
                          <a class="dropdown-item" href="#">Action</a>
                          <a class="dropdown-item" href="#">Another action</a>
                          <div class="dropdown-divider"></div>
                          <a class="dropdown-item" href="#">Something else here</a>
                        </div>
                      </div>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                      <div class="chart-pie pt-4 pb-2">
                        <canvas id="myPieChart"></canvas>
                      </div>
                      <div class="mt-4 text-center small">
                        <span class="mr-2">
                          <i class="fas fa-circle text-primary"></i>
                          Direct
                        </span>
                        <span class="mr-2">
                          <i class="fas fa-circle text-success"></i>
                          Social
                        </span>
                        <span class="mr-2">
                          <i class="fas fa-circle text-info"></i>
                          Referral
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div class="col-12" *ngIf="radarValues">
                <div class="row">
                  <!-- Area Chart -->
                  <div class="col-12">
                    <div class="card shadow mb-4">
                      <!-- Card Header - Dropdown -->
                      <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                        <h6 class="m-0 text-primary text-gray-800 titulo">Grafico de competencias</h6>
                      </div>
                      <!-- Card Body -->
                      <div class="card-body">
                        <div class="chart-area">
                          <app-radar-chart-component [sorts]="radarValues"></app-radar-chart-component>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="row">
                <div class="col-xl-6 col-lg-2">
                  <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 text-primary text-gray-800 titulo">Liderazgo</h6>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                      <div class="chart-area">
                        <chart type="line" class="type-chart" [data]="graficaLiderazgo" [options]="options"></chart>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-2">
                  <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 text-primary text-gray-800 titulo">Global mind</h6>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                      <div class="chart-area">
                        <chart type="line" class="type-chart" [data]="graficaGlobal_mind" [options]="options"></chart>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-2">
                  <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 text-primary text-gray-800 titulo">Impacto social</h6>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                      <div class="chart-area">
                        <chart type="line" class="type-chart" [data]="graficaImpacto_social" [options]="options"></chart>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-2">
                  <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 text-primary text-gray-800 titulo">Competencia académica</h6>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                      <div class="chart-area">
                        <chart type="line" class="type-chart" [data]="graficaCompetencia_academica" [options]="options"></chart>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-xl-6 col-lg-2">
                  <div class="card shadow mb-4">
                    <!-- Card Header - Dropdown -->
                    <div class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                      <h6 class="m-0 text-primary text-gray-800 titulo">Logro y acción</h6>
                    </div>
                    <!-- Card Body -->
                    <div class="card-body">
                      <div class="chart-area">
                        <chart type="line" class="type-chart" [data]="graficaLogro_accion" [options]="options"></chart>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" *ngIf="tabActivo !== 'indicadores'">
        <div class="col-6 margin-bott">
          <label>Lista de códigos</label>
          <ng2-smart-table
          [settings]="settings01"
          [source]="listCodigosLibres"
          (custom)="onCustomAction($event)"
        ></ng2-smart-table>
        </div>
        <div class="col-6 margin-bott">
          <label>Lista de códigos asignados</label>

          <ng2-smart-table
          [settings]="settings02"
          [source]="listCodigosUsados"
          (custom)="onCustomAction($event)"
        ></ng2-smart-table>
        </div>
      </div>
    </div>
</section>
</main>

<ng-template #cardsGeneral>
  <div class="card-application-container">
    <div class="row">
      <div class="col-2 application-icon-container">
        <div class="application-icon-subcontainer">
          <img class="application-icon" src="/assets/school-dashboard/documentos-2-blanco.png" alt="internacionales">
        </div>
      </div>

      <div class="row col-10">
        <div class="col-12">
          <label class="application-label-vulue">{{aplicacionesInter}}%</label>
        </div>

        <div class="col-12 pr-0">
          <label class="application-label-desc">Aplicaciones internacionales</label>
        </div>

      </div>
    </div>
  </div>

  <div class="card-application-container">
    <div class="row">
      <div class="col-2 application-icon-container">
        <div class="application-icon-subcontainer">
          <img class="application-icon" src="/assets/school-dashboard/enviar.png" alt="totales">
        </div>
      </div>

      <div class="row col-10">
        <div class="col-12">
          <label class="application-label-vulue">{{totalAplicaciones}}</label>
        </div>

        <div class="col-12">
          <label class="application-label-desc">Solicitudes enviadas</label>
        </div>

      </div>
    </div>
  </div>

  <div class="card-application-container">
    <div class="row">
      <div class="col-2 application-icon-container">
        <div class="application-icon-subcontainer">
          <img class="application-icon" src="/assets/school-dashboard/enviar.png" alt="totales">
        </div>
      </div>

      <div class="row col-10">
        <div class="col-12">
          <label class="application-label-vulue">{{aplicacionesAceptadas}}</label>
        </div>

        <div class="col-12">
          <label class="application-label-desc">Solicitudes aceptadas</label>
        </div>

      </div>
    </div>
  </div>
  <div class="card-application-container">
    <div class="row">
      <div class="col-2 application-icon-container">
        <div class="application-icon-subcontainer">
          <img class="application-icon" src="/assets/school-dashboard/tiempo.png" alt="totales">
        </div>
      </div>

      <div class="row col-10">
        <div class="col-xl-12">
          <label class="application-label-vulue">{{aplicacionesEspera}}</label>
        </div>

        <div class="col-12">
          <label class="application-label-desc">Solicitudes en espera</label>
        </div>

      </div>
    </div>
  </div>
</ng-template>
