import { NavigationService } from './../../services/navigation.service';
import { AlertService } from './../../services/alert.service';
import {
  FormGroup,
  FormBuilder,
  AbstractControl,
  FormArray,
} from '@angular/forms';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { S3Service } from '../../services/s3.service';
import { from } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-travel-history',
  templateUrl: './travel-history.component.html',
  styleUrls: ['./travel-history.component.scss'],
})
export class TravelHistoryComponent implements OnInit {
  /** CONSTANTS */
  /** Variable that holds the api path for use services */
  readonly USER_API = 'api/user/';

  /** Variable tha will contain the travel history form */
  travelHistoryForm: FormGroup;
  /** Variable that contains the id of the current user */
  id: string;
  /** Variable that signals if the component is loading after submit */
  loading: boolean;
  /** Variable that signals if the componente is uploading something into S3 */
  uploading: boolean;

  constructor(
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    private navigationService: NavigationService,
    private s3Service: S3Service,
    @Inject(PLATFORM_ID) private plaftormId,
    private translate: TranslateService,
    private ngxSpinnerService: NgxSpinnerService
  ) {}

  ngOnInit(): void {
    this.travelHistoryForm = this.fb.group({
      pasaporte: '',
      numeroPasaporte: '',
      paisPasaporte: '',
      vencimientoPasaporte: '',
      leHanNegadoVisas: false,
      negaciones: this.fb.array([]),
      viajesUltimos3Anios: '',
      viajes3A5Anios: '',
    });
    if (isPlatformBrowser(this.plaftormId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.tipo === 'Recruitment') {
        const student = localStorage.getItem('student_profile');
        this.id = student;
      } else {
        this.id = user._id;
      }
      this.getUserInfo();
    }
  }

  /**
   * Returns the passport form control
   */
  get passport(): AbstractControl {
    return this.travelHistoryForm.get('pasaporte');
  }
  /**
   * Returns the passport number form control
   */
  get passportNumber(): AbstractControl {
    return this.travelHistoryForm.get('numeroPasaporte');
  }
  /**
   * Returns the passport country form control
   */
  get passportCountry(): AbstractControl {
    return this.travelHistoryForm.get('paisPasaporte');
  }
  /**
   * Returns the passport expiration date form control
   */
  get passportExpiration(): AbstractControl {
    return this.travelHistoryForm.get('vencimientoPasaporte');
  }

  /**
   * Returns the 'haTenidoVisasRechazadas' form control
   */
  get rejectedVisasQuestion(): AbstractControl {
    return this.travelHistoryForm.get('leHanNegadoVisas');
  }

  /**
   * Returns the form array of visa denials
   */
  get denials(): FormArray {
    return this.travelHistoryForm.get('negaciones') as FormArray;
  }
  /**
   * Returns the form control of travels in the last 3 years
   */
  get travelLast3Years(): AbstractControl {
    return this.travelHistoryForm.get('viajesUltimos3Anios');
  }

  /**
   * Returns the form control of travels between the last 3 and 5 years
   */
  get travelLast3To5Years(): AbstractControl {
    return this.travelHistoryForm.get('viajes3A5Anios');
  }

  /**
   * Change the value of the 'haTenidoVisasrechazadas' form control
   * @param value new form control value (boolean)
   */
  onChangeRejectedVisas({ value }: { value: boolean }): void {
    this.rejectedVisasQuestion.setValue(value);
  }

  /**
   * Adds a new visa denial to the list
   */
  onAddVisaDenial(): void {
    const denial = this.fb.group({
      pais: '',
      motivo: '',
      fecha: '',
    });

    this.denials.push(denial);
  }

  /**
   * Removes a visa denial from the list
   * @param index index of the element that is going to be deleted
   */
  onRemoveVisaDenial({ index }: { index: number }): void {
    this.denials.removeAt(index);
  }

  /**
   * Retrieves user information from the backend
   */
  getUserInfo(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.USER_API + this.id }).subscribe(
      (response) => {
        if (response.pasaporte !== undefined && response.pasaporte !== null) {
          this.passport.setValue(response.pasaporte);
        }
        if (response.paisPasaporte !== undefined) {
          this.passportCountry.setValue(response.paisPasaporte);
        }
        if (response.vencimientoPasaporte !== undefined) {
          this.passportExpiration.setValue(response.vencimientoPasaporte);
        }
        if (response.numeroPasaporte !== undefined) {
          this.passportNumber.setValue(response.numeroPasaporte);
        }
        if (response.leHanNegadoVisas !== undefined) {
          this.rejectedVisasQuestion.setValue(response.leHanNegadoVisas);
        }
        if (response.viajes3A5Anios) {
          this.travelLast3To5Years.setValue(response.viajes3A5Anios);
        }
        if (response.viajesUltimos3Anios) {
          this.travelLast3Years.setValue(response.viajesUltimos3Anios);
        }
        for (const denial of response.negaciones) {
          const denialForm = this.fb.group({
            pais: denial.pais,
            motivo: denial.motivo,
            fecha: denial.fecha,
          });
          this.denials.push(denialForm);
        }
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Travel-history.load-error'),
        });
        this.ngxSpinnerService.hide();
      }, (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  /**
   * Submit the form value into the backend
   */
  updatetUserInfo(): void {
    this.loading = true;
    const data = this.travelHistoryForm.value;
    this.apiService.put({ api: this.USER_API + this.id, data }).subscribe(
      (response) => {
        if (response.success === false) {
          this.loading = false;
          this.alertService.showError({
            msg: this.translate.instant('Travel-history.submit-error'),
          });
        } else {
          this.alertService.showSuccess({
            msg: 'La información se ha actualizado correctamente',
          });
          this.navigationService.navigateTo({ path: 'profile'});
          this.loading = false;
        }
      },
      (err) => {
        this.alertService.showError({
          msg: 'Hubo un error actualizadndo los datos',
        });
        this.loading = false;
      }
    );
  }

  /**
   * handles changes on general documents (passport, grades, diplomas) file inputs
   * @param param0 Change event for input type file
   */
  onUploadChange({ event }): void {
    if (event.target.files.length > 1) {
      alert(this.translate.instant('Travel-history.you-must-upload-1-file'));
    } else {
      this.uploading = true;
      const file = event.target.files[0];
      const key = `user/${this.id}/passport-travel/`;
      // Callback that handles S3 response
      const callback = (err, data) => {
        if (err) {
          // If there is an error alert the user
          this.alertService.showError({
            msg: this.translate.instant('Error al subir el documento'),
          });
          this.uploading = false;
        } else {
          // Assign the value to the corresponding component
          this.passport.setValue(data.Location);
          this.uploading = false;
        }
      };
      this.s3Service.uploadFile({ file, key, callback });
    }
  }
}
