<div class="container-process">
</div>
<main role="main" class="container">
  <div class="row">
    <div class="col-12" style="margin-top: 7%;">
      <h6 class="border-bottom border-gray pb-2 mb-0 text-center titleDashboard">
        Perfil evaluado:
        <span class="titulo-u">{{ perfil }}</span>
      </h6>
    </div>
  </div>

  <div class="row" style="margin-top: 2rem">
    <div class="col-12" style="margin-left:3%;">
      <h5 style="font-weight: bold;">Ajuste del estudiante al perfil:</h5>
    </div>
  </div>

  <div style="margin-top:3%;" class="row">

    <div class="col-md-6">
      <div class="col-12 text-center">
        <img style="margin-right:18%;" src="assets/wizard-images/universidad.png" alt="" width="300" />
      </div>
    </div>

    <div class="col-md-6">
      <div class="row">
        <div class="col-12" style="padding: 0">

          <div class="progressCom" style="padding: 0">
            <label style="position: absolute; margin-top: -1rem">0 %</label>
            <label style="position: absolute; float: right; margin-left: 90%; margin-top: -1rem">100 %</label>
            <div class="progress-bar" role="progressbar" [style]="progreso" aria-valuenow="25" aria-valuemin="0"
              aria-valuemax="100"></div>
            <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
              [style]="progresoGeneral" class="ejemplo">
              <span class="indicador">
                {{ promedioGeneral }}%
                <span class="triangulo"></span>
              </span>
            </div>
            <span>0%</span>
            <span>100%</span>
          </div>

        </div>
        <div class="col-12">
          <div class="row h-100 top">
            <div class="col-4 my-auto text-center borderA newBorder">
              <P>Mucho por trabajar</P>
            </div>
            <div class="col-4 my-auto text-center borderA">
              <p style="font-size: 95%;">Recomendado con sugerencías</p>
            </div>
            <div class="col-4 my-auto text-center borderA newBorder">
              <p>Recomendado</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-12">
    <div class="col-12" style="margin-top: 1rem; text-align: justify" [hidden]="perfil != 'Top Tier'">
      Las universidades TOP TIER, se caracterizan principalmente por tener un perfil muy académico y de investigación, y
      son las más selectivas en su proceso de admisión. Están en el top 1% de las mejores universidades del mundo y
      acceder a ellas requiere contar con un perfil integral, siendo el tipo de universidad que más exige las 5
      competencias internacionales. Entre ellas se encuentran las universidades de la Ivy League y el Russell Group. A
      pesar de ser exigentes en su admisión, existen algunas rutas alternas para ingresar a estas universidades si no se
      cuenta con el perfil académico exigido.
    </div>
    <div class="col-12" style="margin-top: 1rem; text-align: justify" [hidden]="perfil != 'Experiential University'">
      En esta categoría, se encuentran las universidades que tienen un enfoque en la experiencia integral del
      estudiante, no solo en la parte académica y de investigación. Le dan la misma importancia tanto a pregrados como
      posgrados y se encuentran generalmente en el top 5% del mundo, sin estar en el top 1%; Crean un ambiente
      universitario de balance entre lo académico / práctico + experiencia estudiantil, y tienen un pensamiento muy
      moderno. Entre estas podemos encontrar universidades jóvenes con buenos rankings en su categoría (por facultades),
      universidades de rankings medios y algunas de enfoque internacional.
    </div>
    <div class="col-12" style="margin-top: 1rem; text-align: justify" [hidden]="perfil != 'Practical learning'">
      Es una institución que tiene un alto enfoque en la enseñanza práctica “hands on”, con alto énfasis en tecnologías
      y habilidades que se usarán en el puesto de trabajo, bajo el concepto “Job Ready”. Tienen muy buenas conexiones
      laborales. No se preocupan por los rankings internacionales sino por la tasa de empleabilidad. Entre estas podemos
      encontrar, por ejemplo, algunas Universidades de Ciencias Aplicadas en Europa y los Colleges en Canadá. El proceso
      de admisión es generalmente menos exigente y cuenta con programas de 1 a 4 años de duración: desde certificados,
      técnicos, tecnológicos y licenciaturas prácticas, así como posgrados técnicos y masters.
    </div>
    <div class="col-12" style="margin-top: 1rem; text-align: justify" [hidden]="perfil != 'Vocational / Passion'">
      Academias, institutos o facultades especializadas en áreas de vocación como escuelas de diseño, cine, letras y
      algunos colleges privados especializados. El énfasis para escoger este tipo de institución no es el ranking
      internacional sino el interés vocacional de la persona y la pasión por su carrera. Algunas son reconocidas
      mundialmente y ganadoras de premios en sus industrias. Otras son locales con muy buenos convenios laborales. El
      proceso de admisión dependerá de un portafolio o competencias individuales especializadas, sin necesidad de contar
      con altos promedios académicos, por lo que tienen mayor accesibilidad. </div>
  </div>

  <div class="col-md-12">
    <div class="col-12" style="margin-top: 1rem" [hidden]="perfil != 'Top Tier'">
      <p class="recomendacionTitulo">Recomendación: <img style="width: 5% ;" src="assets/wizard-images/favorite.png" />
      </p>
      <p style="text-align: justify ;">
        Cumples con los requisitos para acceder a una universidad TOP TIER, sin embargo no debes descuidarte y debes
        continuar poniendo tu perfil a tope para maximizar tus probabilidades de ingreso. No olvides asesorarte para
        revisar requisitos especificos de admisión cómo examenes internacionales, idioma, entre otros.
      </p>
    </div>
    <div class="col-12" style="margin-top: 1rem" [hidden]="perfil != 'Experiential University'">
      <p class="recomendacionTitulo">Recomendación: <img style="width: 5% ;" src="assets/wizard-images/favorite.png" />
      </p>
      <p style="text-align: justify ;">
        Cumples con los requisitos para acceder a una EXPERIENTIAL UNIVERSITY, continua desarrollando tus competencias y
        no tendrás inconvenientes en tu desempeño universitario. No olvides asesorarte para revisar requisitos
        especificos de admisión.
      </p>
    </div>
    <div class="col-12" style="margin-top: 1rem" [hidden]="perfil != 'Practical learning'">
      <p class="recomendacionTitulo">Recomendación: <img style="width: 5% ;" src="assets/wizard-images/favorite.png" />
      </p>
      <p style="text-align: justify ;">
        Cumples con todos los requisitos para acceder a una PRACTICAL LEARNING INSTITUTION, contacta a tu coach de
        educación internacional para entender que institución internacional o nacional abrira tus puertas profesionales
        y te conectará con la industria de tu interés. </p>
    </div>
    <div class="col-12" style="margin-top: 1rem" [hidden]="perfil != 'Vocational / Passion'">
      <p class="recomendacionTitulo">Recomendación: <img style="width: 5% ;" src="assets/wizard-images/favorite.png" />
      </p>
      <p style="text-align: justify ;">
        Cumples con los requisitos para acceder a una SPECIALIZED INSTITUTION, contacta a tu coach de educación
        internacional para entender que institución internacional o nacional abrira tus puertas profesionales y te
        conectará con la industria de tu interés. </p>
    </div>
  </div>


  <div class="my-3 p-3 bg-white rounded box-shadow" style="margin-top:3% ;">
    <h6 class="border-bottom border-gray pb-2 mb-0 titleDashboard">Liderazgo</h6>
    <div class="col-12">
      <div class="row h-100" style="margin-top: 2rem">
        <div class="col-md-6">
          <div class="row">
            <div class="col-10">
              <p class="competenciaDef">
                El liderazgo es la capacidad de mantener motivado a tu equipo o tus para obtener un resultado común. Al
                ser líder aprendes el poder de la atención enfocada en lo importante, y de trasmitir la pasión de un
                objetivo en el que todos continúan creyendo.
              </p>
            </div>
            <div class="col-2">
            </div>
          </div>
        </div>

        <div class="col-md-6 my-auto boxProgress">
          <div class="row">
            <div class="col-12">
              <div class="progressCom" style="padding: 0">
                <label style="position: absolute; margin-top: -1rem">0 %</label>
                <label style="position: absolute; float: right; margin-left: 86%; margin-top: -1rem">100 %</label>
                <div class="progress-bar" role="progressbar" [style]="progreso" aria-valuenow="25" aria-valuemin="0"
                  aria-valuemax="100" id="prog1"></div>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                  [style]="progresoLiderazgo" class="ejemplo" id="prog2">
                  <span class="indicador">
                    {{ puntosLiderazgo }}%
                    <span class="triangulo"></span>
                  </span>
                </div>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                  [style]="indicadorLiderazgo" class="ejemplo2">
                  <span class="indicador2">{{ this.indicadoresGlobales[0].porcentaje }}%</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-12" style="margin-top: 5%;">
          <div class="col-12" [hidden]="recomendacionesLiderazgo.length == 0">
            <h5 style="font-weight: bold;">Sugerencias:</h5>
          </div>
          <div class="col-12" [hidden]="recomendacionesLiderazgo.length == 0">
            <ul>
              <li style="text-align: justify;" *ngFor="let preg of recomendacionesLiderazgo">{{ preg.recomendacion }}
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="my-3 p-3 bg-white rounded box-shadow">
    <h6 class="border-bottom border-gray pb-2 mb-0 titleDashboard">Global Mind</h6>
    <div class="col-12">
      <div class="row" style="margin-top: 2rem">
        <div class="col-md-6">
          <div class="row">
            <div class="col-10">
              <p class="competenciaDef">
                Es la capacidad que una persona tiene de proyectarse en el mundo, basado en sus fortalezas y acompañados
                de un nivel de investigación y conocimiento que demuestre la profesionalización
              </p>
            </div>
            <div class="col-2">
            </div>
          </div>
        </div>
        <div class="col-md-6 boxProgress">
          <div class="row">
            <div class="col-12">
              <div class="progressCom" style="padding: 0">
                <label style="position: absolute; margin-top: -1rem">0 %</label>
                <label style="position: absolute; float: right; margin-left: 86%; margin-top: -1rem">100 %</label>
                <div class="progress-bar" role="progressbar" [style]="progreso" aria-valuenow="25" aria-valuemin="0"
                  aria-valuemax="100"></div>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                  [style]="progresoGlobal" class="ejemplo">
                  <span class="indicador">
                    {{ puntosGlobal }}%
                    <span class="triangulo"></span>
                  </span>
                </div>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                  [style]="indicadorGlobal" class="ejemplo2">
                  <span class="indicador2">{{ this.indicadoresGlobales[1].porcentaje }}%</span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="col-12" style="margin-top: 5%;">
          <div class="col-12" [hidden]="recomendacionesGlobal.length == 0">
            <h5 style="font-weight: bold;">Sugerencias:</h5>
          </div>
          <div class="col-12" [hidden]="recomendacionesGlobal.length == 0">
            <ul>
              <li style="text-align: justify;" *ngFor="let preg of recomendacionesGlobal">{{ preg.recomendacion }}</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="my-3 p-3 bg-white rounded box-shadow">
    <h6 class="border-bottom border-gray pb-2 mb-0 titleDashboard">Impacto Social</h6>
    <div class="col-12">
      <div class="row" style="margin-top: 2rem">
        <div class="col-md-6">
          <div class="row">
            <div class="col-10">
              <p class="competenciaDef">
                El mundo esta llamado a finalmente superar grandes problemáticas tales cómo pobreza, falta de acceso al
                agua y los alimentos, calentamiento global, salud emocional, entre muchas otras. Un primer paso para
                demostrar tu capacidad de solidarizarte y aportar es a través del Impacto Social. Aportar vale desde
                temprana edad, y si no lo has hecho aun no es tarde, solo ten en cuenta que para una universidad TOP
                suma muchos puntos conocer esta faceta. </p>
            </div>
            <div class="col-2">
            </div>
          </div>
        </div>
        <div class="col-md-6 boxProgress">
          <div class="row">
            <div class="col-12">
              <div class="progressCom" style="padding: 0">
                <label style="position: absolute; margin-top: -1rem">0 %</label>
                <label style="position: absolute; float: right; margin-left: 86%; margin-top: -1rem">100 %</label>
                <div class="progress-bar" role="progressbar" [style]="progreso" aria-valuenow="25" aria-valuemin="0"
                  aria-valuemax="100"></div>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                  [style]="progresoImpacto" class="ejemplo">
                  <span class="indicador">
                    {{ puntosImpacto }}%
                    <span class="triangulo"></span>
                  </span>
                </div>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                  [style]="indicadorImpacto" class="ejemplo2">
                  <span class="indicador2">{{ this.indicadoresGlobales[2].porcentaje }}%</span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="col-12" style="margin-top: 5%;">
          <div class="col-12" [hidden]="recomendacionesImpacto.length == 0">
            <h5 style="font-weight: bold;">Sugerencias:</h5>
          </div>
          <div class="col-12" [hidden]="recomendacionesImpacto.length == 0">
            <ul>
              <li style="text-align: justify;" *ngFor="let preg of recomendacionesImpacto">{{ preg.recomendacion }}</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="my-3 p-3 bg-white rounded box-shadow">
    <h6 class="border-bottom border-gray pb-2 mb-0 titleDashboard">Logro y Acción</h6>
    <div class="col-12">
      <div class="row" style="margin-top: 2rem">
        <div class="col-md-6">
          <div class="row">
            <div class="col-10">
              <p class="competenciaDef">
                El mejor profesional es quien decide poner en práctica el conocimiento, es habitual que los mejores
                lideres demuestren desde temprana edad su capacidad superior para iniciar proyectos y demostrar
                resultados.
              </p>
            </div>
            <div class="col-2">
            </div>
          </div>
        </div>
        <div class="col-md-6 boxProgress">
          <div class="row">
            <div class="col-12">
              <div class="progressCom" style="padding: 0">
                <label style="position: absolute; margin-top: -1rem">0 %</label>
                <label style="position: absolute; float: right; margin-left: 86%; margin-top: -1rem">100 %</label>
                <div class="progress-bar" role="progressbar" [style]="progreso" aria-valuenow="25" aria-valuemin="0"
                  aria-valuemax="100"></div>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                  [style]="progresoLogro" class="ejemplo">
                  <span class="indicador">
                    {{ puntosLogro }}%
                    <span class="triangulo"></span>
                  </span>
                </div>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                  [style]="indicadorLogro" class="ejemplo2">
                  <span class="indicador2">{{ this.indicadoresGlobales[3].porcentaje }}%</span>
                </div>
              </div>
            </div>

          </div>
        </div>


        <div class="col-12" style="margin-top: 5%;">
          <div class="col-12" [hidden]="recomendacionesLogro.length == 0">
            <h5 style="font-weight: bold;">Sugerencias:</h5>
          </div>
          <div class="col-12" [hidden]="recomendacionesLogro.length == 0">
            <ul>
              <li style="text-align: justify;" *ngFor="let preg of recomendacionesLogro">{{ preg.recomendacion }}</li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="my-3 p-3 bg-white rounded box-shadow">
    <h6 class="border-bottom border-gray pb-2 mb-0 titleDashboard">Competencia Académica / Aprendizaje</h6>
    <div class="col-12">
      <div class="row" style="margin-top: 2rem">
        <div class="col-md-6">
          <div class="row">
            <div class="col-10">
              <p class="competenciaDef">
                Las universidades TOP en el mundo entenderán a través de tus notas tu capacidad para ser constante en la
                dedicación para obtener resultados. También entenderán tu afinidad para la carrera a la que te
                presentas, dónde recurrentemente se deben obtener los mejores resultados. Si no las tienes aun tienes
                oportunidad de nivelarte, sea durante tu estudio actual o mediante llamados Pathways.
              </p>
            </div>
            <div class="col-2">
            </div>
          </div>
        </div>
        <div class="col-md-6 boxProgress">
          <div class="row">
            <div class="col-12">
              <div class="progress" style="padding: 0">
                <label style="position: absolute; margin-top: -1rem">0 %</label>
                <label style="position: absolute; float: right; margin-left: 86%; margin-top: -1rem">100 %</label>
                <div class="progress-bar" role="progressbar" [style]="progreso" aria-valuenow="25" aria-valuemin="0"
                  aria-valuemax="100"></div>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                  [style]="progresoCompetencia" class="ejemplo">
                  <span class="indicador">
                    {{ puntosCompetencia }}%
                    <span class="triangulo"></span>
                  </span>
                </div>
                <div class="progress-bar" role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100"
                  [style]="indicadorLogro" class="ejemplo2">
                  <span class="indicador2">{{ this.indicadoresGlobales[4].porcentaje }}%</span>
                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="col-12" style="margin-top: 5%;">
          <div class="col-12" [hidden]="recomendacionesAprendizaje.length == 0">
            <h5 style="font-weight: bold;">Sugerencias:</h5>
          </div>
          <div class="col-12" [hidden]="recomendacionesAprendizaje.length == 0">
            <ul>
              <li style="text-align: justify;" *ngFor="let preg of recomendacionesAprendizaje">{{ preg.recomendacion }}
              </li>
            </ul>
          </div>
        </div>

      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
      <h6 class="border-bottom border-gray pb-2 mb-0 text-center pad10">
        Checklist de ingreso a tu universidad
      </h6>
    </div>

    <div id="boxTable">
      <table class="table">
        <thead>
          <tr class="table-info">
            <th scope="col">Pregunta</th>
            <th class="boxRespuesta" scope="col">Respuesta</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let pregunta of preguntasCerradas">
            <td>{{pregunta.pregunta}}</td>
            <!-- <td>{{pregunta.opcionSeccionada}}</td> -->
            <td class="boxRespuesta">
              <ng-container *ngIf="pregunta.opcionSeccionada=='si'">
                <div>
                  <input class="inputCheckSi" id="checkSi" type="checkbox" disabled checked />
                  <label for="checkSi">Si</label>
                </div>
              </ng-container>

              <ng-container *ngIf="pregunta.opcionSeccionada=='no'">
                <div>
                  <input class="inputCheckNo" id="checkNo" type="checkbox" disabled checked />
                  <label for="checkNo">No</label>
                </div>
              </ng-container>

              <ng-container *ngIf="pregunta.opcionSeccionada=='Lo hago'">
                <div>
                  <input class="inputCheckSi" id="checkSi" type="checkbox" disabled checked />
                  <label for="checkSi">Lo hago</label>
                </div>
              </ng-container>

              <ng-container *ngIf="pregunta.opcionSeccionada=='Me gustaría hacerlo'">
                <div>
                  <input class="inputCheckNo" id="checkNo" type="checkbox" disabled checked />
                  <label for="checkNo">Me gustaría hacerlo</label>
                </div>
              </ng-container>

              <ng-container *ngIf="pregunta.lista">
                <ng-container class="boxPais" *ngFor="let pais of pregunta.lista">
                  <img class="imgPais" src="assets/flags-countries/united-states.png"
                    *ngIf="pais.pais=='Estados Unidos'" />
                  <img class="imgPais" src="assets/flags-countries/canada.png" *ngIf="pais.pais=='Canadá'" />
                  <img class="imgPais" src="assets/flags-countries/france.png" *ngIf="pais.pais=='Francia'" />
                  <img class="imgPais" src="assets/flags-countries/germany.png" *ngIf="pais.pais=='Alemanía'" />
                  <img class="imgPais" src="assets/flags-countries/spain.png" *ngIf="pais.pais=='España'" />
                  <img class="imgPais" src="assets/flags-countries/united-kingdom.png"
                    *ngIf="pais.pais=='Reino Unido'" />
                </ng-container>
              </ng-container>
            </td>
          </tr>

          <tr *ngFor="let presupuesto of preguntasPresupuesto">
            <td>{{presupuesto.titulo}}</td>

            <td>
              <ng-container *ngIf="presupuesto.seleccionada==true">
                <div class="boxRespuesta">
                  <input class="inputCheckSi" id="checkSi" type="checkbox" disabled checked />
                  <label for="checkSi">Si</label>
                </div>
              </ng-container>

              <ng-container *ngIf="presupuesto.seleccionada==false">
                <div class="boxRespuesta">
                  <input class="inputCheckNo" id="checkNo" type="checkbox" disabled checked />
                  <label for="checkNo">No</label>
                </div>
              </ng-container>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</main>
