<div class="row">
    <div class="col-12">
        <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
        <h4 class="titulo-wizard">Hablemos de liderazgo</h4>
        <h5>Pregunta 5 / 16</h5>
        <p>A continuación, déjanos saber si te has involucrado con alguna de las siguientes afirmaciones.</p>
        <hr class="hrLine" />
        <h6>Has pertenecido a grupos de liderazgo como:</h6>
    </div>
  </div>
  <div class="row marginBottom">
    <div class="col-sm-6 content-img">
        <img src = "/assets/wizard-images/Test-10.png" class = "img-from-divs" alt="Grupos de investigación">
       <h6><input type = "checkbox" value = "1"
        [(ngModel)]="listCheck[0].seleccionado" [ngModelOptions]="{standalone: true}"  (change)="checkValue(0)" [checked]="listCheck[0].seleccionado"
        id="subscribeNews"
        >Grupos de investigación</h6>
       <p>¿Has pertenecido o liderado una investigación con resultados demostrables?.</p>
      </div>
      <div class="col-sm-6 content-img">
        <img src = "/assets/wizard-images/Test-11.png" class = "img-from-divs" alt="Dirección de Proyecto">
        <h6><input type = "checkbox" value = "2"
          [(ngModel)]="listCheck[1].seleccionado" [ngModelOptions]="{standalone: true}" (change)="checkValue(1)" [checked]="listCheck[1].seleccionado">
          Dirección de Proyectos, Planes de Negocio, Mentoría</h6>
        <p>¿Tienes interes por sacar un proyecto adelante y lo has estructurado o trabajado para sacarlo adelante?</p>
      </div>

  </div>

  <div class="row marginBottom">
    <div class="col-sm-6 content-img">
        <img src = "/assets/wizard-images/Test-13.png" class = "img-from-divs" alt="Liderazgo deportivo">
       <h6><input type = "checkbox" value = "3"
        [(ngModel)]="listCheck[2].seleccionado" [ngModelOptions]="{standalone: true}" (change)="checkValue(2)" [checked]="listCheck[2].seleccionado"
        >Liderazgo deportivo, práctica competitiva de algún deporte</h6>
       <p>¿Has pertenecido por al menos un año seguido, y en los últimos dos años a entrenamientos extracurriculares de algún deporte?</p>
      </div>
      <div class="col-sm-6 content-img">
        <img src = "/assets/wizard-images/Test-12.png" class = "img-from-divs" alt="Líder de Equipo">
        <h6><input type = "checkbox" value = "4"
          [(ngModel)]="listCheck[3].seleccionado" [ngModelOptions]="{standalone: true}" (change)="checkValue(3)" [checked]="listCheck[3].seleccionado"
          >Líder de Equipo</h6>
        <p>¿Has pertenecido y liderado grupos en música, emisoras, bandas, personería, sesiones con ONGs, ONU, entre otros? Has puesto tus fortalezas en escenarios donde impactes a tu comunidad?.</p>
      </div>

  </div>

  <div class="row marginBottom">
    <div class="col-sm-6 content-img">
        <img src = "/assets/wizard-images/Test-14.png" class = "img-from-divs" alt="Participación activa">
       <h6><input type = "checkbox" value = "5"
        [(ngModel)]="listCheck[4].seleccionado" [ngModelOptions]="{standalone: true}" (change)="checkValue(4)"
        >Participación activa</h6>
       <p>¿Tienes diplomas de participación en cursos o actividades internacionales?</p>
      </div>
      <div class="col-sm-6">

      </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
        <em class="fas fa-solid fa-angle-left brnVolver" (click)="volverPaso5()" ></em>
        <button class="btn-continuar" (click)="continuarPaso7()">Continuar</button>
    </div>
  </div>
