import { NgxSpinnerService } from 'ngx-spinner';
import { PasswordRecoveryComponent } from './../../auth/password-recovery/password-recovery.component';
import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import csc from 'country-state-city';
import { NavigationService } from '../../services/navigation.service';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-personal-info',
  templateUrl: './personal-info.component.html',
  styleUrls: ['./personal-info.component.scss'],
})
export class PersonalInfoComponent implements OnInit {
  /** Variable that holds the user api */
  readonly USER_API = 'api/user/';
  /** Variable that holds the personal information binded to the form */
  personalInfo = {
    nombre: '',
    apellido: '',
    email: '',
    telefono: '',
    ciudad: '',
    pais: '',
    direccion: '',
    fechaNacimiento: '',
    sexo: '',
    facebook: '',
    linkedIn: '',
    instagram: '',
  };

  /** Variable that holds the id of the current user */
  id: string;

  /** Variable that holds the list of countries */
  countries = [];

  /** Variable that holds the list of cities */
  cities = [];

  /** Variable that shows if the component is loading */
  loading = false;

  constructor(
    private apiService: ApiService,
    private activatedRoute: ActivatedRoute,
    private alertService: AlertService,
    private navigationService: NavigationService,
    private dialog: MatDialog,
    @Inject(PLATFORM_ID) private plaformId,
    private translate: TranslateService,
    private ngxSpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    if (isPlatformBrowser(this.plaformId)) {
      const user = JSON.parse(localStorage.getItem('user'));
      if (user.tipo === 'Recruitment') {
        const student = localStorage.getItem('student_profile');
        this.id = student;
      } else {
        this.id = user._id;
      }
      this.getUserinfo();
      this.countries = csc.getAllCountries();
    }
  }

  getUserinfo(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.USER_API + this.id }).subscribe(
      (response) => {
        this.personalInfo.nombre = response.nombre;
        this.personalInfo.apellido = response.apellido;
        this.personalInfo.email = response.email;
        this.personalInfo.telefono = response.telefono;
        this.personalInfo.ciudad = response.ciudad;
        this.personalInfo.pais = response.pais;
        if (response.pais !== undefined) {
          const states = csc.getStatesOfCountry(response.pais);
          // Make a list of cities from all the states
          let cities = [];
          for (const state of states) {
            cities = cities.concat(csc.getCitiesOfState(state.id));
          }
          // Sort the list alphebitically, so that it is displayed in a friendly manner
          cities.sort((a, b) => {
            if (a.name < b.name) {
              return -1;
            } else {
              return 1;
            }
          });
          this.cities = cities;
        }
        this.personalInfo.direccion = response.direccion;
        this.personalInfo.fechaNacimiento = response.fechaNacimiento;
        this.personalInfo.sexo = response.sexo;
        this.personalInfo.facebook = response.facebook;
        this.personalInfo.linkedIn = response.linkedIn;
        this.personalInfo.instagram = response.instagram;
      },
      (err) => {
        this.alertService.showError({
          msg: this.translate.instant('Personal-information.load-error'),
        })
      }
      , (complete?) => {
        this.ngxSpinnerService.hide();
      });
  }

  /**
   * Saves changes in the profile
   */
  onSaveChanges(): void {
    this.loading = true;
    const data = this.personalInfo;
    if(data.nombre.length<1 || data.apellido.length<1){
      this.alertService.showSuccess({
        msg: 'Por favor llena los campos obligatorios',
      });
    }else{
      this.apiService.put({ api: this.USER_API + this.id, data }).subscribe(
        (response) => {
          this.alertService.showSuccess({
            msg: 'Se han actualizado los datos correctamente',
          });
          this.navigationService.navigateTo({ path: 'profile' });
        },
        (err) => {
          this.alertService.showError({
            msg: 'Hubo error actualizando los datos',
          });
        }
      );
    }
  }

  /**
   * Handles the selection of a country in the country select
   * @param id country id in the city-state-country library
   */
  onSelectCountry({ id }: { id: string }): void {
    const states = csc.getStatesOfCountry(id);
    // Make a list of cities from all the states
    let cities = [];
    for (const state of states) {
      cities = cities.concat(csc.getCitiesOfState(state.id));
    }
    // Sort the list alphebitically, so that it is displayed in a friendly manner
    cities.sort((a, b) => {
      if (a.name < b.name) {
        return -1;
      } else {
        return 1;
      }
    });
    this.cities = cities;
  }

  /**
   * Opens the modal to change the password
   */
  onChangePasswordClick(): void {
    const user = JSON.parse(localStorage.getItem('user'));
    const email = user.email;
    this.dialog.open(PasswordRecoveryComponent, {
      data: { userId: this.id, userEmail: email },
      height: '400px',
      width: '600px',
      
    });
  }
}
