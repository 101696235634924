<div class="row">
    <div class="col-12">
        <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
        <h4 class="titulo-wizard">Hablemos de Logro y Acción</h4>
        <h5>Pregunta 7 / 16</h5>
        <p>Selecciona las opciones con las que te identifiques.</p>
        <hr class="hrLine" />
    </div>
  </div>

  <!-- <div class="row">
    <div class="col-12">
        <table summary="Pregunta 6">
            <tr>
                <th scope="col"></th>
                <th scope="col" class="paddingTh">Si</th>
                <th scope="col" class="paddingTh">No</th>
            </tr>
            <tr *ngFor="let preg of rondaPreguntas; let i = index;" class="marginTr">
                <td>{{preg.pregunta}}</td>
                <td class="textCenterInput"> <input type="checkbox" [value]="1" name="opcion" id = "checkBox{{i}}value1" [checked]="preg.opcionSeccionada == 'si'" (click) = "getCheckBox($event,i,'si')"></td>
                <td class="textCenterInput"> <input type="checkbox" [value]="2" name="opcion" id = "checkBox{{i}}value2" [checked]="preg.opcionSeccionada == 'no'" (click) = "getCheckBox($event,i,'no')"></td>
            </tr>

        </table>
    </div>
  </div> -->

  <div class="row">
    <!-- <div class="col-md-6 cardContent content-img" *ngFor="let preg of rondaPreguntas; let imageIndex=index">

        <div class="row">
            <div class="col-md-12">
                <img src = "/assets/images_360/pantalla28/{{imageIndex}}.jpg" class="img-from-divs sizingImage">
            </div>

            <div class="col-md-12 questionContainer">
                <p class="question">{{preg.pregunta}}</p>
            </div>

            <div class="col-md-12">
                <div class="optionsContent">
                    <div><strong class="labelCheckbox" *ngFor="let indexGroup of ['Si','No']">{{indexGroup}}</strong></div>
                    <input class="checkBoxEdit" type="checkbox" [value]="1" name="opcion" id = "checkBox{{imageIndex}}value1" [checked]="preg.opcionSeccionada == 'si'" (click) = "getCheckBox($event,imageIndex,'si')">
                    <input class="checkBoxEdit" type="checkbox" [value]="2" name="opcion" id = "checkBox{{imageIndex}}value2" [checked]="preg.opcionSeccionada == 'no'" (click) = "getCheckBox($event,imageIndex,'no')">
                </div>
            </div>
        </div>
    </div> -->

    <div class="col-6">
        <div class="row">
            <div class="col-md-12">
                <img src = "/assets/images_360/pantalla28/0.jpg" class="img-from-divs sizingImage">
            </div>

            <div class="col-md-12 questionContainer">
                <p class="question">{{rondaPreguntas[0].pregunta}}</p>
            </div>

            <div class="col-md-12">
                <div class="">
                    <div><strong class="labelCheckbox" *ngFor="let indexGroup of ['Si','No']">{{indexGroup}}</strong></div>
                    <input class="checkBoxEdit" type="checkbox" [value]="1" name="opcion" id = "checkBox0value1" [checked]="rondaPreguntas[0].opcionSeccionada == 'si'" (click) = "getCheckBox($event,0,'si')">
                    <input class="checkBoxEdit" type="checkbox" [value]="2" name="opcion" id = "checkBox0value2" [checked]="rondaPreguntas[0].opcionSeccionada == 'no'" (click) = "getCheckBox($event,0,'no')">
                </div>
            </div>
        </div>
    </div>

    <div class="col-6">
        <div class="row">
            <div class="col-md-12">
                <img src = "/assets/images_360/pantalla28/2.jpg" class="img-from-divs sizingImage">
            </div>

            <div class="col-md-12 questionContainer">
                <p class="question">{{rondaPreguntas[1].pregunta}}</p>
            </div>

            <div class="col-md-12">
                <div class="">
                    <div><strong class="labelCheckbox" *ngFor="let indexGroup of ['Si','No']">{{indexGroup}}</strong></div>
                    <input class="checkBoxEdit" type="checkbox" [value]="1" name="opcion" id = "checkBox1value1" [checked]="rondaPreguntas[1].opcionSeccionada == 'si'" (click) = "getCheckBox($event,1,'si')">
                    <input class="checkBoxEdit" type="checkbox" [value]="2" name="opcion" id = "checkBox1value2" [checked]="rondaPreguntas[1].opcionSeccionada == 'no'" (click) = "getCheckBox($event,1,'no')">
                </div>
            </div>
        </div>
    </div>

    <div class="col-6 cardContent">
        <div class="row">
            <div class="col-md-12">
                <img src = "/assets/images_360/pantalla28/3.jpg" class="img-from-divs sizingImage">
            </div>

            <div class="col-md-12 questionContainer">
                <p class="question">{{rondaPreguntas[2].pregunta}}</p>
            </div>

            <div class="col-md-12">
                <div class="">
                    <div><strong class="labelCheckbox" *ngFor="let indexGroup of ['Si','No']">{{indexGroup}}</strong></div>
                    <input class="checkBoxEdit" type="checkbox" [value]="1" name="opcion" id = "checkBox2value1" [checked]="rondaPreguntas[2].opcionSeccionada == 'si'" (click) = "getCheckBox($event,2,'si')">
                    <input class="checkBoxEdit" type="checkbox" [value]="2" name="opcion" id = "checkBox2value2" [checked]="rondaPreguntas[2].opcionSeccionada == 'no'" (click) = "getCheckBox($event,2,'no')">
                </div>
            </div>
        </div>
    </div>

    <div class="col-6 cardContent">
        <div class="row">
            <div class="col-md-12">
                <img src = "/assets/images_360/pantalla28/1.jpg" class="img-from-divs sizingImage">
            </div>

            <div class="col-md-12 questionContainer">
                <p class="question">{{rondaPreguntas[3].pregunta}}</p>
            </div>

            <div class="col-md-12">
                <div class="">
                    <div><strong class="labelCheckbox" *ngFor="let indexGroup of ['Si','No']">{{indexGroup}}</strong></div>
                    <input class="checkBoxEdit" type="checkbox" [value]="1" name="opcion" id = "checkBox3value1" [checked]="rondaPreguntas[3].opcionSeccionada == 'si'" (click) = "getCheckBox($event,3,'si')">
                    <input class="checkBoxEdit" type="checkbox" [value]="2" name="opcion" id = "checkBox3value2" [checked]="rondaPreguntas[3].opcionSeccionada == 'no'" (click) = "getCheckBox($event,3,'no')">
                </div>
            </div>
        </div>
    </div>

    <div class="col-6 cardContent">
        <div class="row">
            <div class="col-md-12">
                <img src = "/assets/images_360/pantalla28/4.jpg" class="img-from-divs sizingImage">
            </div>

            <div class="col-md-12 questionContainer">
                <p class="question">{{rondaPreguntas[4].pregunta}}</p>
            </div>

            <div class="col-md-12">
                <div>
                    <div><strong class="labelCheckbox" *ngFor="let indexGroup of ['Si','No']">{{indexGroup}}</strong></div>
                    <input class="checkBoxEdit" type="checkbox" [value]="1" name="opcion" id = "checkBox4value1" [checked]="rondaPreguntas[4].opcionSeccionada == 'si'" (click) = "getCheckBox($event,4,'si')">
                    <input class="checkBoxEdit" type="checkbox" [value]="2" name="opcion" id = "checkBox4value2" [checked]="rondaPreguntas[4].opcionSeccionada == 'no'" (click) = "getCheckBox($event,4,'no')">
                </div>
            </div>
        </div>
    </div>
  </div>

  <div class="row">
    <div class="col-12 text-center">
        <em class="fas fa-solid fa-angle-left brnVolver" (click)="volverTab5()" ></em>
        <button class="btn-continuar" (click)="continuarTab8()">Continuar</button>
    </div>
  </div>
