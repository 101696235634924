import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApiService } from 'src/app/public/services/api.service';

@Component({
  selector: 'app-more-info',
  templateUrl: './more-info.component.html',
  styleUrls: ['./more-info.component.scss']
})
export class MoreInfoComponent implements OnInit {

  createForm: FormGroup = this.formInformation.group({
    nombre: ['', [Validators.required]],
    apellido: ['', [Validators.required]],
    correo: ['', [Validators.required, Validators.email]],
    rol:['', [Validators.required]],
    telefono: ['', [Validators.required, Validators.min(1000000000), Validators.max(9999999999), Validators.pattern("^[0-9]*$")]],
    pregunta: ['', [Validators.required]],
  });

  // Display form
  public displayForm: boolean = false;

  constructor(
    private formInformation: FormBuilder,
    private apiService: ApiService,
  ) { }

  ngOnInit(): void {
  }

  public validField({ field }) {
    return (
      this.createForm.controls[field].errors &&
      this.createForm.controls[field].touched &&
      this.createForm.controls[field].invalid
    );
  }

}
