import { Component, OnDestroy, OnInit } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { ChartConfiguration } from 'chart.js';
import { take } from 'rxjs/operators';
import { NavigationService } from '../../../services/navigation.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { BlogComponent } from 'src/app/public/blog/blog.component';
import { Console } from 'console';


@Component({
  selector: 'app-paso18',
  templateUrl: './paso18.component.html',
  styleUrls: ['./paso18.component.scss'],
})
export class Paso18Component implements OnInit, OnDestroy {
  countries: any[];

  formulario: any;
  public progreso: any;
  public progreso2: any;
  public progreso3: any;
  public progreso4: any;
  public progresoLiderazgo: any;
  public progreso5: any;

  public scrollBar = 100;
  public scrollBar2 = 20.00;
  public scrollBar3 = 60.00;
  public scrollBar4 = 70.00;
  public scrollBar5 = 50.00;
  public TopTier = [
    { habilidad: 'Liderazgo', porcentaje: 85 },
    { habilidad: 'Global Mind', porcentaje: 90 },
    { habilidad: 'Impacto Social', porcentaje: 85 },
    { habilidad: 'Logro y Acción', porcentaje: 95 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 95 },
    { habilidad: 'Promedio', porcentaje: 90 },
  ];
  public Experiential = [
    { habilidad: 'Liderazgo', porcentaje: 70 },
    { habilidad: 'Global Mind', porcentaje: 65 },
    { habilidad: 'Impacto Social', porcentaje: 50 },
    { habilidad: 'Logro y Acción', porcentaje: 65 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 70 },
    { habilidad: 'Promedio', porcentaje: 64 },
  ];
  public PracticalLearning = [
    { habilidad: 'Liderazgo', porcentaje: 50 },
    { habilidad: 'Global Mind', porcentaje: 50 },
    { habilidad: 'Impacto Social', porcentaje: 30 },
    { habilidad: 'Logro y Acción', porcentaje: 65 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 60 },
    { habilidad: 'Promedio', porcentaje: 51 },
  ];
  public Vocational = [
    { habilidad: 'Liderazgo', porcentaje: 70 },
    { habilidad: 'Global Mind', porcentaje: 65 },
    { habilidad: 'Impacto Social', porcentaje: 65 },
    { habilidad: 'Logro y Acción', porcentaje: 75 },
    { habilidad: 'Aprendizaje / Competencia Academica', porcentaje: 70 },
    { habilidad: 'Promedio', porcentaje: 69 },
  ];

  public userData15 = JSON.parse(localStorage.getItem('user'));
  readonly getTestPaso15 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest15 = 'api/get_internalizacionUser/';
  readonly deleteTest = 'api/delete_internalizacionUser/';
  readonly GET_STUDENT = 'api/oneUser/';

  /** Variable to get active semester from test inter */
  readonly API_GET_CODE = 'api/codigoTestByEmailUser/';

  pasoActivo: any;
  nombre: any;
  edad: any;
  fecha: any;
  perfil: any;
  recomendacionesLiderazgo: any[] = [];
  recomendacionesGlobal: any[] = [];
  recomendacionesImpacto: any[] = [];
  recomendacionesLogro: any[] = [];
  recomendacionesAprendizaje: any[] = [];
  recomendacionesPromedio: any[] = [];
  // Variables para calcular el puntaje en % del estudiante
  liderazgo_puntaje_maximo = 85;
  global_puntaje_maximo = 80;
  impacto_puntaje_maximo = 40;
  logro_puntaje_maximo = 45;
  competencia_puntaje_maximo = 50;
  puntosLiderazgo: any;
  puntosGlobal: any;
  puntosImpacto: any;
  puntosLogro: any;
  puntosCompetencia: any;
  indicadoresGlobales: { habilidad: string; porcentaje: number; }[];
  indicadorLiderazgo: string;
  indicadorGlobal: string;
  indicadorImpacto: string;
  indicadorLogro: string;
  indicadorAprendizaje: string;
  progresoGlobal: string;
  progresoImpacto: string;
  progresoLogro: string;
  progresoCompetencia: string;
  promedioGeneral: any;
  progresoGeneral: string;
  indicadorGeneral: number;
  preguntasCerradas = [];
  preguntasPresupuesto = [];
  preguntasPaises = [];
  public idTest: any;
  grafico: any;
  public sortsGraph: any;
  detalleCompetencia = false;
  contenidoCompetencia = {};
  perfilFinal: any;
  sourceInsignia: any;
  semestreAct: any;
  tipPerfil: any;
  buttonsActions: boolean;
  isReadOnly: boolean;
  hojaTest = 0;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public navigationService: NavigationService,
    private ngxSpinnerService: NgxSpinnerService,
    private location: Location,
  ) { }

  ngOnDestroy(): void {
    this.navigationService.setIsVisibleSidebar(true);
  }


  ngOnInit(): void {
    this.activatedRoute.data
      .pipe(take(1))
      .subscribe((data) => {
        this.isReadOnly = data.readOnly;
      });

    if (window.location.pathname.includes('viewDashboardStudent')) {
      let student = window.location.pathname;
      student = student.slice(26);
      this.getDataStudent({ data: student });
    } else {
      this.loadActivedSemester();
    }
  }

  loadData() {
    this.progreso = 'width:' + this.scrollBar + '%';
    this.progreso2 = 'width:' + this.scrollBar2 + '%';
    this.progreso3 = 'width:' + this.scrollBar3 + '%';
    this.progreso4 = 'width:' + this.scrollBar4 + '%';
    this.progreso5 = 'width:' + this.scrollBar4 + '%';


    if (this.userData15) {
      this.apiService.get({ api: this.getTestPaso15 + this.userData15._id + '/' + this.semestreAct }).subscribe(
        (response) => {
          if (response) {
            this.idTest = response._id;
            this.hojaTest = response.hojaTest;
            const resp = response;
            resp.test = JSON.parse(response.test);

            const listaPreguntas = resp.test.preguntas;
            //
            //
            this.nombre = resp.test.nombreUSer;
            this.edad = resp.test.edadActual;
            this.fecha = moment(resp.createdAt).format('DD-MMMM-YYYY');

            for (const uni of resp.test.preguntas[2].preguntas[0].opciones) {
              if (uni.interesa) {
                this.perfil = uni.institucion;
              }
            }

            this.listarPreguntas(resp.test.preguntas);
            this.AsignarInsignia();

            this.sortsGraph = {
              type: 'radar',
              data: {
                labels: [
                  [`Liderazgo`, `${Math.round(this.puntosLiderazgo)}%`],
                  [`Global`, ` Mind`, `  ${Math.round(this.puntosGlobal)}%`],
                  [`Logro y`, ` acción`, `   ${Math.round(this.puntosLogro)}%`],
                  [`Impacto`, `social  `, `${Math.round(this.puntosImpacto)}%   `],
                  [`Competencia`, `Académica  `, `${Math.round(this.puntosCompetencia)}%       `]
                ],
                datasets: [
                  {
                    label: 'Puntuación',
                    data: [
                      this.puntosLiderazgo,
                      this.puntosGlobal,
                      this.puntosLogro,
                      this.puntosImpacto,
                      this.puntosCompetencia
                    ],
                    backgroundColor: 'rgba(255, 255, 255, 0.5)',
                    borderColor: '#969FFB',
                    borderWidth: 0,
                    pointBackgroundColor: [
                      '#86388F',
                      '#E3B458',
                      '#5C9B9B',
                      '#DE748F',
                      '#3B61DD'
                    ]
                  },
                  {
                    label: 'Máximo',
                    data: [
                      100,
                      100,
                      100,
                      100,
                      100
                    ],
                    backgroundColor: 'rgba(176, 183, 255, 0.5)',
                    borderColor: '#969FFB',
                    pointRadius: 0,
                    borderWidth: 0,
                  },
                ]
              },
              options: {
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  customCanvasBackgroundColor: {
                    color: '#B0B7FF',
                  },
                },
                tooltips: {
                  enabled: false
                },
                legend: {
                  display: false,
                },
                scale: {
                  gridLines: {
                    color: '#969FFB',
                    lineWidth: '1'
                  },
                  ticks: {
                    display: false,
                    min: 0,
                    max: 100,
                  },
                  pointLabels: {
                    fontColor: ['#3B61DD', '#86388F', '#E3B458', '#5C9B9B', '#DE748F'],
                    fontSize: 8,
                    fontStyle: 'bold'
                  }
                },
              },
            };

            listaPreguntas.map(registro => {
              if (registro.tipo == 'optener_datos') {
                registro.preguntas.map(pregunta => {
                  //
                  if (!pregunta.opciones && !pregunta.lista) {
                    this.preguntasCerradas.push(pregunta); // -> Para evitar la pregunta general de presepuesto
                  }

                  if (pregunta.opciones) {
                    pregunta.opciones.map(presupuesto => {
                      this.preguntasPresupuesto.push(presupuesto);
                    });
                  }

                  if (pregunta.lista) {
                    this.preguntasPaises.push(pregunta);
                  }

                });
              }
            });
          } else {

            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Para ver resultados, por favor completa el test IA Skills',
            });
            return false;
          }
        },
        (err) => {
          // Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    }
  }

  reiniciarTest() {
    Swal.fire({
      title: '¿Estas seguro que deseas reiniciar el test?',
      showDenyButton: true,
      showCancelButton: false,
      confirmButtonText: 'Aceptar',
      denyButtonText: `Cancelar`,
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        this.apiService.delete({ api: this.deleteTest + this.idTest }).subscribe(response => {

          if (response) {
            this.router.navigate(['leaderTest']);
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al reiniciar el test',
            });
            return false;
          }
        });
      }
    });


  }

  listarPreguntas(lista) {
    const preguntasEvaluar = [];
    for (const secciones of lista) {
      if (secciones.tipo == 'puntaje') {
        preguntasEvaluar.push(secciones);
      }
    }
    let liderazgo = 0;
    let global = 0;
    let impacto = 0;
    let logro = 0;
    let competencia = 0;
    const recomendaciones = preguntasEvaluar;

    let total_liderazgo = 0;
    let total_global = 0;
    let total_impacto = 0;
    let total_competencia = 0;
    let total_logro = 0;

    // console.log('PREGUNTAS EVALUAR',preguntasEvaluar)
    preguntasEvaluar.map(seccion => {
      seccion.preguntas.map(pregunta => {
        if (pregunta.afecciones) {
          pregunta.afecciones.map(habilidades => {
            if (habilidades.habilidad) {
              if (habilidades.habilidad == 'Liderazgo') {
                total_liderazgo++;
              }
              if (habilidades.habilidad == 'Global Mind') {
                total_global++;
              }
              if (habilidades.habilidad == 'Impacto Social') {
                total_impacto++;
              }

              if (habilidades.habilidad == 'Compentecia Academica / Aprendizaje') {
                total_competencia++;
              }

              if (habilidades.habilidad == 'Logro y Acción') {
                total_logro++;
              }
            }
          });
        }
      });
    });

    total_liderazgo = total_liderazgo * 5;
    total_global = total_global * 5;
    total_impacto = total_impacto * 5;
    total_competencia = total_competencia * 5;
    total_logro = total_logro * 5;

    for (const eva of preguntasEvaluar) {
      for (const not of eva.habilidades) {
        if (not.nombre == 'Liderazgo') {
          liderazgo += parseInt(not.puntaje);
        }
        if (not.nombre == 'Global Mind') {
          global += parseInt(not.puntaje);
        }
        if (not.nombre == 'Impacto Social') {
          impacto += parseInt(not.puntaje);
        }
        if (not.nombre == 'Logro y Acción') {
          logro += parseInt(not.puntaje);
        }
        if (not.nombre == 'Compentecia Academica / Aprendizaje') {
          competencia += parseInt(not.puntaje);
        }
      }
    }
    const listRecomendaciones = [];
    for (const eva of recomendaciones) {
      for (const recomendaciones of eva.preguntas) {
        //
        if (recomendaciones.invertida) {
          if (parseFloat(recomendaciones.opcionSeccionada) > 3) {
            listRecomendaciones.push(recomendaciones);
          }
        }
        if (parseFloat(recomendaciones.opcionSeccionada) < 3) {
          listRecomendaciones.push(recomendaciones);
        }
      }
    }

    this.puntosLiderazgo = (liderazgo / total_liderazgo) * 100;
    this.puntosLiderazgo = this.puntosLiderazgo.toFixed(2);
    if (this.puntosLiderazgo > 100) { this.puntosLiderazgo = 100; }
    this.progresoLiderazgo = 'width:' + this.puntosLiderazgo + '%';

    this.puntosGlobal = (global / total_global) * 100;
    this.puntosGlobal = this.puntosGlobal.toFixed(2);
    if (this.puntosGlobal > 100) { this.puntosGlobal = 100; }
    this.progresoGlobal = 'width:' + this.puntosGlobal + '%';

    this.puntosImpacto = (impacto / total_impacto) * 100;
    this.puntosImpacto = this.puntosImpacto.toFixed(2);
    if (this.puntosImpacto > 100) { this.puntosImpacto = 100; }
    this.progresoImpacto = 'width:' + this.puntosImpacto + '%';

    this.puntosLogro = (logro / total_logro) * 100;
    this.puntosLogro = this.puntosLogro.toFixed(2);
    if (this.puntosLogro > 100) { this.puntosLogro = 100; }
    this.progresoLogro = 'width:' + this.puntosLogro + '%';

    this.puntosCompetencia = (competencia / total_competencia) * 100;
    this.puntosCompetencia = this.puntosCompetencia.toFixed(2);
    if (this.puntosCompetencia > 100) { this.puntosCompetencia = 100; }
    this.progresoCompetencia = 'width:' + this.puntosCompetencia + '%';

    this.promedioGeneral = (parseFloat(this.puntosLiderazgo) + parseFloat(this.puntosGlobal) + parseFloat(this.puntosImpacto) + parseFloat(this.puntosLogro) + parseFloat(this.puntosCompetencia));
    this.promedioGeneral = (this.promedioGeneral / 5);
    this.promedioGeneral = this.promedioGeneral.toFixed(2);
    if (this.promedioGeneral > 100) { this.promedioGeneral = 100; }
    this.progresoGeneral = 'width:' + this.promedioGeneral + '%';

    if (this.promedioGeneral >= 85) {
      this.perfilFinal = 'Master';

    } else if (this.promedioGeneral >= 75 && this.promedioGeneral <= 84) {
      this.perfilFinal = 'Profesional';

    } else if (this.promedioGeneral >= 65 && this.promedioGeneral <= 74) {
      this.perfilFinal = 'Avanzado';

    } else if (this.promedioGeneral >= 50 && this.promedioGeneral <= 64) {
      this.perfilFinal = 'Promedio';

    } else if (this.promedioGeneral >= 0 && this.promedioGeneral <= 49) {
      this.perfilFinal = 'En construccion';

    }

    switch (this.perfil) {
      case 'Top Tier':
        this.indicadoresGlobales = this.TopTier;
        if (this.promedioGeneral < 70) {
          this.tipPerfil = 'Tienes mucho por trabajar para acceder a una universidad TOP TIER, estas universidades son las más exigentes y te pedirán todas tus competencias a tope además de examenes internacionales. Tu perfil podría adaptarse a una Practical University o una Experiential University, no las pierdas de vista, en ellas podrás desarrollar todo tu potencial. Si tu intención sigue siendo una TOP TIER existen programas academicos de puetne para lograrlo.';
        } else if (this.promedioGeneral >= 70 && this.promedioGeneral <= 89) {
          this.tipPerfil = 'Cumples con la mayoría de requisitos para acceder a una universidad TOP TIER, sin embargo debes  poner tu perfil a tope para maximizar tus probabilidades de ingreso. Vale la pena que contactes un coach que acompañe tu proceso que exigirá mucho de ti.';
        } else if (this.promedioGeneral >= 90) {
          this.tipPerfil = 'Cumples con los requisitos para acceder a una universidad TOP TIER, sin embargo no debes descuidarte y debes continuar poniendo tu perfil a tope para maximizar tus probabilidades de ingreso.  No olvides asesorarte para revisar requisitos especificos de admisión cómo examenes internacionales, idioma, entre otros.';
        }
        break;
      case 'Experiential University':
        this.indicadoresGlobales = this.Experiential;
        if (this.promedioGeneral < 60) {
          this.tipPerfil = 'Tienes algunas cosas que trabajar para ingresar a una Experiential University.  Recuerda que también existen las universidades Practical Learning, dónde te enfocarás en desrrollar habilidades para el trabajo, contacta a tu coach para entender cuales paises potenciarán mejor tu desarrollo profesional de acuerdo a tus intereses. Si tu intención sigue siendo una EXPERIENTIAL existen programas academicos de puetne para lograrlo.';
        } else if (this.promedioGeneral >= 60 && this.promedioGeneral <= 69) {
          this.tipPerfil = 'Cumples con la mayoría de requisitos para acceder a una  EXPERIENTIAL UNIVERSITY, en este tipo de universidad desarrollaras tu potencial y de la mano de las competencias asegurarás tu exito académico.';
        } else if (this.promedioGeneral >= 70) {
          this.tipPerfil = 'Cumples con los requisitos para acceder a una EXPERIENTIAL UNIVERSITY, continua desarrollando tus competencias y no tendrás inconvenientes en tu desempeño universitario.  No olvides asesorarte para revisar requisitos especificos de admisión.';
        }
        break;
      case 'Practical learning':
        this.indicadoresGlobales = this.PracticalLearning;
        if (this.promedioGeneral < 40) {
          this.tipPerfil = 'Te faltan algunos requisitos para acceder a una PRACTICAL LEARNING INSTITUTION, sin embargo con ayuda de un consultor de educación internacional podrás entender que institución internacional o nacional abrira tus puertas profesionales y te conectará con la industria de tu interés.';
        } else if (this.promedioGeneral >= 40 && this.promedioGeneral <= 49) {
          this.tipPerfil = 'Cumples con la mayoría de requisitos para acceder a una PRACTICAL LEARNING INSTITUTION, contacta a tu coach de educación internacional para entender que institución internacional o nacional abrira tus puertas profesionales y te conectará con la industria de tu interés.';
        } else if (this.promedioGeneral >= 50) {
          this.tipPerfil = 'Cumples con todos los requisitos para acceder a una PRACTICAL LEARNING INSTITUTION, contacta a tu coach de educación internacional para entender que institución internacional o nacional abrira tus puertas profesionales y te conectará con la industria de tu interés.';
        }
        break;
      case 'Vocational / Passion':
        this.indicadoresGlobales = this.Vocational;
        if (this.promedioGeneral < 60) {
          this.tipPerfil = 'Te faltan algunos requisitos para acceder a una SPECIALIZED INSTITUTION, contacta a tu coach de educación internacional para entender que institución internacional o nacional abrira tus puertas profesionales y te conectará con la industria de tu interés.';
        } else if (this.promedioGeneral >= 60 && this.promedioGeneral <= 69) {
          this.tipPerfil = 'Cumples con la mayoría de los para acceder a una SPECIALIZED INSTITUTION, contacta a tu coach de educación internacional para entender que institución internacional o nacional abrira tus puertas profesionales y te conectará con la industria de tu interés.';
        } else if (this.promedioGeneral >= 70) {
          this.tipPerfil = 'Cumples con los requisitos para acceder a una SPECIALIZED INSTITUTION, contacta a tu coach de educación internacional para entender que institución internacional o nacional abrira tus puertas profesionales y te conectará con la industria de tu interés.';
        }
        break;
    }
    console.log(this.indicadoresGlobales, "1")
    console.log(this.indicadoresGlobales[0], "2")
    console.log(this.indicadoresGlobales[0].porcentaje, "3")
    this.indicadorLiderazgo = 'width:' + this.indicadoresGlobales[0].porcentaje + '%';
    this.indicadorGlobal = 'width:' + this.indicadoresGlobales[1].porcentaje + '%';
    this.indicadorImpacto = 'width:' + this.indicadoresGlobales[2].porcentaje + '%';
    this.indicadorLogro = 'width:' + this.indicadoresGlobales[3].porcentaje + '%';
    this.indicadorAprendizaje = 'width:' + this.indicadoresGlobales[4].porcentaje + '%';
    this.indicadorGeneral = this.indicadoresGlobales[5].porcentaje;

    const data = {
      liderazgo: this.puntosLiderazgo,
      gobal_mind: this.puntosGlobal,
      impacto_social: this.puntosImpacto,
      logro_accion: this.puntosLogro,
      puntaje_general: this.promedioGeneral,
      competencia_academica: this.puntosCompetencia,
      perfil: this.perfilFinal
    };
    this.apiService.put({ api: this.updateFrmTest15 + this.idTest, data }).subscribe(response => {

    });

    for (const newList of listRecomendaciones) {
      if (newList.recomendacion && newList.recomendacion !== '') {
        for (const habilidades of newList.afecciones) {
          //
          if (habilidades.habilidad == 'Liderazgo') {
            this.recomendacionesLiderazgo.push(newList);
          }
          if (habilidades.habilidad == 'Global Mind') {
            this.recomendacionesGlobal.push(newList);
          }
          if (habilidades.habilidad == 'Impacto Social') {

            this.recomendacionesImpacto.push(newList);
          }
          if (habilidades.habilidad == 'Compentecia Academica / Aprendizaje') {
            this.recomendacionesAprendizaje.push(newList);
          }
          if (habilidades.habilidad == 'Logro y Acción') {
            this.recomendacionesLogro.push(newList);
          }
        }
      }

    }
  }

  AsignarInsignia(): void {
    switch (this.perfilFinal) {
      case 'Master':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/master.png';
        break;

      case 'Profesional':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/profesional.png';
        break;

      case 'Avanzado':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/avanzado.png';
        break;

      case 'Promedio':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/promedio.png';
        break;

      case 'En construccion':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/en-construccion.png';
        break;

      default:

    }
  }

  mostrarVistaDetalle({ competencia }): void {
    this.navigationService.setIsVisibleSidebar(false);
    this.detalleCompetencia = true;
    switch (competencia) {
      case 'liderazgo':
        this.contenidoCompetencia = {
          titulo: 'Liderazgo',
          descripcion: 'El liderazgo es la capacidad de mantener motivado a tu equipo o tus para obtener un resultado común. Al ser líder aprendes el poder de la atención enfocada en lo importante, y de trasmitir la pasión de un objetivo en el que todos continúan creyendo.',
          puntaje: this.puntosLiderazgo,
          indicador: this.indicadoresGlobales[0].porcentaje,
          sugerencias: this.recomendacionesAprendizaje
        };
        break;

      case 'global':
        this.contenidoCompetencia = {
          titulo: 'Global mind',
          descripcion: 'Es la capacidad que una persona tiene de proyectarse en el mundo, basado en sus fortalezas y acompañados de un nivel de investigación y conocimiento que demuestre la profesionalización.',
          puntaje: this.puntosGlobal,
          indicador: this.indicadoresGlobales[1].porcentaje,
          sugerencias: this.recomendacionesGlobal
        };
        break;

      case 'impacto':
        this.contenidoCompetencia = {
          titulo: 'Impacto social',
          descripcion: 'El mundo esta llamado a finalmente superar grandes problemáticas tales cómo pobreza, falta de acceso al agua y los alimentos, calentamiento global, salud emocional, entre muchas otras. Un primer paso para demostrar tu capacidad de solidarizarte y aportar es a través del Impacto Social. Aportar vale desde temprana edad, y si no lo has hecho aun no es tarde, solo ten en cuenta que para una universidad TOP suma muchos puntos conocer esta faceta.',
          puntaje: this.puntosImpacto,
          indicador: this.indicadoresGlobales[2].porcentaje,
          sugerencias: this.recomendacionesImpacto
        };
        break;

      case 'logro':
        this.contenidoCompetencia = {
          titulo: 'Logro y acción',
          descripcion: 'El mejor profesional es quien decide poner en práctica el conocimiento, es habitual que los mejores lideres demuestren desde temprana edad su capacidad superior para iniciar proyectos y demostrar resultados.',
          puntaje: this.puntosLogro,
          indicador: this.indicadoresGlobales[3].porcentaje,
          sugerencias: this.recomendacionesLogro
        };
        break;

      case 'aprendizaje':
        this.contenidoCompetencia = {
          titulo: 'Competencia Académica / Aprendizaje',
          descripcion: 'Las universidades TOP en el mundo entenderán a través de tus notas tu capacidad para ser constante en la dedicación para obtener resultados. También entenderán tu afinidad para la carrera a la que te presentas, dónde recurrentemente se deben obtener los mejores resultados. Si no las tienes aun tienes oportunidad de nivelarte, sea durante tu estudio actual o mediante llamados Pathways.',
          puntaje: this.puntosCompetencia,
          indicador: this.indicadoresGlobales[4].porcentaje,
          sugerencias: this.recomendacionesAprendizaje
        };
        break;
    }
  }

  handleHowToImproveClick(competence: string): void {
    switch (competence) {
      case 'liderazgo':
        this.navigationService.navigateTo({
          path: '/blog',
          params: {
            pais: this.countries,
            competencias: "liderazgo",
            page: 1,
          }
        });
        break;
      case 'global':
        this.navigationService.navigateTo({
          path: '/blog',
          params: {
            pais: this.countries,
            competencias: "global-mind",
            page: 1
          }
        });
        break;
      case 'impacto':
        this.navigationService.navigateTo({
          path: '/blog',
          params: {
            pais: this.countries,
            competencias: "impacto",
            page: 1
          }
        });
        break;
      case 'logro':
        this.navigationService.navigateTo({
          path: '/blog',
          params: {
            pais: this.countries,
            competencias: "trabajo-accion",
            page: 1
          }
        });
        break;
      case 'aprendizaje':
        this.navigationService.navigateTo({
          path: '/blog',
          params: {
            pais: this.countries,
            competencias: "aprendizaje",
            page: 1
          }
        });
        break;
    }
  }


  loadActivedSemester(): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.API_GET_CODE + this.userData15.email }).subscribe(
      (register) => {
        if (register.length > 0) {

          const dataColegio = register[0].idColegio;
          this.semestreAct = dataColegio.semestreActivo;

          localStorage.setItem('semestreActivo', dataColegio.semestreActivo);
          this.loadData();

        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'No se le ha asignado ningun codigo',
          });
        }
        this.ngxSpinnerService.hide();
      }
    );
  }

  getDataStudent({ data }) {
    this.apiService.get({ api: this.GET_STUDENT + data }).subscribe(
      (response) => {
        this.userData15 = response;
        this.loadActivedSemester();
      }
    );
  }

  /**
   * Handles the click on the back button
   */
  onBackButtonClick(): void {
    this.location.back();
  }
}
