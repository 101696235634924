<!-- <div class="row">
    <div class="col-12" style="border: solid;">
        <app-header></app-header>

    </div>
</div>

<div class="row" *ngIf="!isLoggedIn">
    <div class="col-12 content-outlet">
        <router-outlet></router-outlet>
    </div>
</div>

 <div class="row" *ngIf="isLoggedIn">
    <div class="col-3" style="border: solid;">
        <app-principal-menu></app-principal-menu>
    </div>

    <div class="col-9" style="border: solid;">
        <div class="content-outlet">
            <router-outlet></router-outlet>
        </div>
    </div>

 </div> -->
<div class="app" [ngClass]="{ 'app-overflow' : !isLoggedIn }">
    <app-header [hidden]="ocultaHeader== true"></app-header>
    <ngx-spinner
        template="<img style='width: 12rem;' src='assets/home/logos/estado-vacio.png' alt='logo-u360/>">
    </ngx-spinner>
    <simple-notifications></simple-notifications>

    <div *ngIf="!isLoggedIn; else testing">
      <div class="content-outlet">
          <router-outlet></router-outlet>
      </div>
      <app-footer class="footer" [hidden]="ocultaFooter== true"></app-footer>
    </div>

    <ng-template #testing>
        <img class="header-background display-mobile-none" src="assets/home/images/background-app.png">
        <img class="header-background display-mobile" src="assets/home/images/title-cover-mobile.png">
        <div class="row h-100">
            <div *ngIf="isActiveSidebar || scrWidth > 950" class="col-lg-3 col-xxl-2 pr-0 sidebar-mobile" [ngClass]="{ 'overlap' : isActiveMenuMobile }" style="margin-top: 1%;">
                <app-principal-menu (isActiveMenuMobileEmitter)="onActiveMenu($event)"></app-principal-menu>
            </div>
            <div class="col-lg-9 col-xxl-10 px-xl-0 scroll-router content-mobile" [ngClass]="{ 'margin-left-content' : isActiveSidebar }">
                <router-outlet></router-outlet>
            </div>
        </div>
    </ng-template>
</div>

<!-- <div class="main-container" *ngIf="isLoggedIn">
    <app-header [hidden]="ocultaHeader== true"></app-header>
    <ngx-spinner
        template="<img style='width: 12rem;' src='assets/home/logos/estado-vacio.png' alt='logo-u360/>">
    </ngx-spinner>
    <simple-notifications></simple-notifications>
    <div class="row">
        <div class="col-xl-3">
            <app-principal-menu></app-principal-menu>
        </div>

        <div class="col-xl-9">
            <router-outlet></router-outlet>
        </div>
    </div>
    <app-footer [hidden]="ocultaFooter== true"></app-footer>
</div> -->
