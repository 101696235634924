<div class="col-xl-12" *ngIf="!isMyCv">
  <img class="back-button" src="/assets/wizard-images/back.png" alt="back" (click)="onBackButtonClick()">
</div>
<div id="boxBaner" class="profile-section"> <!-- Sección de foto del perfil -->
  <img [src]="fotoBanner" alt="Foto de baner" class="banner-image" for="bannerImage">
  <div class="h-100 w-100">
    <div class="div-icon-link">
      <div class="mt-3">
        <div [hidden]="actived!='icon'">
          <div class="d-flex justify-content-end">
            <img id="img-copiar" class="img-icon-banner" (click)="LinkProfileCopy()"
              src="/assets/curriculum/icon-banner.png" alt="">
          </div>
        </div>
        <div [hidden]="actived!='txt'">
          <div class="d-flex justify-content-around div-enlace" (click)="hideTxt()">
            <div>
              <img src="/assets/curriculum/documentos-2-gris.png" alt="" class="img-icon-enlace">
            </div>
            <div>
              <label class="txt-style txt-skills">Enlace copiado</label>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-start div-principal">
      <img [src]="fotoPerfil" alt="Foto de perfil" class="profile-photo">
      <div class="d-flex justify-content-start div-info">
        <div class="w-100 d-flex justify-content-between align-items-end align-items-lg-start">
          <label class="titulo-1">{{ nombre }}</label>
          <img class="img-pencil" routerLink="/curriculum-edit" src="/assets/curriculum/pencil.png" alt="editar"
            *ngIf="isMyCv">
        </div>
        <div class="d-flex justify-content-between w-100">
          <div class="display-mobile-none">
            <div class="w-100 txt-style">
              <label class="titulo-2"><b>Colegio:</b> {{ colegio }}</label>
            </div>
            <div class="w-100 txt-style">
              <label class="titulo-2"><b>Proyecto:</b> {{ proyecto }}</label>
            </div>
            <div class="w-100 txt-style">
              <label class="titulo-2"><b>Palabras clave:</b> {{ palabrasClave }}</label>
            </div>
          </div>
          <div class="d-flex align-items-center justify-content-end" *ngIf="perfilFinal">
            <img class="img-master" [src]="sourceInsignia" alt="insignia">
            <label class="txt-style txt-skills txt-master px-1">Perfil {{perfilFinal}}</label>
            <div class="tooltip-icon">
              <img class="img-question" src="/assets/curriculum/preguntas.png" alt="tooltip">
              <span>
                Cumple con todos los requisitos para acceder a una universidad Top Rier, sin embargo, debe continuar
                trabajando en su perfil.
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="div-secundario"> <!-- Sección de sobre mi y carta de intención -->
  <div class="display-mobile profile-info">
    <div class="w-100 txt-style row m-0 p-0">
      <label class="titulo-2 col-4 p-0"><b>Colegio:</b></label>
      <label class="titulo-2 col-8 p-0">{{ colegio }}</label>
    </div>
    <div class="w-100 txt-style row m-0 p-0">
      <label class="titulo-2 col-4 p-0"><b>Proyecto:</b></label>
      <label class="titulo-2 col-8 p-0">{{ proyecto }}</label>
    </div>
    <div class="w-100 txt-style row m-0 p-0">
      <label class="titulo-2 col-4 p-0"><b>Palabras clave:</b></label>
      <label class="titulo-2 col-8 p-0">{{ palabrasClave }}</label>
    </div>
  </div>
  <div class="div-about-me display-mobile-none">
    <label class="about-me-title line txt-style">Sobre mi</label>
    <br>
    <div class="about-me-description">
      <label class="txt-style txt-about-me-description">{{ sobreMi }}</label>
    </div>
    <div>
      <button class="btn-experiences" (click)="goToExperiences()">Mis experiencias</button>
    </div>
  </div>
  <div class="border-card" *ngIf="isMyCv">
    <div class="m-auto">
      <div class="w-100">
        <div class="d-flex justify-content-center">
          <label class="txt-style about-me-title title-letter">Carta de intención</label>
        </div>
        <div class="d-flex justify-content-center">
          <label class="txt-style txt-letter">Experto en Aplicaciones artísticas en Tecnología</label>
        </div>
      </div>
      <div class="d-flex justify-content-around mt-2">
        <div class="d-flex justify-content-around w-70">
          <div>
            <img routerLink="/letter" class="img-edit cursor-pointer" src="/assets/curriculum/edit.png" alt="">
          </div>
          <div class="w-55">
            <button class="button-letter w-100 h-100" (click)="goToLetterReady()">Ver</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="div-about-me display-mobile">
    <label class="about-me-title line txt-style">Sobre mi</label>
    <br>
    <div class="about-me-description">
      <label class="txt-style txt-about-me-description">{{ sobreMi }}</label>
    </div>
  </div>
</div>

<div class="d-flex flex-column flex-lg-row mt-2 mt-lg-5 div-terciario">
  <div class="languages">
    <label class="txt-style txt-skills">Idiomas:</label>
    <div class="d-flex flex-column flex-lg-row justify-content-between w-100">
      <div *ngFor="let idioma of idiomas" class="idiomas-container">
        <div>
          <label class="language"><b>{{ idioma.nombre }}</b> {{ idioma.nivel }}</label>
        </div>
      </div>

    </div>
  </div>
  <div class="d-flex mt-3 mt-lg-0 border-left areas-container">
    <label class="txt-style txt-skills">Áreas de interés</label>
    <div class="d-flex areas-icon-container w-100">
      <div *ngFor="let area of areas">
        <div>
          <p class="areaName"><b>{{ area.nombre }}</b> </p>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex border-left mt-3 mt-lg-0 paises-container">
    <label class="txt-style txt-skills">Paises de interés</label>
    <div class="d-flex paises-icon-container">
      <div *ngFor="let pais of paises">
        <div class="countryNameIcon">
          <img class="countryImage" [src]="pais.imagen" alt="pais.nombre">
          <p class="areaName"><b>{{ pais.nombre }}</b> </p>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex mt-3 mt-lg-5 competencias" *ngIf="userTest">
  <div>
    <label class="about-me-title txt-style mb-2 mb-lg-4">Competencias</label>
    <div class="mb-4 d-flex">
      <div class="display-mobile-none">
        <img class="img-edit" src="/assets/curriculum/icono-amarillo.png" alt="">
      </div>
      <div class="ml-lg-4 card-mobile">
        <div>
          <label class="txt-style txt-title-capacities">Puntaje general</label>
        </div>
        <div class="display-mobile-none">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.puntaje_general}}%</label>
            <progress max="100" [value]="userTest.puntaje_general"></progress>
          </div>
        </div>
        <div>
          <label class="txt-style txt-capacities">
            El puntaje alcanzado corresponder al 15% mejor dentro de la media de su grupo. Su perfil se adapta
            fácilmente a la educación de tipo XYZ. Presenta 3 competencias con puntaje alto.
          </label>
        </div>
        <div class="display-mobile mt-1">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.puntaje_general}}%</label>
            <progress class="w-100" max="100" [value]="userTest.puntaje_general"></progress>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex">
      <div class="display-mobile-none">
        <img class="img-edit" src="/assets/curriculum/icono-amarillo.png" alt="">
      </div>
      <div class="ml-lg-4 card-mobile">
        <div>
          <label class="txt-style txt-title-capacities">Liderazgo</label>
        </div>
        <div class="display-mobile-none">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.liderazgo}}%</label>
            <progress max="100" [value]="userTest.liderazgo"></progress>
          </div>
        </div>
        <div>
          <label class="txt-style txt-capacities">
            El liderazgo es la capacidad de mantener motivado a tu equipo o tus para obtener un resultado común.
            Al ser líder aprendes el poder de la atención enfocada en lo importante, y de trasmitir la pasión de un
            objetivo en el
            que todos continúan creyendo.
          </label>
        </div>
        <div class="display-mobile mt-1">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <progress class="w-100" max="100" [value]="userTest.liderazgo"></progress>
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.liderazgo}}%</label>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex">
      <div class="display-mobile-none">
        <img class="img-edit" src="/assets/curriculum/icono-amarillo.png" alt="">
      </div>
      <div class="ml-lg-4 card-mobile">
        <div>
          <label class="txt-style txt-title-capacities">Global mind</label>
        </div>
        <div class="display-mobile-none">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.gobal_mind}}%</label>
            <progress max="100" [value]="userTest.gobal_mind"></progress>
          </div>
        </div>
        <div>
          <label class="txt-style txt-capacities">
            Es la capacidad que una persona tiene de proyectarse en el mundo, basado en sus fortalezas y acompañados
            de un nivel de investigación y conocimiento que demuestre la profesionalización.
          </label>
        </div>
        <div class="display-mobile mt-1">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <progress class="w-100" max="100" [value]="userTest.gobal_mind"></progress>
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.gobal_mind}}%</label>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex">
      <div class="display-mobile-none">
        <img class="img-edit" src="/assets/curriculum/icono-amarillo.png" alt="">
      </div>
      <div class="ml-lg-4 card-mobile">
        <div>
          <label class="txt-style txt-title-capacities">Impacto social</label>
        </div>
        <div class="display-mobile-none">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.impacto_social}}%</label>
            <progress max="100" [value]="userTest.impacto_social"></progress>
          </div>
        </div>
        <div>
          <label class="txt-style txt-capacities">
            El mundo esta llamado a finalmente superar grandes problemáticas tales cómo pobreza, falta de acceso al agua
            y los alimentos,
            calentamiento global, salud emocional, entre muchas otras. Un primer paso para demostrar tu capacidad de
            solidarizarte y aportar
            es a través del Impacto Social. Aportar vale desde temprana edad, y si no lo has hecho aun no es tarde, solo
            ten en cuenta que para una
            universidad TOP suma muchos puntos conocer esta faceta.
          </label>
        </div>
        <div class="display-mobile mt-1">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <progress class="w-100" max="100" [value]="userTest.impacto_social"></progress>
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.impacto_social}}%</label>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex">
      <div class="display-mobile-none">
        <img class="img-edit" src="/assets/curriculum/icono-amarillo.png" alt="">
      </div>
      <div class="ml-lg-4 card-mobile">
        <div>
          <label class="txt-style txt-title-capacities">Logro y acción</label>
        </div>
        <div class="display-mobile-none">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.logro_accion}}%</label>
            <progress max="100" [value]="userTest.logro_accion"></progress>
          </div>
        </div>
        <div>
          <label class="txt-style txt-capacities">
            El mejor profesional es quien decide poner en práctica el conocimiento, es habitual que los mejores lideres
            demuestren desde
            temprana edad su capacidad superior para iniciar proyectos y demostrar resultados.
          </label>
        </div>
        <div class="display-mobile mt-1">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <progress class="w-100" max="100" [value]="userTest.logro_accion"></progress>
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.logro_accion}}%</label>
          </div>
        </div>
      </div>
    </div>
    <div class="mb-4 d-flex">
      <div class="display-mobile-none">
        <img class="img-edit" src="/assets/curriculum/icono-amarillo.png" alt="">
      </div>
      <div class="ml-lg-4 card-mobile">
        <div>
          <label class="txt-style txt-title-capacities">Competencia Académica / Aprendizaje</label>
        </div>
        <div class="display-mobile-none">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.competencia_academica}}%</label>
            <progress max="100" [value]="userTest.competencia_academica"></progress>
          </div>
        </div>
        <div>
          <label class="txt-style txt-capacities">
            Las universidades TOP en el mundo entenderán a través de tus notas tu capacidad para ser constante en la
            dedicación para
            obtener resultados. También entenderán tu afinidad para la carrera a la que te presentas, dónde
            recurrentemente se deben
            obtener los mejores resultados. Si no las tienes aun tienes oportunidad de nivelarte, sea durante tu estudio
            actual o mediante
            llamados Pathways.
          </label>
        </div>
        <div class="display-mobile mt-1">
          <div class="d-flex flex-column flex-lg-row justify-content-between w-30">
            <progress class="w-100" max="100" [value]="userTest.competencia_academica"></progress>
            <label class="txt-style txt-subtitle-capacities">Alcanzado {{userTest.competencia_academica}}%</label>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<div class="mt-1 mt-lg-5 competencias" *ngIf="logros.length>0">
  <label class="about-me-title txt-style mb-lg-3">Logros e intereses</label>
  <div class="d-flex flex-column flex-lg-row card-mobile">
    <div class="text-center">
      <img class="img-trophy mt-2 mt-lg-0" src="/assets/curriculum/trophy.png" alt="">
    </div>
    <div class="mt-2 mt-lg-0">
      <ul>

        <li class="logro" *ngFor="let item of logros">{{item.descripcion}}</li>
      </ul>
    </div>
  </div>
</div>
<div *ngIf="referencias.length>0" class="referencias mb-4">
  <div class="mt-3 mt-lg-5">
    <label class="about-me-title txt-style mb-2 mb-lg-3">Notas de Referencia</label>
  </div>

  <div class="mb-3 mb-lg-0 mt-lg-5" *ngFor="let item of referencias">
    <div class="d-flex">
      <div class="display-mobile-none">
        <img class="img-edit" src="/assets/curriculum/icono-amarillo.png" alt="">
      </div>
      <div class="ml-lg-4 card-mobile w-100">
        <div>
          <label class="txt-style txt-title-capacities">{{ item.nombre }}</label>
        </div>
        <div>
          <label class="txt-style txt-subtitle-capacities">{{ item.cargo }}</label>
        </div>
        <div>
          <label class="txt-style txt-capacities mt-2 mt-lg-0">{{ item.nota }}</label>
        </div>
      </div>
    </div>
  </div>
</div>
