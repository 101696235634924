<div class="favourites-page">
  <div class="table-section">
    <div class="student-selection mb-20" *ngIf="isRecruitment">
      <h5>{{ "Favourites.select-student" | translate }}</h5>
      <div class="student-selector">
        <select
          placeholder="Seleccionar estudiante"
          [(ngModel)]="id"
          (ngModelChange)="onStudentChange({ id: $event })"
          class="student-select"
        >
          <option value="0" disabled selected>
            {{ "Seleccionar estudiante" }}
          </option>
          <option *ngFor="let s of students" [value]="s._id">
            {{ s.nombre }} {{ s.apellido }}
          </option>
        </select>
      </div>
    </div>
    <h3 class="favourites-title">
      {{ "Favourites.comparison-chart" | translate }}
    </h3>
    <p class="favourites-disclaimer">
      {{ "Favourites.add-remove" | translate }}
    </p>
    
    <!-- <div class="comparison-chart"> 
      <ng2-smart-table
        [settings]="settings"
        [source]="tableData"
        (custom)="onCustomAction($event)"
      ></ng2-smart-table>
    </div> -->

    <div class="comparison-chart" *ngIf="!isRecruitment">
      <ng2-smart-table class="order-table"
        [settings]="Studentsettings"
        [source]="favouritesStudent"
        (custom)="onCustomAction($event)"
        (editConfirm)="updateFavorite($event.newData)"
      ></ng2-smart-table>
    </div>

    <div class="comparison-chart" *ngIf="isRecruitment">
      <ng2-smart-table class="order-table"
        [settings]="settings"
        [source]="favouritesStudent"
        (custom)="onCustomAction($event)"
        (editConfirm)="updateFavorite($event.newData)"
      ></ng2-smart-table>
    </div>

    <div class="comparison-chart mt-20" *ngIf="isRecruitment">
      {{ "Favourites.other-programs" | translate }}
      <ng2-smart-table class="order-table"
        [settings]="otherProgramsSettings"
        [source]="otherPrograms"
        (custom)="onCustomOtherPrograms($event)"
      ></ng2-smart-table>
    </div>
  </div>
  <div class="favourites">
    <div class="favourites-header">
      <h3 class="favourites-title">
        {{ "Favourites.favourites" | translate }}
      </h3>
      <div>
        <button id="comparison-pdf" (click)="openAddFavouriteModal()" *ngIf="isRecruitment">{{ "Favourites.aggregate-favourite" | translate }}</button>
        <button id="comparison-pdf" (click)="onDownloadPdfTable()">
          {{ "Favourites.download" | translate }}
        </button>
      </div>
    </div>
    <p class="favourites-disclaimer">
      {{ "Favourites.prices-may-change" | translate }}
    </p>
    <div class="favourites-list">
      <div class="favcol">
        <h3>Programas</h3>
        <app-program-card
          *ngFor="let program of favourites"
          [program]="program"
        ></app-program-card>
      </div>
      <div class="favcol">
        <h3>Universidades</h3>
        <app-program-card-universities *ngFor="let program of favouriteUniveristies"
        [program]="program"></app-program-card-universities>
      </div>
    </div>
  </div>
</div>

<table id="mytable" style="display: none">
  <thead>
    <tr>
      <th>Nombre de programa</th>
      <th>Universidad / College</th>
      <th>Tipo de programa</th>
      <th>Ubicación</th>
      <th>Duración</th>
      <th>Inicio</th>
      <th>Precio matrícula</th>´
      <th>Comentarios</th>
      <th>Link</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let favorite of dataPdf">
      <td>{{ favorite[0] || "" }}</td>
      <td>{{ favorite[1] || "" }}</td>
      <td>{{ favorite[2] || "" }}</td>
      <td>{{ favorite[3] || "" }}</td>
      <td>{{ favorite[4] || "" }}</td>
      <td>{{ favorite[5] || "" }}</td>
      <td>{{ favorite[6] || "" }}</td>
      <td>{{ favorite[7] || "" }}</td>
      <td><a href="{{ favorite[8] }}"></a></td>
    </tr>
  </tbody>
</table>
