import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso14',
  templateUrl: './paso14.component.html',
  styleUrls: ['./paso14.component.scss']
})
export class Paso14Component implements OnInit {
  @Output() notificacionTab15 = new EventEmitter<string>();
  public preguntas_seccion15:any;
  public dataFrm15:any;
  public dataFrm15Copy:any;
  public preguntas: any[]=[];


  public userData14: any;
  readonly getTestPaso14 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest14 = 'api/get_internalizacionUser/';
  pasoActivo: any;
  public sumItems: number = 0;
  
  constructor(private apiService:ApiService) {
    this.userData14 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.userData14) {
      this.apiService.get({ api: this.getTestPaso14 + this.userData14._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //  
            let data_test= JSON.parse(response.test[0]);
            this.dataFrm15 = response;
            this.pasoActivo= response.hojaTest;
            this.dataFrm15.test = JSON.parse(this.dataFrm15.test);
            this.preguntas_seccion15= data_test.preguntas[14];
             
            let i = 0
            for(let item of this.preguntas_seccion15.preguntas){
               
              this.preguntas[i].opcionSeccionada= item.opcionSeccionada
              i++
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    } 

   }

  ngOnInit(): void {
    this.preguntas=[
      {pregunta:"¿Me lleva un tiempo tolerar cambios muy radicales de costumbres, estilos de vida o pensamientos?.",opcionSeccionada:0},
      {pregunta:"Busco tener nuevas experiencias culturales y sociales; estoy abierto a integrar de manera positiva nuevas costumbres y estilos de vida a mi realidad.",opcionSeccionada:0},
      {pregunta:"¿Suelo generar respuestas innovadoras cuando las circunstancias lo exigen?",opcionSeccionada:0},
      {pregunta:"¿Logro trabajar en situaciones y condiciones variadas, con diversos individuos o grupos; manteniéndome fiel a mis principios y convicciones?.",opcionSeccionada:0},
      {pregunta:"Creo que las cosas salen mejor a mi manera.", opcionSeccionada:0},
      {pregunta:"Suelo investigar sobre situaciones mundiales en mis campos de interés profesional.", opcionSeccionada:0},
      {pregunta:"Existen culturas en el mundo o en mi país con las que no me gustaría interactuar.", opcionSeccionada:0},
      {pregunta:"Me gusta conocer y comprender las normas inclusive en otras culturas.", opcionSeccionada:0},
      {pregunta:"Se me dificulta interactuar con personas de culturas diferente a la mía.", opcionSeccionada:0},
      {pregunta:"Creo que los otros deben adaptarse a mi cultura si estoy en mi ciudad.", opcionSeccionada:0},
    ]
  }
  public volverTab14(){
    this.notificacionTab15.emit("volver")

   }
   radioChange(event,pos) {
    //  
    //  
    //  
    // this.preguntas_seccion15.preguntas[pos].opcionSeccionada)
    this.preguntas_seccion15.preguntas[pos].opcionSeccionada= event.target.value
}
   public continuarTab16(){
    let resetCalculo = this.preguntas_seccion15.habilidades
    for(let calculos of resetCalculo){
      calculos.puntaje=0;
    }
    this.preguntas_seccion15.habilidades= resetCalculo
     
    for(let preg of this.preguntas_seccion15.preguntas){
      let puntos;
      if (parseInt(preg.opcionSeccionada) > 0) {
        this.sumItems = this.sumItems + 1;
      }
       
       
      if(preg.invertida){
         
        if(preg.opcionSeccionada == '1'){
          puntos= '5'
        }
        if(preg.opcionSeccionada == '2'){
          puntos= '4'
        }
        if(preg.opcionSeccionada == '3'){
          puntos= '3'
        }
        if(preg.opcionSeccionada == '4'){
          puntos= '2'
        }
        if(preg.opcionSeccionada == '5'){
          puntos= '1'
        }
      }else{
        puntos = preg.opcionSeccionada
      }
       
      for(let afeccion of preg.afecciones){
           
         let index
         const found = this.preguntas_seccion15.habilidades.find(element => element.nombre === afeccion.habilidad);
         
         if(found){
          let resultadoOperacion = found.puntaje+parseFloat(puntos);
          found.puntaje = resultadoOperacion
         }
      }
       
    }
    let paso = this.pasoActivo
    if(this.pasoActivo < 14){
      paso = this.pasoActivo = 14;
    }
    this.dataFrm15.hojaTest= paso
     

    if (this.sumItems >= 10) {
      this.dataFrm15.test.preguntas[14] = this.preguntas_seccion15;
      this.dataFrm15.test = JSON.stringify(this.dataFrm15.test );
      this.actualizarPaso11();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe responder todas las preguntas',
      });
      this.sumItems=0
      return false;
    }
  }
  actualizarPaso11(){
    let data = this.dataFrm15;
    this.apiService
    .put({ api: this.updateFrmTest14 + this.dataFrm15._id, data })
    .subscribe(
      (response) => {
         
        this.notificacionTab15.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
}
