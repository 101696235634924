<!-- MODAL COMPONENT -->
<ng-template #content let-modal>
  <div class="modal-header customHeader">
    <h4 class="modalTitle">Tu experiencia</h4>
    <button *ngIf="student" type="button" class="close" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <form [formGroup]="form">
    <div class="modal-body customBody">
      <div class="activityTypePage" *ngIf="currentStep === 1">
        <div>
          <p for="name" class="sectionTitle">Nombre de la experiencia:</p>
          <input class="experienceInput" type="text" id="name" name="experienceName" formControlName="experienceName"
          [disabled]="!student" [ngClass]="{ 'disabled-input': !student }"
          required [class.is-invalid]="form.controls['experienceName'].invalid && form.controls['experienceName'].touched" />        
          <div class="invalid-feedback" *ngIf="form.controls['experienceName'].invalid && form.controls['experienceName'].touched">Nombre
            requerido</div>
        </div>
        <div class="experienceInfo">
          <!-- Activity Type checkboxes -->
          <div class="skillsTopSide">
            <div class="experienceCheckBox">
              <p class="experienceLabel checkBoxLabel">Tipo de actividad</p>
              <ng-select
                [items]="activityType"
                [multiple]="true"
                formControlName="selectedActivity"
                [ngClass]="{ 'disabled-input': !student }"
                [disabled]="!student"
                [ngClass]="{ 'disabled-input': !student }"
                name="selectedActivityType"
                bindLabel="name"
                bindValue="id"
                class="custom-ng-select"
                [appendTo]="'body'">
                <ng-template ng-multi-label-tmp let-items="items">
                  <ng-container *ngFor="let item of items.slice(0, 3); let last = last">
                    <div class="ng-value itemNameWrapper">
                      <span class="ng-value-label itemName" [ngClass]="{ 'itemDisable': !student }">
                        {{ item.name }}
                      </span>
                    </div>
                  </ng-container>
                  <span *ngIf="items.length > 3" class="separationSpan">...</span>
                  <div class="ng-value" *ngIf="items.length > 3">
                    <span class="ng-value-label itemNameWrapper">{{ items.length - 3 }}</span>
                  </div>
                </ng-template>
              </ng-select>
              <p class="error-text" *ngIf="form.controls['selectedActivity'].invalid && form.controls['selectedActivity'].touched">
                Tipo de actividad requerido
              </p>
            </div>

            <div class="experienceCheckBox">
              <p class="experienceLabel checkBoxLabel">Tipo de acción de servicio</p>
              <ng-select
                [items]="typeOfService"
                [multiple]="true"
                formControlName="selectedType"
                [disabled]="!student"
                [ngClass]="{ 'disabled-input': !student }"
                name="selectedTypeOfService"
                bindLabel="name"
                bindValue="id"
                class="custom-ng-select">
                <ng-template ng-multi-label-tmp let-items="items">
                  <ng-container *ngFor="let item of items.slice(0, 3); let last = last">
                    <div class="ng-value itemNameWrapper">
                      <span class="ng-value-label itemName" [ngClass]="{ 'itemDisable': !student }">
                        {{ item.name }}
                      </span>
                    </div>
                  </ng-container>
                  <span *ngIf="items.length > 3" class="separationSpan">...</span>
                  <div class="ng-value" *ngIf="items.length > 3">
                    <span class="ng-value-label itemNameWrapper">{{ items.length - 3 }}</span>
                  </div>
                </ng-template>
              </ng-select>
              <p class="error-text" *ngIf="form.controls['selectedActivity'].invalid && form.controls['selectedActivity'].touched">
                Tipo de acción requerido
              </p>
            </div>
          </div>
          <div class="skillsDownSide">
            <div class="experienceCheckBox">
              <p class="experienceLabel checkBoxLabel">Acercamientos</p>
              <ng-select
                [items]="approaches"
                [multiple]="true"
                formControlName="selectedApproaches"
                [disabled]="!student"
                [ngClass]="{ 'disabled-input': !student }"
                name="selectedApproaches"
                bindLabel="name"
                bindValue="id"
                class="custom-ng-select"
                [appendTo]="'body'">
                <ng-template ng-multi-label-tmp let-items="items">
                  <ng-container *ngFor="let item of items.slice(0, 3); let last = last">
                    <div class="ng-value itemNameWrapper">
                      <span class="ng-value-label itemName" [ngClass]="{ 'itemDisable': !student }">
                        {{ item.name }}
                      </span>
                    </div>
                  </ng-container>
                  <span *ngIf="items.length > 3" class="separationSpan">...</span>
                  <div class="ng-value" *ngIf="items.length > 3">
                    <span class="ng-value-label itemNameWrapper">{{ items.length - 3 }}</span>
                  </div>
                </ng-template>
              </ng-select>
              <p class="error-text" *ngIf="form.controls['selectedActivity'].invalid && form.controls['selectedActivity'].touched">
                Tipo de acercamientos requerido
              </p>
            </div>

            <div class="experienceCheckBox">
              <p class="experienceLabel checkBoxLabel">Skills</p>
              <ng-select
                [items]="skills"
                [multiple]="true"
                formControlName="selectedSkills"
                [disabled]="!student"
                [ngClass]="{ 'disabled-input': !student }"
                name="selectedSkills"
                bindLabel="name"
                bindValue="id"
                class="custom-ng-select"
                [appendTo]="'body'">
                <ng-template ng-multi-label-tmp let-items="items">
                  <ng-container *ngFor="let item of items.slice(0, 3); let last = last">
                    <div class="ng-value itemNameWrapper">
                      <span class="ng-value-label itemName" [ngClass]="{ 'itemDisable': !student }">
                        {{ item.name }}
                      </span>
                    </div>
                  </ng-container>
                  <span *ngIf="items.length > 3" class="separationSpan">...</span>
                  <div class="ng-value" *ngIf="items.length > 3">
                    <span class="ng-value-label itemNameWrapper">{{ items.length - 3 }}</span>
                  </div>
                </ng-template>
              </ng-select>
              <p class="error-text" *ngIf="form.controls['selectedActivity'].invalid && form.controls['selectedActivity'].touched">
                Skills requeridas.
              </p>
            </div>
          </div>
        </div>

        <div class="dateSectionWrapper">
          <div class="dateSection">
            <div class="col-xs-12 col-12 col-md-4 form-group">
              <p class="experienceLabel">Fecha de inicio</p>
              <div class="dateInput" [ngClass]="{'disabled-input': form.get('initDate')?.disabled}">
                <img class="dateIcon" src="../../../assets/experiences/dateIcon.png" alt="dateIcon">
                <input name="selectedDate" type="text" placeholder="dd/mm/aaaa" class="form-control my-datepicker"
                bsDatepicker [bsConfig]="datepickerConfig" placement="top" id="initDate" formControlName="initDate"
                [ngClass]="{'is-invalid': form.controls['initDate'].invalid && form.controls['initDate'].touched, 'disabled-input': !student}"/>              
              </div>
            </div>
            <div class="col-xs-12 col-12 col-md-4 form-group">
              <p class="experienceLabel">Fecha final</p>
              <div class="dateInput" [ngClass]="{'disabled-input': form.get('endDate')?.disabled}">
                <img class="dateIcon" src="../../../assets/experiences/dateIcon.png" alt="dateIcon">
                <input name="endDate" type="text" placeholder="dd/mm/aaaa" class="form-control my-datepicker"
                  bsDatepicker [bsConfig]="datepickerConfig" id="endDate" formControlName="endDate"
                  [ngClass]="{'disabled-input': form.get('endDate')?.disabled, 'is-invalid': form.controls['endDate'].invalid && form.controls['endDate'].touched}" />
              </div>
            </div>
            <div class="form-check">
              <input
                formControlName="isOngoing"
                class="form-check-input"
                type="checkbox"
                value=""
                id="flexCheckDefault"
                name="onGoing"
                (change)="toggleEndDate()" 
                [checked]="form.value.isOngoing"
                [disabled]="!student"
                [ngClass]="{ 'disabled-input': !student }" />
              <label class="form-check-label ongoingCheckbox" for="flexCheckDefault">En curso</label>
            </div>
          </div>
        </div>
        <div class="supervisorContainer">
          <p class="sectionTitle">Informacion del supervisor:</p>
          <div class="supervisorInfo">
            <div class="supervisorDetailsContainer">
              <p for="supervisorName" class="supervisorLabels">Nombre:</p>
              <input class="supervisorInput" type="text" id="supervisorName" name="supervisorName"
                formControlName="supervisorName" required
                [class.is-invalid]="form.controls['supervisorName'].invalid && form.controls['supervisorName'].touched"
                [ngClass]="{ 'disabled-input': !student }" />
              <div class="invalid-feedback"
                *ngIf="form.controls['supervisorName'].invalid && form.controls['supervisorName'].touched">
                Nombre del supervisor requerido.
              </div>
            </div>
            
            <div class="supervisorDetailsContainer">
              <p for="supervisorLastName" class="supervisorLabels">Apellido:</p>
              <input class="supervisorInput" type="text" id="supervisorLastName" name="supervisorLastName"
                formControlName="supervisorLastName" required
                [class.is-invalid]="form.controls['supervisorLastName'].invalid && form.controls['supervisorLastName'].touched"
                [ngClass]="{ 'disabled-input': !student }" />
              <div class="invalid-feedback"
                *ngIf="form.controls['supervisorLastName'].invalid && form.controls['supervisorLastName'].touched">
                Apellido requerido.
              </div>
            </div>            
          </div>
          <div class="supervisorInfo">
            <div class="supervisorDetailsContainer">
              <p for="supervisorEmail" class="supervisorLabels">Correo Electrónico:</p>
              <input formControlName="supervisorEmail" class="supervisorInput" type="text" id="supervisorEmail"
                name="supervisorEmail" required
                [class.is-invalid]="form.controls['supervisorEmail'].invalid && form.controls['supervisorEmail'].touched"
                [disabled]="!student" [ngClass]="{ 'disabled-input': !student }"/>
              <div class="invalid-feedback"
                *ngIf="form.controls['supervisorEmail'].invalid && form.controls['supervisorEmail'].touched">Correo inválido.</div>
            </div>
            
            <div class="supervisorDetailsContainer">
              <p for="supervisorNumber" class="supervisorLabels">Número telefónico:</p>
              <input class="supervisorInput supervisorNumber" type="number" id="supervisorNumber"
                name="supervisorNumber" formControlName="supervisorNumber" required
                [class.is-invalid]="form.controls['supervisorNumber'].invalid && form.controls['supervisorNumber'].touched"
                [disabled]="!student" [ngClass]="{ 'disabled-input': !student }"/>
              <div class="invalid-feedback"
                *ngIf="form.controls['supervisorNumber'].invalid && form.controls['supervisorNumber'].touched">Número requerido.</div>
            </div>
            
          </div>
        </div>
      </div>

      <div class="descriptionSection" *ngIf="currentStep === 2">
        <div class="descriptionTopSide">
          <p class="sectionTitle descriptionTitle">Describe tu experiencia:</p>
          <div [ngClass]="{ 'disabled-input': !student }" [disabled]="!student">
            <angular-editor
            formControlName="htmlContent"
            id="htmlContent"
            [config]="config">
          </angular-editor>
          </div>
        <div class="invalid-feedback"
          *ngIf="form.controls['htmlContent'].invalid && form.controls['htmlContent'].touched">Descripción requerida.</div>        
        </div>
        <div class="descriptionDownSide">
          <p class="sectionTitle descriptionTitle">Sube tus documentos:</p>
          <div  [disabled]="!student" [ngClass]="{ 'disabled-input': !student }" class="custom-dropzone" ngx-dropzone (change)="onSelect($event)">
            <ngx-dropzone-label  [disabled]="!student" [ngClass]="{ 'disabled-input': !student }">
              <div>
                <h2 class="dropzonePlaceholder">Arrastra tus archivos aqui o selecciona un archivo</h2>
              </div>
            </ngx-dropzone-label>
            <ngx-dropzone-preview
              class="dropzoneImagePreview"
              ngProjectAs="ngx-dropzone-preview"
              *ngFor="let f of files"
              [file]="f"
              [removable]="true"
              (removed)="onRemove(f)">
              <ngx-dropzone-label class="dropzoneLabel">{{ f.name }}</ngx-dropzone-label>
            </ngx-dropzone-preview>
          </div>
        </div>
      </div>
      <div class="recommendationSection" *ngIf="currentStep === 3 && !student">
        <div class="recommendationContainer">
          <div>
            <p class="sectionTitle descriptionTitle">Deja tu recomendación:</p>
            <textarea class="recommendation" id="" cols="30" rows="10"></textarea>
          </div>
          <div>
            <input class="approveInput" id="approve" type="checkbox" />
            <label for="approve">Aprueba esta experiencia</label>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-footer customFooter">
      <div class="requirementMessageContainer" *ngIf="currentStep === 1 && !student">
        <p class="requirementMessage" (click)="nextStep()">Por favor, revisa toda la información de la experiencia.</p>
      </div>
      <div class="buttonWrapper" *ngIf="currentStep === 1 && student">
        <button class="nextButton" (click)="nextStep()">Siguiente</button>
      </div>
      <div class="buttonWrapper" *ngIf="currentStep === 1 && !student">
        <button class="nextButton" [ngClass]="{ 'approvalButtons': !student }" (click)="nextStep()">
          Ver soportes
        </button>
      </div>
      <div class="buttonWrapper" *ngIf="currentStep === 2 && student">
        <button class="nextButton" (click)="previousStep()">Atrás</button>
        <button type="submit" class="nextButton" (click)="onSubmit()">Finalizar</button>
      </div>
      <div class="buttonWrapper" *ngIf="currentStep === 2 && !student">
        <button class="nextButton" [ngClass]="{ 'approvalButtons': !student }" (click)="previousStep()">Volver</button>
        <button class="nextButton" [ngClass]="{ 'approvalButtons': !student }" (click)="nextStep()">
          Dar recomendación
        </button>
      </div>
      <div class="buttonWrapper" *ngIf="currentStep === 3 && !student">
        <button class="nextButton" [ngClass]="{ 'approvalButtons': !student }" (click)="previousStep()">Volver</button>
        <button type="submit" class="nextButton" [ngClass]="{ 'approvalButtons': !student }" (click)="onSubmit()">
          Finalizar revisión
        </button>
      </div>
    </div>
  </form>
</ng-template>
