import { Component, Input, OnInit } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: 'app-blog-card',
  templateUrl: './blog-card.component.html',
  styleUrls: ['./blog-card.component.scss']
})
export class BlogCardComponent implements OnInit {

  /** Variable that will hold the blog data */
  @Input() blog: any;

  constructor(private cookieService: CookieService) { }

  ngOnInit(): void {
    this.cookieService.delete('blogSlug');
  }

  saveBlogSlug() {
    // 
    this.cookieService.set('blogSlug', JSON.stringify(this.blog.slug));
  }

}
