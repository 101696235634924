import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso9',
  templateUrl: './paso9.component.html',
  styleUrls: ['./paso9.component.scss']
})
export class Paso9Component implements OnInit {
  @Output() notificacionTab12 = new EventEmitter<string>();
  public posElement:number;
  public preguntas_seccion12:any;
  public dataFrm12:any;
  public dataFrm12Copy:any;
  public userData: any;
  readonly getTestPaso12 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest12 = 'api/get_internalizacionUser/';
  pasoActivo: any;
  constructor(private apiService:ApiService,) { 
    this.userData = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.userData) {
      this.apiService.get({ api: this.getTestPaso12 + this.userData._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            
            let data_test= JSON.parse(response.test[0]);
            this.dataFrm12 = response;
            this.pasoActivo= response.hojaTest;
            this.dataFrm12.test = JSON.parse(this.dataFrm12.test);
            this.preguntas_seccion12= data_test.preguntas[11];
             
            for(let item of this.preguntas_seccion12.preguntas[0].opciones){
               
              if(item.seleccionada){
                if(item.titulo == 'Básico - A1'){
                    this.posElement=0;
                }
                if(item.titulo == 'Intermedio - B1'){
                  this.posElement=1;
                }
                if(item.titulo == 'Avanzado - C1'){
                  this.posElement=2;
                }
                if(item.titulo == 'Básico - A2'){
                  this.posElement=3;
                }
                if(item.titulo == 'Intermedio - B2'){
                  this.posElement=4;
                }
                if(item.titulo == 'Avanzado - C2'){
                  this.posElement=5;
                }
              }
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    } 
  }

  ngOnInit(): void {
  }
  verTest(){
    window.open('http://localhost:4200/web/english-test', '_blank');
  }
  changeColor(event, pos){
    this.posElement=pos;
    for(let preg of this.preguntas_seccion12.preguntas[0].opciones){
      preg.seleccionada=false
    }
     
    this.preguntas_seccion12.preguntas[0].opciones[pos].seleccionada=true;
    //let buttonColor = document.getElementById(event.target.id);
   /* if(buttonColor.style.backgroundColor != "rgb(74, 80, 147)"){
      buttonColor.style.backgroundColor = "#4a5093";
    }
    else{
      buttonColor.style.backgroundColor = "";
    }*/
   
  }
  public volverTab11(){
    this.notificacionTab12.emit("volver")

   }
   public continuarTab13(){
    let paso = this.pasoActivo
    if(this.pasoActivo < 12){
      paso = this.pasoActivo = 12;
    }
    this.dataFrm12.hojaTest= paso
    for(let item of this.preguntas_seccion12.preguntas[0].opciones){
       
      if(item.seleccionada){
        if(item.titulo == 'Básico - A1'){
            this.preguntas_seccion12.habilidades[0].puntaje=1;
            this.preguntas_seccion12.habilidades[1].puntaje=1;
        }
        if(item.titulo == 'Básico - A2'){
          this.preguntas_seccion12.habilidades[0].puntaje=2;
          this.preguntas_seccion12.habilidades[1].puntaje=2;
        }
        if(item.titulo == 'Intermedio - B1'){
          this.preguntas_seccion12.habilidades[0].puntaje=3;
          this.preguntas_seccion12.habilidades[1].puntaje=3;
        }
        if(item.titulo == 'Intermedio - B2'){
          this.preguntas_seccion12.habilidades[0].puntaje=4;
          this.preguntas_seccion12.habilidades[1].puntaje=4;
        }
        if(item.titulo == 'Avanzado - C1'){
          this.preguntas_seccion12.habilidades[0].puntaje=5;
          this.preguntas_seccion12.habilidades[1].puntaje=5;
        }
        if(item.titulo == 'Avanzado - C2'){
          this.preguntas_seccion12.habilidades[0].puntaje=5;
          this.preguntas_seccion12.habilidades[1].puntaje=5;
        }
      }
    }
    this.dataFrm12.test.preguntas[11] = this.preguntas_seccion12;
    this.dataFrm12.test = JSON.stringify(this.dataFrm12.test );
    this.actualizarPaso11();
  }
  actualizarPaso11(){
    let data = this.dataFrm12;
    this.apiService
    .put({ api: this.updateFrmTest12 + this.dataFrm12._id, data })
    .subscribe(
      (response) => {
         
        this.notificacionTab12.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
}
