import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatExpansionModule } from '@angular/material/expansion';
import { NgxPaginationModule } from 'ngx-pagination';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AgmCoreModule } from '@agm/core';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CreditCardDirectivesModule } from 'angular-cc-library';
import { NgImageSliderModule } from 'ng-image-slider';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import {
  CommonModule,
  DatePipe,
  CurrencyPipe,
  DecimalPipe,
} from '@angular/common';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { HomeComponent } from './home/home.component';
import { SearchBarComponent } from './home/search-bar/search-bar.component';
import { ServiceOverviewComponent } from './home/service-overview/service-overview.component';
import { AuthComponent } from './auth/auth.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatDialogModule } from '@angular/material/dialog';
import { NgxSliderModule } from '@m0t0r/ngx-slider';
import { MatRadioModule } from '@angular/material/radio';
import { ProgramCardComponent } from './program/program-card/program-card.component';
import { RouterModule } from '@angular/router';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ProgramDetailComponent } from './program/program-detail/program-detail.component';
import { AccordionModule } from 'ngx-bootstrap/accordion';
import { MatIconModule } from '@angular/material/icon';
import { FilterCityPipe } from './pipes/filter-city.pipe';
import { FilterUniversityPipe } from './pipes/filter-university.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { ApplicationComponent } from './application/application.component';
import { ActiveResourcePipe } from './pipes/active-resource.pipe';
import { ElegibilityComponent } from './application/steps/elegibility/elegibility.component';
import { PlatformPaymentComponent } from './application/steps/platform-payment/platform-payment.component';
import { ApplyComponent } from './application/steps/apply/apply.component';
import { BeAcceptedComponent } from './application/steps/be-accepted/be-accepted.component';
import { VisaComponent } from './application/steps/visa/visa.component';
import { TravelComponent } from './application/steps/travel/travel.component';
import { TuitionComponent } from './application/steps/tuition/tuition.component';
import { EnrolmentComponent } from './application/steps/enrolment/enrolment.component';
import { SubstepCardComponent } from './application/utils/substep-card/substep-card.component';
import { VisaFormComponent } from './visa-form/visa-form.component';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { MatTooltipModule } from '@angular/material/tooltip';
import { BlogComponent } from './blog/blog.component';
import { BlogCardComponent } from './blog/blog-card/blog-card.component';
import { HashtagPipe } from './pipes/hashtag.pipe';
import { ProfileComponent } from './profile/profile.component';
import { BlogDetailComponent } from './blog/blog-detail/blog-detail.component';
import { PersonalInfoComponent } from './profile/personal-info/personal-info.component';
import { AcademicInterestsComponent } from './profile/academic-interests/academic-interests.component';
import { TravelHistoryComponent } from './profile/travel-history/travel-history.component';
import { LegalRepresentativeComponent } from './profile/legal-representative/legal-representative.component';
import { MyBudgetComponent } from './profile/my-budget/my-budget.component';
import { MyLanguagesComponent } from './profile/my-languages/my-languages.component';
import { FavouritesComponent } from './favourites/favourites.component';
import { Ng2SmartTableModule } from 'ng2-smart-table';
import { PasswordRecoveryComponent } from './auth/password-recovery/password-recovery.component';
import { ForgotPasswordComponent } from './auth/forgot-password/forgot-password.component';
import { AcademicHistoryComponent } from './profile/academic-history/academic-history.component';
import { ItemCulturalComponent } from './item-cultural/item-cultural.component';
import { CulturalMatchesComponent } from './cultural-matches/cultural-matches.component';
import { CulturalMatchCardComponent } from './cultural-matches/cultural-match-card/cultural-match-card.component';
import { MainMatchComponent } from './item-cultural/main-match/main-match.component';
import { VisaThermometerComponent } from './visa-thermometer/visa-thermometer.component';
import { ShoppingCartComponent } from './shopping-cart/shopping-cart.component';
import { MarketplaceComponent } from './marketplace/marketplace.component';
import { TranslationServiceCardComponent } from './marketplace/translation-service-card/translation-service-card.component';
import { VisaServiceCardComponent } from './marketplace/visa-service-card/visa-service-card.component';
import { TravelServiceCardComponent } from './marketplace/travel-service-card/travel-service-card.component';
import { CityComponent } from './city/city.component';
import { TranslationServicesShoppingCardComponent } from './shopping-cart/translation-services-shopping-card/translation-services-shopping-card.component';
import { MedicalServicesShoppingCardComponent } from './shopping-cart/medical-services-shopping-card/medical-services-shopping-card.component';
import { VisaServicesShoppingCardComponent } from './shopping-cart/visa-services-shopping-card/visa-services-shopping-card.component';
import { UniversityComponent } from './university/university.component';
import { InvoiceComponent } from './shopping-cart/invoice/invoice.component';
import { PurchaseHistoryComponent } from './purchase-history/purchase-history.component';
import { PaymentInfoComponent } from './shopping-cart/payment-info/payment-info.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsConditionsComponent } from './terms-conditions/terms-conditions.component';
import { EllipsisPipe } from './pipes/ellipsis.pipe';
import { AccreditationPipe } from './pipes/accreditation.pipe';
import { HttpClient } from '@angular/common/http';
import { translateBrowserLoaderFactory } from './shared/loader/translate-browser.loader';
import { BrowserModule } from '@angular/platform-browser';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { HttpClientModule} from '@angular/common/http';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { TransferState } from '@angular/platform-browser';
import { LoginComponent } from './login/login.component';
import { MyApplicationsComponent } from './my-applications/my-applications.component';
import { PaymentInfoPseComponent } from './shopping-cart/payment-info-pse/payment-info-pse.component';
import { ApplicationCardComponent } from './my-applications/application-card/application-card.component';
import { LanguageTestCardComponent } from './marketplace/language-test-card/language-test-card.component';
import { LanguageTestShoppingCartComponent } from './shopping-cart/language-test-shopping-cart/language-test-shopping-cart.component';
import { ScholarshipCardComponent } from './marketplace/scholarship-card/scholarship-card.component';
import { ServiceDetailModalComponent } from './marketplace/service-detail-modal/service-detail-modal.component';
import { NotFoundComponent } from './not-found/not-found.component';
import { AlertsComponent } from './alerts/alerts.component';
import { AlertCardComponent } from './alerts/alert-card/alert-card.component';
import { AlertModalComponent } from './alerts/alert-modal/alert-modal.component';
import { CreateAlertComponent } from './alerts/create-alert/create-alert.component';
import { TicketCardComponent } from './alerts/ticket-card/ticket-card.component';
import { TicketDetailComponent } from './alerts/ticket-detail/ticket-detail.component';
import { ApplicationTermsConditionsComponent } from './application-terms-conditions/application-terms-conditions.component';
import { OneTimeFeeComponent } from './one-time-fee/one-time-fee.component';
import { ContactFormComponent } from './visa-thermometer/contact-form/contact-form.component';
import { ImageSliderModalComponent } from './shared/image-slider-modal/image-slider-modal.component';
import { AcademicLevelExplanationComponent } from './profile/academic-interests/academic-level-explanation/academic-level-explanation.component';
import { WorkExperienceComponent } from './profile/work-experience/work-experience.component';
import { ApplicationFeePaymentComponent } from './application-fee-payment/application-fee-payment.component';
import { SetPhoneComponent } from './shared/set-phone/set-phone.component';
import { EmailConfirmationComponent } from './email-confirmation/email-confirmation.component';
import { RecruitmentModalComponent } from './shared/recruitment-modal/recruitment-modal.component';
import { RegisterStudentComponent } from './register-student/register-student.component';
import { PseResponseComponent } from './pse-response/pse-response.component';
import { DeleteApplicationComponent } from './my-applications/delete-application/delete-application.component';
import { BlockedModalComponent } from './shared/blocked-modal/blocked-modal.component';
import { SignUpSuccessComponent } from './auth/sign-up-success/sign-up-success.component';
import { NotVerifiedComponent } from './auth/not-verified/not-verified.component';
import { ViewWebinarsComponent } from './view-webinars/view-webinars.component';
import { CardViewWebinarComponent } from './view-webinars/card-view-webinar/card-view-webinar.component';
import { MdlJoinWebinarComponent } from './view-webinars/mdl-join-webinar/mdl-join-webinar.component';
import { ChangeHoursPipe } from './pipes/change-hours.pipe';
import { ViewApplyComponent } from './shared/view-apply/view-apply.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { TestSectionComponent } from './shared/test-section/test-section.component';
import { UbicationComponent } from './university/ubication/ubication.component';
import { MoreInformationComponent } from './university/more-information/more-information.component';
import { ListVideosQaComponent } from './university/list-videos-qa/list-videos-qa.component';
import { VideosPhotosComponent } from './university/videos-photos/videos-photos.component';
import { FormContactComponent } from './university/form-contact/form-contact.component';
import { ServicePrincipalComponent } from './home/service-principal/service-principal.component';
import { StudentsComponent } from './students/students.component';
import { CardStudentComponent } from './students/card-student/card-student.component';
import { StudentDetailComponent } from './students/student-detail/student-detail.component';
import { EnglishTestComponent } from './english-test/english-test.component';
import { FormStudentComponent } from './students/form-student/form-student.component';
import { UnlinkAccountComponent } from './unlink-account/unlink-account.component';
import { EnglishFormComponent } from './english-test/english-form/english-form.component';
import { ViewAgenciesComponent } from './shared/view-agencies/view-agencies.component';
import { SimilarProgramsComponent } from './program/similar-programs/similar-programs.component';
import { ProgramCardUniversitiesComponent } from './program/program-card-universities/program-card-universities.component';
import { ProgramComponent } from './program/program.component';
import { ViewTransactionComponent } from './shared/view-transaction/view-transaction.component';
import { TermsAndConditionsComponent } from './terms-and-conditions/terms-and-conditions.component';
import { PersonalDataPolicyComponent } from './personal-data-policy/personal-data-policy.component';
import { ManifestComponent } from './manifest/manifest.component';
import { AddFavouriteComponent } from './favourites/add-favourite/add-favourite.component';
import { FreeGuidesDetailComponent } from './free-guides/free-guides-detail/free-guides-detail.component';
import { FreeGuidesComponent } from './free-guides/free-guides.component';
import { FreeGuidesResourcesComponent } from './free-guides/free-guides-resources/free-guides-resources.component';
import { LeaderTestComponent } from './leader-test/leader-test.component';
import { Paso1Component } from './leader-test/wizard/paso1/paso1.component';
import { Paso2Component } from './leader-test/wizard/paso2/paso2.component';
import { Paso3Component } from './leader-test/wizard/paso3/paso3.component';
import { Paso4Component } from './leader-test/wizard/paso4/paso4.component';
import { Paso5Component } from './leader-test/wizard/paso5/paso5.component';
import { Paso6Component } from './leader-test/wizard/paso6/paso6.component';
import { Paso7Component } from './leader-test/wizard/paso7/paso7.component';
import { Paso8Component } from './leader-test/wizard/paso8/paso8.component';
import { Paso9Component } from './leader-test/wizard/paso9/paso9.component';
import { Paso10Component } from './leader-test/wizard/paso10/paso10.component';
import { Paso11Component } from './leader-test/wizard/paso11/paso11.component';
import { Paso12Component } from './leader-test/wizard/paso12/paso12.component';
import { Paso13Component } from './leader-test/wizard/paso13/paso13.component';
import { Paso14Component } from './leader-test/wizard/paso14/paso14.component';
import { Paso15Component } from './leader-test/wizard/paso15/paso15.component';
import { Paso16Component } from './leader-test/wizard/paso16/paso16.component';
import { Paso17Component } from './leader-test/wizard/paso17/paso17.component';
import { Paso18Component } from './leader-test/wizard/paso18/paso18.component';
import { ValidateCodeComponent } from './leader-test/validate-code/validate-code.component';
import { DashboardSchoolComponent } from './dashboard-school/dashboard-school.component';
import { RadarChartComponentComponent } from './leader-test/radarChart/radar-chart-component/radar-chart-component.component';
import { CarouselSimilarProgramsComponent } from './program/carousel-similar-programs/carousel-similar-programs.component';
import { ResumenTestUserComponent } from './dashboard-school/resumen-test-user/resumen-test-user.component';
import { PublicHomeComponent } from './home/public-home/public-home.component'
import { MatSidenavModule } from '@angular/material/sidenav';
import { PreTestComponent } from './leader-test/pre-test/pre-test.component';
import { MoreInfoComponent } from './home/public-home/more-info/more-info.component';
import { CurriculumComponent } from './curriculum/curriculum/curriculum.component';
import { LetterComponent } from './curriculum/letter/letter.component';
import { EditComponent } from './curriculum/curriculum/edit/edit.component';
import { InfoSugerenciasComponent } from './leader-test/wizard/paso18/info-sugerencias/info-sugerencias.component';
import { FormAmbassadorComponent } from './shared/footer/form-ambassador/form-ambassador.component';
import { SwiperModule } from 'swiper/angular';
import { WishlistComponent } from './favourites/wishlist/wishlist.component';
import { ProgramWishlistCardComponent } from './favourites/wishlist/program-wishlist-card/program-wishlist-card.component';

import { ChartsModule } from 'ng2-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { LetterTipsComponent } from './curriculum/letter/letter-tips/letter-tips.component';
import { LetterPreviewComponent } from './curriculum/letter/letter-preview/letter-preview.component';
import { LetterReadyComponent } from './curriculum/letter/letter-ready/letter-ready.component';
import { SchoolStudentsListComponent } from './school-students-list/school-students-list.component';

import { MatTableModule } from '@angular/material/table';
import { MatButtonModule } from '@angular/material/button';
import { DragScrollModule } from 'ngx-drag-scroll';
import { MatInputModule } from '@angular/material/input';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatPaginatorModule } from '@angular/material/paginator';
import { StudentResumeComponent } from './school-students-list/student-resume/student-resume.component';
import { StudentDocumentsComponent } from './school-students-list/student-documents/student-documents.component';
import { ExperienceModalComponent } from './experienceModalComponent/experienceModalComponent';
import {ExperienceComponent} from '../public/experiences/experience.component';
import { ExperienceCardsComponent } from './experiencesCards/experiencesCards.component';
import { ExperienceApprovalComponent } from './experienceApprovalComponent/experienceApproval.component';
// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

@NgModule({
  declarations: [
    HomeComponent,
    SearchBarComponent,
    ServiceOverviewComponent,
    AuthComponent,
    SetPhoneComponent,
    ProgramComponent,
    ProgramCardComponent,
    FilterCityPipe,
    FilterUniversityPipe,
    ProgramDetailComponent,
    SafePipe,
    ApplicationComponent,
    ActiveResourcePipe,
    ElegibilityComponent,
    PlatformPaymentComponent,
    ApplyComponent,
    BeAcceptedComponent,
    VisaComponent,
    TravelComponent,
    TuitionComponent,
    EnrolmentComponent,
    SubstepCardComponent,
    VisaFormComponent,
    BlogComponent,
    BlogCardComponent,
    HashtagPipe,
    ProfileComponent,
    BlogDetailComponent,
    PersonalInfoComponent,
    AcademicInterestsComponent,
    TravelHistoryComponent,
    LegalRepresentativeComponent,
    MyBudgetComponent,
    MyLanguagesComponent,
    FavouritesComponent,
    PasswordRecoveryComponent,
    ForgotPasswordComponent,
    AcademicHistoryComponent,
    ItemCulturalComponent,
    CulturalMatchesComponent,
    CulturalMatchCardComponent,
    MainMatchComponent,
    VisaThermometerComponent,
    ShoppingCartComponent,
    MarketplaceComponent,
    TranslationServiceCardComponent,
    VisaServiceCardComponent,
    TravelServiceCardComponent,
    CityComponent,
    TranslationServicesShoppingCardComponent,
    MedicalServicesShoppingCardComponent,
    VisaServicesShoppingCardComponent,
    InvoiceComponent,
    PurchaseHistoryComponent,
    UniversityComponent,
    InvoiceComponent,
    PaymentInfoComponent,
    PrivacyPolicyComponent,
    TermsConditionsComponent,
    EllipsisPipe,
    AccreditationPipe,
    LoginComponent,
    MyApplicationsComponent,
    PaymentInfoPseComponent,
    ApplicationCardComponent,
    LanguageTestCardComponent,
    LanguageTestShoppingCartComponent,
    ScholarshipCardComponent,
    ServiceDetailModalComponent,
    NotFoundComponent,
    AlertsComponent,
    AlertCardComponent,
    AlertModalComponent,
    CreateAlertComponent,
    TicketCardComponent,
    TicketDetailComponent,
    ApplicationTermsConditionsComponent,
    OneTimeFeeComponent,
    ContactFormComponent,
    ImageSliderModalComponent,
    AcademicLevelExplanationComponent,
    WorkExperienceComponent,
    ApplicationFeePaymentComponent,
    RecruitmentModalComponent,
    RegisterStudentComponent,
    EmailConfirmationComponent,
    RecruitmentModalComponent,
    PseResponseComponent,
    DeleteApplicationComponent,
    BlockedModalComponent,
    SignUpSuccessComponent,
    NotVerifiedComponent,
    ViewWebinarsComponent,
    CardViewWebinarComponent,
    MdlJoinWebinarComponent,
    ChangeHoursPipe,
    ViewApplyComponent,
    TruncatePipe,
    TestSectionComponent,
    UbicationComponent,
    MoreInformationComponent,
    ListVideosQaComponent,
    VideosPhotosComponent,
    FormContactComponent,
    ServicePrincipalComponent,
    StudentsComponent,
    CardStudentComponent,
    StudentDetailComponent,
    EnglishTestComponent,
    FormStudentComponent,
    UnlinkAccountComponent,
    EnglishFormComponent,
    ViewAgenciesComponent,
    SimilarProgramsComponent,
    ProgramCardUniversitiesComponent,
    ViewTransactionComponent,
    FreeGuidesComponent,
    FreeGuidesResourcesComponent,
    FreeGuidesDetailComponent,
    TermsAndConditionsComponent,
    PersonalDataPolicyComponent,
    ManifestComponent,
    AddFavouriteComponent,
    LeaderTestComponent,
    Paso1Component,
    Paso2Component,
    Paso3Component,
    Paso4Component,
    Paso5Component,
    Paso6Component,
    Paso7Component,
    Paso8Component,
    Paso9Component,
    Paso10Component,
    Paso11Component,
    Paso12Component,
    Paso13Component,
    Paso14Component,
    Paso15Component,
    Paso16Component,
    Paso17Component,
    Paso18Component,
    ValidateCodeComponent,
    DashboardSchoolComponent,
    RadarChartComponentComponent,
    CarouselSimilarProgramsComponent,
    ResumenTestUserComponent,
    PublicHomeComponent,
    PreTestComponent,
    MoreInfoComponent,
    CurriculumComponent,
    LetterComponent,
    EditComponent,
    InfoSugerenciasComponent,
    FormAmbassadorComponent,
    WishlistComponent,
    ProgramWishlistCardComponent,
    LetterTipsComponent,
    LetterPreviewComponent,
    LetterReadyComponent,
    SchoolStudentsListComponent,
    StudentResumeComponent,
    StudentDocumentsComponent,
    ExperienceComponent,
    ExperienceModalComponent,
    ExperienceCardsComponent,
    ExperienceApprovalComponent
  ],
  entryComponents: [AuthComponent],
  imports: [
    CommonModule,
    FormsModule,
    IvyCarouselModule,
    ReactiveFormsModule,
    RouterModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    NgxSliderModule,
    MatRadioModule,
    NgSelectModule,
    MatSidenavModule,
    MatSnackBarModule,
    AccordionModule,
    MatIconModule,
    MatTooltipModule,
    NgMultiSelectDropDownModule.forRoot(),
    Ng2SmartTableModule,
    MatProgressBarModule,
    MatExpansionModule,
    NgbModule,
    BsDropdownModule.forRoot(),
    BrowserModule,
    BsDatepickerModule.forRoot(),
    HttpClientModule,
    NgxDropzoneModule,
    SwiperModule,
    CreditCardDirectivesModule,
    MatCheckboxModule,
    MatSelectModule,
    DragScrollModule,
    MatPaginatorModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyCXQykrbcRcIeHivanZ9l9C4reIpFUzo9Q',
    }),
    NgImageSliderModule,
    NgxPaginationModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
    }),
    AngularEditorModule,
    ChartsModule,
    MatButtonModule,
    MatTableModule,
    MatInputModule,
    MatAutocompleteModule,
  ],
  providers: [DatePipe, CurrencyPipe, DecimalPipe],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PublicModule { }
