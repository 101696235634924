import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { NavigationService } from '../../services/navigation.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-pre-test',
  templateUrl: './pre-test.component.html',
  styleUrls: ['./pre-test.component.scss']
})
export class PreTestComponent implements OnInit {
  /**Variable that holds user data*/
  user = JSON.parse(localStorage.getItem('user'));

  ////////////////// Apis/////////////////////////////
  /**Api get inter test by user id */
  API_GET_TEST_BY_USER = 'api/test_internalizacionUser/';

  /**Api get visa test by user id */
  API_GET_VISA_BY_USER = 'api/respuesta-termometro/estudiante/';

  /**Api get match test by user id */
  API_GET_MATCH_BY_USER = 'api/respuesta-match/estudiante/';
  ///////////////////////////////////////////////////


  /**Variable that holds user test */
  userTestInt: any;
  userTestVisa: any;
  userTestMatch: any;

  /**Variable for test status */
  statusImages = {
    start: {
      src: '/assets/pre-test/status-start.png',
      alt: 'start'
    },
    pending: {
      src: '/assets/pre-test/status-pending.png',
      alt: 'pending'
    },
    done: {
      src: '/assets/pre-test/status-done.png',
      alt: 'done'
    },
    back: {
      src: '/assets/pre-test/status-back.png',
      alt: 'back'
    }
  };

  /**Variable that hold tests status */
  testIntStatus: any;
  testEnglish: any;
  testMatchStatus: any;
  testVisaStatus: any;

  constructor(
    private apiService: ApiService,
    public navigationService: NavigationService,
    private ngxSpinnerService: NgxSpinnerService,
  ) {
    
  }

  ngOnInit(): void {
    //  
    this.ngxSpinnerService.show();
    this.checkUserTestInternationalization({idUser: this.user._id});
    this.checkUserVisaTest({idUser: this.user._id});
    this.checkUserMatchTest({idUser: this.user._id});
  } 

  /**Method to check if logged user has done an internationalization test*/
  checkUserTestInternationalization({idUser}){
    this.apiService.get({api: this.API_GET_TEST_BY_USER + idUser}).subscribe(
      (response) => {
        if (response){
          if (response.hojaTest < 18){
            this.testIntStatus = this.statusImages.pending;
          }else{
            this.testIntStatus = this.statusImages.done;
          }
        }else{
          this.testIntStatus = this.statusImages.pending;
        }
        this.ngxSpinnerService.hide();
      }
    );
  }

  checkUserVisaTest({idUser}){
    this.apiService.get({api: this.API_GET_VISA_BY_USER + idUser}).subscribe(
      (response) => {
        if (response){
          this.userTestVisa = response ? response : null;
          if (this.userTestVisa == null || this.userTestVisa.length<1){
            this.testVisaStatus = this.statusImages.pending;
          }else{
            this.testVisaStatus = this.statusImages.done;
          }
        }else{
          this.testVisaStatus = this.statusImages.pending;
        }
        this.ngxSpinnerService.hide();
      }
    );
  }

  checkUserMatchTest({idUser}){
    this.apiService.get({api: this.API_GET_MATCH_BY_USER + idUser}).subscribe(
      (response) => {
        if (response){
           
          this.userTestMatch = response ? response : null;
          if (this.userTestMatch == null || this.userTestMatch.length<1){
            this.testMatchStatus = this.statusImages.pending;
          }else{
            this.testMatchStatus = this.statusImages.done;
          }
        }else{
          this.testMatchStatus = this.statusImages.pending;
        }
        this.ngxSpinnerService.hide();
      }
    );
  }

  /**Method to change image depending about status test */
  onImage(test, status) {
    if (test == 'match'){
      switch (status) {
        case 'done':
          this.testMatchStatus = this.statusImages.back;
          break;

        case 'pending':
          this.testMatchStatus = this.statusImages.start;
          break;
      }
    }else if (test == 'visa'){
      switch (status) {
        case 'done':
          this.testVisaStatus = this.statusImages.back;
          break;

        case 'pending':
          this.testVisaStatus = this.statusImages.start;
          break;
      }
    }else if (test == 'inter'){
      switch (status) {
        case 'done':
          this.testIntStatus = this.statusImages.back;
          break;

        case 'pending':
          this.testIntStatus = this.statusImages.start;
          break;
      }
    }

  }

  /** */
  goToTestInt(){
    this.navigationService.navigateTo({ path: '/leaderTest' });
  }

  goToTestMatch(){
    this.navigationService.navigateTo({ path: '/cultural-item' });
  }

  goToTestVisa(){
    this.navigationService.navigateTo({ path: '/visa-thermometer' });
  }
}
