<form [formGroup]="createForm" (ngSubmit)="onSubmit({event: $event.submitter})">
    <div  class="container-body information">
        <div class="container-representative">
            <label>Contacta un representante<br />de la institución</label>
            <!-- <img
              (click)="showDetails({ item: 'form' })"
              *ngIf="scrWidth < 951 && !isInformation"
              src="assets/utils/detail-arrow.png"
              [class.hide-details]="displayForm"
              alt="dropdown arrow"
            /> -->
            <p>
              <span
                >¿Quieres ser embajador? </span
              >Déjanos tus datos y un representante de u360 te contactará para agendar
              una cita siempre que cumplas los requisitos.
            </p>
        </div>
    
        <div class="container-form">
          <div class="container-form-label">
            <label for="name">Nombre</label>
            <input
              [ngClass]="{ 'red-border': validField({ field: 'nombre' }) }"
              id="name"
              formControlName="nombre"
              class="form-control"
              type="text"
              placeholder="Escribe tu nombre"
            />
            <span *ngIf="validField({ field: 'nombre' })" class="invalid-warning">
              Campo requerido!
            </span>
          </div>
          <div class="container-form-label">
            <label for="lastname">Apellido</label>
            <input
              [ngClass]="{ 'red-border': validField({ field: 'apellido' }) }"
              id="lastname"
              formControlName="apellido"
              class="form-control"
              type="text"
              placeholder="Escribe tu apellido"
            />
            <span *ngIf="validField({ field: 'apellido' })" class="invalid-warning">
              Campo requerido!
            </span>
          </div>
          <div class="container-form-label">
            <label for="email">Correo</label>
            <input
              [ngClass]="{ 'red-border': validField({ field: 'correo' }) }"
              id="email"
              formControlName="correo"
              class="form-control"
              type="email"
              placeholder="Escribe tu correo eléctronico"
            />
            <span *ngIf="validField({ field: 'correo' })" class="invalid-warning">
              Campo requerido!
            </span>
          </div>
          
          <div class="container-form-label">
            <label for="phone">Teléfono</label>
            <input
              [ngClass]="{ 'red-border': validField({ field: 'telefono' }) }"
              id="phone"
              formControlName="telefono"
              class="form-control"
              min="0"
              type="number"
              [placeholder]="'Sign-up.telephone' | translate"
            />
            <span *ngIf="validField({ field: 'telefono' })" class="invalid-warning">
              Campo requerido!
            </span>
          </div>

          <div class="container-form-label">
            <label for="question">Preguntas</label>
            <textarea
              [ngClass]="{ 'red-border': validField({ field: 'pregunta' }) }"
              formControlName="pregunta"
              id="question"
              class="form-control"
              type="text"
              placeholder="Por favor escribe tu pregunta principal aquí"
            ></textarea>
            <span *ngIf="validField({ field: 'pregunta' })" class="invalid-warning">
              Campo requerido!
            </span>
          </div>
        </div>
    </div>
    <div class="btn-form">
        <button type="submit">Contactar</button>
      </div>
</form>
