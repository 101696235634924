<div class="alert-detail-container">
  <div class="close">
    <mat-icon (click)="selfClose()">close</mat-icon>
  </div>
  <!--If the service is a Visa service display this information -->
  <div>
    <div class="title">
      {{'Alerts.alert-of-program' | translate}} <br>
      {{data.application.programa.nombre}}
    </div>
    <p class="overview">
      {{data.message}}
    </p>
    <div class="action">
      <a [href]="'application/' + data.application._id">{{'Alerts.go-to-application' | translate}}</a>
    </div>
  </div>
