import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso11',
  templateUrl: './paso11.component.html',
  styleUrls: ['./paso11.component.scss']
})
export class Paso11Component implements OnInit {
  @Output() notificacionTab16 = new EventEmitter<string>();
  public preguntas_seccion16:any;
  public dataFrm16:any;
  public dataFrm16Copy:any;
  public paises=[];
  public universities = [];
  public otro: any;
  userDataUser11: any;
  readonly getTestPaso12 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest12 = 'api/get_internalizacionUser/';
  pasoActivo: any;
  constructor(private apiService:ApiService,) { 
    this.userDataUser11 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.userDataUser11) {
      this.apiService.get({ api: this.getTestPaso12 + this.userDataUser11._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //  
            let res = response;
            let data_test
            if(res.test[0].preguntas){
              data_test=res.test[0];
            }else{
              data_test=JSON.parse(res.test[0]);
            }
            this.dataFrm16 = response;
            this.pasoActivo= response.hojaTest;
            this.dataFrm16.test = JSON.parse(this.dataFrm16.test);
            this.preguntas_seccion16= data_test.preguntas[15];
            //  
            for(let pais of this.preguntas_seccion16.preguntas[0].lista){

                        for(let paises2 of this.paises){
                          //  
                          if(pais.pais == paises2.pais){
                            paises2.seleccionado=true;
                          }
                          let busqueda = this.paises.find(element => element.pais == pais.pais);
                          //  
                          if(!busqueda){
                            this.otro =pais.pais; 
                          }
                        }
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    } 
   }

  ngOnInit(): void {
    this.paises=[
      {pais: "Estados Unidos", seleccionado: false},
      {pais: "Canadá", seleccionado: false},
      {pais: "Reino Unido", seleccionado: false},
      {pais: "Francia", seleccionado: false},
      {pais: "Alemanía", seleccionado: false},
      {pais: "España", seleccionado: false},
    ]
  }
  ejecuta(data){
    const universities = [];
    universities.push({
      pais:data,
  });
  //  
  }
  changeColor(id){
    this.paises[id].seleccionado = !this.paises[id].seleccionado;   
  }
  public volverTab14(){
    this.notificacionTab16.emit("volver")

   }
   public continuarTab16(){
    let insert = []
    if(this.otro){
      insert.push({pais:this.otro})
    }
    for(let pais of this.paises){
      if(pais.seleccionado){
        insert.push({pais:pais.pais})
      }
    }
    this.universities = insert;
    let paso = this.pasoActivo
    if(this.pasoActivo < 17){
      paso = this.pasoActivo = 16;
    }
    //  
    this.dataFrm16.hojaTest= paso;
    this.preguntas_seccion16.preguntas[0].lista=this.universities;
    this.dataFrm16.test.preguntas[15] = this.preguntas_seccion16;
    //  
    this.dataFrm16.test = JSON.stringify(this.dataFrm16.test );
    //  
    this.actualizarPaso16();
  }
  public actualizarPaso16(){
    //  
    let data = this.dataFrm16;
    this.apiService
    .put({ api: this.updateFrmTest12 + this.dataFrm16._id, data })
    .subscribe(
      (response) => {
        // 
        this.notificacionTab16.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
}
