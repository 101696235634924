import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso12',
  templateUrl: './paso12.component.html',
  styleUrls: ['./paso12.component.scss']
})
export class Paso12Component implements OnInit {
  @Output() notificacionTab13 = new EventEmitter<string>();

  public preguntas_seccion13:any;
  public dataFrm13:any;
  public dataFrm13Copy:any;
  public preguntas: any[]=[];

  userDataUser13: any;
  readonly getTestPaso13 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest13 = 'api/get_internalizacionUser/';
  pasoActivo: any;
  public sumItems: number = 0;
  /*constructor() {
    this.dataFrm13 = JSON.parse(localStorage.getItem("formulario_test12"));
    this.dataFrm13Copy= this.dataFrm13;
    this.preguntas_seccion13= this.dataFrm13Copy.preguntas[12];
     
   }*/
   constructor(private apiService:ApiService,) { 
    this.userDataUser13 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.userDataUser13) {
      this.apiService.get({ api: this.getTestPaso13 + this.userDataUser13._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //  
            let res = response;
            let data_test
            if(res.test[0].preguntas){
              data_test=res.test[0];
            }else{
              data_test=JSON.parse(res.test[0]);
            }
            this.dataFrm13 = response;
            this.pasoActivo= response.hojaTest;
            this.dataFrm13.test = JSON.parse(this.dataFrm13.test);
            this.preguntas_seccion13= data_test.preguntas[12];
            //  
            let i = 0
            for(let item of this.preguntas_seccion13.preguntas){
              //  
              this.preguntas[i].opcionSeccionada= item.opcionSeccionada
              i++
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    } 
   }
  ngOnInit(): void {
    this.preguntas=[
      {pregunta:"Conozco cuales son mis métodos de aprendizaje convenientes, y los hago parte de mi rutina.", opcionSeccionada:0},
      {pregunta:"Cuento con la capacidad para analizar de forma precisa y profunda los datos o hechos disponibles; soy detallista, reviso de forma completa toda la información identificando sus relaciones, validez y utilidad.", opcionSeccionada:0},
      {pregunta:"¿Suelo solucionar los problemas con aportes originales y creativos de manera recurrente?.", opcionSeccionada:0},
      {pregunta:"He mantenido una constante actitud de búsqueda y exploración en los campos y áreas que me interesan.", opcionSeccionada:0},
      {pregunta:"He desarrollado nuevas habilidades y competencias a partir de la exploración con distintas fuentes de conocimiento.", opcionSeccionada:0},
      {pregunta:"¿Tengo la capacidad para organizar y establecer a futuro los recursos que necesito para lograr cumplir con mis metas?.", opcionSeccionada:0},
      {pregunta:"Tengo rutinas de estudio o aprendizaje definidas (Recurrentes en hora, lugar y tiempo suficiente dedicado)", opcionSeccionada:0},
    ]
  }
  public volverTab12(){
    this.notificacionTab13.emit("volver")

   }
   public continuarTab14(){
    let resetCalculo = this.preguntas_seccion13.habilidades
    for(let calculos of resetCalculo){
      calculos.puntaje=0;
    }
    this.preguntas_seccion13.habilidades= resetCalculo
    //  
    for(let preg of this.preguntas_seccion13.preguntas){
      let puntos;

      if (parseInt(preg.opcionSeccionada) > 0) {
        this.sumItems = this.sumItems + 1;
      }
      //  
      //  
      if(preg.invertida){
        //  
        if(preg.opcionSeccionada == '1'){
          puntos= '5'
        }
        if(preg.opcionSeccionada == '2'){
          puntos= '4'
        }
        if(preg.opcionSeccionada == '3'){
          puntos= '3'
        }
        if(preg.opcionSeccionada == '4'){
          puntos= '2'
        }
        if(preg.opcionSeccionada == '5'){
          puntos= '1'
        }
      }else{
        puntos = preg.opcionSeccionada
      }
      //  
      for(let afeccion of preg.afecciones){
          //  
         let index
         const found = this.preguntas_seccion13.habilidades.find(element => element.nombre === afeccion.habilidad);
        //  
         if(found){
          let resultadoOperacion = found.puntaje+parseFloat(puntos);
          found.puntaje = resultadoOperacion
         }
      }
      //  
    }
   
    let paso = this.pasoActivo
    if(this.pasoActivo < 13){
      paso = this.pasoActivo = 13;
    }
    this.dataFrm13.hojaTest= paso
    //  

    if (this.sumItems >= 7) {
      this.dataFrm13.test.preguntas[12] = this.preguntas_seccion13;
      this.dataFrm13.test = JSON.stringify(this.dataFrm13.test );
      this.actualizarPaso16();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe responder todas las preguntas',
      });
      this.sumItems=0
      return false;
    }
  }
  public actualizarPaso16(){

    let data = this.dataFrm13;
    this.apiService
    .put({ api: this.updateFrmTest13 + this.dataFrm13._id, data })
    .subscribe(
      (response) => {
        //  
        this.notificacionTab13.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
  radioChange(event,i) {
    //  
    //  
    this.preguntas_seccion13.preguntas[i].opcionSeccionada= event.target.value
}
}
