import {ChangeDetectorRef, Component, HostListener, Inject, OnInit, PLATFORM_ID} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './public/services/auth.service';
import { ProgramCardUniService } from './public/services/program-card-uni.service';
import { TimeService } from './public/services/time.service';
import { isPlatformBrowser } from '@angular/common';
import { Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import {NavigationService} from './public/services/navigation.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],

})
export class AppComponent implements OnInit {
  constructor(
    private translate: TranslateService,
    private programCardUniService: ProgramCardUniService,
    private timeService: TimeService,
    private authService: AuthService,
    private router: Router,
    private navigationService: NavigationService,
    private ref: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId
  ) {
    // if (!this.authService.loggedIn.value == false && isPlatformBrowser(this.platformId)) {
    //   this.timeService.startNotLoggedInTime()
    // }
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        // 
        this.isLoggedIn = this.authService.loggedIn.value

        if(this.isLoggedIn){
          this.ocultaFooter = true;
        }

        if (event.url == '/leaderTest') {
          this.ocultaFooter = true;
          this.ocultaHeader = false;
        } else if (event.url == '/wizard') {
          this.ocultaHeader = false;
          this.ocultaFooter = true;
        } else {
          this.ocultaFooter = false;
          this.ocultaHeader = false;
        }
      }

    });
  }
  title = 'u360-web';
  public ocultaFooter: boolean = false;
  public ocultaHeader: boolean = false

  public toggle = false;
  isLoggedIn: any;
  scrWidth: number;

  isActiveMenuMobile: boolean;
  isActiveSidebar: boolean;
  @HostListener('window:beforeunload')
  EndLoggedInTime() {
    this.timeService.endNotLoggedInTime()
  }

  public toggleNav(): void {
    this.toggle = !this.toggle;
  }

  onActiveMenu(isActive: boolean): void {
    this.isActiveMenuMobile = isActive;
  }

  ngOnInit(): void {
    this.getScreenSize();
    this.navigationService
      .getIsVisibleSidebar()
      .subscribe((value) => {
        this.isActiveSidebar = value;
        this.ref.detectChanges();
      });
  }


  @HostListener('window:resize', ['$event'])
  getScreenSize(event?): void {
    this.scrWidth = window.innerWidth;
  }
}
