<main class="dashboard">
  <ng-container *ngIf="(idTest && hojaTest==18) && !detalleCompetencia">
    <div class="col-xl-12" *ngIf="!isReadOnly">
      <img class="back-img" src="/assets/wizard-images/back.png" alt="back" (click)="onBackButtonClick()">
    </div>
    <div class="row mx-0" [class.component-container]="isReadOnly">

      <div class="row col-xl-12 container-profile">
        <div class="col-xl-8 col-12 column-info-profile">
          <p><strong class="evaluated-profile">Perfil evaluado: </strong> <span class="type-university">{{ perfil
              }}</span></p>

          <p [hidden]="perfil != 'Top Tier'" class="profile-description display-mobile-none">
            Las universidades TOP TIER, se caracterizan principalmente por tener un perfil muy académico y de
            investigación,
            y son las más selectivas en su proceso de admisión.<br>Están en el top 1% de las mejores universidades del
            mundo
            y acceder a ellas requiere contar con un perfil integral, siendo el tipo de universidad que más exige las 5
            competencias internacionales. Entre ellas se encuentran las universidades de la Ivy League y el Russell
            Group.
            A pesar de ser exigentes en su admisión, existen algunas rutas alternas para ingresar a estas universidades
            si no se cuenta
            con el perfil académico exigido.
          </p>
          <p [hidden]="perfil != 'Experiential University'" class="profile-description display-mobile-none">
            En esta categoría, se encuentran las universidades que tienen un enfoque en la experiencia integral del
            estudiante,
            no solo en la parte académica y de investigación. Le dan la misma importancia tanto a pregrados como
            posgrados y se
            encuentran generalmente en el top 5% del mundo, sin estar en el top 1%; Crean un ambiente universitario de
            balance
            entre lo académico / práctico + experiencia estudiantil, y tienen un pensamiento muy moderno. Entre estas
            podemos encontrar
            universidades jóvenes con buenos rankings en su categoría (por facultades), universidades de rankings medios
            y algunas de enfoque
            internacional.
          </p>
          <p [hidden]="perfil != 'Practical learning'" class="profile-description display-mobile-none">
            Es una institución que tiene un alto enfoque en la enseñanza práctica “hands on”, con alto énfasis en
            tecnologías y
            habilidades que se usarán en el puesto de trabajo, bajo el concepto “Job Ready”. Tienen muy buenas
            conexiones laborales.
            No se preocupan por los rankings internacionales sino por la tasa de empleabilidad. Entre estas podemos
            encontrar, por ejemplo,
            algunas Universidades de Ciencias Aplicadas en Europa y los Colleges en Canadá. El proceso de admisión es
            generalmente menos
            exigente y cuenta con programas de 1 a 4 años de duración: desde certificados, técnicos, tecnológicos y
            licenciaturas prácticas,
            así como posgrados técnicos y masters.
          </p>
          <p [hidden]="perfil != 'Vocational / Passion'" class="profile-description display-mobile-none">
            Academias, institutos o facultades especializadas en áreas de vocación como escuelas de diseño, cine, letras
            y algunos colleges
            privados especializados. El énfasis para escoger este tipo de institución no es el ranking internacional
            sino el interés vocacional
            de la persona y la pasión por su carrera. Algunas son reconocidas mundialmente y ganadoras de premios en sus
            industrias.
            Otras son locales con muy buenos convenios laborales. El proceso de admisión dependerá de un portafolio o
            competencias
            individuales especializadas, sin necesidad de contar con altos promedios académicos, por lo que tienen mayor
            accesibilidad.</p>
        </div>

        <div class="col-xl-3 container-college-img">
          <img src="/assets/wizard-images/college.png" alt="universidad-img" class="college-img">
        </div>

        <div class="col-xl-12 display-mobile-none" style="margin: 2%;">
          <h3 class="title-adjustment-profile">Ajuste del estudiante al perfil</h3>
          <div style="margin-bottom: 1%;"
            [ngClass]="{'config-progress': promedioGeneral < indicadoresGlobales[5].porcentaje}">
            <span class="reached">Alcanzado {{ promedioGeneral }}% </span> <progress [value]="promedioGeneral"
              [max]="indicadoresGlobales[5].porcentaje" style="vertical-align: revert !important;"> </progress> <span
              class="desired"> {{indicadoresGlobales[5].porcentaje}}% Deseable</span>
          </div>

        </div>
      </div>

      <div class="row col-12 border-bottom">
        <h2 class="title-section">Tu puntaje global</h2>
      </div>
      <div class="row col-12 justify-content-around p-0 mx-0 px-0" style="margin-bottom: 32px;">
        <div class="row col-xl-6 col-12 pr-2 container-result">
          <div class="row">
            <p>
              <img class="profile-badge" [src]="sourceInsignia" alt="insignia de perfil">
              <label class="title-profile">Perfil {{perfilFinal}}</label>
            </p>
          </div>

          <div class="row container-tip">
            <div class="col-1">
              <img class="tip-img" src="/assets/dashboard-skill/tips-puntaje-global/cara-feliz.png" alt="cara feliz">
            </div>

            <div class="col-10">
              <p class="desc-tip">
                {{tipPerfil}}
              </p>
            </div>
          </div>

          <div class="row container-tip-alert">
            <div class="col-1">
              <img class="tip-img" src="/assets/dashboard-skill/tips-puntaje-global/alerta.png" alt="cara feliz">
            </div>

            <div class="col-10">
              <p class="desc-tip">
                No olvides asesorarte para revisar requisitos especificos de admisión cómo examenes
              </p>
            </div>
          </div>
        </div>

        <div class="col-xl-5 col-12 align-items-center justify-content-end pt-5 pt-lg-5 px-0" *ngIf="hojaTest==18">
          <app-radar-chart-component class="chart" [sorts]="sortsGraph"></app-radar-chart-component>
        </div>

      </div>

      <div class="row col-xl-12 skills-section border-bottom">
        <h2 class="title-section">Recomendaciones por competencias</h2>
      </div>

      <div class="row col-xl-12 pr-0">
        <div class="col-xl-12 card style-card">
          <div class="card-body px-0">
            <div class="col-xl-12">
              <h3 class="title-skill">Liderazgo</h3>
            </div>
            <div class="col-xl-12">
              <p class="desc-skill">
                El liderazgo es la capacidad de mantener motivado a tu equipo o tus para obtener un resultado común.
                Al ser líder aprendes el poder de la atención enfocada en lo importante, y de trasmitir la pasión de un
                objetivo en el
                que todos continúan creyendo.
              </p>
            </div>
            <div class="col-xl-12" [ngClass]="{'config-progress': puntosLiderazgo < indicadoresGlobales[0].porcentaje}">
              <span class="reached">Alcanzado {{ puntosLiderazgo }}% </span>
              <progress [value]="puntosLiderazgo" [max]="indicadoresGlobales[0].porcentaje"
                style="vertical-align: revert !important;"> </progress>
              <span class="desired"> {{indicadoresGlobales[0].porcentaje}}% Deseable</span>
            </div>
            <div class="col-xl-12" style="text-align: end;">
              <label (click)="mostrarVistaDetalle({competencia:'liderazgo'})"><span class="view-more-link">Ver
                  mas</span></label>
              <label (click)="handleHowToImproveClick('liderazgo')"><span class="how-to-improve">| Cómo mejorar</span></label>
            </div>
          </div>

        </div>

        <div class="col-xl-12 card style-card">
          <div class="card-body px-0">
            <div class="col-xl-12">
              <h3 class="title-skill">Global mind</h3>
            </div>
            <div class="col-xl-12">
              <p class="desc-skill">
                Es la capacidad que una persona tiene de proyectarse en el mundo, basado en sus fortalezas y acompañados
                de un nivel de investigación y conocimiento que demuestre la profesionalización.
              </p>
            </div>
            <div class="col-xl-12" [ngClass]="{'config-progress': puntosGlobal < indicadoresGlobales[1].porcentaje}">
              <span class="reached">Alcanzado {{ puntosGlobal }}% </span>
              <progress [value]="puntosGlobal" [max]="indicadoresGlobales[1].porcentaje"
                style="vertical-align: revert !important;"> </progress>
              <span class="desired"> {{indicadoresGlobales[1].porcentaje}}% Deseable</span>
            </div>
            <div class="col-xl-12" style="text-align: end;">
              <label (click)="mostrarVistaDetalle({competencia:'global'})"><span class="view-more-link">Ver
                  mas</span></label>
              <label (click)="handleHowToImproveClick('global')"><span class="how-to-improve">| Cómo mejorar</span></label>
            </div>
          </div>

        </div>

        <div class="col-xl-12 card style-card">
          <div class="card-body px-0">
            <div class="col-xl-12">
              <h3 class="title-skill">Impacto social</h3>
            </div>
            <div class="col-xl-12">
              <p class="desc-skill">
                El mundo esta llamado a finalmente superar grandes problemáticas tales cómo pobreza, falta de acceso al
                agua y los alimentos,
                calentamiento global, salud emocional, entre muchas otras. Un primer paso para demostrar tu capacidad de
                solidarizarte y aportar
                es a través del Impacto Social. Aportar vale desde temprana edad, y si no lo has hecho aun no es tarde,
                solo ten en cuenta que para una
                universidad TOP suma muchos puntos conocer esta faceta.
              </p>
            </div>
            <div class="col-xl-12" [ngClass]="{'config-progress': puntosImpacto < indicadoresGlobales[2].porcentaje}">
              <span class="reached">Alcanzado {{ puntosImpacto }}% </span>
              <progress [value]="puntosImpacto" [max]="indicadoresGlobales[2].porcentaje"
                style="vertical-align: revert !important;"> </progress>
              <span class="desired"> {{indicadoresGlobales[2].porcentaje}}% Deseable</span>
            </div>
            <div class="col-xl-12" style="text-align: end;">
              <label (click)="mostrarVistaDetalle({competencia:'impacto'})"><span class="view-more-link">Ver
                  mas</span></label>
              <label (click)="handleHowToImproveClick('impacto')"><span class="how-to-improve">| Cómo mejorar</span></label>
            </div>
          </div>

        </div>

        <div class="col-xl-12 card style-card">
          <div class="card-body px-0">
            <div class="col-xl-12">
              <h3 class="title-skill">Logro y acción</h3>
            </div>
            <div class="col-xl-12">
              <p class="desc-skill">
                El mejor profesional es quien decide poner en práctica el conocimiento, es habitual que los mejores
                lideres demuestren desde
                temprana edad su capacidad superior para iniciar proyectos y demostrar resultados.
              </p>
            </div>
            <div class="col-xl-12" [ngClass]="{'config-progress': puntosLogro < indicadoresGlobales[3].porcentaje}">
              <span class="reached">Alcanzado {{ puntosLogro }}% </span>
              <progress [value]="puntosLogro" [max]="indicadoresGlobales[3].porcentaje"
                style="vertical-align: revert !important;"> </progress>
              <span class="desired"> {{indicadoresGlobales[3].porcentaje}}% Deseable</span>
            </div>
            <div class="col-xl-12" style="text-align: end;">
              <label (click)="mostrarVistaDetalle({competencia:'logro'})"><span class="view-more-link">Ver
                  mas</span></label>
              <label (click)="handleHowToImproveClick('logro')"><span class="how-to-improve">| Cómo mejorar</span></label>
            </div>
          </div>

        </div>

        <div class="col-xl-12 card style-card">
          <div class="card-body px-0">
            <div class="col-xl-12">
              <h3 class="title-skill">Competencia Académica / Aprendizaje</h3>
            </div>
            <div class="col-xl-12">
              <p class="desc-skill">
                Las universidades TOP en el mundo entenderán a través de tus notas tu capacidad para ser constante en la
                dedicación para
                obtener resultados. También entenderán tu afinidad para la carrera a la que te presentas, dónde
                recurrentemente se deben
                obtener los mejores resultados. Si no las tienes aun tienes oportunidad de nivelarte, sea durante tu
                estudio actual o mediante
                llamados Pathways.
              </p>
            </div>
            <div class="col-xl-12"
              [ngClass]="{'config-progress': puntosCompetencia < indicadoresGlobales[4].porcentaje}">
              <span class="reached">Alcanzado {{ puntosCompetencia }}% </span>
              <progress [value]="puntosCompetencia" [max]="indicadoresGlobales[4].porcentaje"
                style="vertical-align: revert !important;"> </progress>
              <span class="desired"> {{indicadoresGlobales[4].porcentaje}}% Deseable</span>
            </div>
            <div class="col-xl-12" style="text-align: end;">
              <label (click)="mostrarVistaDetalle({competencia:'aprendizaje'})"><span class="view-more-link">Ver
                  mas</span></label>
              <label (click)="handleHowToImproveClick('aprendizaje')"><span class="how-to-improve">| Cómo
                  mejorar</span></label>
            </div>
          </div>

        </div>
      </div>


      <div class="row col-xl-12 checklist-section">
        <h2 class="title-section">Check list de ingreso</h2>
      </div>
      <div class="row col-xl-12 pr-0">
        <div class="card checklist-container">
          <div class="card-body px-0">
            <div id="boxTable">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col" class="col-xl-10"></th>
                    <th class="boxRespuesta col-xl-1" scope="col">Si</th>
                    <th class="boxRespuesta col-xl-1" scope="col">No</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let pregunta of preguntasCerradas" class="separator-table">
                    <td>
                      <p class="check-list-question">
                        {{pregunta.pregunta}}
                      </p>

                    </td>
                    <!-- <td>{{pregunta.opcionSeccionada}}</td> -->
                    <td name="respuesta-si" class="boxRespuesta">
                      <ng-container *ngIf="pregunta.opcionSeccionada=='si' || pregunta.opcionSeccionada=='no'">
                        <div>
                          <!-- <input class="inputCheckSi" id="checkSi" type="radio" disabled [checked]="pregunta.opcionSeccionada=='si'"/> -->
                          <img *ngIf="pregunta.opcionSeccionada=='si'" class="input-radio-img"
                            src="/assets/dashboard-skill/check-list/radio-boton-azul-lleno.png" alt="si marcado">
                          <img *ngIf="pregunta.opcionSeccionada=='no'" class="input-radio-img"
                            src="/assets/dashboard-skill/check-list/radio-boton-azul-vacio.png" alt="si no-marcado">
                          <!-- <label for="checkSi">Si</label> -->
                        </div>
                      </ng-container>

                      <ng-container *ngIf="pregunta.opcionSeccionada=='' ">
                        <img class="input-radio-img" src="/assets/dashboard-skill/check-list/radio-boton-azul-vacio.png"
                          alt="vacio">
                      </ng-container>
                    </td>

                    <td name="respuesta-no" class="boxRespuesta">
                      <ng-container *ngIf="pregunta.opcionSeccionada=='no' || pregunta.opcionSeccionada=='si'">
                        <div>
                          <img *ngIf="pregunta.opcionSeccionada=='no'" class="input-radio-img"
                            src="/assets/dashboard-skill/check-list/radio-boton-azul-lleno.png" alt="si marcado">
                          <img *ngIf="pregunta.opcionSeccionada=='si'" class="input-radio-img"
                            src="/assets/dashboard-skill/check-list/radio-boton-azul-vacio.png" alt="si no-marcado">
                        </div>
                      </ng-container>

                      <ng-container *ngIf="pregunta.opcionSeccionada=='' ">
                        <img class="input-radio-img" src="/assets/dashboard-skill/check-list/radio-boton-azul-vacio.png"
                          alt="vacio">
                      </ng-container>
                    </td>
                  </tr>

                  <tr *ngFor="let presupuesto of preguntasPresupuesto" class="separator-table">
                    <td>
                      <p class="check-list-question">
                        {{presupuesto.titulo}}
                      </p>
                    </td>

                    <td>
                      <ng-container *ngIf="presupuesto.seleccionada==true || presupuesto.seleccionada==false">
                        <div class="boxRespuesta">
                          <!-- <input class="inputCheckSi" id="checkSi" type="radio" disabled checked/> -->
                          <img *ngIf="presupuesto.seleccionada==true" class="input-radio-img"
                            src="/assets/dashboard-skill/check-list/radio-boton-azul-lleno.png" alt="si marcado">
                          <img *ngIf="presupuesto.seleccionada==false" class="input-radio-img"
                            src="/assets/dashboard-skill/check-list/radio-boton-azul-vacio.png" alt="si no-marcado">
                          <!-- <label for="checkSi">Si</label> -->
                        </div>
                      </ng-container>
                    </td>

                    <td>
                      <ng-container *ngIf="presupuesto.seleccionada==false || presupuesto.seleccionada==true">
                        <div class="boxRespuesta">
                          <img *ngIf="presupuesto.seleccionada==false" class="input-radio-img"
                            src="/assets/dashboard-skill/check-list/radio-boton-azul-lleno.png" alt="si marcado">
                          <img *ngIf="presupuesto.seleccionada==true" class="input-radio-img"
                            src="/assets/dashboard-skill/check-list/radio-boton-azul-vacio.png" alt="si no-marcado">
                        </div>
                      </ng-container>
                    </td>
                  </tr>

                  <tr *ngFor="let lista of preguntasPaises" class="separator-table">
                    <td class="row">
                      <div class="col-xl-6" style="align-self: center;">
                        <p class="check-list-question">
                          Lista de paises seleccionados:
                        </p>
                      </div>

                      <div class="col-xl-6">
                        <ng-container *ngIf="lista.lista">
                          <ng-container class="boxPais" *ngFor="let pais of lista.lista">
                            <img class="imgPais" src="assets/flags-countries/united-states.png"
                              *ngIf="pais.pais=='Estados Unidos'" />
                            <img class="imgPais" src="assets/flags-countries/canada.png" *ngIf="pais.pais=='Canadá'" />
                            <img class="imgPais" src="assets/flags-countries/france.png" *ngIf="pais.pais=='Francia'" />
                            <img class="imgPais" src="assets/flags-countries/germany.png"
                              *ngIf="pais.pais=='Alemanía'" />
                            <img class="imgPais" src="assets/flags-countries/spain.png" *ngIf="pais.pais=='España'" />
                            <img class="imgPais" src="assets/flags-countries/united-kingdom.png"
                              *ngIf="pais.pais=='Reino Unido'" />
                          </ng-container>
                        </ng-container>
                      </div>


                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>

    </div>

    <ng-container *ngIf="!isReadOnly">
      <div class="col-12" style="border-bottom: 1px solid #dee2e6!important;"></div>
      <div class="col-12 boxButtons">
        <a routerLink="/preTest"><button type="button" class="btn btn-primary">Finalizar test</button></a>
        <button style="margin-left: 2%;" type="button" class="btn btn-danger" (click)="reiniciarTest()">Reinicar
          test</button>
      </div>
    </ng-container>

  </ng-container>

  <ng-container *ngIf="detalleCompetencia">
    <div class="row backgroundSkillDetail">
      <div class="row">
        <div class="col-xl-1">
          <label (click)="detalleCompetencia=false; navigationService.setIsVisibleSidebar(true)">
            <img class="back-img" src="/assets/wizard-images/back.png" alt="volver">
          </label>
        </div>

        <div class="row col-xl-11">
          <div class="col-xl-12">
            <h3 class="evaluated-profile">{{contenidoCompetencia.titulo}}</h3>
          </div>
          <div class="col-xl-12"
            [ngClass]="{'config-progress': contenidoCompetencia.puntaje < contenidoCompetencia.indicador}">
            <span class="reached">Alcanzado {{ contenidoCompetencia.puntaje }}% </span>
            <progress [value]="contenidoCompetencia.puntaje" [max]="contenidoCompetencia.indicador"
              style="vertical-align: revert !important;"> </progress>
            <span class="desired"> {{contenidoCompetencia.indicador}}% Deseable</span>
          </div>
          <div class="col-xl-12" style="margin-top: 2%;">
            <p class="skill-description">
              {{contenidoCompetencia.descripcion}}
            </p>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-xl-1"></div>

        <div class="row col-xl-11">
          <div class="col-xl-12 border-bottom">
            <h3 class="evaluated-profile">Sugerencias</h3>
          </div>

          <div class="col-xl-12 mt-2">
            <ul>
              <li class="skill-description" *ngFor="let item of contenidoCompetencia.sugerencias">{{ item.recomendacion
                }}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </ng-container>

  <ng-container *ngIf="isReadOnly && !userData15">
    <div class="col-xl-12">
      <img class="back-img" src="/assets/wizard-images/back.png" alt="back" (click)="onBackButtonClick()">
    </div>

    <div *ngIf="isReadOnly" class="col-xl-12">
      <p class="px-5 pt-4">No hay resultados...</p>
    </div>

  </ng-container>

</main>
