<main class="component-container">
    <div class="row d-flex flex-column flex-lg-row align-items-center">
        <div class="col-lg-7 pr-lg-0">
            <label class="section-title">Lista de estudiantes {{nombreColegio}}</label>
        </div>

        <div class="col-lg-5 filter-container">
            <input id="search" class="student-filter student-filter-img" type="text" placeholder="Busca por palabra clave"
            (keyup)="applyFilter($event)" #input [(ngModel)]="filter">
        </div>

        <div class="col-xl-12 studentList-container">
          <div class="table-container">
            <table mat-table [dataSource]="studentsData">

              <ng-container matColumnDef="nombre">
                <th mat-header-cell *matHeaderCellDef class="table-columns"> Nombre </th>
                <td mat-cell *matCellDef="let element; index as i" class="data-table d-flex align-items-center">
                  <label class="name">
                    {{i+1}} <img class="user-profilePhoto" alt="foto de perfil" [src]="element.fotoPerfil"> {{element.nombre}}
                  </label>
                </td>
              </ng-container>

              <ng-container matColumnDef="grado">
                <th mat-header-cell *matHeaderCellDef class="table-columns"> Grado </th>
                <td mat-cell *matCellDef="let element" class="data-table"> {{element.grado}} </td>
              </ng-container>

              <ng-container matColumnDef="insignia">
                <th mat-header-cell *matHeaderCellDef class="table-columns text-center"> Insignia </th>
                <td mat-cell *matCellDef="let element" class="data-table text-center">
                  <img class="user-insignia" [src]="element.insignia" alt="insignia">
                </td>
              </ng-container>

              <ng-container matColumnDef="promedio">
                <th mat-header-cell *matHeaderCellDef class="table-columns text-center"> Promedio </th>
                <td mat-cell *matCellDef="let element" class="data-table text-center">
                  <label class="promedio-desc mb-0">
                    {{element.promedio}}%
                  </label>
                </td>
              </ng-container>

              <ng-container matColumnDef="aplicaciones">
                <th mat-header-cell *matHeaderCellDef class="table-columns text-center"> Aplicaciones </th>
                <td mat-cell *matCellDef="let element" class="data-table text-center">
                  <label class="applications mb-0">
                    {{element.aplicaciones}}
                  </label>
                </td>
              </ng-container>

              <ng-container matColumnDef="verperfil">
                <th mat-header-cell *matHeaderCellDef class="table-columns"></th>
                <td mat-cell *matCellDef="let element" class="data-table">
                  <label class="viewProfile mb-0" (click)="navigateToResume(element)">
                    Ver perfil
                    <img class="go-profile" src="/assets/school-dashboard/flecha-derecha-azul.png" alt="ir">
                  </label>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
              <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>


            </table>
          </div>
        </div>
    </div>
</main>
