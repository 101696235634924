import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AlertService } from 'src/app/public/services/alert.service';
import Swal from "sweetalert2";
import { ApiService } from 'src/app/public/services/api.service';
import { CookieService } from 'ngx-cookie-service';
import { AuthService } from 'src/app/public/services/auth.service';

@Component({
  selector: 'app-paso2',
  templateUrl: './paso2.component.html',
  styleUrls: ['./paso2.component.scss']
})
export class Paso2Component implements OnInit {
  @Output() notificacion = new EventEmitter<string>();
  public preguntas_seccion02:any;
  public dataFrm02:any;
  public disciplina:string = '';
  public otraDisciplina:string = '';
  public otraDisciplinaOpcional:string = '';
  public nivel_academico:string = '';
  public edad:string = '';
  public colegio: string = '';
  public genero: string = '';
  public ListDisciplinas: any[]=[];
  cookieValue: string;
  userID: any;
  nombreColegio:{type:string, required:false};
  nivelAcademico:{type:string, required:false};;
  nombreDisciplina:{type:string, required:false};
  readonly getTest = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest = 'api/get_internalizacionUser/';
  pasoActivo: any;
  constructor(private alertService: AlertService, private apiService:ApiService, private authService:AuthService) { 
    this.ListDisciplinas = [
      {
        value: 'Arts, Design and Architecture',
        name: 'Home.arts-architecture',
        text: '',
      },
      {
        value: 'Business and Management',
        name: 'Home.business',
        text: '',
      },
      {
        value: 'Computer Sciences and IT',
        name: 'Home.computer-science',
        text: '',
      },
      {
        value: 'Engineering',
        name: 'Home.engineering',
        text: '',
      },
      {
        value: 'Sciences and Mathematics',
        name: 'Home.sciences-math',
        text: '',
      },
      {
        value: 'Social Sciences and Humanities',
        name: 'Home.social-sciences',
        text: '',
      },
      {
        value: 'Education and Sport',
        name: 'Home.education',
        text: '',
      },
      {
        value: 'Community Services',
        name: 'Home.community',
        text: '',
      },
      {
        value: 'Environmental Studies and Earth Sciences',
        name: 'Home.env-studies',
        text: '',
      },
      {
        value: 'Health',
        name: 'Home.health',
        text: '',
      },
      {
        value: 'Journalism and Media',
        name: 'Home.journalism',
        text: '',
      },
      {
        value: 'Law and Politic Sciences',
        name: 'Home.law-politics',
        text: '',
      },
      {
        value: 'Languages',
        name: 'Home.languages',
        text: '',
      },
      {
        value: 'Tourism and Hospitality',
        name: 'Home.tourism-hospitality',
        text: '',
      },
      {
        value: 'Other',
        name: 'Home.other',
        text: '',
      },
    ];
    this.userID = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();

    if (this.userID) {
      this.apiService.get({ api: this.getTest + this.userID._id+'/'+ semestreAct}).subscribe(
        (response) => {
          if (response) {
            //  
            let res = response;
            let data_test
            if(res.test[0].preguntas){
              data_test=res.test[0];
            }else{
              data_test=JSON.parse(res.test[0]);
            }
            this.dataFrm02 = res;
            this.pasoActivo= res.hojaTest;
            this.dataFrm02.test = data_test;
            this.preguntas_seccion02= data_test.preguntas[1];
            //  
            for(let resp of this.preguntas_seccion02.preguntas[0].opciones){
              //  
              if(resp.titulo == 'Género' && resp.respuesta != ''){
                this.genero= resp.respuesta
              }
              if(resp.titulo == 'Colegio o universidad:' && resp.respuesta != ''){
                this.colegio= resp.respuesta
              }
              if(resp.titulo == 'Tu edad:' && resp.respuesta != ''){
                this.edad= resp.respuesta
              }
              if(resp.titulo == 'Nivel académico actual:' && resp.respuesta != ''){
                this.nivel_academico= resp.respuesta
              }
              if(resp.titulo == '¿Qué te gustaría estudiar?' && resp.respuesta != ''){
                this.disciplina= resp.respuesta
              }
            }

          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    } 
  }

  ngOnInit(): void {
  }
  public async continuar(){
    if(
      this.disciplina == '' ||
      this.nivel_academico == '' ||
      this.edad == '' ||
      this.genero == '' ||
      this.colegio == ''
    ){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe diligenciar todos los campos'
      })
      return false
    }else{
      let paso = this.pasoActivo
      if(this.pasoActivo < 2){
        paso = this.pasoActivo = 2;
      }
      this.dataFrm02.nivelAcademico=this.nivel_academico;
      this.dataFrm02.hojaTest= paso
      this.dataFrm02.edad=this.edad;
      this.dataFrm02.nombreColegio=this.colegio;
      this.dataFrm02.disciplina=this.disciplina;
      this.dataFrm02.disciplinaOpcional=this.otraDisciplinaOpcional;
      this.dataFrm02.test.edadActual=this.edad;
      this.dataFrm02.test.pasoActivo=paso;
      this.dataFrm02.test.genero=this.genero;
      this.dataFrm02.test.nivel_academico=this.nivel_academico;
      this.dataFrm02.test.disciplina=this.disciplina;
      this.dataFrm02.test.disciplinaOpcional=this.otraDisciplinaOpcional;
      this.dataFrm02.test.nombreColegio= this.colegio;
      this.preguntas_seccion02.preguntas[0].opciones[0].respuesta = this.genero;
      this.preguntas_seccion02.preguntas[0].opciones[1].respuesta = this.colegio;
      this.preguntas_seccion02.preguntas[0].opciones[2].respuesta = this.edad;
      this.preguntas_seccion02.preguntas[0].opciones[3].respuesta = this.nivel_academico;
      this.preguntas_seccion02.preguntas[0].opciones[4].respuesta = this.disciplina;
      this.preguntas_seccion02.preguntas[0].opciones[5].respuesta = this.otraDisciplinaOpcional;
      //  
      this.dataFrm02.test.preguntas[1] = this.preguntas_seccion02;
      this.dataFrm02.test = JSON.stringify(this.dataFrm02.test );
      this.actualizarPaso2();
    }
  }
  public actualizarPaso2(){
    let data = this.dataFrm02;
    this.apiService
    .put({ api: this.updateFrmTest + this.dataFrm02._id, data })
    .subscribe(
      (response) => {
        //  
        this.notificacion.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
  public volver(){
    this.notificacion.emit("volver")
  }
}
