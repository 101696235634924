<div class="legal-representative">
    <div class="legal-representative-header" routerLink="../">
        <img class="back-button" src="assets/utils/back.png" alt="go back">
        <h3 class="legal-representative-title"> {{ "Legal-representative.legal-guardian-info" | translate }} </h3>
    </div>
    <div class="legal-representatives">
        <div class="legal-guardian-form" *ngFor="let guardian of legalRepresentatives.controls; index as i"
            [formGroup]="guardian">
            <div class="form-group">
                <label class="form-label" [for]="'name' + i"> {{ "Legal-representative.name" | translate }} (<span style="color: red;">*</span>)</label> <br>
                <input [placeholder]="'Legal-representative.name'|translate" formControlName="nombre" [id]="'name' + i" type="text" class="form-input" />
            </div>
            <div class="form-group">
                <label class="form-label" [for]="'lastname' + i"> {{ "Legal-representative.last-name" | translate }} (<span style="color: red;">*</span>)
                </label><br>
                <input [placeholder]="'Legal-representative.last-name'|translate" formControlName="apellido" [id]="'lastname' + i" type="text"
                    class="form-input" />
            </div>
            <div class="form-group">
                <label class="form-label" [for]="'relationship' + i"> {{ "Legal-representative.relationship" | translate
                    }} (<span style="color: red;">*</span>)</label><br>
                <input [placeholder]="'Legal-representative.relationship'|translate" formControlName="parentesco" [id]="'relationship' + i" type="text"
                    class="form-input" />
            </div>
            <div class="form-group">
                <label class="form-label" [for]="'telephone' + i"> {{ "Legal-representative.telephone" | translate }} (<span style="color: red;">*</span>)
                </label><br>
                <input [placeholder]="'Legal-representative.telephone'|translate" formControlName="telefono" [id]="'telephone' + i" type="tel"
                    class="form-input" />
            </div>
            <div class="form-group">
                <label class="form-label" [for]="'email' + i"> {{ "Legal-representative.e-mail" | translate }} </label><br>
                <input [placeholder]="'Legal-representative.e-mail'|translate" formControlName="correo" [id]="'email' + i" type="text"
                    class="form-input" />
            </div>
            <div class="form-group">
              <button class="delete-entry" (click)="onRemoveEntry({index: i})"> {{ "Legal-representative.remove" | translate }} </button>
            </div>
        </div>
        <button (click)="onAddContact()" id="add-guardian-button">
            + {{ "Legal-representative.add-contacto" | translate }}
        </button>
        <button id="save-changes" *ngIf="!loading" (click)="onSubmitData()">
            {{ "Legal-representative.save-changes" | translate }}
        </button>
        <mat-spinner class="legal-guardian-spinner" *ngIf="loading" diameter="40"></mat-spinner>
    </div>
</div>
