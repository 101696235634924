import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Validators, FormGroup, FormBuilder } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { AlertService } from '../../services/alert.service';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-english-form',
  templateUrl: './english-form.component.html',
  styleUrls: ['./english-form.component.scss'],
})
export class EnglishFormComponent implements OnInit {
  private readonly USER_API = 'api/user/';
  private readonly API_FORM = 'api/formulario';
  private levelNumber: number = 0;
  private user: any;

  /* Child References */
  createForm: FormGroup = this.fb.group({
    // nombre: ['', [Validators.required]],
    // apellido: ['', [Validators.required]],
    // correo: ['', [Validators.required, Validators.email]],
    anioInteres: ['', [Validators.required]],
    edad: ['', [Validators.required]],
    // telefono: ['', [Validators.required]],
    programa: ['', [Validators.required]],
    pregunta: ['', [Validators.required]],
  });

  constructor(
    private router: Router,
    private fb: FormBuilder,
    private apiService: ApiService,
    private alertService: AlertService,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    // Obtener el nivel de ingles
    let url = this.router.url.split('/');
    this.levelNumber = parseInt(url[url.length - 1]);
    // Si el viene un numero mayor a 9 volverlo 9 que es el maximo valor y lo mismo si viene menor a 1 ya que este es el minimo
    this.levelNumber = this.levelNumber > 9 ? 9 : this.levelNumber < 1 ? 1 : this.levelNumber;
    // Se obtiene el usuario para evitar pedirle algunos datos nuevamente como el nombre
    this.user = JSON.parse(this.authService.getCurrentLoggedUser());
    // Objeto para enviar al back y actualizar el nivel de ingles del usuario
    let nivIngles = {
      nivelIngles: this.levelNumber,
    };
    //Solicitud al back para actualizar el nivel de ingles del usuario
    this.apiService.put({ api: this.USER_API + this.user._id, data: nivIngles }).subscribe(
      (response) => {
        this.alertService.showSuccess({
          msg: 'Se guardó el nivel de inglés',
        });
      },
      (err) => {
        this.alertService.showError({
          msg: 'Error guardando el nivel de inglés',
        });
      }
    );
  }

  /**
   * Validate that the fields are correct
   * @param field field to validate
   */
  public validField({ field }) {
    return this.createForm.controls[field].errors && this.createForm.controls[field].touched;
  }

  onSubmit({ event }): void {
    // Se crea el formulario a enviar con la informacion correspondiente
    if (this.createForm.valid) {
      const form = {
        nombre: this.user.nombre,
        apellido: this.user.apellido,
        correo: this.user.email,
        anioInteres: this.createForm.controls.anioInteres.value,
        edad: this.createForm.controls.edad.value,
        telefono: this.user.telefono,
        programa: this.createForm.controls.programa.value,
        pregunta: this.createForm.controls.pregunta.value,
        fuente: 'Test Inglés, nivel: ' + this.levelNumber,
      };
      this.createFormByContact({ form });
    } else {
      this.createForm.markAllAsTouched();
    }
  }

  /**
   * create a form
   * @param form
   */
  public async createFormByContact({ form }) {
    let resp = await this.apiService.post({ api: `${this.API_FORM}`, data: form }).toPromise();
    if (resp.success) {
      this.createForm.reset();
      this.alertService.showSuccess({
        msg: `Formulario creado`,
      });
    }
  }
}
