<div id="my-profile">
    <div class="max-width">
    <!-- <img class="back-button" src="assets/utils/back.png" routerLink="../home" alt="go back" /> -->
    <h3 class="account-title"> {{ "Profile.my-account" | translate }} </h3>
    <div class="profile-info">
        <div class="flex-row align-items-center">

            <img *ngIf="imageProfile" class="profile-image" [src]="imageProfile" alt="profile image" />
            <div class="Profile-header">
                <div class="username">
                    {{userData.name}} {{userData.lastName}}
                </div>
                <label id="edit-photo">
                    {{ "Profile.edit-photo" | translate }}
                    <input (change)="onChangeProfileImage({event: $event})" class="image-input" type="file">
                </label>
            </div>
        </div>
        <div class="student-selector" *ngIf="isRecruitment">
            <select placeholder="Seleccionar estudiante" [(ngModel)]="student" (ngModelChange)="onStudentChange({id: $event})" class="student-select">
            <option value="0" disabled selected>{{ "Seleccionar estudiante" }} </option>
            <option *ngFor="let s of recruitmentStudents" [value]="s._id">{{s.nombre}} {{s.apellido}}</option>
    </select>
        </div>

    </div>
    <div class="profile-menu" *ngIf="(isRecruitment && (student !== '0')) || (!isRecruitment)">
        <div [routerLink]="menuItem.link" class="profile-menu-item" *ngFor="let menuItem of menuItems">
            <img class="profile-menu-image" [src]="menuItem.image" alt="menuItem.alt"/>
            <div class="profile-menu-action">
                {{menuItem.title | translate}}
            </div>
            <div *ngIf="menuItem.buttonText === 'Completado'" class="profile-status profile-status-completed">
                {{menuItem.buttonText}}
            </div>
            <div *ngIf="menuItem.buttonText === 'En progreso'" class="profile-status profile-status-progress">
              {{menuItem.buttonText}}
            </div>
            <div *ngIf="menuItem.buttonText === 'Iniciar'" class="profile-status">
              {{menuItem.buttonText}}
            </div>
        </div>
    </div>
    <div class="other-actions display-mobile-none">
      <p>{{ "Profile.you-can" | translate }}<button id="explore-button" routerLink="../program/search">{{ "Profile.explore-some" | translate }}</button></p>
    </div>
    </div>

    <!-- Documentos -->
    <div class="row mt-2 mt-lg-5 documents">
        <div class="col-xl-12">
            <h4 class="account-title">Mis documentos (PDF)</h4>
        </div>

        <!-- Documento de identidad -->
        <div class="col-xl-12 action-buttons" *ngIf="documentId">
            <input type="file" id="identification-file" style="display: none;"
                (change)="uploadDocumentFile('identification',$event)" accept="application/pdf">
            <label for="identification-file"><img class="icon-button" src="/assets/profile/subir.png" alt="subir"></label>

            <img (click)="deleteDocumentFile('identification')" class="icon-button" src="/assets/profile/eliminar.png" alt="eliminar">
            <a [href]="documentId.archivo" target="_blank"><img class="icon-button" src="/assets/profile/descargar.png" alt="descargar"></a>
            <label class="title-document-button">Documento de identidad</label>
        </div>

        <div class="col-xl-12" *ngIf="!documentId">
            <input type="file" id="identification-file" style="display: none;"
                (change)="uploadDocumentFile('identification',$event)" accept="application/pdf">
            <label for="identification-file" class="button-document">
                <!-- <button class="button-document">Adjuntar</button> -->
                Adjuntar
            </label>

            <label class="title-document-button">Documento de identidad</label>
        </div>

        <!-- Pasaporte -->
        <div class="col-xl-12 action-buttons" *ngIf="documentPassport">
            <input type="file" id="passport-file" style="display: none;"
                (change)="uploadDocumentFile('passport',$event)" accept="application/pdf">
            <label for="passport-file"><img class="icon-button" src="/assets/profile/subir.png" alt="subir"></label>

            <img (click)="deleteDocumentFile('passport')" class="icon-button" src="/assets/profile/eliminar.png" alt="eliminar">
            <a [href]="documentPassport.archivo" target="_blank"><img class="icon-button" src="/assets/profile/descargar.png" alt="descargar"></a>
            <label class="title-document-button">Pasaporte</label>
        </div>

        <div class="col-xl-12" *ngIf="!documentPassport">
            <input type="file" id="passport-file" style="display: none;"
                (change)="uploadDocumentFile('passport',$event)" accept="application/pdf">
            <label for="passport-file" class="button-document">
                <!-- <button class="button-document">Adjuntar</button> -->
                Adjuntar
            </label>

            <label class="title-document-button">Pasaporte</label>
        </div>


        <!-- Notas -->
        <div class="col-xl-12 action-buttons" *ngIf="documentQualifications">
            <input type="file" id="qualifications-file" style="display: none;"
                (change)="uploadDocumentFile('qualifications',$event)" accept="application/pdf">
            <label for="qualifications-file"><img class="icon-button" src="/assets/profile/subir.png" alt="subir"></label>

            <img (click)="deleteDocumentFile('qualifications')" class="icon-button" src="/assets/profile/eliminar.png" alt="eliminar">
            <a [href]="documentQualifications.archivo" target="_blank"><img class="icon-button" src="/assets/profile/descargar.png" alt="descargar"></a>
            <label class="title-document-button">Certificado de notas de los ultimos 3 años</label>
        </div>

        <div class="col-xl-12" *ngIf="!documentQualifications">
            <input type="file" id="qualifications-file" style="display: none;"
                (change)="uploadDocumentFile('qualifications',$event)" accept="application/pdf">
            <label for="qualifications-file" class="button-document">
                <!-- <button class="button-document">Adjuntar</button> -->
                Adjuntar
            </label>

            <label class="title-document-button">Certificado de notas de los últimos 3 años</label>
        </div>


        <!-- Idiomas -->
        <div class="col-xl-12 action-buttons" *ngIf="documentLanguages">
            <input type="file" id="languages-file" style="display: none;"
                (change)="uploadDocumentFile('languages',$event)" accept="application/pdf">
            <label for="languages-file"><img class="icon-button" src="/assets/profile/subir.png" alt="subir"></label>

            <img (click)="deleteDocumentFile('languages')" class="icon-button" src="/assets/profile/eliminar.png" alt="eliminar">
            <a [href]="documentLanguages.archivo" target="_blank"><img class="icon-button" src="/assets/profile/descargar.png" alt="descargar"></a>
            <label class="title-document-button">Certificados de idiomas: IELTS, CAMBRIDGE, TOEFL, SAT, CAE, DUOLINGO, ACT</label>
        </div>

        <div class="col-xl-12" *ngIf="!documentLanguages">
            <input type="file" id="languages-file" style="display: none;"
                (change)="uploadDocumentFile('languages',$event)" accept="application/pdf">
            <label for="languages-file" class="button-document">
                <!-- <button class="button-document">Adjuntar</button> -->
                Adjuntar
            </label>

            <label class="title-document-button">Certificados de idiomas: IELTS, CAMBRIDGE, TOEFL, SAT, CAE, DUOLING, ACT</label>
        </div>


        <!-- Carta -->
        <div class="col-xl-12 action-buttons" *ngIf="documentLetter">
            <input type="file" id="letter-file" style="display: none;"
                (change)="uploadDocumentFile('letter',$event)" accept="application/pdf">
            <label for="letter-file"><img class="icon-button" src="/assets/profile/subir.png" alt="subir"></label>

            <img (click)="deleteDocumentFile('letter')" class="icon-button" src="/assets/profile/eliminar.png" alt="eliminar">
            <a [href]="documentLetter.archivo" target="_blank"><img class="icon-button" src="/assets/profile/descargar.png" alt="descargar"></a>
            <label class="title-document-button">Carta de motivación</label>
        </div>

        <div class="col-xl-12" *ngIf="!documentLetter">
            <input type="file" id="letter-file" style="display: none;"
                (change)="uploadDocumentFile('letter',$event)" accept="application/pdf">
            <label for="letter-file" class="button-document">
                <!-- <button class="button-document">Adjuntar</button> -->
                Adjuntar
            </label>

            <label class="title-document-button">Carta de motivación</label>
        </div>


        <!-- Visa -->
        <div class="col-xl-12 action-buttons" *ngIf="documentVisaLetter">
            <input type="file" id="visaLetter-file" style="display: none;"
                (change)="uploadDocumentFile('visaLetter',$event)" accept="application/pdf">
            <label for="visaLetter-file"><img class="icon-button" src="/assets/profile/subir.png" alt="subir"></label>

            <img (click)="deleteDocumentFile('visaLetter')" class="icon-button" src="/assets/profile/eliminar.png" alt="eliminar">
            <a [href]="documentVisaLetter.archivo" target="_blank"><img class="icon-button" src="/assets/profile/descargar.png" alt="descargar"></a>
            <label class="title-document-button">Carta de intención VISA</label>
        </div>

        <div class="col-xl-12" *ngIf="!documentVisaLetter">
            <input type="file" id="visaLetter-file" style="display: none;"
                (change)="uploadDocumentFile('visaLetter',$event)" accept="application/pdf">
            <label for="visaLetter-file" class="button-document">
                <!-- <button class="button-document">Adjuntar</button> -->
                Adjuntar
            </label>

            <label class="title-document-button">Carta de intención VISA</label>
        </div>


        <!-- Portafolio -->
        <div class="col-xl-12 action-buttons" *ngIf="documentPortfolio">
            <input type="file" id="portfolio-file" style="display: none;"
                (change)="uploadDocumentFile('portfolio',$event)" accept="application/pdf">
            <label for="portfolio-file"><img class="icon-button" src="/assets/profile/subir.png" alt="subir"></label>

            <img (click)="deleteDocumentFile('portfolio')" class="icon-button" src="/assets/profile/eliminar.png" alt="eliminar">
            <a [href]="documentPortfolio.archivo" target="_blank"><img class="icon-button" src="/assets/profile/descargar.png" alt="descargar"></a>
            <label class="title-document-button">Artistas*: Portafolio, reel</label>
        </div>

        <div class="col-xl-12" *ngIf="!documentPortfolio">
            <input type="file" id="portfolio-file" style="display: none;"
                (change)="uploadDocumentFile('portfolio',$event)" accept="application/pdf">
            <label for="portfolio-file" class="button-document">
                <!-- <button class="button-document">Adjuntar</button> -->
                Adjuntar
            </label>

            <label class="title-document-button">Artistas*: Portafolio, reel</label>
        </div>

        <!-- Otro documento -->
        <!-- <div class="col-xl-12 action-buttons" *ngIf="documentOther">
            <input type="file" id="other-file" style="display: none;"
                (change)="uploadDocumentFile('other',$event)" accept="application/pdf">
            <label for="other-file"><img class="icon-button" src="/assets/profile/subir.png" alt="subir"></label>

            <img (click)="deleteDocumentFile('other')" class="icon-button" src="/assets/profile/eliminar.png" alt="eliminar">
            <a [href]="documentOther.archivo" target="_blank"><img class="icon-button" src="/assets/profile/descargar.png" alt="descargar"></a>
            <label class="title-document-button">Otro documento</label>
        </div>

        <div class="col-xl-12" *ngIf="!documentOther" style="margin-top: 24px;">
            <input type="file" id="other-file" style="display: none;"
                (change)="uploadDocumentFile('other',$event)" accept="application/pdf">
            <label for="other-file">
                <img class="icon-button" src="/assets/profile/agregar.png" alt="subir">
            </label>

            <label class="title-document-button">Otro documento</label>
        </div> -->


        <!-- Nuevo otro documento -->
        <ng-container *ngIf="listOtherDocuments.length>0">
            <div class="col-xl-12 action-buttons" *ngFor="let singleDocument of listOtherDocuments; index as i">
                <input type="file" [id]="'other-file-'+i" style="display: none;"
                (change)="uploadDocumentFile('other-'+i , $event)" accept="application/pdf">

                <label [for]="'other-file-'+i"><img class="icon-button" src="/assets/profile/subir.png" alt="subir"></label>

                <img (click)="deleteDocumentFile(singleDocument.nombre)" class="icon-button" src="/assets/profile/eliminar.png" alt="eliminar">
                <a [href]="singleDocument.archivo" target="_blank"><img class="icon-button" src="/assets/profile/descargar.png" alt="descargar"></a>
                <label class="title-document-button"></label>

                <label class="title-document-button">{{singleDocument.text}}</label>
            </div>
        </ng-container>

        <div class="row col-xl-12">
            <div class="col-xl-12" style="margin-top: 24px;">
                <input type="file" [id]="'other-file-'+lastIndexOtherDocuments" style="display: none;"
                    (change)="uploadDocumentFile('other-'+lastIndexOtherDocuments , $event)" accept="application/pdf">
                <label [for]="'other-file-'+lastIndexOtherDocuments">
                    <img class="icon-button" src="/assets/profile/agregar.png" alt="subir">
                </label>
                
                <label class="title-document-button">Otro documento</label>
            </div>
        </div>
    </div>
</div>
