<div class="row">
    <div class="col-12">
        <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
        <h4 class="titulo-wizard">Hablemos de Impacto Social</h4>
        <h5>Pregunta 8 / 16</h5>
        <p>Califica cada una de las siguientes afirmaciones del 1 al 5 según corresponda. Ten encuenta que debes tener soportes reales
            de cada una de las afirmaciones.</p>
        <p> <span>1) Nunca</span>
            <span style="margin-left: 2%;">2) Casi nunca</span>
            <span style="margin-left: 2%;">3) A veces</span>
            <span style="margin-left: 2%;">4) Casi siempre</span>
            <span style="margin-left: 2%;">5) Siempre</span>
        </p>
        <hr class="hrLine" />
    </div>
  </div>
  <div class="row">
    <div class="col-12">
        <table summary="Pregunta 9" style="width: 100%;">
            <tr>
                <th scope="col"></th>
                <th scope="col" class="paddingTh" style="text-align: center;">1</th>
                <th scope="col" class="paddingTh" style="text-align: center;">2</th>
                <th scope="col" class="paddingTh" style="text-align: center;">3</th>
                <th scope="col" class="paddingTh" style="text-align: center;">4</th>
                <th scope="col" class="paddingTh" style="text-align: center;">5</th>
            </tr>
            <tr *ngFor="let preg of preguntas; let i = index" class="marginTr">
                <td><em class="fa fa-circle font-s" aria-hidden="true"></em> {{preg.pregunta}}</td>
                <td class="textCenterRadio"> <input type="radio" [value]="1" name="group{{i}}" [checked]="preg.opcionSeccionada == '1'" (change)="radioChangeSelect($event,i)"></td>
                <td class="textCenterRadio"> <input type="radio" [value]="2" name="group{{i}}" [checked]="preg.opcionSeccionada == '2'"  (change)="radioChangeSelect($event,i)"></td>
                <td class="textCenterRadio"> <input type="radio" [value]="3" name="group{{i}}" [checked]="preg.opcionSeccionada == '3'"  (change)="radioChangeSelect($event,i)"></td>
                <td class="textCenterRadio"> <input type="radio" [value]="4" name="group{{i}}" [checked]="preg.opcionSeccionada == '4'"  (change)="radioChangeSelect($event,i)"></td>
                <td class="textCenterRadio"> <input type="radio" [value]="5" name="group{{i}}" [checked]="preg.opcionSeccionada == '5'"  (change)="radioChangeSelect($event,i)"></td>
            </tr>
        </table>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
        <em class="fas fa-solid fa-angle-left brnVolver" (click)="volverTab9()" ></em>
        <button class="btn-continuar" (click)="continuarTab11()">Continuar</button>
    </div>
  </div>