import { Component, OnInit } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { ApiService } from '../services/api.service';
import { AlertService } from '../services/alert.service';
import { NavigationService } from '../services/navigation.service';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-students',
  templateUrl: './students.component.html',
  styleUrls: ['./students.component.scss']
})
export class StudentsComponent implements OnInit {

  /** API */
  readonly STUDENT_API = 'api/estudiante-hablemos/search';

  /** Variable that holds the list of students */
  students = [];
  queryNumber = 0;
  flag: number = 1;

  /** Variable that holds the current page of the pagination */
  page = 1;
  /** Variable that shows if the there is a next page */
  nextPage = false;
  /** Variable that shows if there is a previous page */
  previousPage = false;
  /** Variable that holds the number of documents that are displayed in the page */
  programsPerPage = 25;
  /** Variable that holds the student type */
  type:any;

  constructor(private ngxSpinnerService: NgxSpinnerService,
    private alertService: AlertService,
    private apiService: ApiService,
    private navigationService: NavigationService,
    private activatedRoute: ActivatedRoute,
  ) {

  }

  ngOnInit(): void {
    const query = {
      page: 0,
      limit: 10,
      flag: 1,
      type:'especialista'
    }
    this.getTypeStudents({ query });
  }

  /**
   * Retrieves all the students from the backend
   */
  getAllStudents({ query }): void {
    this.ngxSpinnerService.show();
    this.apiService.post({ api: this.STUDENT_API, data: query }).subscribe(
      (response) => {
        this.queryNumber = response.totalDocs;
        this.students = response.docs;
        this.nextPage = response.hasNextPage;
        this.previousPage = response.hasPreviousPage;
      },
      (err) => {
        this.alertService.showError({
          msg: "No se pudieron cargar los estudiantes",
        });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  getTypeStudents({ query }): void {
    this.ngxSpinnerService.show();
    this.apiService.post({ api: this.STUDENT_API, data: query }).subscribe(
      (response) => {
      //   
       this.students = response;
      },
      (err) => {
        this.alertService.showError({
          msg: "No se pudieron cargar los estudiantes",
        });
      },
      (complete?) => {
        this.ngxSpinnerService.hide();
      }
    );
  }

  filterStudentsByCountry({ flag }) {
    if (this.flag != flag) {
      this.flag = flag;
      this.onSubmitSearch({ page: 1, flag });
    }
  }

  filterStudentsByType({ flag }) {
   if(flag == 1){
    this.flag = flag;
    this.type='especialista'
    this.onSubmitSearch({ page: 1, flag });

   }else if (flag == 2){
    this.flag = flag;
    this.type='consultor'
    this.onSubmitSearch({ page: 1, flag });

   }else if (flag ==3){
    this.flag = flag;
    this.type='estudiante'
    this.onSubmitSearch({ page: 1, flag });
   }
  }

  /**
   * Handles the change event in the programs per page selector
   * @param event
   */
   onPerPageChange(event): void {
    this.programsPerPage = event;
    this.onSubmitSearch({ page: 1, flag: this.flag });
  }

  /**
   * Handles page changes in the paginations controls
   */
   onPageChange(event): void {
    this.onSubmitSearch({ page: event, flag: this.flag});
  }

  /**
   * Handles the apply filters click event
   * @param page page;
   */
   onSubmitSearch({ page, flag }: { page: number, flag: number }): void {
    this.page = page;
    this.navigationService.navigateTo({
      path: 'student',
      params: {
        page,
        limit: this.programsPerPage,
        flag
      },
    });
    const query = {
      page,
      limit: this.programsPerPage,
      flag,
      type: this.type
    };
    this.getTypeStudents({query});
  }

}
