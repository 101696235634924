<!-- <section class="main-section" [class.active-principal-menu]="displayMenu"> -->
  <div
  class="card menu-container"
  [ngClass]="{ 'menu-collapsed-mobile': !isActiveMenuMobile, 'college-menu': isColegio, 'full-menu': isPremiumUser }"
  #boxSidebarMenu>
  <!-- <p class="cursorP">
    <em class="fas fa-light  fa-angle-left " (click)="openMenu()">
      <span class="btnVolver"> Volver</span>
    </em>
  </p>
  <div class="header lmenu"> Menú</div> -->

  <div class="card-body menu-options-container" *ngIf="isEstudiante || isRecruitment">
    <div class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isPremiumUser || isRecruitment">
      <div class="barra" [hidden]="actived != 'match'"></div>
      <img src="/assets/principal-menu/medidor-gris.png" alt="match" />
      <p
        [ngClass]="{ 'option-active': actived == 'match', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/preTest"
        (click)="activeOption('match')">
        Admissions Test
      </p>
    </div>
    <hr />
    <div class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isPremiumUser || isRecruitment">
      <div class="barra" [hidden]="actived != 'dashboard'"></div>
      <img src="/assets/principal-menu/dashboard-gris.png" alt="dashboard" />
      <p
        [ngClass]="{ 'option-active': actived == 'dashboard', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/skill-dashboard"
        (click)="activeOption('dashboard')">
        Tablero de Competencias
      </p>
    </div>

    <hr *ngIf="isPremiumUser || isRecruitment" />
    <div
      id="myProfileContainer"
      class="menu-option"
      (click)="onToogleMenuMobile()"
      *ngIf="isPremiumUser || isRecruitment">
      <div class="barra" [hidden]="actived != 'cv' || showMisExperiencias"></div>
      <div class="menu-options">
        <img src="/assets/principal-menu/cv.png" alt="cv" />
        <p
          [ngClass]="{
            'option-active': actived == 'cv' || showMisExperiencias,
            'display-mobile-none': !isActiveMenuMobile
          }"
          routerLink="/curriculum"
          (click)="activeOption('cv'); toggleMisExperiencias()">
          Ruta de Experiencias
        </p>
      </div>
      <div class="mis-experiencias" *ngIf="showMisExperiencias">
        <div class="optionWrapper">
          <p
          [ngClass]="{ 'option-active': actived == 'cv' }"
          routerLink="/curriculum"
          (click)="activeOption('cv')">
            Mi hoja de vida
          </p>
        </div>
        <div class="optionWrapper">
          <p
            [ngClass]="{ 'option-active': actived == 'experiences' }"
            routerLink="/my-experiences"
            (click)="activeOption('experiences', 'cv')">
            Mis experiencias
          </p>
        </div>
      </div>
    </div>
    <hr *ngIf="isPremiumUser || isRecruitment" />
    <div class="menu-option" (click)="onToogleMenuMobile()">
      <div class="barra" [hidden]="actived != 'search'"></div>
      <img src="/assets/principal-menu/loupe.png" alt="search" />
      <p
        [ngClass]="{ 'option-active': actived == 'search', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/home"
        (click)="activeOption('search')">
        Match Universitario
      </p>
    </div>
    <hr *ngIf="isPremiumUser || isRecruitment" />
    <div class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isPremiumUser || isRecruitment">
      <div class="barra" [hidden]="actived != 'favourites'"></div>
      <img src="/assets/principal-menu/corazon-gris.png" alt="favourite" />
      <p
        *ngIf="isRecruitment == false"
        [ngClass]="{ 'option-active': actived == 'favourites', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/wishlist"
        (click)="activeOption('favourites')">
        Wishlist de Programas
      </p>
      <p
        *ngIf="isRecruitment == true"
        [ngClass]="{ 'option-active': actived == 'favourites', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/favourites"
        (click)="activeOption('favourites')">
        Wishlist de Programas
      </p>
    </div>
    <hr *ngIf="isPremiumUser || isRecruitment" />
    <div class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isPremiumUser || isRecruitment">
      <div class="barra" [hidden]="actived != 'applications'"></div>
      <img src="/assets/principal-menu/check-cuadrado-gris.png" alt="applications" />
      <p
        [ngClass]="{ 'option-active': actived == 'applications', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/my-applications"
        (click)="activeOption('applications')">
        Mis Aplicaciones
      </p>
    </div>
    <hr *ngIf="isPremiumUser || isRecruitment" />
    <div class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isPremiumUser || isRecruitment">
      <div class="barra" [hidden]="actived != 'documents'"></div>
      <img src="/assets/principal-menu/documentos-2-gris.png" alt="documents" />
      <p
        [ngClass]="{ 'option-active': actived == 'documents', 'display-mobile-none': !isActiveMenuMobile }"
        (click)="activeOption('documents'); onGoToProfile()">
        Mis Documentos
      </p>
    </div>
    <hr *ngIf="isPremiumUser || isRecruitment" />
    <div class="menu-option" (click)="onToogleMenuMobile()" *ngIf="isPremiumUser || isRecruitment">
      <div class="barra" [hidden]="actived != 'blog'"></div>
      <img src="/assets/principal-menu/blog-gris.png" alt="blog" />
      <p
        [ngClass]="{ 'option-active': actived == 'blog', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/blog"
        (click)="activeOption('blog')">
        Herramientas
      </p>
    </div>
    <hr *ngIf="isPremiumUser || isRecruitment" />
    <div class="menu-option" (click)="onToogleMenuMobile()">
      <div class="barra" [hidden]="actived != 'marketplace'"></div>
      <img src="/assets/principal-menu/marketplace-gris.png" alt="marketplace" />
      <p
        [ngClass]="{ 'option-active': actived == 'marketplace', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/marketplace"
        (click)="activeOption('marketplace')">
        Asesorías y Servicios
      </p>
    </div>
  </div>
  <div class="card-body menu-options-container" *ngIf="isColegio">
    <div class="menu-option" (click)="onToogleMenuMobile()">
      <div class="barra" [hidden]="actived != 'search'"></div>
      <img src="/assets/principal-menu/loupe.png" alt="search" />
      <p
        [ngClass]="{ 'option-active': actived == 'search', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/home"
        (click)="activeOption('search')">
        Match Universitario
      </p>
    </div>
    <hr />
    <div class="menu-option" (click)="onToogleMenuMobile()">
      <div class="barra" [hidden]="actived != 'dashboard-school'"></div>
      <img src="/assets/principal-menu/dashboard-gris.png" alt="search" />
      <p
        [ngClass]="{ 'option-active': actived == 'dashboard-school', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/dashboard-school"
        (click)="activeOption('dashboard-school')">
        Tablero de Competencias
      </p>
    </div>
    <hr />
    <div class="menu-option" (click)="onToogleMenuMobile()">
      <div class="barra" [hidden]="actived != 'students'"></div>
      <img src="/assets/principal-menu/check-cuadrado-gris.png" alt="search" />
      <p
        [ngClass]="{ 'option-active': actived == 'students', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/studentsList"
        (click)="activeOption('students')">
        Aplicaciones / Estudiantes
      </p>
    </div>
    <hr />
    <div class="menu-option" (click)="onToogleMenuMobile()">
      <div class="barra" [hidden]="actived != 'blog'"></div>
      <img src="/assets/principal-menu/blog-gris.png" alt="search" />
      <p
        [ngClass]="{ 'option-active': actived == 'blog', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/blog"
        (click)="activeOption('blog')">
        Herramientas
      </p>
    </div>
    <hr />
    <div class="menu-option" (click)="onToogleMenuMobile()">
      <div class="barra" [hidden]="actived != 'marketplace'"></div>
      <img src="/assets/principal-menu/marketplace-gris.png" alt="search" />
      <p
        [ngClass]="{ 'option-active': actived == 'marketplace', 'display-mobile-none': !isActiveMenuMobile }"
        routerLink="/marketplace"
        (click)="activeOption('marketplace')">
        Asesorías y Servicios
      </p>
    </div>
  </div>
</div>
