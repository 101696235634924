<!--Jumbotron section-->
<div class="h-100">
  <!-- <img class="ambassadors-img principal-img" src="assets/home/images/home-blur.png" alt="banner"/> -->

  <!-- <img class="ambassadors-img img-responsive" src="assets/home/images/banner-home-responsive.jpg" alt="banner" /> -->
  <div class="ambassadors principal-container">
    <div class="text-separator row m-0 p-0">
      <div class="col-12 m-0 p-0 d-lg-flex justify-content-center">
        <div class="pr-3 display-mobile-none">
          <img class="girl-img" src="assets/home/images/girl.png" alt="girl"/>
        </div>
        <div class="principal-text-container visa-container pt-lg-3">
          <img class="display-mobile-none" id="birrete-icon" src="assets/home/images/birrete-rojo.png" alt=" birrete rojo" />
          <h2><strong>¿Quieres estudiar en Europa, Canadá o Estados Unidos?</strong></h2>
          <p>
            Usa los filtros y test para encontrar tu destino y programa ideal. U360 te pondrá en contacto con un representante autorizado para guiarte.
          </p>
          <div class="div-button-explore row m-0">
            <ng-multiselect-dropdown
            class="col-lg-6 col-5 pl-0 pr-0"
            placeholder="Programa"
            [settings]="settingsA"
            [data]="disciplineDropdownList"
            [(ngModel)]="discipline"
            (onSelect)="onSubmitSearch({ page: 1 })"
          >
            </ng-multiselect-dropdown>

            <ng-multiselect-dropdown
              class="col-lg-6 col-5 drop"
              placeholder="Nivel"
              [settings]="settingsA"
              [data]="accreditationDropdownList"
              [(ngModel)]="accreditation"
              (onSelect)="onSubmitSearch({ page: 1 })"
            >
            </ng-multiselect-dropdown>

            <div class="Search-Img-Out col-12 row m-0">
              <div class="col-lg-11 col-8 pr-2 pl-0">
                <div class="search-bar">
                  <input
                    (keydown.enter)="onSubmitSearch({ page: 1 })"
                    class="search-input"
                    [placeholder]="
                    scrWidth > 951
                    ? 'Busca programas, universidades, destinos...'
                    : 'Programas, universidades, destinos...'"
                    type="text"
                    id="program-name"
                    [(ngModel)]="name" />
                </div>
              </div>
              <div class="search-img-container col-lg-1 col-2">
                <img
                  (click)="onSubmitSearch({ page: 1 })"
                  class="search-image"
                  src="assets/utils/second-search-icon.png"
                  alt="search"
                  />
              </div>
            </div>
          </div>
          <!-- <div class="div-button-explore">
            <button routerLink="/program/search" class="button-explore" id="program-explore">Explorar <br> programas</button>
            <button (click)="navigateUniversitiesSection()" class="button-explore">Explorar universidades</button>
          </div> -->
        </div>
      </div>
    </div>
    <div id="tabs-content" class="search">
      <!-- <app-search-bar></app-search-bar> -->
    </div>
  </div>
</div>

<!-- Test section -->
<!-- <app-test-section></app-test-section> -->

<!--Ambassadors section-->
<!-- <div class="row">
  <div class="col-12 ambassadors background-ambassadors mb-40">
    <img class="ambassadors-img" src="assets/home/images/ambassadors.jpg" alt="u360 ambassadors"/>
    <img class="ambassadors-img img-responsive" src="assets/home/images/banner-home-responsive-2.png" alt="u360 ambassadors" />

    <div class="row" style="margin-left:6%">
      <div class="col-12 max-width-container ambassadors-container">
        <div class="careers-header-container career-section career-left">
          <div class="title-ambassadors-centered">
            <img class="careers-special-logo" src="assets/home/images/birrete-rojo.png" alt="u360 ambassadors" />
            <label id="title-ambassadors-section" class="ml-0">U360 Embajadores</label>
          </div>
          <p class="ambassadors-paragraph">
            <strong>Habla con estudiantes</strong>
            <br />
            Y conoce su experiencia
          </p>
          <button routerLink="/student" class="ambassadors-contact" id="ambassadors-button">
            Contactar estudiantes
          </button>
        </div>
      </div>
    </div>

  </div>
</div> -->


<!-- Careers section -->
<!-- <div class="careers">
  <div class="max-width-container">
    <div class="careers-header-container">
      <div class="title-centered mb-12">
        <h3 id="title-section" class="ml-0">U360 Programas</h3>
        <img class="careers-special-logo" src="assets/home/images/birrete-azul.png" alt="u360 popular careers" />
      </div>
      <p class="programs-subtitle">
        <strong>Carreras populares</strong>
        <br />
        ¡Ve directo a lo que más te gusta!
      </p>
    </div>
    <div class="all-program-container">
      <div class="career-top-row">
        <div class="program-container">
          <button *ngFor="let disciplina of config?.disciplineList"
            routerLink="/program/search"
            [queryParams]="{ discipline: disciplina.url }">
            {{ disciplina.name }}
          </button>
        </div>
      </div>
      <div class="all-careers-container">
        <button routerLink="/program/search"><span>Ver todos</span></button>
      </div>
    </div>
  </div>
</div> -->

<!--Visa section-->
<!-- <div class="visa">
  <div class="visa-principal">
    <p>
      <strong>¿Dudas sobre dónde empezar?</strong>
      <span>
        Prueba nuestros Tests gratuitos:
      </span>
    </p>
    <button (click)="onProfileBasedSearchClick()" id="profile-search-button">
      {{ 'Home.search-based-on-profile' | translate }}
    </button>
  </div>
</div> -->

<!--University section-->
<!-- <div class="ambassadors university-principal-container">
  <img class="ambassadors-img" src="assets/home/images/universities.jpg" alt="universities"/>
  <div class="max-width-container university-content">
    <div class="careers-header-container career-section careers-header-container-right title-university">
      <div class="title-centered-right">
        <img class="careers-special-logo" src="assets/home/images/birrete-rojo.png" alt="u360 ambassadors" />
        <h3 class="university-label ml-0" id="title-ambassadors-section">U360 Universidades</h3>
      </div>
      <p class="university-paragraph">
        <strong>¡Las universidades nos recomiendan!</strong>
      </p>
      <button (click)="(false)" id="university-button">Conoce más</button>
    </div>
    <div class="frame-container" *ngIf="isBrowser">
      <carousel id="carousel-1" cellsToShow="1">
        <div *ngFor="let data of slidesData; let i = index" class="carousel-cell">
          <iframe
            *ngIf="data.universidad?.linkVideo"
            [src]="data.universidad.linkVideo"
            frameborder="0"
            allowfullscreen></iframe>
          <label id="universidad-{{ i }}">
            <span>{{ data.universidad?.nombre | truncate: [29] }}</span>
            <br />
            <span id="city-country">
              {{ data.universidad?.ciudad?.nombre }}, {{ data.universidad?.ciudad?.pais?.nombre }}
            </span>
          </label>
        </div>
      </carousel>
    </div>
  </div>
</div> -->

<!-- Trusted universities -->
<!-- <div class="trusted-universities">
  <div class="trusted-universities-container">
    <h4 class="trusted-universities-paragraph">Trabajamos con una red de <strong>más de 1.000 universidades en el mundo</strong>, que confían en nosotros</h4>
    <div class="all-universities-container">
      <carousel *ngIf="scrWidth < 768" id="carousel-3" [cellsToShow]="scrWidth < 400 ? 1 : 2">
        <div *ngFor="let university of universitiesLogosList; let i = index" class="carousel-cell">
          <img [src]="university" [alt]="'Universidad confiada ' + index " class="trusted-university">
        </div>
      </carousel>
      <carousel *ngIf="scrWidth >= 768" id="carousel-3" [cellsToShow]="scrWidth < 1200 ? 3 : 4">
        <div *ngFor="let university of universitiesLogosList; let i = index" class="carousel-cell">
          <img [src]="university" [alt]="'Universidad confiada ' + index " class="trusted-university">
        </div>
      </carousel>
    </div>
  </div>
</div> -->

<!-- Instaram section (no funciona correctamente) -->
<!-- <div class="instagram-feed">
  <div class="max-width-container">
    <div class="careers-header-container career-section">
      <br />
      <div class="title-centered res-centered-left">
        <h3 id="title-section" class="ml-0">U360 Instagram</h3>
        <img class="careers-special-logo" src="assets/home/images/birrete-azul.png" alt="u360 popular careers" />
      </div>
      <div data-mc-src="7a107c68-5d5e-458f-ab78-939d035fcdce#instagram"></div>
    </div>
  </div>
</div> -->

<!-- Blog section -->
<!-- <div class="careers blogs">
  <div class="max-width-container">
    <div class="careers-header-container career-section">
      <br />
      <div class="title-centered res-centered-left">
        <h3 id="title-section" class="ml-0">U360 Blog</h3>
        <img class="careers-special-logo" src="assets/home/images/birrete-azul.png" alt="u360 popular careers" />
      </div>
      <p class="text-section-blogs" id="text-section" style="width: 100%;text-align: center;">
        <strong id="strong-text">Toma decisiones informadas.</strong>
        <br />
        ¡Tanta información en la red confunde!
        <br />
        Encuentra información de expertos en la industria para guiar tu proyecto.
      </p>
    </div>
    <div class="test-principal-container" *ngIf="isBrowser">
      <div class="all-blog-container">
        <carousel *ngIf="scrWidth < 768" id="carousel-2" [cellsToShow]="scrWidth < 500 ? 1 : 2">

          <div *ngFor="let blog of blogs" class="carousel-cell">
            <div class="test-img-container blog-container">
              <div class="blog-info ">
                <iframe *ngIf="blog.video" [src]="blog.video" title="Video Blogs" name="blogs" frameborder="0" allowfullscreen></iframe>
                <div class="blog-title">{{ blog.titulo }}</div>
                <div class="blog-text" [innerHTML]="blog.texto | truncate: [120]"></div>
              </div>
              <div class="blog-bottom">
                <label class="blog-date">Publicado el {{ blog.createdAt | date }}</label>
                <label class="blog-more" routerLink="/blog/{{ blog.slug }}">Leer más</label>
              </div>
            </div>
          </div>
        </carousel>
        <carousel *ngIf="scrWidth > 768" id="carousel-2" [cellsToShow]="scrWidth < 1200 ? 3 : 4">
          <div *ngFor="let blog of blogs" class="carousel-cell">
            <div class="test-img-container blog-container">
              <div class="blog-info">
                <iframe *ngIf="blog.video" [src]="blog.video" frameborder="0" allowfullscreen></iframe>
                <div class="blog-title">{{ blog.titulo }}</div>
                <div class="blog-text" [innerHTML]="blog.texto | truncate: [120]"></div>
              </div>
              <div class="blog-bottom">
                <label class="blog-date">Publicado el {{ blog.createdAt | date }}</label>
                <label class="blog-more" routerLink="/blog/{{ blog.slug }}">Leer más</label>
              </div>
            </div>
          </div>
        </carousel>
      </div>
      <div class="all-careers-container button-container">
        <button routerLink="/blog"><span>Ver más</span></button>
      </div>
    </div>
  </div>
</div> -->
