import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CookieService } from 'ngx-cookie-service';
import { AlertService } from 'src/app/public/services/alert.service';
import { ApiService } from 'src/app/public/services/api.service';
import { AuthService } from 'src/app/public/services/auth.service';

import Swal from "sweetalert2";

@Component({
  selector: 'app-paso3',
  templateUrl: './paso3.component.html',
  styleUrls: ['./paso3.component.scss'],
})
export class Paso3Component implements OnInit {
  @Output() notificacionTab3 = new EventEmitter<string>();
  public preguntas_seccion03:any;
  public dataFrm03:any;
  public listVideos: any[] = [];
  public urlSafe:SafeResourceUrl
  public urlFinal:string
  public detalle:any;
  public muestraDiv:boolean = false;
  public mostrarLayout:boolean = false;
  seleccionado: boolean = false;
  cookieValue: string;
  responseUser03: any;
  readonly getTest03 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest03 = 'api/get_internalizacionUser/';
  pasoActivo: any;
  constructor(public domSanitizer: DomSanitizer, private alertService: AlertService,
    private authService:AuthService,private apiService:ApiService,) {
    this.responseUser03 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.responseUser03) {
      this.apiService.get({ api: this.getTest03 + this.responseUser03._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //
            let res = response;
            let data_test03
            if(res.test[0].preguntas){
              data_test03=res.test[0];
            }else{
              data_test03=JSON.parse(res.test[0]);
            }
            this.dataFrm03 = response;
            this.pasoActivo= response.hojaTest;
            this.dataFrm03.test = data_test03;
            this.preguntas_seccion03= data_test03.preguntas[2];
            //
            for(let item of this.preguntas_seccion03.preguntas[0].opciones){
              //
              for(let item2 of  this.listVideos){
                //
                if(item.institucion == item2.detalle){
                  item2.seleccionado= item.interesa
                  if(item2.seleccionado){
                    this.seleccionado=true;
                  }
                }
              }
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    }
  }

  ngOnInit(): void {
    let variableDiv = document.getElementById('detalle-video');
    let variableDiv2 = document.getElementById('detalle-informacion');
    let variableDiv3 = document.getElementById('detalle-requisitos');
    let txt = `<div>
    <video controls src = "/assets/new-wizard-videos/new-top-tier.mp4" height = "330" style = "object-fit: cover;"></video>
    </div>`;
    let txt2 = `<div>
    <p><h5 style = "color: #219B9B;">Top Tier</h5></p>
    <p> Se caracterizan principalmente por tener un perfil muy académico y de investigación, y son las más selectivas en su proceso de admisión.</p>
    <p>Están en el top 1% de las mejores universidades del mundo y acceder a ellas requiere contar con un perfil integral, siendo el tipo de universidad que más exige las 5 competencias internacionales.
    <p>Entre ellas se encuentran las universidades de la Ivy League y el Russell Group.
    <p>A pesar de ser exigentes en su admisión, existen algunas  alternas para ingresar a estas universidades si no se cuenta con el perfil académico exigido.
    </div>`;
    let txt3 = `<div>
    <p><h5 style = "color: #219B9B;" id = "title-requisito">Requisitos generales admisión:</h5></p>
    <p><h6>La que más exige las 5 competencias:</h6></p>
    <p><ul>
    <li>Promedio académico alto 85%+/100% o un portafolio avanzado. </li>
    <li>Demostración de liderazgo / Impacto Social / Trabajo y Acción. Referencias personales / académicas y/o laborales.</li>
    <li>Idioma del destino en nivel avanzado</li>
    <li>Exámenes de admisión internos y del país. Ejemplo, SAT con promedio por encima de 1400 en Estados Unidos. A levels con buenos resultados en UK. </li>
    <li>SOP / Carta de motivos</li>
    </ul></p>
    <p>Pasa por un comité de revisión estricto y competitivo.</p>
    <p>NOTA: En caso de no tener perfil de admisión, se pueden revisar  alternas de ingreso por medio de programas diseñados como “puente” (pathways) para la finalización exitosa de estudios en estas instituciones.</p>
    </div>`;

    variableDiv.innerHTML = txt;
    variableDiv2.innerHTML = txt2;
    variableDiv3.innerHTML = txt3;

    let videos = [
      {
        _id: '6151ebec01f8150018593ee9',
        detalle: 'Top Tier',
        tipo: 'video',
        seleccionado: false,
        url: '/assets/new-wizard-videos/new-top-tier.mp4',
        video: '<div>' +
        '<video class="video-responsive" controls src = "/assets/new-wizard-videos/new-top-tier.mp4"></video>' +
        "</div>",
        informacion:
        '<div>' +
        '<p><h5>Top Tier</h5></p>' +
        '<p> Se caracterizan principalmente por tener un perfil muy académico y de investigación, y son las más selectivas en su proceso de admisión.</p>' +
        '<p>Están en el top 1% de las mejores universidades del mundo y acceder a ellas requiere contar con un perfil integral, siendo el tipo de universidad que más exige las 5 competencias internacionales. ' +
        '<p>Entre ellas se encuentran las universidades de la Ivy League y el Russell Group.' +
        '<p>A pesar de ser exigentes en su admisión, existen algunas  alternas para ingresar a estas universidades si no se cuenta con el perfil académico exigido."' +
        "</div>",
        requisitos:
        ' <div> ' +
        '<p><h5 id = "title-requisito">Requisitos generales admisión:</h5></p>' +
        '<p><h6>La que más exige las 5 competencias:</h6></p>' +
        '<p><ul>' +
        '<li>Promedio académico alto 85%+/100% o un portafolio avanzado. </li>' +
        '<li>Demostración de liderazgo / Impacto Social / Trabajo y Acción. Referencias personales / académicas y/o laborales.</li>' +
        '<li>Idioma del destino en nivel avanzado</li>' +
        '<li>Exámenes de admisión internos y del país. Ejemplo, SAT con promedio por encima de 1400 en Estados Unidos. A levels con buenos resultados en UK. </li>' +
        '<li>SOP / Carta de motivos</li>' +
        '</ul></p>' +
        '<p>Pasa por un comité de revisión estricto y competitivo.</p>' +
        '<p>NOTA: En caso de no tener perfil de admisión, se pueden revisar  alternas de ingreso por medio de programas diseñados como “puente” (pathways) para la finalización exitosa de estudios en estas instituciones.</p>' +
        "</div>"
      },
      {
        _id: '6151ec5001f8150018593eed',
        detalle: 'Experiential University',
        tipo: 'video',
        seleccionado: false,
        url: '/assets/new-wizard-videos/new-experiential.mp4',
        video: '<div>' +
        '<video class="video-responsive" controls src = "/assets/new-wizard-videos/new-experiential.mp4"></video>' +
        "</div>",
        informacion:
        '<div>' +
        '<p><h5>Experiential University</h5></p>' +
        '<p>En esta categoría, se encuentran las universidades que tienen un enfoque en la experiencia integral del estudiante, no solo en la parte académica y de investigación.</p>' +
        '<p> Le dan la misma importancia tanto a pregrados como posgrados y se encuentran generalmente en el top 5% del mundo, sin estar en el top 1% ' +
        '<p>Crean un ambiente universitario de balance entre lo académico / práctico + experiencia estudiantil, y tienen un pensamiento muy moderno.' +
        '<p>Entre estas podemos encontrar universidades jóvenes con buenos rankings en su categoría (por facultades), universidades de rankings medios y algunas de enfoque internacional."' +
        "</div>",
        requisitos:
        ' <div> ' +
        '<p><h5>Requisitos generales admisión:</h5></p>' +
        '<p><h6>La que más exige las 5 competencias:</h6></p>' +
        '<p><ul>' +
        '<li>Promedio académico alto 85%+/100% o un portafolio avanzado. </li>' +
        '<li>Demostración de liderazgo / Impacto Social / Trabajo y Acción. Referencias personales / académicas y/o laborales.</li>' +
        '<li>Idioma del destino en nivel avanzado</li>' +
        '<li>Exámenes de admisión internos y del país. Ejemplo, SAT con promedio por encima de 1400 en Estados Unidos. A levels con buenos resultados en UK. </li>' +
        '<li>SOP / Carta de motivos</li>' +
        '</ul></p>' +
        '<p>Pasa por un comité de revisión estricto y competitivo.</p>' +
        '<p>NOTA: En caso de no tener perfil de admisión, se pueden revisar  alternas de ingreso por medio de programas diseñados como “puente” (pathways) para la finalización exitosa de estudios en estas instituciones.</p>' +
        "</div>"
      },
      {
        _id: '6151ebec01f8150018593ee9',
        detalle: 'Practical learning',
        tipo: 'video',
        seleccionado: false,
        url: '/assets/new-wizard-videos/new-practical-learning.mp4',
        video: '<div>' +
        '<video class="video-responsive" controls src = "/assets/new-wizard-videos/new-practical-learning.mp4"></video>' +
        "</div>",
        informacion:
        '<div>' +
        '<p><h5>Practical learning</h5></p>' +
        '<p>Es una institución que tiene un alto enfoque en la enseñanza práctica “hands on”, con alto énfasis en tecnologías y habilidades que se usarán en el puesto de trabajo, bajo el concepto “Job Ready”</p>' +
        '<p>Tienen muy buenas conexiones laborales. No se preocupan por los rankings internacionales sino por la tasa de empleabilidad.' +
        '<p>Entre estas podemos encontrar, por ejemplo, algunas Universidades de Ciencias Aplicadas en Europa y los Colleges en Canadá' +
        '<p>El proceso de admisión es generalmente menos exigente y cuenta con programas de 1 a 4 años de duración: desde certificados, técnicos, tecnológicos y licenciaturas prácticas, así como posgrados técnicos y masters de' +
        "</div>",
        requisitos:
        ' <div> ' +
        '<p><h5>Requisitos generales admisión:</h5></p>' +
        '<ul>' +
        '<li>Promedio académico 65%+ o portafolio.</li>' +
        '<li>Demostración de idioma intermedio a avanzado dependiendo de la carrera.  alternas de ingreso por medio de Pathways.</li>' +
        '<li>En caso de no contar con un requisito académico, tienen  alternas de admisión: por liderazgo, demostración de trabajo, entre otros.</li>' +
        '</ul>' +
        "</div>"
      },
      {
        _id: '6151ec5001f8150018593eed',
        detalle: 'Vocational / Passion',
        tipo: 'video',
        seleccionado: false,
        url: '/assets/new-wizard-videos/new-vocational.mp4',
        video: '<div>' +
        '<video class="video-responsive" controls src = "/assets/new-wizard-videos/new-vocational.mp4"></video>' +
        "</div>",
        informacion:
        '<div>' +
        '<p><h5>Vocational / Passion</h5></p>' +
        '<p> Academias, institutos o facultades especializadas en áreas de vocación como escuelas de diseño, cine, letras y algunos colleges privados especializados.</p>' +
        '<p>El énfasis para escoger este tipo de institución no es el ranking internacional sino el interés vocacional de la persona y la pasión por su carrera.' +
        '<p>Algunas son reconocidas mundialmente y ganadoras de premios en sus industrias. Otras son locales con muy buenos convenios laborales.' +
        '<p>l proceso de admisión dependerá de un portafolio o competencias individuales especializadas, sin necesidad de contar con altos promedios académicos, por lo que tienen mayor accesibilidad."' +
        "</div>",
        requisitos:
        ' <div> ' +
        '<p><h5>Requisitos generales admisión:</h5></p>' +
        '<ul>' +
        '<li>Promedio académico 60%+ o portafolio.</li>' +
        '<li>Demostración de idioma intermedio a avanzado dependiendo del programa/institución.  alternas de ingreso por medio de Pathways.</li>' +
        '<li>SOP / Carta motivacional / Reel.</li>' +
        "</div>"
      },
    ];
    this.detalle = this.listVideos[1];
    this.listVideos = videos;


  }
  /**
   * Método para ver el detalle de la universidad
   * @param id Posicion de la universidad en el arreglo
   */
  public verdetalle(id) {
    this.muestraDiv=true;
    this.mostrarLayout = true;
    let icon0 = document.getElementById("0");
    let icon1 = document.getElementById("1");
    let icon2 = document.getElementById("2");
    let icon3 = document.getElementById("3");
    let variableDiv = document.getElementById('detalle-video');
    let variableDiv2 = document.getElementById('detalle-informacion');
    let variableDiv3 = document.getElementById('detalle-requisitos');
    // this.detalle = this.listVideos[id];

    if(id == 0){
      icon0.classList.remove('fa-plus');
      icon0.classList.add('fa-minus');

      if(icon1.classList.contains('fa-minus')){
        icon1.classList.remove('fa-minus');
        icon1.classList.add('fa-plus');
      }
      if(icon2.classList.contains('fa-minus')){
        icon2.classList.remove('fa-minus');
        icon2.classList.add('fa-plus');
      }
      if(icon3.classList.contains('fa-minus')){
        icon3.classList.remove('fa-minus');
        icon3.classList.add('fa-plus');
      }
      let txt = `<div>
      <video controls src = "/assets/new-wizard-videos/new-top-tier.mp4"></video>
      </div>`;
      let txt2 = `<div>
      <p><h5 style = "color: #219B9B;">Top Tier</h5></p>
      <p> Se caracterizan principalmente por tener un perfil muy académico y de investigación, y son las más selectivas en su proceso de admisión.</p>
      <p>Están en el top 1% de las mejores universidades del mundo y acceder a ellas requiere contar con un perfil integral, siendo el tipo de universidad que más exige las 5 competencias internacionales.
      <p>Entre ellas se encuentran las universidades de la Ivy League y el Russell Group.
      <p>A pesar de ser exigentes en su admisión, existen algunas  alternas para ingresar a estas universidades si no se cuenta con el perfil académico exigido.
      </div>`;
      let txt3 = `<div>
      <p><h5 style = "color: #219B9B;" id = "title-requisito">Requisitos generales admisión:</h5></p>
      <p><h6>La que más exige las 5 competencias:</h6></p>
      <p><ul>
      <li>Promedio académico alto 85%+/100% o un portafolio avanzado. </li>
      <li>Demostración de liderazgo / Impacto Social / Trabajo y Acción. Referencias personales / académicas y/o laborales.</li>
      <li>Idioma del destino en nivel avanzado</li>
      <li>Exámenes de admisión internos y del país. Ejemplo, SAT con promedio por encima de 1400 en Estados Unidos. A levels con buenos resultados en UK. </li>
      <li>SOP / Carta de motivos</li>
      </ul></p>
      <p>Pasa por un comité de revisión estricto y competitivo.</p>
      <p>NOTA: En caso de no tener perfil de admisión, se pueden revisar  alternas de ingreso por medio de programas diseñados como “puente” (pathways) para la finalización exitosa de estudios en estas instituciones.</p>
      </div>`;

      variableDiv.innerHTML = txt;
      variableDiv2.innerHTML = txt2;
      variableDiv3.innerHTML = txt3;
    }
    else if(id == 1){
      icon1.classList.remove('fa-plus');
      icon1.classList.add('fa-minus');

      if(icon0.classList.contains('fa-minus')){
        icon0.classList.remove('fa-minus');
        icon0.classList.add('fa-plus');
      }
      if(icon2.classList.contains('fa-minus')){
        icon2.classList.remove('fa-minus');
        icon2.classList.add('fa-plus');
      }
      if(icon3.classList.contains('fa-minus')){
        icon3.classList.remove('fa-minus');
        icon3.classList.add('fa-plus');
      }
      let txt = `<div>
      <video controls src = "/assets/new-wizard-videos/new-experiential.mp4"></video>
      </div>`;
    let txt2 =  `<div>
    <p><h5 style = "color: #219B9B;">Experiential University</h5></p>
    <p>En esta categoría, se encuentran las universidades que tienen un enfoque en la experiencia integral del estudiante, no solo en la parte académica y de investigación.</p>
    <p> Le dan la misma importancia tanto a pregrados como posgrados y se encuentran generalmente en el top 5% del mundo, sin estar en el top 1%
    <p>Crean un ambiente universitario de balance entre lo académico / práctico + experiencia estudiantil, y tienen un pensamiento muy moderno.
    <p>Entre estas podemos encontrar universidades jóvenes con buenos rankings en su categoría (por facultades), universidades de rankings medios y algunas de enfoque internacional.
    </div>`;
    let txt3 = `<div>
    <p><h5 style = "color: #219B9B;">Requisitos generales admisión:</h5></p>
    <p><h6>La que más exige las 5 competencias:</h6></p>
    <p><ul>
    <li>Promedio académico alto 85%+/100% o un portafolio avanzado. </li>
    <li>Demostración de liderazgo / Impacto Social / Trabajo y Acción. Referencias personales / académicas y/o laborales.</li>
    <li>Idioma del destino en nivel avanzado</li>
    <li>Exámenes de admisión internos y del país. Ejemplo, SAT con promedio por encima de 1400 en Estados Unidos. A levels con buenos resultados en UK. </li>
    <li>SOP / Carta de motivos</li>
    </ul></p>
    <p>Pasa por un comité de revisión estricto y competitivo.</p>
    <p>NOTA: En caso de no tener perfil de admisión, se pueden revisar  alternas de ingreso por medio de programas diseñados como “puente” (pathways) para la finalización exitosa de estudios en estas instituciones.</p>
    </div>`;

    variableDiv.innerHTML = txt;
    variableDiv2.innerHTML = txt2;
    variableDiv3.innerHTML = txt3;
    }

    else if(id == 2){
      icon2.classList.remove('fa-plus');
      icon2.classList.add('fa-minus');

      if(icon0.classList.contains('fa-minus')){
        icon0.classList.remove('fa-minus');
        icon0.classList.add('fa-plus');
      }
      if(icon1.classList.contains('fa-minus')){
        icon1.classList.remove('fa-minus');
        icon1.classList.add('fa-plus');
      }
      if(icon3.classList.contains('fa-minus')){
        icon3.classList.remove('fa-minus');
        icon3.classList.add('fa-plus');
      }
      let txt = `<div>
      <video controls src = "/assets/new-wizard-videos/new-practical-learning.mp4"></video>
      </div>`;
      let txt2 = `<div>
      <p><h5 style = "color: #219B9B;">Practical learning</h5></p>
      <p>Es una institución que tiene un alto enfoque en la enseñanza práctica “hands on”, con alto énfasis en tecnologías y habilidades que se usarán en el puesto de trabajo, bajo el concepto “Job Ready”</p>
      <p>Tienen muy buenas conexiones laborales. No se preocupan por los rankings internacionales sino por la tasa de empleabilidad.
      <p>Entre estas podemos encontrar, por ejemplo, algunas Universidades de Ciencias Aplicadas en Europa y los Colleges en Canadá
      <p>El proceso de admisión es generalmente menos exigente y cuenta con programas de 1 a 4 años de duración: desde certificados, técnicos, tecnológicos y licenciaturas prácticas, así como posgrados técnicos y masters de
      </div>`;;
      let txt3 = `<div>
      <p><h5 style = "color: #219B9B;">Requisitos generales admisión:</h5></p>
      <ul>
      <li>Promedio académico 65%+ o portafolio.</li>
      <li>Demostración de idioma intermedio a avanzado dependiendo de la carrera.  alternas de ingreso por medio de Pathways.</li>
      <li>En caso de no contar con un requisito académico, tienen  alternas de admisión: por liderazgo, demostración de trabajo, entre otros.</li>
      </ul>
      </div>`;

      variableDiv.innerHTML = txt;
      variableDiv2.innerHTML = txt2;
      variableDiv3.innerHTML = txt3;
    }
    else if(id == 3){
      icon3.classList.remove('fa-plus');
      icon3.classList.add('fa-minus');

      if(icon0.classList.contains('fa-minus')){
        icon0.classList.remove('fa-minus');
        icon0.classList.add('fa-plus');
      }
      if(icon1.classList.contains('fa-minus')){
        icon1.classList.remove('fa-minus');
        icon1.classList.add('fa-plus');
      }
      if(icon2.classList.contains('fa-minus')){
        icon2.classList.remove('fa-minus');
        icon2.classList.add('fa-plus');
      }
      let txt = `<div>
      <video controls src = "/assets/new-wizard-videos/new-vocational.mp4"></video>
      </div>`;
      let txt2 = `<div>
      <p><h5 style = "color: #68CFC8;" >Vocational / Passion</h5></p>
      <p> Academias, institutos o facultades especializadas en áreas de vocación como escuelas de diseño, cine, letras y algunos colleges privados especializados.</p>
      <p>El énfasis para escoger este tipo de institución no es el ranking internacional sino el interés vocacional de la persona y la pasión por su carrera.
      <p>Algunas son reconocidas mundialmente y ganadoras de premios en sus industrias. Otras son locales con muy buenos convenios laborales.
      <p>l proceso de admisión dependerá de un portafolio o competencias individuales especializadas, sin necesidad de contar con altos promedios académicos, por lo que tienen mayor accesibilidad.
      </div>`;
      let txt3 = `<div>
      <p><h5 style = "color: #68CFC8;">Requisitos generales admisión:</h5></p>
      <ul>
      <li>Promedio académico 60%+ o portafolio.</li>
      <li>Demostración de idioma intermedio a avanzado dependiendo del programa/institución.  alternas de ingreso por medio de Pathways.</li>
      <li>SOP / Carta motivacional / Reel.</li>
      </div>`;

      variableDiv.innerHTML = txt;
      variableDiv2.innerHTML = txt2;
      variableDiv3.innerHTML = txt3;
    }
  }
  public async continuarTab2(){
    if(!this.seleccionado){
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe seleccionar una universidad'
      })
      return false
    }else{
      let paso = this.pasoActivo
      if(this.pasoActivo < 3){
        paso = this.pasoActivo = 3;
      }
      this.dataFrm03.hojaTest= paso;

      this.dataFrm03.test.preguntas[2] = this.preguntas_seccion03;
      //
      this.dataFrm03.test = JSON.stringify(this.dataFrm03.test );
     this.actualizarPaso3();
    }
  }
  public actualizarPaso3(){
    let data = this.dataFrm03;
    this.apiService
    .put({ api: this.updateFrmTest03 + this.dataFrm03._id, data })
    .subscribe(
      (response) => {

        this.notificacionTab3.emit("continuar");
      },
      (err) => {

      }
    );
  }
  public volverTab2(){
    this.notificacionTab3.emit("volver")
  }
  public checkValue(pos){

    for(let item of this.preguntas_seccion03.preguntas[0].opciones){
      for(let item2 of  this.listVideos){
        //
        if(item.institucion == item2.detalle){
          //
          item.interesa = item2.seleccionado
        }
      }
    }
        this.seleccionado= this.listVideos[pos].seleccionado;

  }

  // public changeUrl(x:string){
  //
  //   // this.urlSafe = this.domSanitizer.bypassSecurityTrustResourceUrl(x)
  //   // this.urlFinal = this.urlSafe['changingThisBreaksApplicationSecurity']

  //   //
  //   // let video = document.getElementById('videoFrame')

  //   // video.setAttribute('src','this.urlFinal')


  // }

  // public videoTipoUni(x:string){
  //   const boxVideo = document.createElement('iframe')
  //   boxVideo.setAttribute('src',x)
  //   boxVideo.style.width='115.38px'
  //   boxVideo.style.height='64.89px'
  //   const videoDiv= document.getElementById('content-videobox')
  //   videoDiv.appendChild(boxVideo)
  // }
}
