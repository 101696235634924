<div class="header">
  <div class="header-container">
    <div class="d-flex">
      <div *ngIf="!loggedIn" class="dropdown source display-mobile" dropdown>
        <button class="singin-button-menu btn btn-outline-light" dropdownToggle aria-controls="dropdown-basic">
          <img
            id="bars-menu"
            alt="menu"
            src="assets/navbar/menu-2.png"
          />
        </button>
        <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu drop-menu"
            role="menu" aria-labelledby="button-basic">
          <li role="menuitem" routerLink="/services" class="dropdown-item options-item mt-2">
            <img
              alt="servicios"
              src="assets/navbar/servicios-icon.png"
              class="mr-3"
            >
            Servicios
          </li>
          <li role="menuitem" routerLink="/program/search" class="dropdown-item options-item my-4">
            <img
              alt="programas"
              src="assets/navbar/programas-icon.png"
              class="mr-3"
            >
            Programas
          </li>
          <li role="menuitem" routerLink="/home" class="dropdown-item options-item mb-2">
            <img
              alt="buscador"
              src="assets/navbar/buscador-icon.png"
              class="mr-3"
            >
            Buscador</li>
        </ul>
      </div>
      <div class="logo-container">
        <img
          alt="U360 logo"
          id="home-logo"
          src="assets/navbar/logo.png"
          [ngClass]="{ 'mx-4' : loggedIn}"
          (click)="$event.stopPropagation(); onLogoClick()"
        />
      </div>
    </div>
    <!-- <div class="row header-options">
      <label routerLink="/student">Especialistas Hec</label>
      <label routerLink="/services" routerLinkActive="activo">Servicios</label>

      <div class="dropdown source">
        <label>Blog <em class="fas fa-solid fa-chevron-down" ></em></label>
        <div class="dropdown-content source-content-blog">
          <a routerLink="/blog">Blog</a>
          <a routerLink="/free-guides">Guías gratuitas</a>
        </div>
      </div>
      <div class="dropdown source">
        <label>Test <em class="fas fa-solid fa-chevron-down" ></em></label>
        <div class="dropdown-content source-content-blog">
          <a routerLink="/leaderTest">Test de internalización</a>
          <a routerLink="/english-test">Test Inglés Gratuito</a>
          <a routerLink="/visa-thermometer">Probabilidades Visa</a>
          <a routerLink="/cultural-item">Match Destino</a>
        </div>
      </div>
      <label
        id="program-design"
        routerLink="/program/search"
        routerLinkActive="activo"
        >Programas</label
      >
      <label routerLink="/favourites" routerLinkActive="activo" *ngIf="loggedIn"
        >
        <em class="fas fa-light fa-heart" style="color: #58688D;"></em>
      </label >
      <label routerLink="../shopping-cart" routerLinkActive="activo" *ngIf="loggedIn"
        >
        <em class="fa fa-shopping-cart" style="color: #58688D;"></em>
      </label >
    </div> -->

    <div class="row header-options">

      <label class="nav-options" routerLink="/services" routerLinkActive="activo">Servicios</label>
      <label class="nav-options" routerLink="/program/search" routerLinkActive="activo">Programas</label>
      <label class="nav-options" routerLink="/home/buscador" routerLinkActive="activo">Buscador</label>

      <!-- <label class="dropdown source" id="program-design">Iniciar sesion</label> -->
    </div>

    <div class="dropdown source display-mobile" *ngIf="!loggedIn" dropdown>
      <button class="singin-button-menu btn btn-outline-light" dropdownToggle aria-controls="dropdown-basic">
        <img
          class="user-sign-in"
          alt="menu"
          src="assets/navbar/sign-in-icon.png"
        />
      </button>
      <ul id="dropdown-alignment" *dropdownMenu class="dropdown-menu dropdown-menu-right drop-menu"
          role="menu" aria-labelledby="button-alignment">
        <li role="menuitem" class="dropdown-item sign-item" (click)="onLoginLogoutClick()">Iniciar sesión</li>
        <li role="menuitem" class="dropdown-item sign-item" (click)="onSignUpButtonClick()">Registrarse</li>
      </ul>
    </div>

    <div class="header-singin display-mobile-none" *ngIf="!loggedIn">
      <label class="register" (click)="onSignUpButtonClick()">Regístrate</label>
      <div class="dropdown source">

        <button class="singin-button" (click)="$event.stopPropagation();onLoginLogoutClick()">
          <!-- <span class="material-icons" id="icon"> person </span> -->
          <!-- <em class="fas fa-light fa-user" style="color: #58688D;"></em>
           -->
           <label class="log-in">Iniciar sesión</label>
        </button>
        <!-- <div class="dropdown-content source-content-login">
          <a (click)="$event.stopPropagation(); onLoginLogoutClick()"
            >Inicia Sesión </a
          >
          <a (click)="$event.stopPropagation(); onSignUpButtonClick()"
            >Regístrate</a
          >
        </div> -->

        <!-- <div class="dropdown-content source-content-login">
          <a (click)="$event.stopPropagation(); onLoginLogoutClick()"
            >Como estudiante</a
          >
          <a (click)="$event.stopPropagation();  onLoginLogoutClick()"
            >Como colegio</a
          >
        </div> -->
      </div>
    </div>

    <div class="user-container">
      <div *ngIf="loggedIn" class="dropdown">
        <div class="user-info">
          <button (click)="$event.stopPropagation()" id="profile-menu-button">
            <span class="material-icons mx-2" id="icon"> person </span>
            <div class="user-name mr-2" *ngIf="loggedIn">{{ username }}</div>
          </button>
        </div>
        <div class="dropdown-content mt-1">
          <a class="sign-item" (click)="$event.stopPropagation(); onGoToProfile()">{{
            "Header.my-profile" | translate
          }}</a>
          <a
            class="sign-item"
            *ngIf="currentUserType === 'Recruitment'"
            (click)="$event.stopPropagation(); onRegisterStudent()"
          >
            Register student
          </a>
          <a class="sign-item" (click)="$event.stopPropagation()" routerLink="unlink-account" *ngIf="tipoUSer != 'Colegio'">Desvincular Cuenta (FB)</a>
          <a class="sign-item" *ngIf="!isPremiumUser && tipoUSer == 'Estudiante'" (click)="onFullAccess($event)">Acceso premium</a>
          <a class="sign-item" (click)="$event.stopPropagation()" routerLink="/shopping-cart">Carrito de compras</a>
          <a class="sign-item" (click)="$event.stopPropagation(); goDashbboard()" routerLink="dashboard-school" *ngIf="tipoUSer=='Colegio'">Mi Dashboard</a>
          <!-- <label *ngIf="currentLanguage === 'en'" id="language-change-logo-es" (click)="$event.stopPropagation(); useLanguage('es')">Change language</label>
          <label *ngIf="currentLanguage === 'es'" id="language-change-logo-en" (click)="$event.stopPropagation(); useLanguage('en')">Cambiar idioma</label> -->
          <a class="sign-item" (click)="$event.stopPropagation(); onLoginLogoutClick()">{{
            "Header.logout" | translate
          }}</a>
        </div>
      </div>
    </div>
  </div>
  <!-- <div
    class="wrap"
    [ngClass]="
      isHome || isProgram || isUniversity ? 'home-header' : 'general-header'
    "
  >
    <p class="marquee">
      <a href="/web/visa-thermometer" class="breaking-news-headline" *ngIf="isHome">
        {{ newsTexts }}
      </a>
      <a
        href="#"
        class="breaking-news-headline"
        *ngIf="isProgram || isUniversity"
      >
        ESTA INFORMACIÓN ESTÁ SIENDO TRADUCIDA POR UN ROBOT
      </a>
    </p>
  </div> -->
  <div
    class="menu-responsive"
    [ngClass]="showMenu ? 'show-container' : 'noShow-container'"
  >
    <div class="menu-container-img">
      <img
        alt="U360 logo"
        id="home-logo"
        src="assets/navbar/logo.png"
        (click)="$event.stopPropagation(); onLogoClick()"
      />
      <img
        id="close-img"
        alt="close menu"
        src="assets/utils/close-outline.svg"
        (click)="$event.stopPropagation(); showMenuAction()"
      />
    </div>
    <label
      id="program-design-two"
      (click)="showMenuAction()"
      routerLink="/program/search"
      routerLinkActive="activo"
      >Programas</label
    >
    <label (click)="showMenuAction()" routerLink="/student"
      >Especialistas Hec</label
    >
    <label
      (click)="showMenuAction()"
      routerLink="/services"
      routerLinkActive="activo"
      >Servicios</label
    >
    <label
      (click)="showMenuAction()"
      routerLink="/favourites"
      routerLinkActive="activo"
      >Favoritos</label
    >
    <div class="dropdown source">
      <label>Blog</label>
      <div class="dropdown-content source-content">
        <a (click)="showMenuAction()" routerLink="/blog">Blog</a>
        <a (click)="showMenuAction()" routerLink="/cultural-item"
          >Match Destino</a
        >
        <a (click)="showMenuAction()" routerLink="/visa-thermometer"
          >Probabilidades Visa</a
        >
        <a (click)="showMenuAction()" routerLink="/english-test"
          >Test Inglés Gratuito</a
        >
      </div>
    </div>
  </div>
</div>
<!-- <app-principal-menu
[displayMenu]="openMenu"
(newEventEmiter)="onDisplayMenu()"
></app-principal-menu> -->
