import { Component, OnInit, ViewChild } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-experience-cards',
  templateUrl: './experiencesCards.component.html',
  styleUrls: ['./experienceCards.component.scss']
})
export class ExperienceCardsComponent implements OnInit {
  @ViewChild('confirmationModal', { static: false }) confirmationModal: any;
  formList: any[] = [];
  formData: any = {
    experienceName: '',
    supervisorName: '',
    supervisorLastName: '',
    supervisorEmail: '',
    supervisorNumber: Number,
    initDate: Date,
    endDate: Date,
    isOngoing: Boolean,
    selectedActivity: [],
    selectedType: [],
    selectedApproaches: [],
    selectedSkills: [],
    htmlContent: '',
    approvalStatus: '',
  };

  selectedExperienceIndex: number | undefined;
  selectedExperience: any;
  showConfirmation = false;
  skillsArray = [
    { id: 'Liderazgo', name: 'Liderazgo', selected: false },
    { id: 'Trabajo y acción', name: 'Trabajo y acción', selected: false },
    { id: 'Aprendizaje', name: 'Aprendizaje', selected: false },
    { id: 'Impacto', name: 'Impacto', selected: false },
    { id: 'Global Mind', name: 'Global Mind', selected: false },
  ];
  isModalOpen = false;
  modalRef: NgbModalRef;
  selectedSkills: string[] = [];

  constructor(private modalService: NgbModal) { }

  ngOnInit(): void { }

  updateFormData(formData: any[]) {
    this.formList = formData;
    this.sortByEndDate();
  }

  toggleSkillSelection(skill: string) {
    const index = this.selectedSkills.indexOf(skill);
    if (index > -1) {
      this.selectedSkills.splice(index, 1);
    } else {
      this.selectedSkills.push(skill);
    }
  }

  isSelectedSkill(skill: string): boolean {
    return this.selectedSkills.includes(skill);
  }

  resetExperience() {
    this.formData = {};
    this.skillsArray.forEach(skill => (skill.selected = false));
    this.selectedSkills = [];
  }

  shouldShowCard(card: any) {
    if (this.selectedSkills.length === 0) {
      return true;
    }

    for (const skill of this.selectedSkills) {
      if (!card.selectedSkills.includes(skill)) {
        return false;
      }
    }

    return true;
  }

  formatDate(date: string): string {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    const formattedDate = new Date(date).toLocaleDateString('en-US', options);
    return formattedDate;
  }

  showDeleteConfirmation(experience: any, index: number) {
    Swal.fire({
      title: 'Confirmar Eliminación',
      text: '¿Estás seguro de que deseas eliminar esta experiencia?',
      icon: 'warning',
      customClass: {
        title: 'my-title-class',
        icon: 'my-icon-class',
        confirmButton: 'my-confirm-button-class',
        cancelButton: 'my-cancel-button-class',
      },
      confirmButtonColor: '#545ba8',
      showCancelButton: true,
      confirmButtonText: 'Eliminar',
      cancelButtonText: 'Cancelar'
    }).then((result) => {
      if (result.isConfirmed) {
        this.selectedExperience = experience;
        const selectedIndex = this.formList.indexOf(this.selectedExperience);

        if (selectedIndex !== -1) {
          this.formList.splice(selectedIndex, 1);
        }

        this.selectedExperience = null;

        Swal.fire(
          'Eliminada!',
          'Tu experiencia ha sido eliminada.',
          'success'
        );
      }
    });
  }

  decodeEntities(encodedString: string): string {
    const parser = new DOMParser();
    const dom = parser.parseFromString('<!doctype html><body>' + encodedString, 'text/html');
    return dom.body.textContent;
  }

  trimHtmlContent(content: string): string {
    const maxLength = 270;
    if (content.length <= maxLength) {
      return content;
    } else {
      const trimmedContent = content.substring(0, maxLength);
      return trimmedContent + '...';
    }
  }

  sortByEndDate() {
    this.formList.sort((a, b) => {

      if (!a.endDate) {
        return -1;
      }
      if (!b.endDate) {
        return 1;
      }

      const endDateA = new Date(a.endDate);
      const endDateB = new Date(b.endDate);
      const currentDate = new Date();

      const diffA = Math.abs(endDateA.getTime() - currentDate.getTime());
      const diffB = Math.abs(endDateB.getTime() - currentDate.getTime());

      return diffA - diffB;
    });
  }

}
