import { Component, OnInit, AfterViewInit, ViewChild } from '@angular/core';
import { ApiService } from '../services/api.service';
import { MatTableDataSource } from '@angular/material/table';
import {MatPaginator} from '@angular/material/paginator';
import { NavigationService } from '../services/navigation.service';
import {NgxSpinnerService} from 'ngx-spinner';

@Component({
  selector: 'app-school-students-list',
  templateUrl: './school-students-list.component.html',
  styleUrls: ['./school-students-list.component.scss']
})
export class SchoolStudentsListComponent implements OnInit  {

  /**Api to get data from school */
  readonly GET_DATA_SCHOOL = 'api/colegioAdmin/';

  /**Api to get data from students */
  readonly GET_STUDENTS = 'api/test_internalizacionUserByColegio/student-list';

  filter = '';

  schoolData: any;
  studentsData: any;
  nombreColegio = '';
  dataSource: any;


  studentPerPage: any;

  pageLimit: any;
  pageResult: any;
  hasPrevPage: any;
  hasNextPage: any;
  prevPage: any;
  nextPage: any;
  totalStudents: any;

  sizePagination = [];

  user = JSON.parse(localStorage.getItem('user'));

  displayedColumns: string[] = ['nombre', 'grado', 'insignia', 'promedio', 'aplicaciones', 'verperfil'];

  // variables para filtro
  studentFilter: any;

  listTable: any = [];

  constructor(
    private apiService: ApiService,
    private navigationService: NavigationService,
    private ngxSpinnerService: NgxSpinnerService,
  ) { }

  ngOnInit(): void {
    this.getColegio();
  }


  getColegio() {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.GET_DATA_SCHOOL + this.user._id }).subscribe(
      (response) => {
        if (response._id) {
          this.schoolData = response;
          this.nombreColegio = this.schoolData.nombre;

          const queryStudents = {
            colegio: this.schoolData._id,
            page: 1,
            limit: 10
          };
          this.getStudentsList(queryStudents);
        }
      }, () => this.ngxSpinnerService.hide()
    );
  }

  getStudentsList(queryStudents){
    this.apiService.post({api: this.GET_STUDENTS, data: queryStudents}).subscribe(
      (resultPaginate) => {
        if (resultPaginate && resultPaginate.length > 0){
          const students = resultPaginate;

          const formatData = [];
          for (const student of students) {
            const insigniaAssigned = this.checkInsignia(student.perfil);
            const fotoPerfil = student.documentos[0]?.filter((document) => document.nombre == 'photo');
            const data = {
              userId: student.userId[0],
              nombre: `${student.nombreUsuario[0]} ${student.apellidoUsuario[0]}`,
              grado: student.grado,
              insignia: insigniaAssigned,
              promedio: parseInt(student.promedio).toFixed(0),
              aplicaciones: student.cant_aplicaciones,
              fotoPerfil: fotoPerfil?.length > 0 ? fotoPerfil[0].archivo : '/assets/sidebar/profile.png'
            };
            formatData.push(data);
          }
          this.listTable = formatData;
          this.studentsData = new MatTableDataSource(formatData);
          this.ngxSpinnerService.hide();
        }
      }, () => this.ngxSpinnerService.hide()
    );
  }

  applyFilter(event: Event) {
    let filterValue = (event.target as HTMLInputElement).value;
    filterValue = filterValue.trim().toLowerCase();
    const newInfoFilter = this.listTable.filter((student) => {
      if (student.nombre.trim().toLowerCase().includes(filterValue) ||
          student.grado.trim().toLowerCase().includes(filterValue) ||
          student.promedio.trim().toLowerCase().includes(filterValue) ||
          student.aplicaciones.toString().trim().toLowerCase().includes(filterValue)) {
        return true;
      }
    })
    this.studentsData = new MatTableDataSource(newInfoFilter);
  }

  checkInsignia(perfil){
    switch (perfil){
      case 'Master':
        return '/assets/dashboard-skill/insignias/master.png';

      case 'Profesional':
        return '/assets/dashboard-skill/insignias/profesional.png';

      case 'Avanzado':
        return '/assets/dashboard-skill/insignias/avanzado.png';

      case 'Promedio':
        return '/assets/dashboard-skill/insignias/promedio.png';

      case 'En construccion':
        return '/assets/dashboard-skill/insignias/en-construccion.png';

      default:
        return '/assets/curriculum/preguntas.png';
    }
  }

  getByPagination(event){
    const queryStudents = {
      colegio: this.schoolData._id,
      limit: event.pageSize,
      page: event.pageIndex + 1

    };
    this.getStudentsList(queryStudents);
  }

  navigateToResume(event){
    this.navigationService.navigateTo({
      path: 'student-resume/' + event,
      params: event
    });
  }
}
