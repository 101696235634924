import {Component, HostListener, OnInit} from '@angular/core';
import Swal from 'sweetalert2';
import { ApiService } from '../services/api.service';
import * as moment from 'moment';
import * as XLSX from 'xlsx';
import { Router } from '@angular/router';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import {NgxSpinnerService} from 'ngx-spinner';
import {tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs';

@Component({
  selector: 'app-dashboard-school',
  templateUrl: './dashboard-school.component.html',
  styleUrls: ['./dashboard-school.component.scss'],
})
export class DashboardSchoolComponent implements OnInit {
  generalDisplay = true;
  skillsDisplay = false;

  resultadoGeneral: any;
  chartPlugins = [ChartDataLabels];
  graphicGlobalSchools = {
    data: {
      labels: [ '1', '2', '3', '4', '5'],
      datasets: [
        { data: [ 0, 0, 0, 0, 0 ],
          backgroundColor: ['#3d45ac', '#a3b798', '#c09c9d', '#cc8d8f', '#90beb5'],
          hoverBackgroundColor: []
        },
      ]
    },
    options: {
      responsive: false,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          barPercentage: 0.5
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            max: 100,
            stepSize: 20,
            callback: function (value) {
              return (value / this.max * 100).toFixed(0) + '%'; // convert it to percentage
            },
          }
        }]
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          formatter: function(value, context) {
            return value + '%';
          }
        }
      }
    }
  };
  graphicCountriesApplications = {
    data: {
      labels: ['1', '2', '3', '4', '5'],
      datasets: [
        {
          data: [0, 0, 0, 0, 0],
          backgroundColor: ['#f8c185', '#4b9d9d', '#4a51ab', '#d57693', '#3d88c9'],
          hoverOffset: 8
        },
      ]
    },
    options: {
      responsive: false,
      legend: {
        position: 'bottom',
      },
      tooltips: {
        enabled: false
      },
      plugins: {
        datalabels: {
          color: '#ffffff',
          formatter: function(value, context) {
            return value + '%';
          }
        }
      }
    },
  };
  graphicEnglishTest = {
    data: {
      labels: ['1', '2', '3', '4', '5'],
      datasets: [
        {
          data: [30, 10, 10, 25, 25],
          backgroundColor: ['#f8c185', '#4b9d9d', '#4a51ab', '#d57693', '#3d88c9'],
          hoverOffset: 8
        },
      ]
    },
    options: {
      responsive: false,
      legend: {
        position: 'bottom',
      },
      tooltips: {
        enabled: false
      },
      plugins: {
        datalabels: {
          color: '#ffffff',
          formatter: function(value, context) {
            return value + '%';
          }
        }
      }
    },
  };
  graphicCompetences = {
    data: {
      labels: [ '1', '2', '3', '4', '5'],
      datasets: [
        { data: [ 0, 0, 0, 0, 0 ],
          backgroundColor: ['#3d45ac', '#4a93c7', '#4c9d9d', '#d36910',  '#dc597b'],
          hoverBackgroundColor: []
        },
      ]
    },
    options: {
      responsive: false,
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            display: false
          },
          barPercentage: 0.5
        }],
        yAxes: [{
          ticks: {
            beginAtZero: true,
            max: 100,
            stepSize: 20,
            callback: function (value) {
              return (value / this.max * 100).toFixed(0) + '%'; // convert it to percentage
            },
          }
        }]
      },
      plugins: {
        datalabels: {
          anchor: 'end',
          align: 'end',
          formatter: function(value, context) {
            return value + '%';
          }
        }
      }
    }
  };
  graphicStudentsDiscipline = {
    data: {
      labels: ['1', '2', '3', '4', '5'],
      datasets: [
        {
          data: [0, 0, 0, 0, 0, 0],
          backgroundColor: ['#3d88c9', '#f8c185', '#4a51ab', '#d36910', '#d57693', '#4b9d9d'],
          hoverOffset: 8
        },
      ]
    },
    options: {
      responsive: false,
      legend: {
        position: 'right',
      },
      tooltips: {
        enabled: false
      },
      plugins: {
        datalabels: {
          color: '#ffffff',
          formatter: function(value, context) {
            return value + '%';
          }
        }
      }
    },
  };
  graphicGeneralSkills = {
    data: {
      labels: [ '0', '2023-1', '2023-2', '2024-1', '2024-2', '2025-1', '2025-3', '2026-1', '2026-2', '2027-1', '2027-2'],
      datasets: [
        {
          data: [ 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
          backgroundColor: ['rgba(92, 155, 155, 0.8)'],
          borderColor: '#032560',
          pointBackgroundColor: '#FFFFFF',
          pointBorderColor: '#032560',
          pointRadius: 5,
          hoverBackgroundColor: []
        },
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      elements: {
        line: {
          tension: 0.1,
          borderWidth: 1,
        }
      },
      legend: {
        display: false
      },
      tooltips: {
        enabled: false
      },
      scales: {
        xAxes: [{
          gridLines: {
            color: '#545BA8'
          },
        }],
        yAxes: [{
          gridLines: {
            color: '#545BA8'
          },
          ticks: {
            beginAtZero: true,
            max: 100,
            stepSize: 10,
          }
        }]
      },
    }
  };
  graphicLeadership = {
    ...this.graphicGeneralSkills,
    data: {
      labels: this.graphicGeneralSkills.data.labels,
      datasets: [
        {
          ...this.graphicGeneralSkills.data.datasets[0],
          backgroundColor: ['rgba(233, 195, 121, 0.8)'],
        },
      ]
    },
  };
  graphicGlobalMind = {
    ...this.graphicGeneralSkills,
    data: {
      labels: this.graphicGeneralSkills.data.labels,
      datasets: [
        {
          ...this.graphicGeneralSkills.data.datasets[0],
          backgroundColor: ['rgba(226, 165, 185, 0.8)'],
        },
      ]
    },
  };
  graphicSocialImpact = {
    ...this.graphicGeneralSkills,
    data: {
      labels: this.graphicGeneralSkills.data.labels,
      datasets: [
        {
          ...this.graphicGeneralSkills.data.datasets[0],
          backgroundColor: ['rgba(158, 96, 165, 0.8)'],
        },
      ]
    },
  };
  graphicAcademic = {
    ...this.graphicGeneralSkills,
    data: {
      labels: this.graphicGeneralSkills.data.labels,
      datasets: [
        {
          ...this.graphicGeneralSkills.data.datasets[0],
          backgroundColor: ['rgba(225, 127, 112, 0.8)'],
        },
      ]
    },
  };

  sortsGraph = {
    type: 'radar',
    data: {
      labels: [
        [`Liderazgo`,  `${Math.round(0)}%`],
        [`Global`, ` Mind`, `  ${Math.round(0)}%`],
        [`Logro y`, ` acción`, `   ${Math.round(0)}%`],
        [`Impacto`, `social  `, `${Math.round(0)}%   `],
        [`Competencia`, `Académica  `, `${Math.round(0)}%       `]
      ],
      datasets: [
        {
          label: 'Puntuación',
          data: [0, 0, 0, 0, 0 ],
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          borderColor: '#969FFB',
          borderWidth: 0,
          pointBackgroundColor: ['#86388F', '#E3B458', '#5C9B9B', '#DE748F', '#3B61DD']
        },
        {
          label: 'Máximo',
          data: [100, 100, 100, 100, 100],
          backgroundColor: 'rgba(176, 183, 255, 0.5)',
          borderColor: '#969FFB',
          pointRadius: 0,
          borderWidth: 0,
        },
      ]
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        customCanvasBackgroundColor: {
          color: '#B0B7FF',
        },
      },
      tooltips: {
        enabled: false
      },
      legend: {
        display: false,
      },
      scale: {
        gridLines: {
          color: '#969FFB',
          lineWidth: '1'
        },
        ticks: {
          display: false,
          min: 0,
          max: 100,
        },
        pointLabels: {
          fontColor: ['#3B61DD', '#86388F', '#E3B458', '#5C9B9B', '#DE748F'],
          fontSize: 8,
          fontStyle: 'bold'
        }
      },
    },
  };

  public disciplina = '';
  public nivelAcademico = '';
  public ListDisciplinas: any[] = [];
  public tiposSeleccionados: any = [];
  public mostrarCodigos = false;
  public mostrarLista = false;
  user: any;
  public fechaInicio: any;
  public fechaFin: any;
  tabActivo: any;
  idColegio: any;
  data = {
    labels: ['January', 'February', 'March', 'April', 'May', 'June', 'July'],
    datasets: [
      {
        label: 'My First dataset',
        data: [65, 59, 80, 81, 56, 55, 40],
      },
    ],
  };
  readonly getTestColegio = 'api/test_internalizacionColegio/';
  readonly getTestColegioAll = 'api/test_internalizacionColegioAll/';
  readonly getTestColegioGeneral = 'api/colegioPuntuacion/';
  readonly getTestColegioGeneralAll = 'api/colegioPuntuacionAll/';
  readonly getDataColegio = 'api/colegioAdmin/';
  readonly getTestNumber = 'api/testCount/';
  readonly API_COLEGIO_CODIGOS = 'api/codigoTestColegioUserAllActive';
  readonly API_COLEGIO_CODIGOSUSADA = 'api/codigoTestColegioUserAllUsed';
  readonly API_UPDATE_CODIGOSUSADA = 'api/codigoTest/';
  readonly API_APLICACIONES_ESTUDIANTES = 'api/test_internalizacionUserByColegio/applications/';
  graficaGeneral: { datasets: { data: any; borderColor: any; backgroundColor: any }[]; labels: any };
  graficaLiderazgo: { datasets: { data: any; borderColor: any; backgroundColor: any }[]; labels: any };
  graficaGlobal_mind: { datasets: { data: any; borderColor: any; backgroundColor: any }[]; labels: any };
  graficaImpacto_social: { datasets: { data: any; borderColor: any; backgroundColor: any }[]; labels: any };
  graficaCompetencia_academica: { datasets: { data: any; borderColor: any; backgroundColor: any }[]; labels: any };
  graficaLogro_accion: { datasets: { data: any; borderColor: any; backgroundColor: any }[]; labels: any };
  tableResult = [];

  options = {
    responsive: true,
    maintainAspectRatio: false,
    legend: {
      display: true,
      labels: {
        fontColor: 'rgb(255, 99, 132)',
      },
    },
    scales: {
      xAxes: [
        {
          display: true,
          scaleLabel: {
            labelString: '',
            display: false,
          },
          ticks: {
            autoSkip: false,
            maxRotation: 0,
            minRotation: 0,

            callback(val, index, values) {
              const s_obj = new String(val);
              if (s_obj.length > 50) {
                return s_obj.slice(0, 50) + '...';
              } else {
                return s_obj;
              }

              // Hide the label of every 2nd dataset
            },
          },
        },
      ],
      yAxes: [
        {
          scaleLabel: {
            display: true,
            labelString: '',
          },
          ticks: {
            min: 0,
          },
        },
      ],
    },
  };
  cantCodigos: any;
  primerSemestre: any;
  segundoSemestre: any;
  colegioData: any;
  verListado = false;
  readonly settingsLisado = {
    pager: {
      display: true,
      perPage: 10,
    },
    actions: {
      position: 'right',
      add: false,
      edit: false,
      delete: false,
      custom: [],
    },
    columns: {
      nombres: {
        title: 'Nombres',
      },
      email: {
        title: 'Email',
      },
      gobal_mindA: {
        title: 'Global mind Semestre 1',
      },
      impacto_socialA: {
        title: 'Impacto social Semestre 1',
      },
      liderazgoA: {
        title: 'Liderazgo Semestre 1',
      },
      competencia_academicaA: {
        title: 'Competencia académica Semetre 1',
      },
      logro_accionA: {
        title: 'Logro y acción Semestre 1 ',
      },
      puntaje_generalA: {
        title: 'Puntaje Semestre 1',
      },
      fechaA: {
        title: 'Fecha test',
      },
      gobal_mindB: {
        title: 'Global mind Semestre 2',
      },
      impacto_socialB: {
        title: 'Impacto social Semestre 2',
      },
      liderazgoB: {
        title: 'Liderazgo Semestre 2',
      },
      competencia_academicaB: {
        title: 'Competencia académica Semetre 2',
      },
      logro_accionB: {
        title: 'Logro y acción Semestre 2 ',
      },
      puntaje_generalB: {
        title: 'Puntaje Semestre 2',
      },
      fechaB: {
        title: 'Fecha test',
      },
    },
  };
    /** Settings for the smart table */
    readonly settings01 = {
      actions: {
        position: 'right',
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: 'detalle',
            title: '<img src="assets/utils/search.png" alt="search" class="btn-lupa"></img>',
          }
        ],
      },
      columns: {
        codigo: {
          title: 'Código',
        },
        createdAt: {
          title: 'Fecha',
        },
      },
    };
    /** Settings for the smart table */
    readonly settings02 = {
      actions: {
        position: 'right',
        add: false,
        edit: false,
        delete: false,
        custom: [
          {
            name: 'view',
            title: '<img src="assets/utils/search.png" alt="search"  class="btn-lupa"></img>',
          }
        ],
      },
      columns: {
        codigo: {
          title: 'Código',
        },
        fechaCreado: {
          title: 'Fecha',
        },
        emailUsuario: {
          title: 'Email estudiante',
        },
        dataUser: {
          title: 'Estudiante',
          filter: true,
          valuePrepareFunction: (data) => {
            if (data.users){
              return data.users[0].nombre + ' ' + data.users[0].apellido;
            }else{
              return '';
            }
          },
        },
        dataTest: {
          title: 'Promedio',
          filter: true,
          valuePrepareFunction: (data) => {
            if (data.users){
              return data.puntaje_general;
            }else{
              return '';
            }
          },
        }
      },
    };
  inicioA: any;
  inicioB: any;
  finB: any;
  finA: any;
  radarValues: any;
  listCodigosLibres: any;
  cantCodigosUsados: any;
  listCodigosUsados: any;

  aplicacionesInter = 0;
  totalAplicaciones = 0;
  aplicacionesAceptadas = 0;
  aplicacionesEspera = 0;
  submenuOptionActive: number = 0;
  scrWidth: number;

  constructor(
    private apiService: ApiService,
    private router: Router,
    private ngxSpinnerService: NgxSpinnerService,
  ) {
    this.ListDisciplinas = [
      {
        value: 'Arts, Design and Architecture',
        name: 'Home.arts-architecture',
        text: 'Arts,-Design-and-Architecture',
      },
      {
        value: 'Business and Management',
        name: 'Home.business',
        text: 'Business-and-Management',
      },
      {
        value: 'Computer Sciences and IT',
        name: 'Home.computer-science',
        text: 'Computer-Sciences-and-IT',
      },
      {
        value: 'Engineering',
        name: 'Home.engineering',
        text: 'Engineering',
      },
      {
        value: 'Sciences and Mathematics',
        name: 'Home.sciences-math',
        text: 'Sciences-and-Mathematics',
      },
      {
        value: 'Social Sciences and Humanities',
        name: 'Home.social-sciences',
        text: 'Social-Sciences-and-Humanities',
      },
      {
        value: 'Education and Sport',
        name: 'Home.education',
        text: 'Education-and-Sport',
      },
      {
        value: 'Community Services',
        name: 'Home.community',
        text: 'Community-Services',
      },
      {
        value: 'Environmental Studies and Earth Sciences',
        name: 'Home.env-studies',
        text: 'Environmental-Studies-and-Earth-Sciences',
      },
      {
        value: 'Health',
        name: 'Home.health',
        text: 'Health',
      },
      {
        value: 'Journalism and Media',
        name: 'Home.journalism',
        text: 'Journalism-and-Media',
      },
      {
        value: 'Law and Politic Sciences',
        name: 'Home.law-politics',
        text: 'Law-and-Politic-Sciences',
      },
      {
        value: 'Languages',
        name: 'Home.languages',
        text: '',
      },
      {
        value: 'Tourism and Hospitality',
        name: 'Home.tourism-hospitality',
        text: 'Tourism-and-Hospitality',
      },
    ];
  }

  ngOnInit(): void {
    this.tabActivo = 'indicadores';
    this.user = JSON.parse(localStorage.getItem('user'));
    this.scrWidth = window.innerWidth;
    if (this.user) {
      this.ngxSpinnerService.show();
      this.getColegio(this.user._id);
    }
  }

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?): void {
    this.scrWidth = window.innerWidth;
  }

  getColegio(idCol) {
    this.apiService.get({ api: this.getDataColegio + idCol }).subscribe(
      (response) => {
        if (response._id) {
          this.colegioData = response;
          this.inicioA = moment(response.inicioSemestreA).format('YYYY-MM-DD');
          this.finA = moment(response.finSemestreA).format('YYYY-MM-DD');
          this.inicioB = moment(response.inicioSemestreB).format('YYYY-MM-DD');
          this.finB = moment(response.finSemestreB).format('YYYY-MM-DD');
          this.fechaInicio = moment(response.inicioSemestreA).format('YYYY-MM-DD');
          this.fechaFin = moment(response.finSemestreB).format('YYYY-MM-DD');
          this.getTest(response._id, response.inicioSemestreA, response.finSemestreB);
          this.getTestCount(response._id);
          this.getCodigos(response._id);
          this.getApplicationsStudents({idColegio: response._id});
          this.buildGeneralGraphics();
          this.buildSkillsGraphics(this.disciplina, this.nivelAcademico);
        }
      },
      (err) => {
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor vuelve a intentarlo',
        });
        this.ngxSpinnerService.hide();
        return false;
      }
    );
  }

  onCustomAction(event): void {
    if (event.action === 'detalle') {
      Swal.fire({
        title: '',
        text: 'Para asociar al estudiante por favor indique el correo electronico.',
        input: 'text',
        inputAttributes: {
          autocapitalize: 'off'
        },
        showCancelButton: true,
        cancelButtonText: 'Cancelar',
        confirmButtonText: 'Agregar',
        showLoaderOnConfirm: true,
        preConfirm: (email) => {
          //
          if (email){
            const update = {
              estado: 'Usado',
              emailUsuario: email.toLowerCase()
            };
            //
            const data = update;
            this.apiService
                .put({ api: this.API_UPDATE_CODIGOSUSADA + event.data._id, data })
                .subscribe(
                  (response) => {
                    //
                    if (response.success){
                      Swal.fire({
                        icon: 'success',
                        title: 'Se agregó con éxito',
                        text: '',
                      });
                      this.getCodigos(this.colegioData._id);

                    }else{
                      Swal.fire({
                        icon: 'error',
                        title: 'Error',
                        text: 'Por favor vuelve a intentarlo',
                      });
                    }
                  },
                  (err) => {
                    Swal.fire({
                      icon: 'error',
                      title: 'Error',
                      text: 'Por favor vuelve a intentarlo',
                    });
                  }
                );
          }else{
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Por favor vuelve a intentarlo',
            });
          }
          /*return fetch(`//api.github.com/users/${login}`)
            .then(response => {
              if (!response.ok) {
                throw new Error(response.statusText)
              }
              return response.json()
            })
            .catch(error => {
              Swal.showValidationMessage(
                `Request failed: ${error}`
              )
            })*/
        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then((result) => {
        if (result.isConfirmed) {
          //
          /*Swal.fire({
            title: `${result.value.login}'s avatar`,
            imageUrl: result.value.avatar_url
          })*/
        }
      });
    }
    if (event.action === 'view') {
      //
      if (!event.data.dataTest){
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'El estudiante no ha comenzado el test',
        });
      }else{
        const id = event.data.dataTest.users[0]._id;
        const semestre = event.data.dataTest.semestreActivo;
        //
        const url = this.router.serializeUrl(
          this.router.createUrlTree([
            `web/resument-test/${id}/${semestre}`,
          ])
        );
        window.open(url, '_blank');
      }
    }
  }
  ejecutarFiltro() {

    this.getTestFiltro(this.colegioData._id, this.fechaInicio, this.fechaFin, this.disciplina, this.nivelAcademico );
  }
  getTestCount(idCol) {
    this.apiService.get({ api: this.getTestNumber + idCol }).subscribe(
      (response) => {
        for (const resp of response) {
          if (resp._id == 0) {
            this.primerSemestre = resp.total;
          } else if (resp._id == 1) {
            this.segundoSemestre = resp.total;
          }
        }
      },
      (err) => {
        // Caso error de plataforma
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor vuelve a intentarlo',
        });
        return false;
      }
    );
  }
  getLisTest() {
    this.verListado = true;
    this.apiService
      .get({ api: this.getTestColegioAll + this.colegioData._id + '/' + this.fechaInicio + '/' + this.fechaFin })
      .subscribe(
        (response) => {
          this.tableResult = response;
          const arreglo = [];
          const primeraFila = [
            'Nombres',
            'Email',
            'Global mind Semestre 1',
            'Impacto social Semestre 1',
            'Liderazgo Semestre 1',
            'Competencia académica Semetre 1',
            'Logro y acción Semestre 1 ',
            'Puntaje Semestre 1',
            'Fecha test',
            'Global mind Semestre 2',
            'Impacto social Semestre 2',
            'Liderazgo Semestre 2',
            'Competencia académica Semetre 2',
            'Logro y acción Semestre 2 ',
            'Puntaje Semestre 2',
            'Fecha test',
          ];
          arreglo.push(primeraFila);
          this.tableResult.forEach((element) => {
            const array = [
              element.nombres,
              element.email,
              element.gobal_mindA,
              element.impacto_socialA,
              element.liderazgoA,
              element.competencia_academicaA,
              element.logro_accionA,
              element.puntaje_generalA,
              element.fechaA,
              element.gobal_mindB,
              element.impacto_socialB,
              element.liderazgoB,
              element.competencia_academicaB,
              element.logro_accionB,
              element.puntaje_generalB,
              element.fechaB,
            ];
            arreglo.push(array);
          });
          // generate worksheet
          const ws: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet(arreglo);

          /* generate workbook and add the worksheet */
          const wb: XLSX.WorkBook = XLSX.utils.book_new();
          XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');

          // save to file
          XLSX.writeFile(wb, 'Test_realizados.xlsx');
        },
        (err) => {
          // Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
  }
  getListCodigos(){

  }
  getTest(idCol, inicio, fin) {
    this.apiService.get({ api: this.getTestColegioGeneral + idCol + '/' + inicio + '/' + fin }).subscribe(
      (response) => {
        const X = [];
        const Y = [];
        const X_liderazgo = [];
        const Y_liderazgo = [];
        const X_Global = [];
        const Y_Global = [];
        const X_Impacto = [];
        const Y_Impacto = [];
        const X_Competencia = [];
        const Y_Competencia = [];
        const X_Logro = [];
        const Y_Logro = [];
        for (const dat of response) {
          let dataLabel = '';
          if (dat.semestre == 0) {
            dataLabel =
              moment(this.colegioData.inicioSemestreA).format('YYYY-MM-DD') +
              ' / ' +
              moment(this.colegioData.finSemestreA).format('YYYY-MM-DD');
          } else if (dat.semestre == 1) {
            dataLabel =
              moment(this.colegioData.inicioSemestreB).format('YYYY-MM-DD') +
              ' / ' +
              moment(this.colegioData.finSemestreB).format('YYYY-MM-DD');
          }
          X.push(dataLabel);
          Y.push(dat.puntaje_general);
          X_liderazgo.push(dataLabel);
          Y_liderazgo.push(dat.liderazgo);
          X_Global.push(dataLabel);
          Y_Global.push(dat.gobal_mind);
          X_Impacto.push(dataLabel);
          Y_Impacto.push(dat.impacto_social);
          X_Competencia.push(dataLabel);
          Y_Competencia.push(dat.competencia_academica);
          X_Logro.push(dataLabel);
          Y_Logro.push(dat.logro_accion);
        }

        //
        //
        this.graficaGeneral = this.graficoPie({ labels: X, data: Y });
        this.graficaLiderazgo = this.graficoPie({ labels: X_liderazgo, data: Y_liderazgo });
        this.graficaGlobal_mind = this.graficoPie({ labels: X_Global, data: Y_Global });
        this.graficaImpacto_social = this.graficoPie({ labels: X_Impacto, data: Y_Impacto });
        this.graficaCompetencia_academica = this.graficoPie({ labels: X_Competencia, data: Y_Competencia });
        this.graficaLogro_accion = this.graficoPie({ labels: X_Logro, data: Y_Logro });

        this.radarValues = {
          type: 'radar',
          data: {
            labels: [ 'Liderazgo',
                      'Global Mind',
                      'Impacto social',
                      'Logro y accion',
                      'Competencia académica / Aprendizaje'],
            datasets: [
              {
                label: 'Puntuacion de competencias',
                data: [ Y_liderazgo[1],
                        Y_Global[1],
                        Y_Impacto[1],
                        Y_Logro[1],
                      Y_Competencia[1]]
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: true,
            scale: {
              ticks: {
                min: 0,
                max: 100
              }
            }
          }
        };

      },
      (err) => {
        // Caso error de plataforma
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor vuelve a intentarlo',
        });
        return false;
      }
    );
  }
  getTestFiltro(idCol, inicio, fin, disciplina, nivelAcademico) {
    let dis = 'null';
    let nivel = 'null';
    if (disciplina){
      dis = disciplina;
    }
    if (nivelAcademico){
      nivel = nivelAcademico;
    }
    this.apiService.get({ api: this.getTestColegioGeneralAll + idCol + '/' + inicio + '/' + fin + '/' + dis + '/' + nivel}).subscribe(
      (response) => {
        const X = [];
        const Y = [];
        const X_liderazgo = [];
        const Y_liderazgo = [];
        const X_Global = [];
        const Y_Global = [];
        const X_Impacto = [];
        const Y_Impacto = [];
        const X_Competencia = [];
        const Y_Competencia = [];
        const X_Logro = [];
        const Y_Logro = [];
        for (const dat of response) {
          let dataLabel = '';
          if (dat.semestre == 0) {
            dataLabel =
              moment(this.colegioData.inicioSemestreA).format('YYYY-MM-DD') +
              ' / ' +
              moment(this.colegioData.finSemestreA).format('YYYY-MM-DD');
          } else if (dat.semestre == 1) {
            dataLabel =
              moment(this.colegioData.inicioSemestreB).format('YYYY-MM-DD') +
              ' / ' +
              moment(this.colegioData.finSemestreB).format('YYYY-MM-DD');
          }
          X.push(dataLabel);
          Y.push(dat.puntaje_general);
          X_liderazgo.push(dataLabel);
          Y_liderazgo.push(dat.liderazgo);
          X_Global.push(dataLabel);
          Y_Global.push(dat.gobal_mind);
          X_Impacto.push(dataLabel);
          Y_Impacto.push(dat.impacto_social);
          X_Competencia.push(dataLabel);
          Y_Competencia.push(dat.competencia_academica);
          X_Logro.push(dataLabel);
          Y_Logro.push(dat.logro_accion);
        }
        //
        //
        this.graficaGeneral = this.graficoPie({ labels: X, data: Y });
        this.graficaLiderazgo = this.graficoPie({ labels: X_liderazgo, data: Y_liderazgo });
        this.graficaGlobal_mind = this.graficoPie({ labels: X_Global, data: Y_Global });
        this.graficaImpacto_social = this.graficoPie({ labels: X_Impacto, data: Y_Impacto });
        this.graficaCompetencia_academica = this.graficoPie({ labels: X_Competencia, data: Y_Competencia });
        this.graficaLogro_accion = this.graficoPie({ labels: X_Logro, data: Y_Logro });

        this.radarValues = {
          type: 'radar',
          data: {
            labels: [ 'Liderazgo',
                      'Global Mind',
                      'Impacto social',
                      'Logro y accion',
                      'Competencia académica / Aprendizaje'],
            datasets: [
              {
                label: 'Puntuacion de competencias',
                data: [ Y_liderazgo[1],
                        Y_Global[1],
                        Y_Impacto[1],
                        Y_Logro[1],
                      Y_Competencia[1]]
              }
            ]
          },
          options: {
            responsive: true,
            maintainAspectRatio: true,
            scale: {
              ticks: {
                min: 0,
                max: 100
              }
            }
          }
        };
      },
      (err) => {
        // Caso error de plataforma
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor vuelve a intentarlo',
        });
        return false;
      }
    );
  }

  graficoPie({ labels, data }) {
    const datos = {
      datasets: [
        {
          label: '',
          data,
          // boxWidth: 80,
          backgroundColor: 'transparent',
          borderColor: 'rgb(115,113,156)',
        },
      ],
      labels,
    };
    return datos;
  }
  async getCodigos(idCol) {
    await this.apiService.get({ api: this.API_COLEGIO_CODIGOS + '/' + idCol }).subscribe(
      (response) => {
        this.cantCodigos = response.length;
        this.listCodigosLibres = response;
      },
      (err) => {
        // Caso error de plataforma
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor vuelve a intentarlo',
        });
        return false;
      }
    );
    await this.apiService.get({ api: this.API_COLEGIO_CODIGOSUSADA + '/'  + idCol }).subscribe(
      (response) => {
        //
        this.cantCodigosUsados = response.length;
        this.listCodigosUsados = response;
      },
      (err) => {
        // Caso error de plataforma
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor vuelve a intentarlo',
        });
        return false;
      }
    );
  }

  displaySection(section){
    if (section == 'general'){
      this.generalDisplay = true;
      this.skillsDisplay = false;
    }else if (section == 'skills'){
      this.skillsDisplay = true;
      this.generalDisplay = false;
    }
  }

  getApplicationsStudents({idColegio}){
    //
    this.apiService.get({api: this.API_APLICACIONES_ESTUDIANTES + idColegio}).subscribe(
      (response) => {
        if (response){

          this.totalAplicaciones = response.length;
          //
          for (const aplicacion of response) {

            if (aplicacion.programa?.nombrePais !== 'Colombia'){
              this.aplicacionesInter++;
            }

            if (aplicacion.estado === 'Inicial'){
              this.aplicacionesEspera++;
            }else{
              this.aplicacionesAceptadas++;
            }
          }
          if (this.totalAplicaciones > 0) {
            this.aplicacionesInter = (this.aplicacionesInter / this.totalAplicaciones)*100;
          }
        }else{

        }
      }
    );
  }

  private buildGeneralGraphics(): void {
    combineLatest([
      this.apiService.get({api: 'api/test_internalizacionUserByColegio'})
        .pipe(tap((data: object) => {
          const keys = Object.keys(data || {}) || [];
          this.graphicGlobalSchools.data = {
            labels: keys,
            datasets: [{
              data: keys.map((key) => parseInt(data[key]?.promedio || 0, 10)),
              backgroundColor: ['#3d45ac', '#a3b798', '#c09c9d', '#cc8d8f', '#90beb5'],
              hoverBackgroundColor: []
            },
            ]
          };
        })),
      this.apiService.get({api: 'api/test_internalizacionUserByColegio/percentage/applications/' + this.colegioData._id})
        .pipe(tap((data: object) => {
          const keys = Object.keys(data || {}) || [];
          this.graphicCountriesApplications.data = {
            labels: keys,
            datasets: [
              {
                data: keys.map((key) => parseInt(data[key] || 0, 10)),
                backgroundColor: ['#f8c185', '#4b9d9d', '#4a51ab', '#d57693', '#3d88c9'],
                hoverOffset: 8
              },
            ]
          };
        })),
      this.apiService.get({api: 'api/test_internalizacionUserByColegio/percentage/skills-profile/' + this.colegioData._id})
        .pipe(tap((data: object) => {
          //
          const keys = Object.keys(data || {}) || [];
          this.graphicCompetences.data = {
            labels: keys,
            datasets: [{
              data: keys.map((key) => parseInt(data[key] || 0, 10)),
              backgroundColor: ['#3d45ac', '#a3b798', '#c09c9d', '#cc8d8f', '#90beb5'],
              hoverBackgroundColor: []
            },
            ]
          };
        })),
      this.apiService.get({api: 'api/test_internalizacionUserByColegio/percentage/discipline/' + this.colegioData._id})
        .pipe(tap((data: object) => {
          //
          const keys = Object.keys(data || {}) || [];
          this.graphicStudentsDiscipline.data = {
            labels: keys,
            datasets: [{
              data: keys.map((key) => parseInt(data[key] || 0, 10)),
              backgroundColor: ['#3d45ac', '#a3b798', '#c09c9d', '#cc8d8f', '#90beb5', '#f8c185', '#4b9d9d', '#f2774d', '#d57693', '#3d88c9'],
              hoverOffset: 8
            },
            ]
          };
        })),
    ]).subscribe(() => {
      this.ngxSpinnerService.hide();
    }, (err) => {
      this.ngxSpinnerService.hide();
    });
  }

  buildSkillsGraphics(skill: string, level: string): void {
    combineLatest([
      this.apiService.get({api: `api/skillsGraphics/${this.colegioData._id}/2023-01-01/${skill || 'todos'}/${level || 'todos'}`})
        .pipe(tap((result: any) => {
          const data = result?.data;
          const keys = Object.keys(data || {}) || [];
          const currentSemester = this.getCurrentSemester(keys, data);
          this.graphicGeneralSkills.data.labels = ['0', ...keys];
          this.graphicGeneralSkills.data.datasets[0].data = [0, ...keys.map((key) => data[key]?.puntaje_general)];
          this.graphicLeadership.data.labels = ['0', ...keys];
          this.graphicLeadership.data.datasets[0].data = [0, ...keys.map((key) => data[key]?.liderazgo)];
          this.graphicGlobalMind.data.labels = ['0', ...keys];
          this.graphicGlobalMind.data.datasets[0].data = [0, ...keys.map((key) => data[key]?.gobal_mind)];
          this.graphicSocialImpact.data.labels = ['0', ...keys];
          this.graphicSocialImpact.data.datasets[0].data = [0, ...keys.map((key) => data[key]?.impacto_social)];
          this.graphicAcademic.data.labels = ['0', ...keys];
          this.graphicAcademic.data.datasets[0].data = [0, ...keys.map((key) => data[key]?.competencia_academica)];
          this.resultadoGeneral = data[currentSemester];
          this.sortsGraph.data.labels = [
            [`Liderazgo`,  `${Math.round(this.resultadoGeneral?.liderazgo || 0)}%`],
            [`Global`, ` Mind`, `  ${Math.round(this.resultadoGeneral?.gobal_mind || 0)}%`],
            [`Logro y`, ` acción`, `   ${Math.round(this.resultadoGeneral?.logro_accion || 0)}%`],
            [`Impacto`, `social  `, `${Math.round(this.resultadoGeneral?.impacto_social || 0)}%   `],
            [`Competencia`, `Académica  `, `${Math.round(this.resultadoGeneral?.competencia_academica || 0)}%       `]
          ];
          this.sortsGraph.data.datasets[0].data = [
            this.resultadoGeneral?.liderazgo || 0,
            this.resultadoGeneral?.gobal_mind || 0,
            this.resultadoGeneral?.logro_accion || 0,
            this.resultadoGeneral?.impacto_social || 0,
            this.resultadoGeneral?.competencia_academica || 0,
          ];
        })),
    ]).subscribe();
  }

  getCurrentSemester(keys: any, data: any) {
    let semester = keys[0];
    for (let key of keys) {
      if (data[key]?.puntaje_general > 0) {
        semester = key;
      }
    }
    return semester;
  }
}
