  <div class="header">
    <h1 class="titlePage" *ngIf="formList.length > 0">Mis Experiencias.</h1>
    <div class="centerContainer" *ngIf="formList.length === 0">
      <div class="addExperienceBox">
        <h1 class="title">No tienes</h1><br>
        <h1 class="title">Experiencias</h1>
        <button (click)="openModal(content)" class="addButton">
          <img src="../../../assets/experiences/more.png" alt="" class="icon-image">Crear Experiencia
        </button>
      </div>
    </div>
  </div>
  <div>
    <div class="experienceCard" *ngIf="formList.length > 0">
      <button (click)="openModal(content)" class="addAnotherButton">
        <img src="../../../assets/experiences/more.png" alt="">Crear Experiencia
      </button>
    </div>
  </div>
  <app-experience-modal (formDataSubmitted)="handleFormDataSubmitted($event)"></app-experience-modal>
  <app-experience-cards></app-experience-cards>

