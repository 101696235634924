import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-english-test',
  templateUrl: './english-test.component.html',
  styleUrls: ['./english-test.component.scss'],
})
export class EnglishTestComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
