import { ThrowStmt } from '@angular/compiler';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso8',
  templateUrl: './paso8.component.html',
  styleUrls: ['./paso8.component.scss'],
})
export class Paso8Component implements OnInit {
  @Output() notificacionTab11 = new EventEmitter<string>();

  public nota9: number = 0;
  public nota10: number = 0;
  public nota11: number = 0;
  public nota12: number = 0;
  public factorDividendo: number = 0;

  public notaPromedio: any;
  public preguntas_seccion11: any;
  public dataFrm11: any;
  public dataFrm11Copy: any;
  responseUser11: any;
  readonly getTest11 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest11 = 'api/get_internalizacionUser/';
   pasoActivo: any;
  constructor(private apiService:ApiService) {
    this.responseUser11 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.responseUser11) {
      this.apiService.get({ api: this.getTest11 + this.responseUser11._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //  
            let res = response;
            let data_test03
            if(res.test[0].preguntas){
              data_test03=res.test[0];
            }else{
              data_test03=JSON.parse(res.test[0]);
            }
            this.dataFrm11=response;
            this.pasoActivo= response.hojaTest;
            this.preguntas_seccion11= data_test03.preguntas[10];
            // console.log('seccion de lenguaje',this.preguntas_seccion11)
             
            let i = 0;
            for(let item of this.preguntas_seccion11.preguntas){
              if(i == 0){
                parseInt(item.opcionSeccionada)
                this.nota9=parseInt(item.opcionSeccionada)
              }
              if(i == 1){
                parseInt(item.opcionSeccionada)
                this.nota10=parseInt(item.opcionSeccionada)
              }
              if(i == 2){
                parseInt(item.opcionSeccionada)
                this.nota11=parseInt(item.opcionSeccionada)
              }
              if(i == 3){
                parseInt(item.opcionSeccionada)
                this.nota12=parseInt(item.opcionSeccionada)
              }
              if(i == 4){
                parseInt(item.opcionSeccionada)
                this.notaPromedio=parseInt(item.opcionSeccionada).toFixed(2)
              }
              i++
            }
            this.onKeyUp();

          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    }






  }

  ngOnInit(): void {
    // this.nota9=0
    // this.nota10=0
    // this.nota11=0
    // this.nota12=0
  }

  public onKeyUp() {
    let notA = 0;
    let notB = 0;
    let notC = 0;
    let notD = 0;

    let promedio = 0;
    if (this.nota9 > 0) {
      notA = 1;
    } else {
      notA = 0;
    }
    if (this.nota10 > 0) {
      notB = 1;
    } else {
      notB = 0;
    }
    if (this.nota11 > 0) {
      notC = 1;
    } else {
      notC = 0;
    }
    if (this.nota12 > 0) {
      notD = 1;
    } else {
      notD = 0;
    }

    // promedio = (this.nota9+ this.nota10 + this.nota11+ this.nota12);
    if(this.nota9>0)promedio+=this.nota9
    if(this.nota10>0)promedio+=this.nota10
    if(this.nota11>0)promedio+=this.nota11
    if(this.nota12>0)promedio+=this.nota12
     
    let factorDividendo = notA+notB+notC+notD
     
    let media = promedio / factorDividendo;
    this.notaPromedio = media.toFixed(2);
     
  }
  
  public volverTab10() {
    this.notificacionTab11.emit('volver');
  }
  public continuarTab12() {
    let paso = this.pasoActivo
    if(this.pasoActivo < 11){
      paso = this.pasoActivo = 11;
    }
    this.dataFrm11.hojaTest= paso
    this.preguntas_seccion11.preguntas[0].opcionSeccionada = this.nota9;
    this.preguntas_seccion11.preguntas[1].opcionSeccionada = this.nota10;
    this.preguntas_seccion11.preguntas[2].opcionSeccionada = this.nota11;
    this.preguntas_seccion11.preguntas[3].opcionSeccionada = this.nota12;
    this.preguntas_seccion11.preguntas[4].opcionSeccionada = this.notaPromedio;
    this.dataFrm11.test = JSON.parse(this.dataFrm11.test);
     

    this.dataFrm11.test.preguntas[10] = this.preguntas_seccion11;
    if(this.notaPromedio >= 51 && this.notaPromedio <= 60){
      this.preguntas_seccion11.habilidades[0].puntaje=1;
      this.preguntas_seccion11.habilidades[1].puntaje=1;
    }
    if(this.notaPromedio >= 61 && this.notaPromedio <= 70){
      this.preguntas_seccion11.habilidades[0].puntaje=2;
      this.preguntas_seccion11.habilidades[1].puntaje=2;
    }
    if(this.notaPromedio >= 71 && this.notaPromedio <= 80){
      this.preguntas_seccion11.habilidades[0].puntaje=3;
      this.preguntas_seccion11.habilidades[1].puntaje=3;
    }
    if(this.notaPromedio >= 81 && this.notaPromedio <= 90){
      this.preguntas_seccion11.habilidades[0].puntaje=4;
      this.preguntas_seccion11.habilidades[1].puntaje=4;
    }
    if(this.notaPromedio >90){
      this.preguntas_seccion11.habilidades[0].puntaje=5;
      this.preguntas_seccion11.habilidades[1].puntaje=5;
    }
     
    this.dataFrm11.test = JSON.stringify(this.dataFrm11.test );

    this.actualizarPaso11();
   /* this.dataFrm11Copy.preguntas[10] = this.preguntas_seccion11;
     
    localStorage.removeItem('formulario_test');
    localStorage.setItem('formulario_test', JSON.stringify(this.dataFrm11Copy));
     
    this.notificacionTab11.emit('continuar');*/
  }
  public actualizarPaso11(){
    let data = this.dataFrm11;
    this.apiService
    .put({ api: this.updateFrmTest11 + this.dataFrm11._id, data })
    .subscribe(
      (response) => {
         
        this.notificacionTab11.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
}
