import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { ApiService } from '../../services/api.service';
import { isPlatformBrowser, Location } from '@angular/common';
import { AlertService } from '../../services/alert.service';
import { TranslateService } from '@ngx-translate/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { NavigationService } from '../../services/navigation.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-curriculum',
  templateUrl: './curriculum.component.html',
  styleUrls: ['./curriculum.component.scss']
})
export class CurriculumComponent implements OnInit {
  api = 'api/hoja-vida/getById/';

  /** API path for student get test*/
  readonly GET_TEST = 'api/test_internalizacionUser/'

  user = JSON.parse(localStorage.getItem('user'));

  /** Variable that holds the personal information binded to the form */
  fotoBanner: any;
  fotoPerfil: any;
  nombre: any;
  colegio: any;
  proyecto: any;
  palabrasClave: any;
  sobreMi: any;
  idiomas = [];
  areas = [];
  paises = [];
  logros = [];
  referencias = [];
  universidadesInteres = [];
  userTest: any;
  userId: any;
  slug: any;

  /** Variable that holds the id of the current user */
  id: string;
  actived: string;
  perfilFinal: any;
  sourceInsignia: string;
  isMyCv: boolean = true;

  constructor(
    private apiService: ApiService,
    @Inject(PLATFORM_ID) private platformId,
    private alertService: AlertService,
    private translate: TranslateService,
    private ngxSpinnerService: NgxSpinnerService,
    private navigationService: NavigationService,
    private location: Location,
    private router: Router
  ) { }

  ngOnInit(): void {
    if (window.location.pathname.includes('viewProfile')) {
      let slug = window.location.pathname
      slug = slug.slice(17)
      this.isMyCv = false
      this.api = "api/hoja-vida/getByLink/"
      this.getUserCv({ data: slug });
    } else if (window.location.pathname.includes('studentProfile')) {
      let student = window.location.pathname
      student = student.slice(20)
      this.isMyCv = false
      this.getUserCv({ data: student });
    }
    else {
      this.userId = this.user._id
      this.actived = "icon";
      this.getUserCv({ data: this.userId });
      this.getTestInternationalization({ userId: this.userId })
    }

  }

  hideIcon(): void {
    this.actived = "txt";
  }

  hideTxt(): void {
    this.actived = "icon";
  }

  getUserCv({ data }): void {
    this.ngxSpinnerService.show();
    this.apiService.get({ api: this.api + data }).subscribe(
      (response) => {
        if (response) {
          let usuario = response.usuario
          this.nombre = `${usuario.nombre} ${usuario.apellido}`;
          this.fotoPerfil = response.fotoPerfil ? response.fotoPerfil : "assets/sidebar/profile.png";
          this.colegio = response.colegio ? response.colegio : "Sin colegio"
          this.proyecto = response.proyecto ? response.proyecto : "Sin proyecto"
          this.palabrasClave = response.palabrasClave ? response.palabrasClave : "Sin palabras clave"
          this.sobreMi = response.sobreMi ? response.sobreMi : "¡Cuentanos sobre ti!"
          this.slug = response.link ? response.link : null

          if (response.idioma.length > 0) {
            for (let idioma of response.idioma) {
              this.idiomas.push(idioma)
            }
          }


          if (response.areaInteres.length > 0) {
            for (let area of response.areaInteres) {
              if (area.nombre == "Negocios") {
                this.areas.push({
                  nombre: area.nombre,
                  imagen: "/assets/curriculum/economia.png"
                })
              } else if (area.nombre == "Ciencias de la computación e informática") {
                this.areas.push({
                  nombre: area.nombre,
                  imagen: "/assets/curriculum/desarrollo.png"
                })
              }
              else if (area.nombre == "Ingenierías") {
                this.areas.push({
                  nombre: area.nombre,
                  imagen: "/assets/curriculum/piece.png"
                })
              }
              else if (area.nombre == "Artes, diseño y arquitectura") {
                this.areas.push({
                  nombre: area.nombre,
                  imagen: "/assets/curriculum/idiomas-rosado.png"
                })
              }
              else if (area.nombre == "Ciencias y matematicas") {
                this.areas.push({
                  nombre: area.nombre,
                  imagen: "/assets/curriculum/ciencia.png"
                })
              }
              else if (area.nombre == "Salud") {
                this.areas.push({
                  nombre: area.nombre,
                  imagen: "/assets/curriculum/salud.png"
                })
              }
            }
          }


          if (response.paisInteres.length > 0) {
            for (let pais of response.paisInteres) {
              if (pais.nombre == "Estados unidos") {
                this.paises.push({
                  nombre: pais.nombre,
                  imagen: "/assets/curriculum/eeuu.png"
                })
              } else if (pais.nombre == "Canada") {
                this.paises.push({
                  nombre: pais.nombre,
                  imagen: "/assets/curriculum/canada.png"
                })
              } else if (pais.nombre == "Alemania") {
                this.paises.push({
                  nombre: pais.nombre,
                  imagen: "/assets/curriculum/alemania.png"
                })
              }
              else if (pais.nombre == "Brasil") {
                this.paises.push({
                  nombre: pais.nombre,
                  imagen: "/assets/curriculum/brasil.png"
                })
              }
              else if (pais.nombre == "Francia") {
                this.paises.push({
                  nombre: pais.nombre,
                  imagen: "/assets/curriculum/francia.png"
                })
              }
              else if (pais.nombre == "Australia") {
                this.paises.push({
                  nombre: pais.nombre,
                  imagen: "/assets/curriculum/australia.png"
                })
              }
              else if (pais.nombre == "Reino Unido") {
                this.paises.push({
                  nombre: pais.nombre,
                  imagen: "/assets/curriculum/united-kingdom.png"
                })
              }
              else if (pais.nombre == "España") {
                this.paises.push({
                  nombre: pais.nombre,
                  imagen: "/assets/curriculum/spain.png"
                })
              }

            }
          }

          if (response.logros.length > 0) {
            for (let logro of response.logros) {
              this.logros.push(logro)
            }
          }

          if (response.referencias.length > 0) {
            for (let ref of response.referencias) {
              this.referencias.push(ref)
            }
          }

          if (response.universidadInteres.length > 0) {
            for (const uni of response.universidadInteres) {
              this.universidadesInteres.push(uni)
            }
          }
          //Foto del baner de la hoja de vida del usuario
          if (response.fotoBaner) {
            this.fotoBanner = response.fotoBaner
          } else {
            this.fotoBanner = "/assets/curriculum/background-photo.png"
          }

          if (response.fotoPerfil) {
            this.fotoPerfil = response.fotoPerfil
          } else {
            this.fotoPerfil = "/assets/sidebar/profile.png"
          }

          if (!this.isMyCv) {
            this.userId = usuario._id
            this.getTestInternationalization({ userId: this.userId })
          }

        } else {
          this.fotoPerfil = "assets/sidebar/profile.png";
          this.fotoBanner = "/assets/curriculum/background-photo.png";
          this.colegio = "Sin colegio"
          this.proyecto = "Sin proyecto"
          this.palabrasClave = "Sin palabras clave"
          this.sobreMi = "¡Cuentanos sobre ti!"
        }
      },
      (err) => {

        this.alertService.showError({
          msg: this.translate.instant('Personal-information.load-error'),
        })
        this.ngxSpinnerService.hide();
      }
      , (complete?) => {
        this.ngxSpinnerService.hide();
      });
  }

  getTestInternationalization({ userId }) {
    this.apiService.get({ api: this.GET_TEST + userId }).subscribe(
      (response) => {
        if (response) {
          this.userTest = response;
          this.perfilFinal = this.userTest.perfil
          this.AsignarInsignia()
        }
      }
    )
  }

  AsignarInsignia() {
    switch (this.perfilFinal) {
      case 'Master':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/master.png'
        break;

      case 'Profesional':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/profesional.png'
        break;

      case 'Avanzado':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/avanzado.png'
        break;

      case 'Promedio':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/promedio.png'
        break;

      case 'En construccion':
        this.sourceInsignia = '/assets/dashboard-skill/insignias/en-construccion.png'
        break;

      default:

    }
  }

  goToLetterReady() {
    this.navigationService.navigateTo({ path: 'letter-ready' })
  }

  LinkProfileCopy() {
    if (this.slug != null) {
      navigator.clipboard.writeText(window.location.origin + '/web/viewProfile/' + this.slug);
      this.hideIcon();
    } else {
      this.alertService.showError({ msg: 'Por favor agregue un identificador a su hoja de vida' })
    }
  }

  goToExperiences() {
    this.router.navigate(['/my-experiences']);
  }
  /**
  * Handles the click on the back button
  */
  onBackButtonClick(): void {
    this.location.back();
  }
}
