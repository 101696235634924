import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso13',
  templateUrl: './paso13.component.html',
  styleUrls: ['./paso13.component.scss']
})
export class Paso13Component implements OnInit {
  @Output() notificacionTab17 = new EventEmitter<string>();
  public preguntas_seccion17:any;
  public dataFrm17:any;
  public dataFrm17Copy:any;
  public rondaPreguntas: any[]=[]


  public userData13: any;
  readonly getTestPaso13 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest13 = 'api/get_internalizacionUser/';
  pasoActivo: any;
  constructor(private apiService:ApiService) {

    this.userData13 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.userData13) {
      this.apiService.get({ api: this.getTestPaso13 + this.userData13._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //  
            let data_test= JSON.parse(response.test[0]);
             
            this.dataFrm17 = response;
            this.pasoActivo= response.hojaTest;
            this.dataFrm17.test = JSON.parse(this.dataFrm17.test);
            this.preguntas_seccion17= data_test.preguntas[16];
             
            let i = 0;
            for(let item of this.preguntas_seccion17.preguntas){
               
              this.rondaPreguntas[i].seleccionada= item.seleccionada;
              i++
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    } 






  }

  ngOnInit(): void {
    this.rondaPreguntas=[
      {pregunta:"Cuento con Presupuesto para Estudiar en USA (30.000 USD A 80.000 USD al año)",seleccionada:''},
      {pregunta:"Cuento con Presupuesto para Estudiar en otro destino de Norteamérica o Europa (18.000 USD a 30.000 USD al año)",seleccionada:''},
      {pregunta:"Cuento con Presupuesto para estudiar en una universidad Privada Top en Colombia (14.000 USD)",seleccionada:''},
      {pregunta:"Cuento con Presupuesto para estudiar en una universidad Privada de menor costo en Colombia (10.000 USD ó menos)",seleccionada:''},
    ]
  }

  getCheckBox(event,pos,seleccion){
    let id = event.target.id;
    let disableId = event.target.id;
    let checkBoxPressed = document.getElementById(id) as HTMLInputElement;
     
    this.preguntas_seccion17.preguntas[0].opciones[pos].seleccionada=seleccion;
    if(checkBoxPressed.checked){
      if(id.includes('value1')){
        disableId = id.replace('value1', 'value2');
      }
      else if(id.includes('value2')){
        disableId = id.replace('value2', 'value1');
        
      }
       
      let checkBoxDisable = document.getElementById(disableId);
      checkBoxDisable.setAttribute('disabled', new Boolean(true).toString());
    }
    else{
      if(id.includes('value1')){
        disableId = id.replace('value1', 'value2');
      }
      else if(id.includes('value2')){
        disableId = id.replace('value2', 'value1');
      }
      let checkBoxDisable = document.getElementById(disableId);
      checkBoxPressed.removeAttribute('disabled');
      checkBoxDisable.removeAttribute('disabled');
    }
   
  }
  public volverTab14(){
    this.notificacionTab17.emit("volver")

   }
   public continuarTab16(){
    let paso = this.pasoActivo

    if(this.pasoActivo < 18){
      paso = this.pasoActivo = 17;
    }
    this.dataFrm17.hojaTest= paso+1
    this.dataFrm17.test.preguntas[16] = this.preguntas_seccion17;
     
    this.dataFrm17.test = JSON.stringify(this.dataFrm17.test );
    this.actualizarPaso11();
  }
  actualizarPaso11(){
    let data = this.dataFrm17;
    this.apiService
    .put({ api: this.updateFrmTest13 + this.dataFrm17._id, data })
    .subscribe(
      (response) => {
        this.notificacionTab17.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
  
}
