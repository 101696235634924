<div class="background-profiles pb-4">
    <!--Presentacion-->
    <div class="row custom-margin">
        <div class="col-xl-12 d-flex justify-content-center mx-0 px-0">
            <h2 class="presentation-title mx-0 px-0 display-mobile-none">
                Construye tu perfil nacional e internacional y mejora tus skills para ser
                admitido en la universidad de tus sueños.
            </h2>
          <h2 class="presentation-title mx-0 px-0 display-mobile">
            Construye tu perfil nacional e internacional para ser admitido en la universidad de tus sueños.
          </h2>
        </div>
    </div>


  <!--Info perfiles mobile-->
  <swiper
    [spaceBetween]="30"
    [pagination]="{
      clickable: true
    }"
    class="swiper-perfiles display-mobile pt-2 pb-4">
    <ng-template swiperSlide>
      <div class="card-container">
        <ng-container [ngTemplateOutlet]="infoStudents"></ng-container>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="card-container">
        <ng-container [ngTemplateOutlet]="infoColegios"></ng-container>
      </div>
    </ng-template>
    <ng-template swiperSlide>
      <div class="card-container">
        <ng-container [ngTemplateOutlet]="infoUniversidades"></ng-container>
      </div>
    </ng-template>
  </swiper>


  <!--Info perfiles-->
    <div class="row info-container justify-content-lg-center justify-content-around pb-4 display-mobile-none">
        <!-- Estudiantes -->
        <div class="col-xl-4 col-6 text-center appear-card-container m-0">
            <div class="row first-view display-mobile-none m-0" id="content-student-image">
                <div class="col-xl-12 px-0">
                    <img class="info-images" src="/assets/home/images/para-estudiantes.png" alt="para estudiantes">
                </div>
                <div class="col-xl-12 px-0" style="margin-top: 5%;">
                    <span class="footer-images">Para estudiantes</span>
                </div>
            </div>
          <!--Cards-info-students-->
          <ng-container [ngTemplateOutlet]="infoStudents"></ng-container>
        </div>

        <!-- Colegios -->
        <div class="col-xl-4 col-6 text-center appear-card-container m-0">
            <div class="row first-view display-mobile-none m-0">
                <div class="col-xl-12 px-0">
                    <img class="info-images" src="/assets/home/images/para-colegios.png" alt="para colegios">
                </div>
                <div class="col-xl-12 px-0" style="margin-top: 5%;">
                    <span class="footer-images">Para colegios</span>
                </div>
            </div>

            <!--Cards-info-colegios-->
            <ng-container [ngTemplateOutlet]="infoColegios"></ng-container>
        </div>

        <!-- Universidades -->
        <div class="col-xl-4 col-6 text-center appear-card-container m-0">
            <div class="row first-view display-mobile-none m-0">
                <div class="col-xl-12 px-0">
                    <img class="info-images" src="/assets/home/images/para-universidades-2.png"
                        alt="para universidades">
                </div>
                <div class="col-xl-12 px-0" style="margin-top: 5%;">
                    <span class="footer-images">Para universidades</span>
                </div>
            </div>

          <!--Cards-info-universidades-->
          <ng-container [ngTemplateOutlet]="infoUniversidades"></ng-container>
        </div>
    </div>
</div>



<!--Info servicios-->
<div class="row box-serviceinfo">
  <img class="background-service display-mobile-none" src="/assets/home/images/services-home.png" alt="Informacion de servicios">
  <img class="background-service display-mobile" src="/assets/home/images/servicios-mobile.png" alt="Informacion de servicios">
  <img class="rose-circle display-mobile" src="/assets/home/images/rose-circle.png" alt="Informacion de servicios">
  <img class="purple-circle display-mobile" src="/assets/home/images/purple-circle.png" alt="Informacion de servicios">
  <div class="col-xl-12 services-info-container">
        <div class="col-lg-8 col-xl-6 col-12">
            <div class="row col-xl-12">
                <h2 class="premium-services">Servicios premium:</h2>
            </div>

            <div class="row col-xl-12">
                <div class="row col-xl-12 m-0 p-0 pl-lg-3">
                    <div class="col-1 ml-xl-2 px-0">
                        <img class="service-icon" src="/assets/home/services-icons/edificio-blanco.png" alt="">
                    </div>
                    <div class="col-10 px-0">
                        <p class="desc-services">
                            Encuentra y explora las universidades que hacen match con tu perfil individual.
                        </p>
                    </div>
                </div>

                <div class="row col-xl-12 m-0 p-0 pl-lg-3">
                  <div class="col-1 ml-xl-2 px-0">
                        <img class="service-icon" src="/assets/home/services-icons/medidor-blanco.png" alt="">
                    </div>
                    <div class="col-10 px-0">
                        <p class="desc-services">
                            Mide tus competencias y prepárate para la admisión en tu universidad escogida.
                        </p>
                    </div>
                </div>


                <div class="row col-xl-12 m-0 p-0 pl-lg-3">
                  <div class="col-1 ml-xl-2 px-0">
                        <img class="service-icon" src="/assets/home/services-icons/usuario-blanco.png" alt="">
                    </div>
                    <div class="col-10 px-0">
                        <p class="desc-services">
                            Construye tu SOP y tu HV para universidades de todo el mundo.
                        </p>
                    </div>
                </div>

            </div>

        </div>

        <div class="col-lg-8 col-xl-6 col-12 mt-lg-5">
            <div class="row col-xl-12 m-0 p-0">
                <h2 class="free-services">Servicios gratuitos:</h2>
            </div>

            <div class="row col-xl-12 m-0 p-0">
                <div class="row col-xl-12 m-0 p-0 pl-lg-3">
                  <div class="col-1 ml-xl-2 px-0">
                        <img class="service-icon" src="/assets/home/services-icons/lupa-blanco.png" alt="">
                    </div>
                    <div class="col-10 px-0">
                        <p class="desc-services">
                            Buscador básico sin afinidad, ni probabilidad.
                        </p>
                    </div>
                </div>

                <div class="row col-xl-12 m-0 p-0 pl-lg-3">
                  <div class="col-1 ml-xl-2 px-0">
                        <img class="service-icon" src="/assets/home/services-icons/gorro-blanco.png" alt="">
                    </div>
                    <div class="col-10 px-0">
                        <p class="desc-services">
                            Información de Universidades y precios.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!--Programas-->
<div class="careers">
    <div class="max-width-container">
      <div class="careers-header-container">
        <div class="title-centered">
          <h3 id="title-section" class="ml-0">U360 Programas</h3>
          <img class="careers-special-logo" src="assets/home/images/birrete-azul.png" alt="u360 popular careers" />
        </div>
        <p class="programs-subtitle mt-2">
          <strong>Carreras populares</strong>
        </p>
        <p class="programs-subtitle-2">
            ¡Ve directo a lo que más te gusta!
        </p>
      </div>
      <div class="all-program-container">
        <div class="career-top-row">
          <div class="program-container">
            <button *ngFor="let disciplina of config?.disciplineList"
              routerLink="/program/search"
              [queryParams]="{ discipline: disciplina.url }">
              {{ disciplina.name }}
            </button>
          </div>
        </div>
        <div class="all-careers-container">
          <button routerLink="/program/search"><span>Ver todos</span></button>
        </div>
      </div>
    </div>
  </div>

<!--Universidades-->
<div class="row text-center">
    <div class="col-md-12">
        <h2 class="presentation-title universities-title mx-4 px-4">Universidades que confían en nosotros:</h2>
    </div>
</div>
<div class="trust-college mb-xl-5 mb-3">
    <swiper
      [slidesPerView]="'auto'"
      [spaceBetween]="20"
      class="w-100 swiper-university display-mobile-none"
    >
      <ng-template swiperSlide>
        <div class="row">
          <div class="col-md-3 text-center university-logo-container d-flex align-items-center justify-content-center"
               *ngFor="let logo of universitiesLogosList | slice:0:8; let i = index">
            <img [src]="logo" class="university-logo" alt="'Universidad' + index">
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="row">
          <div class="col-md-4 text-center university-logo-container d-flex align-items-center justify-content-center"
               *ngFor="let logo of universitiesLogosList | slice:8; let i = index">
            <img [src]="logo" class="university-logo" alt="'Universidad' + index">
          </div>
        </div>
      </ng-template>
    </swiper>

    <!--Carrousel mobile-->
    <swiper
      [spaceBetween]="30"
      [pagination]="{
        clickable: true
      }"
      class="display-mobile swiper-university-mobile">
      <ng-template swiperSlide>
        <div class="row">
          <div class="col-6 text-center university-logo-container d-flex align-items-center justify-content-center"
               *ngFor="let logo of universitiesLogosList | slice:0:4; let i = index">
            <img [src]="logo" class="university-logo" alt="'Universidad' + index">
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="row">
          <div class="col-6 text-center university-logo-container d-flex align-items-center justify-content-center"
               *ngFor="let logo of universitiesLogosList | slice:4:8; let i = index">
            <img [src]="logo" class="university-logo" alt="'Universidad' + index">
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="row">
          <div class="col-6 text-center university-logo-container d-flex align-items-center justify-content-center"
               *ngFor="let logo of universitiesLogosList | slice:8:12; let i = index">
            <img [src]="logo" class="university-logo" alt="'Universidad' + index">
          </div>
        </div>
      </ng-template>
      <ng-template swiperSlide>
        <div class="row">
          <div class="col-6 text-center university-logo-container d-flex align-items-center justify-content-center"
               *ngFor="let logo of universitiesLogosList | slice:12; let i = index">
            <img [src]="logo" class="university-logo" alt="'Universidad' + index">
          </div>
        </div>
      </ng-template>
    </swiper>
</div>



<!--Cards-info-students-->
<ng-template #infoStudents>
  <div style="text-align: -webkit-center;">
    <div id="card-for-student" class="card card-student appear-card">
      <div class="card-body">
        <div style="margin-bottom: 5%;">
          <span class="footer-images">Para estudiantes</span>
        </div>

        <div class="row card-detail">
          <div class="row">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check">
            </div>
            <div class="col-9 text-list">
              <p>Mide y mejora competencias de admisión internacional.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%;">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check">
            </div>
            <div class="col-9 text-list">
              <p>Conoce, aplica y haz match con las 100 principales universidades internacionales.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%;">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check">
            </div>
            <div class="col-9 text-list">
              <p>Construye tu HV e impacta en tus aplicaciones.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%;">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check">
            </div>
            <div class="col-9 text-list">
              <p>Acompañamiento de los mejores expertos en tus aplicaciones.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%;">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check">
            </div>
            <div class="col-9 text-list">
              <p>E-learning, contexto académico y vida global.</p>
            </div>
          </div>
        </div>

        <footer class="position-button">
          <button class="card-buttons" (click)="openModalInfo()">Quiero más información</button>
        </footer>
      </div>
    </div>
  </div>
</ng-template>

<!--Cards-info-colegios-->
<ng-template #infoColegios>
  <div style="text-align: -webkit-center;">
    <div class="card card-school appear-card">
      <div class="card-body">
        <div style="margin-bottom: 5%;">
          <span class="footer-images">Para colegios</span>
        </div>

        <div class="row card-detail">
          <div class="row">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check">
            </div>
            <div class="col-9 text-list">
              <p>Medición y mejora de competencias internacionales.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%;">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check">
            </div>
            <div class="col-9 text-list">
              <p>Acceso a aplicación a 100 de las principales universidades.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%;">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check">
            </div>
            <div class="col-9 text-list">
              <p>Herramienta de administración de aplicaciones.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%;">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check">
            </div>
            <div class="col-9 text-list">
              <p>Acompañamiento en las aplicaciones de la mano de expertos.</p>
            </div>
          </div>

          <div class="row" style="margin-top: -3%;">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check">
            </div>
            <div class="col-9 text-list">
              <p>E-learning, contexto académico y vida global.</p>
            </div>
          </div>
        </div>

        <footer class="position-button">
          <button class="card-buttons" (click)="openModalInfo()">Quiero más información</button>
        </footer>
      </div>
    </div>
  </div>
</ng-template >


<!--Cards-info-universidades-->
<ng-template #infoUniversidades>
  <div style="text-align: -webkit-center;">
    <div class="card card-college appear-card">
      <div class="card-body ">
        <div style="margin-bottom: 5%;">
          <span class="footer-images">Para universidades</span>
        </div>

        <div class="row card-detail">

          <div class="row">
            <div class="col-2 container-list-check">
              <img class="list-check" src="/assets/utils/check-circular-azul.png" alt="check">
            </div>
            <div class="col-9 text-list">
              <p>Llega a cientos de miles de estudiantes latinoamericanos con información calificada
                sobre tu institución.</p>
            </div>
          </div>

        </div>

        <footer class="position-button">
          <button class="card-buttons" (click)="openModalInfo()">Quiero más información</button>
        </footer>
      </div>
    </div>
  </div>
</ng-template>
