import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso6',
  templateUrl: './paso6.component.html',
  styleUrls: ['./paso6.component.scss']
})
export class Paso6Component implements OnInit {
  public rondaPreguntas: any[]=[];
  @Output() notificacionTab7 = new EventEmitter<string>();
  public preguntas_seccion07:any;
  public dataFrm07:any;
  public dataFrm07Copy:any;
  responseUser07: any;
  readonly getTest07 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest07 = 'api/get_internalizacionUser/';
   pasoActivo: any;
  constructor(private apiService:ApiService,) {
    this.responseUser07 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.responseUser07) {
      this.apiService.get({ api: this.getTest07 + this.responseUser07._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //  
            let res = response;
            let data_test03
            if(res.test[0].preguntas){
              data_test03=res.test[0];
            }else{
              data_test03=JSON.parse(res.test[0]);
            }
            this.dataFrm07 = response;
            this.pasoActivo= response.hojaTest;
            this.dataFrm07.test = JSON.parse(this.dataFrm07.test);
            this.preguntas_seccion07= data_test03.preguntas[6];
             

           let i = 0
            for(let preg of  this.preguntas_seccion07.preguntas){
               
               

              this.rondaPreguntas[i].opcionSeccionada = preg.opcionSeccionada
              i++
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    }
   }

  ngOnInit(): void {
    this.rondaPreguntas=[
      {pregunta:"¿Tienes promedio académico por encima del 80% en los últimos tres años?",opcionSeccionada:''},
      {pregunta:"¿Has trabajado durante tus vacaciones?",opcionSeccionada:''},
      {pregunta:"¿Perteneces a grupos de Investigación?",opcionSeccionada:''},
      {pregunta:"¿Has sacado adelante un plan de negocio o creado algún emprendimiento?",opcionSeccionada:''},
      {pregunta:"¿Tienes un portafolio con logros o muestras de tu trabajo artístico? (Responde esta pregunta únicamente si eres artista o te interesa esta área)",opcionSeccionada:''},
    ]
  }

  getCheckBox(event, pos,seleccion){
    let id = event.target.id;
    let disableId = event.target.id;
    let checkBoxPressed = document.getElementById(id) as HTMLInputElement;
     
    this.preguntas_seccion07.preguntas[pos].opcionSeccionada=seleccion;
    if(checkBoxPressed.checked){
      if(id.includes('value1')){
        disableId = id.replace('value1', 'value2');
      }
      else if(id.includes('value2')){
        disableId = id.replace('value2', 'value1');
        
      }
      // 
      let checkBoxDisable = document.getElementById(disableId);
      checkBoxDisable.setAttribute('disabled', new Boolean(true).toString());
    }
    else{
      if(id.includes('value1')){
        disableId = id.replace('value1', 'value2');
      }
      else if(id.includes('value2')){
        disableId = id.replace('value2', 'value1');
      }
      let checkBoxDisable = document.getElementById(disableId);
      checkBoxPressed.removeAttribute('disabled');
      checkBoxDisable.removeAttribute('disabled');
    }
   
  }
  public continuarTab8(){
    let paso = this.pasoActivo
    if(this.pasoActivo < 8){
      paso = this.pasoActivo = 8;
    }
    this.dataFrm07.hojaTest= paso
    this.dataFrm07.test.preguntas[6] = this.preguntas_seccion07;
     
    this.dataFrm07.test = JSON.stringify(this.dataFrm07.test );
     this.actualizarPaso6();
  }
  public actualizarPaso6(){
    let data = this.dataFrm07;
    this.apiService
    .put({ api: this.updateFrmTest07 + this.dataFrm07._id, data })
    .subscribe(
      (response) => {
         
        this.notificacionTab7.emit("continuar");
      },
      (err) => {
        
      }
    );
  }
  public volverTab5(){
    this.notificacionTab7.emit("volver")
  }
}
