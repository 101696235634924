<div class="row">
    <div class="col-12">
        <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
        <h4 class="titulo-wizard">Hablemos de Global Mind</h4>
        <h5>Pregunta 15 / 16</h5>
        <p>¿En cuál de los siguientes países te gustaría estudiar?</p>
        <hr class="hrLine" />
    </div>
  </div>
  <div class="row marginBot">
    <div class="col-12">
        <div class="row align-items-stretch">
            <div class="col-4" *ngFor="let pais of paises;let i = index">
                <div class="row">
                    <div class="col-12">
                        <button class="inputPorcentaje" (click) = "changeColor(i)" [ngClass]="{
                            'cardActivaPais': pais.seleccionado,
                            'marginTop': i >= 3
                        }
                            ">{{pais.pais}}</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="row marginBot">
    <div class="col-12">
        <div class="row">
            <div class="col-4">
                <div class="row">
                    <div class="col-12">¿Otro?</div>
                    <div class="col-12">
                        <input type="text" class="inputPorcentaje" [(ngModel)]="otro" placeholder="Escribe tu respuesta" />
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <!-- <div class="row">
    <div class="col-12 div-flex">
        <img src = "/assets/wizard-images/568784-200.png" class = "toggle-button" alt="Match Cultural">
        <p class="color"  class = "width-flex"> <label class="negrilla"> Quiero que me sugieran. (Match Cultural).</label></p>
    </div>
  </div> -->
  <div class="row">
    <div class="col-12 div-flex">
        <label class="switch">
            <input type="checkbox">
            <span class="slider round"></span>
        </label>
        <p class="color"  class = "width-flex"> <label class="negrilla"> Quiero que me sugieran. (Match Cultural).</label></p>
    </div>
</div>

  <div class="row">
    <div class="col-12 text-center">
        <em class="fas fa-solid fa-angle-left brnVolver" (click)="volverTab14()" ></em>
        <button class="btn-continuar" (click)="continuarTab16()">Continuar</button>
    </div>
  </div>
