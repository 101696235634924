import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-paso16',
  templateUrl: './paso16.component.html',
  styleUrls: ['./paso16.component.scss'],
})
export class Paso16Component implements OnInit {
  public preguntas: any[] = [];
  @Output() notificacionTab8 = new EventEmitter<string>();
  public preguntas_seccion08: any;
  public dataFrm08: any;
  public dataFrm08Copy: any;
  public userData15: any;
  readonly getTestPaso15 = 'api/test_internalizacionUserSemestre/';
  readonly updateFrmTest15 = 'api/get_internalizacionUser/';
  pasoActivo: any;
  public sumItems: number = 0;

  constructor(private apiService: ApiService) {
    this.userData15 = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.userData15) {
      this.apiService.get({ api: this.getTestPaso15 + this.userData15._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            //  
            this.dataFrm08 = response;
            let res = response;
            let data_test;
            if (res.test[0].preguntas) {
              data_test = res.test[0];
            } else {
              data_test = JSON.parse(res.test[0]);
            }
            this.pasoActivo = response.hojaTest;
            this.dataFrm08.test = data_test;
            //  
            this.preguntas_seccion08 = data_test.preguntas[7];
            // console.log('preguntas seccion logro y accion',this.preguntas_seccion08)
            let i = 0;
            for (let item of this.preguntas_seccion08.preguntas) {
              //  
              this.preguntas[i].opcionSeccionada = item.opcionSeccionada;
              i++;
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Al buscar el test',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    }
  }

  ngOnInit(): void {
    this.preguntas = [
      {
        pregunta: 'Soy persona autoexigente, que se impone estándares altos de desempeño constantemente.',
        opcionSeccionada: 0,
      },
      {
        pregunta: 'Soy una persona competitiva que se interesa por alcanzar metas desafiantes con retos exigentes.',
        opcionSeccionada: 0,
      },
      {
        pregunta: '¿He emprendido y mantenido acciones que me han ayudado a desarrollar mis habilidades y competencias en las áreas que me interesa?.',
        opcionSeccionada: 0,
      },
      {
        pregunta: '¿Puedo continuar voluntariamente con acciones, procesos o proyectos a pesar de la presencia de obstáculos, dificultades o del agotamiento?.',
        opcionSeccionada: 0,
      },
      {
        pregunta: 'Considero fácil implementar hábitos positivos en mi vida. (Y tengo suficientes).',
        opcionSeccionada: 0,
      },
      {
        pregunta:
          'Entrego mis trabajos a tiempo, y mis notas suelen estar por encima del promedio debido al orden y la calidad.',
        opcionSeccionada: 0,
      },
      {
        pregunta:
          'En los últimos 6 meses he participado todas las semanas en una actividad extracurricular de mi preferencia.',
        opcionSeccionada: 0,
      },
      {
        pregunta: 'Has trabajado o colaborado en algún negocio de manera dedicada en tus vacaciones.',
        opcionSeccionada: 0,
      },
    ];
  }
  public continuarTab8() {
    let resetCalculo = this.preguntas_seccion08.habilidades;
    for (let calculos of resetCalculo) {
      calculos.puntaje = 0;
    }
    this.preguntas_seccion08.habilidades = resetCalculo;
     
    for (let preg of this.preguntas_seccion08.preguntas) {
      let puntos;
       
      if (parseInt(preg.opcionSeccionada) > 0) {
        this.sumItems = this.sumItems + 1;
      }
       
       
      if (preg.invertida) {
         
        if (preg.opcionSeccionada == '1') {
          puntos = '5';
        }
        if (preg.opcionSeccionada == '2') {
          puntos = '4';
        }
        if (preg.opcionSeccionada == '3') {
          puntos = '3';
        }
        if (preg.opcionSeccionada == '4') {
          puntos = '2';
        }
        if (preg.opcionSeccionada == '5') {
          puntos = '1';
        }
      } else {
        puntos = preg.opcionSeccionada;
      }
       
      for (let afeccion of preg.afecciones) {
         
        let index;
        const found = this.preguntas_seccion08.habilidades.find((element) => element.nombre === afeccion.habilidad);
         
        if (found) {
          let resultadoOperacion = found.puntaje + parseFloat(puntos);
          found.puntaje = resultadoOperacion;
        }
      }
       
    }
    let paso = this.pasoActivo;
    if (this.pasoActivo < 7) {
      paso = this.pasoActivo = 7;
    }
    this.dataFrm08.hojaTest = paso;
     
    
     

    if (this.sumItems >= 8) {
      this.dataFrm08.test.preguntas[7] = this.preguntas_seccion08;
      let dataTest = JSON.stringify(this.dataFrm08.test);
      this.dataFrm08.test = dataTest
      this.actualizarPaso15();
    } else {
      Swal.fire({
        icon: 'error',
        title: 'Error',
        text: 'Debe responder todas las preguntas',
      });
      this.sumItems=0
      return false;
    }
  }
  actualizarPaso15() {
    let data = this.dataFrm08;
    this.apiService.put({ api: this.updateFrmTest15 + this.dataFrm08._id, data }).subscribe(
      (response) => {
         
        this.notificacionTab8.emit('continuar');
      },
      (err) => {
         
      }
    );
  }
  public volverTab6() {
    this.notificacionTab8.emit('volver');
  }
  radioChange(event, pos) {
    //  
    //  
    //  
    this.preguntas_seccion08.preguntas[pos].opcionSeccionada = event.target.value;
  }
}
