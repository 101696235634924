import {Component, OnDestroy, OnInit} from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { ApiService } from 'src/app/public/services/api.service';
import Swal from 'sweetalert2';
import { AuthService } from '../../../services/auth.service';
import {NavigationService} from '../../../services/navigation.service';

@Component({
  selector: 'app-paso1',
  templateUrl: './paso1.component.html',
  styleUrls: ['./paso1.component.scss']
})
export class Paso1Component implements OnInit, OnDestroy {
  public cardSeleccionada: string = '';
  public pasoActivo: number = 1;
  public scrollBar: number = 5.88;
  public progreso: any;
  navStyle = 'width:25%;';
  public preguntas_seccion01:any;
  public dataFrm:any;
  user: any;
  readonly testUser = 'api/test_internalizacionUser/';
  readonly updateFrm = 'api/get_internalizacionUser/';
  readonly getColegio= 'api/colegio/'
  readonly TESTGET = 'api/test_internalizacionUserSemestre/';

  dataTest01: any;
  semestreActivo:{ type: Number, required: false, default:0 }

  constructor(
    private authService:AuthService,
    private apiService:ApiService,
    private navigationService: NavigationService
  ) {
    this.user = JSON.parse(localStorage.getItem('user'));
    let semestreAct=   this.apiService.getSemestre();
    if (this.user) {
      this.apiService.get({ api: this.TESTGET + this.user._id+'/'+semestreAct }).subscribe(
        (response) => {
          if (response) {
            this.semestreActivo=response.semestreActivo
            if(response.hojaTest == 0 || response.hojaTest == 1){
              this.pasoActivo = 1;
              this.progreso ='width:'+this.scrollBar+'%;';
              let data_test= JSON.parse(response.test[0]);
              data_test.semestreActivo=this.semestreActivo
              this.dataFrm = response;
              this.dataFrm.test = data_test;
              this.preguntas_seccion01=  this.dataFrm.test.preguntas[0];
              for(let items of this.preguntas_seccion01.preguntas[0].opciones){
                //  
                if(items.seleccionada){
                  //  
                  this.cardSeleccionada =items.titulo
                }
              }
              //  
            }else{
              let progressAct = this.scrollBar*response.hojaTest;
              this.pasoActivo = response.hojaTest;
              this.progreso ='width:'+progressAct+'%;';
            }
          } else {
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: 'Por favor vuelve a intentarlo',
            });
            return false;
          }
        },
        (err) => {
          //Caso error de plataforma
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      );
    }
   }

  ngOnDestroy(): void {
    this.navigationService.setIsVisibleSidebar(true);
  }

  ngOnInit(): void {
    this.navigationService.setIsVisibleSidebar(false);
  }

  verificarSemestre(colegio,user ){
    this.apiService.get({ api: this.getColegio + colegio }).subscribe(
      (response) => {
        if(response){
          this.obtenerTest(user, response.semestreActivo)
        }else{
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Por favor vuelve a intentarlo',
          });
          return false;
        }
      },
      (err) => {
        //Caso error de plataforma
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor vuelve a intentarlo',
        });
        return false;
      }
    );
  }
  obtenerTest(user, semestre ){
    //  
    this.apiService.get({ api: this.TESTGET + user +'/'+ semestre}).subscribe(
      (response) => {
         if (response) {
          //  
          if(response.hojaTest == 0 || response.hojaTest == 1){
            this.pasoActivo = 1;
            this.progreso ='width:'+this.scrollBar+'%;';
            let data_test= JSON.parse(response.test[0]);
            this.dataFrm = response;
            this.dataFrm.test = data_test;
            this.preguntas_seccion01=  this.dataFrm.test.preguntas[0];
            for(let items of this.preguntas_seccion01.preguntas[0].opciones){
              if(items.seleccionada){
                this.cardSeleccionada =items.titulo
              }
            }
          }else{
            let progressAct = this.scrollBar*response.hojaTest;
            this.pasoActivo = response.hojaTest;
            this.progreso ='width:'+progressAct+'%;';
          }
        } else {
          Swal.fire({
            icon: 'error',
            title: 'Error',
            text: 'Al buscar el test',
          });
          return false;
        }
      },
      (err) => {
        //Caso error de plataforma
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Por favor vuelve a intentarlo',
        });
        return false;
      }
    );
  }
  continuarFrm(){
    if(this.pasoActivo <= 18){
      this.scrollBar=this.scrollBar + 5.88;
      this.progreso ='width:'+this.scrollBar+'%';
      this.dataFrm.test.pasoActivo=this.pasoActivo;
      this.dataFrm.test.preguntas[0]=this.preguntas_seccion01;
      this.actualizarPaso(this.dataFrm)
    }
  }
  actualizarPaso(dataFrm){
    let hoja = this.dataFrm.hojaTest
    if(this.dataFrm.hojaTest < this.pasoActivo){
      hoja = this.pasoActivo
    }
    const data = {
      hojaTest:hoja,
      test:JSON.stringify(dataFrm.test)
    };
    this.apiService
    .put({ api: this.updateFrm + dataFrm._id, data })
    .subscribe(
      (response) => {
        this.pasoActivo++;
      },
      (err) => {
        
      }
    );
  }
  seleccionarCard(seleccion){
    for(let items of this.preguntas_seccion01.preguntas[0].opciones){
      if(items.titulo == seleccion){
        items.seleccionada = true
      }else{
        items.seleccionada = false
      }
    }
    this.cardSeleccionada=seleccion;
  }
  volverFrm(){
    if(this.pasoActivo != 1){
      this.pasoActivo--;
      this.scrollBar=this.scrollBar - 5.88;
      let rest = (this.pasoActivo*100)/17
      this.progreso ='width:'+rest+'%';
    }
  }
  ejecutarMetodo(mensaje){
    if(mensaje == 'continuar'){
      if(this.pasoActivo <= 19){
        this.scrollBar=this.scrollBar + 5.88;
        let newParam = this.pasoActivo * this.pasoActivo
        this.progreso ='width:'+newParam+'%';
        this.pasoActivo++;
      }
    }else{
      this.volverFrm();
    }
  }
}
