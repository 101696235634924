<div class="row">
    <div class="col-12">
        <em class="fas fa-light fa-times cerrar" routerLink="/leaderTest"></em>
        <h4 class="titulo-wizard">Hablemos de Competencia Académica</h4>
        <h5>Pregunta 10 / 16</h5>
        <p>Ingresa el aproximado del promedio de tus notas de los últimos 3 años en escala de 0% a 100%.</p>
        <hr class="hrLine" />
        <p class = "info">¡No te preocupes! Aunque las calificaciones son importantes para muchas instituciones, también tendrán en cuenta otros factores cómo tus competencias o cursos preuniversitarios.</p>
    </div>
  </div>
  <div class="row marginBot">
    <div class="col-12">
        <div class="row">
            <div class="col-1">

            </div>
            <div class="col-2">
                <div class="row">
                    <div class="col-12">
                        <h6>Notas 9°</h6>
                    </div>
                    <div class="col-12">
                        <input
                        [(ngModel)]="nota9" [ngModelOptions]="{standalone: true}"
                        class="form-input inputPorcentaje"
                        (keyup)="onKeyUp()"
                        type="number"
                        min="0"
                        placeholder="0%"/>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row">
                    <div class="col-12">
                        <h6>Notas 10°</h6>
                    </div>
                    <div class="col-12">
                        <input
                        [(ngModel)]="nota10" [ngModelOptions]="{standalone: true}"
                        class="form-input inputPorcentaje"
                        type="number"
                        min="0"
                        (keyup)="onKeyUp()"
                        placeholder="0%"/>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row">
                    <div class="col-12">
                        <h6>Notas 11°</h6>
                    </div>
                    <div class="col-12">
                        <input
                        (keyup)="onKeyUp()"
                        [(ngModel)]="nota11" [ngModelOptions]="{standalone: true}"
                        class="form-input inputPorcentaje"
                        type="number"
                        min="0"
                        placeholder="0%"/>
                    </div>
                </div>
            </div>
            <div class="col-2">
                <div class="row">
                    <div class="col-12">
                        <h6>Notas 12°</h6>
                    </div>
                    <div class="col-12">
                        <input
                        [(ngModel)]="nota12" [ngModelOptions]="{standalone: true}"
                        class="form-input inputPorcentaje"
                        type="number"
                        min="0"
                        (keyup)="onKeyUp()"
                        placeholder="0%"/>
                    </div>
                </div>
            </div>
            <div class="col-3">
                <div class="row">
                    <div class="col-12">
                        <h6 class="color">Tu promedio</h6>
                    </div>
                    <div class="col-12">
                        <input
                       [(ngModel)]="notaPromedio" [ngModelOptions]="{standalone: true}"
                       readonly
                        class="form-input inputPorcentaje marginSeleccion"
                        type="number"
                        placeholder="0%"/>
                    </div>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div class="row">
    <div class="col-1">

    </div>
    <div class="col-12 div-flex">
        <label class="switch">
            <input type="checkbox" (click)="continuarTab12()">
            <span class="slider round"></span>
        </label>
        <p class = "width-flex">Si estás en bachillerato (10, 11 o 12) o ya terminaste y quieres que un asesor evalué sin costo las mejores opciones universitarias para ti y opciones de becas activa esta opción.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12">
        <p class="color info">Si en esté momento no sabes tus notas puedes saltarte la pregunta.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-12 text-center">
        <em class="fas fa-solid fa-angle-left brnVolver" (click)="volverTab10()" ></em>
        <button class="btn-continuar" (click)="continuarTab12()">Continuar</button>
    </div>
  </div>