<div id="boxBaner" class="profile-section d-flex flex-column"> <!-- Sección de foto del perfil -->
    <img [src]="fotoBaner" alt="Foto de baner" class="banner-image" for="bannerImage">
    <div class="div-principal">
        <div id="boxPerfil" class="d-flex justify-content-start div-img-profile">
          <img [src]="fotoPerfil" alt="Foto de perfil" class="profile-photo">
          <div class="d-flex align-items-center justify-content-center div-profile-photo">
              <label for="fotoImagen" class="mb-0">
                <input class="input-file" style="display: none" type="file" id="fotoImagen" (change)="onChangeProfileImage({event:$event})">
                <img src="../../../../../assets/curriculum/agregar-foto.png" alt="" class="img-edit-profile">
              </label>
          </div>
        </div>
        <div class="d-flex align-items-center h-100 text-center change-banner">
        <div class="w-100 change-banner-option">
            <label class="title-banner display-mobile-none">Cambiar foto de portada</label>
            <div class="d-flex justify-content-center">
                <label for="bannerImage">
                  <input class="input-file" style="display: none"  type="file" id="bannerImage" (change)="uploadBannerImage({event:$event})">
                  <img src="../../../../../assets/curriculum/agregar-foto.png" alt="" class="img-edit-banner">
                </label>
            </div>
        </div>
        </div>
    </div>
    <div class="link-container">
      <label class="txt mr-2 mb-0" for="copyLink"><b>Link:</b></label>
      <input type="text" id="copyLink" class="blue-border" [(ngModel)]="linked">
    </div>
</div>
<form [formGroup]="editar" class="edit-form">
    <div class="w-100">
        <div class="mt-5">
            <label class="title">Mi nombre</label>
            <div>
                <input type="text" class="name blue-border" formControlName="nombre" disabled>
            </div>
        </div>

        <div class="mt-3 mt-lx-5">
            <label class="title">Mi información</label>
            <div class="">
                <div class="d-flex flex-column flex-lg-row justify-content-center align-items-lg-center mb-3">
                    <label class="txt w-15 mb-0"><b>Colegio:</b></label>
                    <input type="text" class="blue-border" formControlName="colegio">
                </div>
                <div class="d-flex flex-column flex-lg-row justify-content-center align-items-lg-center mb-3">
                    <label class="txt w-15 mb-0"><b>Proyecto:</b></label>
                    <input type="text" class="blue-border" formControlName="proyecto">
                </div>
                <div class="d-flex flex-column flex-lg-row justify-content-center align-items-lg-center mb-3">
                    <label class="txt w-15 mb-0"><b>Palabras clave:</b></label>
                    <input type="text" class="blue-border" formControlName="palabrasClave">
                </div>
            </div>
        </div>

        <div class="mt-3 mt-lx-5">
            <label class="title">Sobre mi</label>
            <div>
                <textarea class="blue-border txt" cols="90" rows="7" formControlName="sobreMi"></textarea>
            </div>
        </div>

        <div class="mt-3 mt-lx-5">
            <label class="title">Idiomas</label>
            <div>
                <label class="txt">Agrega máximo 4 Idiomas:</label>
            </div>

            <div class="d-flex flex-column flex-lg-row">
                <div class="d-flex justify-content-between w-40">
                    <div class="w-45">
                        <label class="txt"><b>Idioma</b></label>
                        <div class="mb-2">
                            <input class="label-border w-100" formControlName="idioma1" type="text">
                        </div>
                        <div class="mb-2">
                            <input type="text" class="label-border w-100" formControlName="idioma2">
                        </div>
                    </div>
                    <div class="w-45">
                        <label class="txt"><b>Nivel</b></label>
                        <div class="mb-2">
                            <select class="label-border w-100 h" formControlName="idioma1Nivel">
                                <option value="Seleccionar">Seleccionar</option>
                                <option value="A1">A1</option>
                                <option value="A2">A2</option>
                                <option value="B1">B1</option>
                                <option value="B2">B2</option>
                                <option value="c1">c1</option>
                                <option value="Nativo">Nativo</option>
                            </select>
                        </div>
                        <div class="mb-2">
                            <select class="label-border w-100" formControlName="idioma2Nivel">
                                <option value="Seleccionar">Seleccionar</option>
                                <option value="A1">A1</option>
                                <option value="A2">A2</option>
                                <option value="B1">B1</option>
                                <option value="B2">B2</option>
                                <option value="c1">c1</option>
                                <option value="Nativo">Nativo</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="d-flex justify-content-between w-40 ml-xl-5">
                    <div class="w-45">
                        <label class="txt"><b>Idioma</b></label>
                        <div class="mb-2">
                            <input class="label-border w-100" type="text" formControlName="idioma3">
                        </div>
                        <div class="mb-2">
                            <input class="label-border w-100" type="text" formControlName="idioma4">
                        </div>
                    </div>
                    <div class="w-45">
                        <label class="txt"><b>Nivel</b></label>
                        <div class="mb-2">
                            <select class="label-border w-100" formControlName="idioma3Nivel">
                                <option value="Seleccionar">Seleccionar</option>
                                <option value="A1">A1</option>
                                <option value="A2">A2</option>
                                <option value="B1">B1</option>
                                <option value="B2">B2</option>
                                <option value="c1">c1</option>
                                <option value="Nativo">Nativo</option>
                            </select>
                        </div>
                        <div class="mb-2">
                            <select class="label-border w-100" formControlName="idioma4Nivel">
                                <option value="Seleccionar">Seleccionar</option>
                                <option value="A1">A1</option>
                                <option value="A2">A2</option>
                                <option value="B1">B1</option>
                                <option value="B2">B2</option>
                                <option value="c1">c1</option>
                                <option value="Nativo">Nativo</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="mt-3 mt-lx-5">
            <label class="title">Áreas de interés:</label>
            <div>
                <label class="txt">Seleccione máximo 5 intereses:</label>
            </div>
            <div class="d-flex flex-wrap">
                <label class="label-border" *ngFor="let area of areas; index as i"
                (click)="paisAreaInteres('area',area.nombre,i)" [ngClass]="{'btn-background': area.selected}">
                    {{area.nombre}}
                </label>
                <!-- <label class="label-border btn-background">Salud</label>
                <label class="label-border btn-background">Deporte</label>
                <label class="label-border btn-background">Ciencia</label>
                <label class="label-border btn-background">Desarrollo</label>
                <label class="label-border btn-background">Idiomas</label>
                <label class="label-border">Economía</label>
                <label class="label-border">Trabajo social</label> -->
            </div>

            <!-- <div class="d-flex" >
                <label class="label-border" *ngFor="let area of areas;index as i">{{area.nombre}}</label>
            </div> -->
        </div>

        <div class="mt-3 mt-lx-5">
            <label class="title">Países de interés:</label>
            <div>
                <label class="txt">Seleccione máximo 5 países:</label>
            </div>
            <div class="d-flex flex-wrap">
                <label class="label-border" *ngFor="let pais of paises; index as i"
                (click)="paisAreaInteres('pais',pais.nombre,i)" [ngClass]="{'btn-background': pais.selected}">
                    {{pais.nombre}}
                </label>
                <!-- <label class="label-border btn-background">Estados Unidos</label>
                <label class="label-border btn-background">Canadá</label>
                <label class="label-border btn-background">Alemania</label>
                <label class="label-border btn-background">Brasil</label>
                <label class="label-border btn-background">Francia</label>
                <label class="label-border">Australia</label>
                <label class="label-border">Reino Unido</label>
                <label class="label-border">España</label> -->
            </div>
        </div>

        <div class="mt-3 mt-lx-5" formArrayName="universities">
            <label class="title">Universidades de interes:</label>
            <div>
                <label class="txt">Seleccione máximo 5 universidades:</label>
            </div>
            <div>
                <ng-multiselect-dropdown class="advanced-filter-select" placeholder="Seleccionar universidad"
                    [settings]="optionsUniversities" [data]="dataUniversidades" [(ngModel)]="universitiesSelected"
                    (onSelect)="onSelect($event)" (onDeSelect)="onDeSelect($event)" [ngModelOptions]="{standalone: true}">
                </ng-multiselect-dropdown>
            </div>
        </div>

        <div class="mt-3 mt-lx-5" formArrayName="logros">
            <label class="title">Logros en intereses:</label>
            <div class="d-flex justify-content-center align-items-start align-items-lg-center flex-column flex-lg-row mb-3" *ngFor="let logro of logros.controls;index as i" [formGroupName]="i">
                <label class="txt w-15 mb-1 mb-lg-0 mr-lg-2"><b>Descripcion:</b></label>
                <input type="text" class="blue-border" formControlName="descripcion" [id]="'desc' + i">
                <button type="button" (click)="removeLogros({index:i})"  class="delete-button">Borrar logro</button>
            </div>
            <button type="button" (click)="addLogros()" class="add-logro">Agregar logro</button>
        </div>

        <div class="mt-3 mt-lx-5" formArrayName="referencias">
            <label class="title">Referencias:</label>
            <div class="justify-content-center mb-3" *ngFor="let referencia of referencias.controls;index as i" [formGroupName]="i">
                <div>
                    <label class="txt w-15"><b>Nombre:</b></label>
                    <input type="text" class="blue-border" formControlName="nombre" [id]="'nombre' + i">
                </div>
                <div>
                    <label class="txt w-15 mt-2"><b>Cargo:</b></label>
                    <input type="text" class="blue-border" formControlName="cargo" [id]="'cargo' + i">
                </div>
                <div>
                    <label class="txt w-15 mt-2"><b>Nota:</b></label>
                    <input type="text" class="blue-border" formControlName="nota" [id]="'nota' + i">
                </div>
                <div>
                    <button type="button" (click)="removeReferencia({index:i})"  class="delete-button mt-3">Borrar Referencia</button>
                </div>
            </div>
            <button type="button" (click)="addReferencia()" class="add-logro">Agregar referencia</button>
        </div>

        <!-- <div class="mt-5">
            <label class="title">Notas de referencia</label>
            <div>
                <div class="d-flex justify-content-between w-100">
                    <label class="txt"><b>Referencia #1</b></label>
                    <img src="../../../../../assets/curriculum/eliminar.png" alt="" (click)="eliminarReferencia1" class="icon-delete">
                </div>
                <div class="mt-3">
                    <label class="txt"><b>Nombre</b></label>
                    <div>
                        <input class="blue-border" type="text" formControlName="referencia1Nombre">
                    </div>
                </div>
                <div class="mt-3">
                    <label class="txt"><b>Cargo</b></label>
                    <div>
                        <input class="blue-border" type="text" formControlName="referencia1Cargo">
                    </div>
                </div>
                <div class="mt-3">
                    <label class="txt"><b>Nota</b></label>
                    <div>
                        <input class="blue-border" type="text" formControlName="referencia1Nota">
                    </div>
                </div>
            </div>
        </div> -->

        <!-- <div class="mt-5">
            <div>
                <div class="d-flex justify-content-between w-100">
                    <label class="txt"><b>Referencia #2</b></label>
                    <img src="../../../../../assets/curriculum/eliminar.png" alt="" (click)="eliminarReferencia2" class="icon-delete">
                </div>
                <div class="mt-3">
                    <label class="txt"><b>Nombre</b></label>
                    <div>
                        <input class="blue-border" type="text" formControlName="referencia2Nombre">
                    </div>
                </div>
                <div class="mt-3">
                    <label class="txt"><b>Cargo</b></label>
                    <div>
                        <input class="blue-border" type="text" formControlName="referencia2Cargo">
                    </div>
                </div>
                <div class="mt-3">
                    <label class="txt"><b>Nota</b></label>
                    <div>
                        <input class="blue-border" type="text" formControlName="referencia2Nota">
                    </div>
                </div>
            </div>
        </div> -->

        <div class="d-flex justify-content-center mt-2 mb-4">
            <div>
                <button class="button-save h-30" (click)="save()">Guardar cambios</button>
            </div>
            <div>
                <button class="button-cancel h-30" (click)="backToCurriculum()">Cancelar</button>
            </div>
        </div>
    </div>
</form>
