import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  
  /**It's to english test login */
  oculto: string = "ocultar";

  constructor(public authService: AuthService) { }

  ngOnInit(): void {
  }

}
